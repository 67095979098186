import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import UserForm from "./UserForm";

const NewUser = () => {
  const { user } = useUserAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserProfile = async () => {
      try {
        if (!user) {
          setLoading(false);
          return;
        }

        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserInfo(userData);

          if (userData.name && userData.nickName) {
            return <Navigate to="/home" />;
          }
        }
      } catch (error) {
        console.error("Error checking user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    checkUserProfile();
  }, [user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-4 border-gray-300 border-t-gray-900 rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center min-h-screen">
      <div className="w-full max-w-2xl bg-white p-4 md:p-10">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-800 mb-2">
            ¡Bienvenido a Livespeed.mx!
          </h1>
          {userInfo?.photoUrl && (
            <img
              src={userInfo.photoUrl}
              alt={userInfo.name}
              className="w-20 h-20 rounded-full mx-auto mb-2"
            />
          )}

          <p className="text-gray-600">
            Para poder continuar necesitas completar tu perfil
          </p>
        </div>

        <UserForm />
      </div>
    </div>
  );
};

export default NewUser;
