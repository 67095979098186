import React, { useEffect } from 'react';

const GoogleAds = () => {
    useEffect(() => {
        try {
            // Check if window and adsbygoogle exist
            if (window.adsbygoogle) {
                // Push the ad after component mounts
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } else {
                console.log('AdSense not available');
            }
        } catch (error) {
            console.error('Error loading AdSense ads:', error);
        }
    }, []);

    return (
        <div className="google-ad-container">
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7411281699009296"
                data-ad-slot="9320665249"
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
        </div>
    );
};

export default GoogleAds;