import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import {
  ArrowDown,
  ArrowUp,
  ArrowLeftRight,
  Info,
  Loader2,
  Eye,
} from "lucide-react";

const SpeedCoinHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [showAllTransactions, setShowAllTransactions] = useState(false);
  const navigate = useNavigate();

  // Función para crear la consulta base
  const createBaseQuery = (isAll = false) => {
    const baseQuery = collection(db, "coinstransactions");
    const queryConstraints = [orderBy("date", "desc")];

    if (!isAll) {
      queryConstraints.push(limit(5));
    }

    return query(baseQuery, ...queryConstraints);
  };

  // Función para manejar la suscripción a Firestore
  const subscribeToTransactions = (isAll = false) => {
    const transactionsQuery = createBaseQuery(isAll);

    return onSnapshot(
      transactionsQuery,
      (querySnapshot) => {
        const transactionsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTransactions(transactionsData);
        setLoading(false);
        setLoadingMore(false);
        setError(null);
      },
      (error) => {
        console.error("Error al obtener las transacciones:", error);
        setError("Error al obtener las transacciones. Intente nuevamente.");
        setLoading(false);
        setLoadingMore(false);
      }
    );
  };

  // Efecto inicial para cargar las primeras 5 transacciones
  useEffect(() => {
    setLoading(true);
    const unsubscribe = subscribeToTransactions(false);
    return () => unsubscribe();
  }, []);

  // Manejador para cambiar entre mostrar todas o limitadas
  const handleToggleTransactions = () => {
    setLoadingMore(true);
    const newShowAll = !showAllTransactions;
    setShowAllTransactions(newShowAll);

    // Cancelar la suscripción anterior y crear una nueva
    const unsubscribe = subscribeToTransactions(newShowAll);
    return () => unsubscribe();
  };

  const getTransactionIcon = (senderUID, receiverUID) => {
    if (senderUID === "SYSTEM")
      return <ArrowDown className="text-green-500 h-5 w-5" />;
    if (receiverUID === "SYSTEM")
      return <ArrowUp className="text-red-500 h-5 w-5" />;
    return <ArrowLeftRight className="text-blue-500 h-5 w-5" />;
  };

  const getTransactionColor = (senderUID, receiverUID) => {
    if (senderUID === "SYSTEM") return "bg-green-50 hover:bg-green-100";
    if (receiverUID === "SYSTEM") return "bg-red-50 hover:bg-red-100";
    return "bg-blue-50 hover:bg-blue-100";
  };

  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatAmount = (amount) => {
    if (typeof amount === "number") {
      return amount.toFixed(2);
    } else if (typeof amount === "string") {
      return parseFloat(amount).toFixed(2);
    }
    return "0.00";
  };

  const memoizedTransactions = useMemo(() => transactions, [transactions]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 mt-8">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg text-center">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-white rounded-xl shadow-lg p-6 space-y-6">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold text-blue-600">
            Historial Global de Transacciones
          </h2>

          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mx-auto max-w-2xl">
            <div className="flex items-start space-x-3">
              <Info className="h-5 w-5 text-blue-500 mt-1 flex-shrink-0" />
              <div className="text-sm text-blue-700 text-left">
                <p className="font-medium mb-2">
                  Sistema de Transparencia SpeedCoin
                </p>
                <p>
                  Todas las transacciones en nuestro ecosistema SpeedCoin son
                  públicas y verificables. Este enfoque garantiza la máxima
                  transparencia y confianza en nuestro sistema monetario,
                  permitiendo a todos los usuarios verificar el flujo de
                  SpeedCoins en tiempo real.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            onClick={handleToggleTransactions}
            disabled={loadingMore}
            className="inline-flex items-center space-x-2 bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loadingMore ? (
              <Loader2 className="h-5 w-5 animate-spin mr-2" />
            ) : (
              <Eye className="h-5 w-5 mr-2" />
            )}
            <span>
              {showAllTransactions
                ? "Mostrar Menos"
                : "Ver Todas las Transacciones"}
            </span>
          </button>
        </div>

        <div className="divide-y divide-gray-200">
          {memoizedTransactions.map((transaction) => (
            <div
              key={transaction.id}
              className={`p-4 ${getTransactionColor(
                transaction.senderUID,
                transaction.receiverUID
              )} transition-colors duration-200 cursor-pointer rounded-lg my-2`}
              onClick={() => navigate(`/transaction/${transaction.id}`)}
            >
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  {getTransactionIcon(
                    transaction.senderUID,
                    transaction.receiverUID
                  )}
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-semibold ${
                      transaction.senderUID === "SYSTEM"
                        ? "bg-green-100 text-green-700"
                        : "bg-blue-100 text-blue-700"
                    }`}
                  >
                    ${formatAmount(transaction.amount)}
                  </span>
                </div>

                <div className="text-sm text-gray-500">
                  {formatDate(transaction.date.seconds)}
                </div>

                <div className="group relative">
                  <div className="text-sm font-medium text-gray-700">
                    Emisor:{" "}
                    <span className="text-gray-900 break-all">
                      {transaction.senderUID}
                    </span>
                  </div>
                </div>

                <div className="group relative">
                  <div className="text-sm font-medium text-gray-700">
                    Receptor:{" "}
                    <span className="text-gray-900 break-all">
                      {transaction.receiverUID}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center text-sm text-gray-500">
          Mostrando {transactions.length} transacciones
          {!showAllTransactions && transactions.length === 5 && " (últimas 5)"}
        </div>
      </div>
    </div>
  );
};

export default SpeedCoinHistory;
