import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import CategoryStep from "./Stepper/CategoryStep";
import AddonsStep from "./Stepper/AddonsStep";
import SummaryStep from "./Stepper/SummaryStep";
import WarningDialog from "./Stepper/WarningDialog";
import { eventsApi } from "../../lib/client";
import toast from "react-hot-toast";

const PayEvent = () => {
  const { id } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState("category");
  const [event, setEvent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonSizes, setAddonSizes] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nfcWarning, setNfcWarning] = useState(false);
  const [nfcAddon, setNfcAddon] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventDoc, addonsSnapshot, categoriesSnapshot] =
          await Promise.all([
            getDoc(doc(db, "events", id)),
            getDocs(collection(db, "events", id, "addons")),
            getDocs(collection(db, "events", id, "categories")),
          ]);

        if (!eventDoc.exists()) throw new Error("Evento no encontrado");

        const eventData = { id: eventDoc.id, ...eventDoc.data() };
        const firebaseAddons = addonsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          required: doc.data().required || false,
          image: doc.data().image || null,
        }));

        const categoriesData = categoriesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          price: doc.data().price || eventData.price || 0,
        }));

        setEvent(eventData);
        setAddons(firebaseAddons);
        setCategories(categoriesData);

        const pulseraAddon = firebaseAddons.find(
          (addon) => addon.name === "Pulsera"
        );
        if (pulseraAddon) {
          setSelectedAddons([pulseraAddon]);
          setNfcAddon(pulseraAddon);
        }
      } catch (error) {
        setError(error.message || "Error al cargar los datos");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (activeStep === "addons" && nfcAddon) setNfcWarning(true);
  }, [activeStep, nfcAddon]);

  const handleAddonChange = (addon, isChecked) => {
    setSelectedAddons((prev) => {
      const pulseraSelected = prev.some((a) => a.name === "Pulsera");
      if (isChecked) {
        if (addon.name === "Pulsera" && pulseraSelected) {
          toast.error("Solo puedes agregar una pulsera a la orden.");
          return prev;
        }
        if (addon.name === "Pulsera") setNfcWarning(true);
        return [...prev, addon];
      } else {
        return prev.filter((a) => a.id !== addon.id);
      }
    });
    if (!isChecked) {
      const newSizes = { ...addonSizes };
      delete newSizes[addon.id];
      setAddonSizes(newSizes);
    }
  };

  const handleSizeChange = (addonId, size) =>
    setAddonSizes((prev) => ({ ...prev, [addonId]: size }));

  const getCategoryPrice = () => {
    if (!selectedCategory || !event) return 0;
    if (event.customPrice) {
      const selectedCat = categories.find((cat) => cat.id === selectedCategory);
      return selectedCat ? selectedCat.price : 0;
    }
    return event.price || 0;
  };

  const getTotalPrice = () => {
    const basePrice = getCategoryPrice();

    const addonsTotal = selectedAddons.reduce((total, addon) => {
      // Precio base del addon
      let addonPrice = addon.price;

      // Si el addon tiene una variante seleccionada, incluir su precio
      if (addon.selectedVariant && addon.selectedVariant.price) {
        addonPrice += addon.selectedVariant.price;
      }
      // Alternativa: buscar en addonSizes si no hay selectedVariant directa
      else if (addonSizes[addon.id] && addon.variants) {
        // Buscar la opción seleccionada en todas las variantes
        const selectedOption = addon.variants
          .flatMap((variant) => variant.options)
          .find((option) => option.name === addonSizes[addon.id]);

        if (selectedOption && selectedOption.price) {
          addonPrice += selectedOption.price;
        }
      }

      // Aplicar descuento si existe
      const finalPrice = addon.discount
        ? addonPrice - addon.discount
        : addonPrice;

      return total + finalPrice;
    }, 0);

    return basePrice + addonsTotal;
  };

  // En PayEvent.jsx, modifica la función handlePayment:

  const handlePayment = async () => {
    try {
      const totalPrice = getTotalPrice();
      if (totalPrice > 0) {
        const selectedCategoryData = categories.find(
          (cat) => cat.id === selectedCategory
        );
        const orderData = {
          userId: user.uid,
          itemId: event.id,
          categoryId: selectedCategory,
          categoryName: selectedCategoryData?.name,
          categoryPrice: getCategoryPrice(),
          status: "in_process",
          total: totalPrice,
          type: "event",
          customPrice: event.customPrice,
          addons: selectedAddons.map((addon) => {
            // Datos básicos del addon sin incluir todas las variantes
            const addonData = {
              id: addon.id,
              name: addon.name,
              description: addon.description,
              required: addon.required || false,
            };

            // Calcular el precio base del addon
            let addonPrice = addon.price;

            // Si el addon tiene una variante seleccionada guardada directamente
            if (addon.selectedVariant) {
              // Incluir solo la variante seleccionada
              addonData.selectedVariant = {
                name: addon.selectedVariant.name,
                option: addon.selectedVariant.option,
                price: addon.selectedVariant.price || 0,
              };

              // Añadir el precio de la variante al precio total del addon
              addonPrice += addon.selectedVariant.price || 0;
            }
            // Alternativa para buscar la variante si no está directamente en el addon
            else if (addonSizes[addon.id] && addon.variants) {
              // Buscar la variante y opción específica seleccionada
              for (const variant of addon.variants) {
                const selectedOption = variant.options.find(
                  (opt) => opt.name === addonSizes[addon.id]
                );
                if (selectedOption) {
                  // Añadir solo la información de la variante seleccionada
                  addonData.selectedVariant = {
                    name: variant.name,
                    option: addonSizes[addon.id],
                    price: selectedOption.price || 0,
                  };

                  // Añadir el precio de la variante al precio total
                  addonPrice += selectedOption.price || 0;
                  break;
                }
              }
            }

            // Aplicar el descuento si existe
            addonData.price = addon.discount
              ? addonPrice - addon.discount
              : addonPrice;

            // Importante: NO incluir el array de variantes completo
            return addonData;
          }),
          createdAt: serverTimestamp(),
        };

        const orderRef = await addDoc(collection(db, "orders"), orderData);
        navigate(`/checkout/${orderRef.id}`);
      } else {
        await eventsApi.addParticipantToEvent(
          event.id,
          user.uid,
          selectedCategory
        );
        toast.success("Te has unido al evento correctamente");
        setTimeout(() => navigate(`/event/${event.id}`), 1000);
      }
    } catch (error) {
      console.error("Error en handlePayment:", error);
      setError("Error al procesar el pago");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-black border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center p-6">
        <p className="text-lg font-semibold text-red-600 mb-4">{error}</p>
        <button
          onClick={() => navigate(-1)}
          className="bg-black text-white py-2 px-4 rounded"
        >
          Volver
        </button>
      </div>
    );
  }

  const renderStepContent = (step) => {
    switch (step) {
      case "category":
        return (
          <div className="min-h-[600px]">
            <CategoryStep
              categories={categories.map((cat) => ({
                ...cat,
                price: event.customPrice ? cat.price : event.price,
              }))}
              selectedCategory={selectedCategory}
              onCategoryChange={(category) => {
                setSelectedCategory(category);
                setActiveStep("addons");
              }}
            />
          </div>
        );
      case "addons":
        return (
          <AddonsStep
            addons={addons}
            selectedAddons={selectedAddons}
            addonSizes={addonSizes}
            onAddonChange={handleAddonChange}
            onSizeChange={handleSizeChange}
          />
        );
      case "summary":
        return (
          <SummaryStep
            event={event}
            selectedCategory={categories.find((c) => c.id === selectedCategory)}
            selectedAddons={selectedAddons}
            addonSizes={addonSizes}
            totalPrice={getTotalPrice()}
            customPrice={event.customPrice}
          />
        );
      default:
        return null;
    }
  };

  const steps = [
    {
      id: "category",
      title: "Categoría",
      isCompleted: !!selectedCategory,
      isActive: activeStep === "category",
      isDisabled: false,
    },
    {
      id: "addons",
      title: "Add-ons",
      isCompleted: activeStep === "summary",
      isActive: activeStep === "addons",
      isDisabled: !selectedCategory,
    },
    {
      id: "summary",
      title: "Resumen",
      isCompleted: false,
      isActive: activeStep === "summary",
      isDisabled: activeStep !== "summary",
    },
  ];

  return (
    <div className="max-w-6xl mx-auto p-4 md:p-6">
      <h2 className="text-center text-2xl md:text-3xl font-bold mt-4 mb-8">
        {event?.eventName}
      </h2>

      <div className="bg-white shadow-lg rounded-xl">
        <div className="space-y-4">
          {steps.map((step) => (
            <div
              key={step.id}
              className="border-b border-gray-200 last:border-0"
            >
              <button
                onClick={() => !step.isDisabled && setActiveStep(step.id)}
                className={`w-full py-4 px-6 flex items-center justify-between ${
                  step.isDisabled
                    ? "cursor-not-allowed opacity-50"
                    : "hover:bg-gray-50"
                }`}
                disabled={step.isDisabled}
              >
                <div className="flex items-center space-x-4">
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      step.isCompleted
                        ? "bg-green-500 text-white"
                        : step.isActive
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-600"
                    }`}
                  >
                    {step.isCompleted
                      ? "✓"
                      : steps.findIndex((s) => s.id === step.id) + 1}
                  </div>
                  <span className="font-semibold">{step.title}</span>
                </div>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    step.isActive ? "rotate-180" : ""
                  }`}
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>

              <div
                className={`transition-all duration-300 ${
                  step.isActive
                    ? "opacity-100 min-h-[600px] py-4 px-6"
                    : "opacity-0 max-h-0 overflow-hidden"
                }`}
              >
                {renderStepContent(step.id)}

                {step.isActive && (
                  <div className="mt-6 flex justify-between">
                    {step.id !== "category" && (
                      <button
                        onClick={() => {
                          const currentIndex = steps.findIndex(
                            (s) => s.id === step.id
                          );
                          setActiveStep(steps[currentIndex - 1].id);
                        }}
                        className="bg-gray-200 text-black py-2 px-6 rounded-lg hover:bg-gray-300 transition-colors"
                      >
                        Anterior
                      </button>
                    )}
                    {step.id === "summary" ? (
                      <button
                        onClick={handlePayment}
                        className="bg-black text-white py-2 px-6 rounded-lg hover:bg-gray-800 transition-colors ml-auto"
                      >
                        {getTotalPrice() > 0
                          ? `Pagar $${getTotalPrice()}`
                          : "Unirme"}
                      </button>
                    ) : (
                      step.id !== "summary" && (
                        <button
                          onClick={() => {
                            const nextStep =
                              steps[
                                steps.findIndex((s) => s.id === step.id) + 1
                              ];
                            setActiveStep(nextStep.id);
                          }}
                          className="bg-black text-white py-2 px-6 rounded-lg hover:bg-gray-800 transition-colors ml-auto"
                        >
                          Continuar
                        </button>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {activeStep === "addons" && nfcAddon && (
        <WarningDialog
          open={nfcWarning}
          onClose={() => setNfcWarning(false)}
          onAddonChange={handleAddonChange}
          nfcAddon={nfcAddon}
        />
      )}
    </div>
  );
};

export default PayEvent;
