import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Users,
  User,
  Trash2,
  Edit,
  Search,
  X,
  Save,
  Filter,
  ChevronDown,
  BarChart3,
  UserCheck,
  Building2,
  Phone,
} from "lucide-react";

const UsersControl = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [team, setTeam] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);
  const [teamFilter, setTeamFilter] = useState("");
  const [showStats, setShowStats] = useState(false);

  // Statistics data
  const [stats, setStats] = useState({
    totalUsers: 0,
    teamsDistribution: {},
    activeUsers: 0,
  });

  // Extract unique team names for filtering
  const teamOptions = [
    ...new Set(users.filter((user) => user.team).map((user) => user.team)),
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map((doc) => doc.data());
        setUsers(usersData);
        setFilteredUsers(usersData);

        // Calculate statistics
        calculateStatistics(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const calculateStatistics = (userData) => {
    // Count total users
    const totalUsers = userData.length;

    // Calculate team distribution
    const teamsDistribution = userData.reduce((acc, user) => {
      const team = user.team || "Sin equipo";
      acc[team] = (acc[team] || 0) + 1;
      return acc;
    }, {});

    // Count users with complete profiles (name, phone, team)
    const activeUsers = userData.filter(
      (user) => user.name && user.phoneNumber && user.team
    ).length;

    setStats({
      totalUsers,
      teamsDistribution,
      activeUsers,
    });
  };

  useEffect(() => {
    // Apply all filters
    let results = [...users];

    // Search filter
    if (searchTerm) {
      results = results.filter(
        (user) =>
          (user.name &&
            user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.nickName &&
            user.nickName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    // Team filter
    if (teamFilter) {
      results = results.filter((user) => user.team === teamFilter);
    }

    setFilteredUsers(results);
  }, [searchTerm, teamFilter, users]);

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setName(user.name || "");
    setNickName(user.nickName || "");
    setPhoneNumber(user.phoneNumber || "");
    setTeam(user.team || "");
    setOpenEditDialog(true);
  };

  const handleEditConfirm = async () => {
    try {
      const userDocRef = doc(db, "Users", selectedUser.uid);
      await updateDoc(userDocRef, {
        name: name,
        nickName: nickName,
        phoneNumber: phoneNumber,
        team: team,
      });
      const updatedUser = {
        ...selectedUser,
        name: name,
        nickName: nickName,
        phoneNumber: phoneNumber,
        team: team,
      };
      const updatedUsers = users.map((user) =>
        user.uid === updatedUser.uid ? updatedUser : user
      );
      setUsers(updatedUsers);
      calculateStatistics(updatedUsers);

      console.log("User edited successfully:", updatedUser);
      setSelectedUser(null);
      setName("");
      setNickName("");
      setPhoneNumber("");
      setTeam("");
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleEditCancel = () => {
    setSelectedUser(null);
    setName("");
    setNickName("");
    setPhoneNumber("");
    setTeam("");
    setOpenEditDialog(false);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const userDocRef = doc(db, "Users", selectedUser.uid);
      await deleteDoc(userDocRef);
      const updatedUsers = users.filter((u) => u.uid !== selectedUser.uid);
      setUsers(updatedUsers);
      calculateStatistics(updatedUsers);

      console.log("User deleted successfully:", selectedUser);
      setSelectedUser(null);
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedUser(null);
    setOpenDeleteDialog(false);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setTeamFilter("");
  };

  const toggleStats = () => {
    setShowStats(!showStats);
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header */}
      <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex items-center mb-4 sm:mb-0">
          <Users className="h-8 w-8 text-indigo-600 mr-2" />
          <h2 className="text-2xl font-bold text-gray-800">
            Gestión de Usuarios
          </h2>
        </div>
        <button
          onClick={toggleStats}
          className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
        >
          <BarChart3 className="h-5 w-5 mr-2" />
          {showStats ? "Ocultar Estadísticas" : "Ver Estadísticas"}
        </button>
      </div>

      {/* Statistics Section */}
      {showStats && (
        <div className="mb-8 bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold text-gray-700 mb-4 flex items-center">
            <BarChart3 className="h-5 w-5 mr-2 text-indigo-600" />
            Estadísticas
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-indigo-50 p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="text-indigo-600 font-bold text-xl">
                  {stats.totalUsers}
                </div>
                <Users className="h-8 w-8 text-indigo-600" />
              </div>
              <div className="text-sm text-gray-600">Total de usuarios</div>
            </div>
            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="text-green-600 font-bold text-xl">
                  {stats.activeUsers}
                </div>
                <UserCheck className="h-8 w-8 text-green-600" />
              </div>
              <div className="text-sm text-gray-600">Perfiles completos</div>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <div className="flex items-center justify-between">
                <div className="text-blue-600 font-bold text-xl">
                  {Object.keys(stats.teamsDistribution).length}
                </div>
                <Building2 className="h-8 w-8 text-blue-600" />
              </div>
              <div className="text-sm text-gray-600">Equipos</div>
            </div>
          </div>
          <div className="mt-4">
            <h4 className="text-sm font-semibold text-gray-700 mb-2">
              Distribución por equipos
            </h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {Object.entries(stats.teamsDistribution).map(([team, count]) => (
                <div
                  key={team}
                  className="flex justify-between items-center bg-gray-50 p-2 rounded"
                >
                  <span className="font-medium">{team}</span>
                  <span className="text-indigo-600 font-semibold">
                    {count} usuarios
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Search and Filter Section */}
      <div className="mb-6 bg-white rounded-lg shadow p-4">
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="pl-10 w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              placeholder="Buscar por nombre, email o nickname..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setSearchTerm("")}
              >
                <X className="h-5 w-5 text-gray-400 hover:text-gray-600" />
              </button>
            )}
          </div>

          <div className="relative">
            <button
              className="w-full md:w-auto flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <Filter className="h-5 w-5 mr-2 text-gray-500" />
              <span>Filtros</span>
              <ChevronDown
                className={`h-4 w-4 ml-2 text-gray-500 transition-transform ${
                  filterOpen ? "transform rotate-180" : ""
                }`}
              />
            </button>

            {filterOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg z-10 p-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Equipo
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded-lg py-2 px-3"
                    value={teamFilter}
                    onChange={(e) => setTeamFilter(e.target.value)}
                  >
                    <option value="">Todos los equipos</option>
                    {teamOptions.map((team) => (
                      <option key={team} value={team}>
                        {team}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  className="w-full flex items-center justify-center px-4 py-2 bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                  onClick={resetFilters}
                >
                  <X className="h-4 w-4 mr-2" />
                  Reiniciar filtros
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nombre
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nickname
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Teléfono
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Equipo
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <tr key={user.uid} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {user.nickName || "-"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {user.phoneNumber || "-"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {user.team ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                          {user.team}
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Sin equipo
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => handleEditClick(user)}
                          className="inline-flex items-center p-1 border border-transparent rounded-full text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <Edit className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(user)}
                          className="inline-flex items-center p-1 border border-transparent rounded-full text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No se encontraron usuarios con los filtros aplicados
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
          <div className="text-sm text-gray-700">
            Mostrando{" "}
            <span className="font-medium">{filteredUsers.length}</span> de{" "}
            <span className="font-medium">{users.length}</span> usuarios
          </div>
        </div>
      </div>

      {/* Delete Dialog */}
      {openDeleteDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Eliminar Usuario
              </h3>
              <button
                onClick={handleDeleteCancel}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="mb-4">
              <p className="text-gray-700">
                ¿Estás seguro de que quieres eliminar a{" "}
                <span className="font-medium">
                  {selectedUser && selectedUser.name}
                </span>
                ?
              </p>
              <p className="text-sm text-gray-500 mt-2">
                Esta acción no se puede deshacer.
              </p>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={handleDeleteCancel}
                className="px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="px-4 py-2 bg-red-600 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Dialog */}
      {openEditDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Editar Usuario
              </h3>
              <button
                onClick={handleEditCancel}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nombre
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="pl-10 w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Nickname
                </label>
                <input
                  type="text"
                  value={nickName}
                  onChange={(e) => setNickName(e.target.value)}
                  className="w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Teléfono
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="pl-10 w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Equipo
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Building2 className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                    className="pl-10 w-full border border-gray-300 rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  >
                    <option value="">Sin equipo</option>
                    {teamOptions.map((t) => (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    ))}
                    {/* Permitir añadir un equipo que no está en la lista */}
                    {team && !teamOptions.includes(team) && (
                      <option value={team}>{team}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex justify-end space-x-3 mt-6">
              <button
                onClick={handleEditCancel}
                className="px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                onClick={handleEditConfirm}
                className="inline-flex items-center px-4 py-2 bg-indigo-600 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700"
              >
                <Save className="h-4 w-4 mr-2" />
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersControl;
