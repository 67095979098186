import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Calendar,
    Tag,
    Nfc,
    TreeDeciduous,
    Users,
    Camera,
    Map,
    Upload,
    Bike,
    Router,
    Notebook,
    Gift,
    Users2,
    Flag,
    LineChart,
    Settings,
    CircleDollarSign,
    Video,
    Tags as TagsIcon,
    Eye,
    Plus,
    Filter,
    Search,
    ArrowRight,
    ChevronRight
} from "lucide-react";

// Categorías reorganizadas y mejoradas con colores distintivos
export const categoryGroups = [
    {
        groupName: "Principales",
        categories: [
            {
                category: "Eventos",
                shortName: "Eve",
                icon: <Calendar size={24} className="text-blue-500" />,
                description: "Gestión completa de eventos",
                path: "event-list",
                color: "bg-blue-500",
                textColor: "text-blue-500",
                bgColor: "bg-blue-50",
            },
            {
                category: "Finanzas",
                shortName: "Fin",
                icon: <CircleDollarSign size={24} className="text-emerald-500" />,
                description: "Control de pagos y estadísticas",
                path: "ordercontrol",
                color: "bg-emerald-500",
                textColor: "text-emerald-500",
                bgColor: "bg-emerald-50",
            },
            {
                category: "Monitoreo",
                shortName: "Mo",
                icon: <Eye size={24} className="text-indigo-500" />,
                description: "Seguimiento en tiempo real",
                path: "rfidscanner",
                color: "bg-indigo-500",
                textColor: "text-indigo-500",
                bgColor: "bg-indigo-50",
            },
            {
                category: "NFC y Tags",
                shortName: "NFC",
                icon: <TagsIcon size={24} className="text-yellow-500" />,
                description: "Gestión de identificadores",
                path: "tagcontrol",
                color: "bg-yellow-500",
                textColor: "text-yellow-500",
                bgColor: "bg-yellow-50",
            },
        ]
    },
    {
        groupName: "Herramientas",
        categories: [
            {
                category: "Live Stream",
                shortName: "Live",
                icon: <Video size={24} className="text-rose-500" />,
                description: "Transmisión en vivo del evento",
                path: "liveriderselect",
                color: "bg-rose-500",
                textColor: "text-rose-500",
                bgColor: "bg-rose-50",
            },
            {
                category: "GiftCards",
                shortName: "Gift",
                icon: <Gift size={24} className="text-purple-500" />,
                description: "Gestión de tarjetas regalo",
                path: "speedgift",
                color: "bg-purple-500",
                textColor: "text-purple-500",
                bgColor: "bg-purple-50",
            },
            {
                category: "Administración",
                shortName: "Admin",
                icon: <Settings size={24} className="text-slate-500" />,
                description: "Configuración y herramientas",
                path: "signaltest",
                color: "bg-slate-500",
                textColor: "text-slate-500",
                bgColor: "bg-slate-50",
            },
            {
                category: "Otros",
                shortName: "Otros",
                icon: <Plus size={24} className="text-gray-500" />,
                description: "Funciones adicionales",
                path: "/CreatePark",
                color: "bg-gray-500",
                textColor: "text-gray-500",
                bgColor: "bg-gray-50",
            },
        ]
    }
];

// Lista completa de rutas
export const routes = [
    {
        category: "Eventos",
        icon: <Calendar size={24} />,
        color: "bg-blue-500",
        textColor: "text-blue-500",
        borderColor: "border-blue-500",
        bgColor: "bg-blue-50",
        links: [
            { label: "Terminar evento", path: "event-list", icon: <Calendar size={20} /> },
            { label: "Reviews", path: "/reviews", icon: <Router size={20} /> },
            { label: "Participantes", path: "event-list-participants", icon: <Users2 size={20} /> },
            { label: "Filtros de datos", path: "event-data-filterer", icon: <Filter size={20} /> },
            { label: "Añadir usuario", path: "addmanualuser", icon: <Users size={20} /> },
            { label: "Cambiar categoría", path: "changecategory", icon: <Tag size={20} /> },
        ],
    },
    {
        category: "Finanzas",
        icon: <CircleDollarSign size={24} />,
        color: "bg-emerald-500",
        textColor: "text-emerald-500",
        borderColor: "border-emerald-500",
        bgColor: "bg-emerald-50",
        links: [
            { label: "Órdenes", path: "ordercontrol", icon: <Notebook size={20} /> },
            { label: "Estadísticas", path: "orderstats", icon: <LineChart size={20} /> },
        ],
    },
    {
        category: "Monitoreo",
        icon: <Eye size={24} />,
        color: "bg-indigo-500",
        textColor: "text-indigo-500",
        borderColor: "border-indigo-500",
        bgColor: "bg-indigo-50",
        links: [
            { label: "RFID Scanner", path: "rfidscanner", icon: <Search size={20} /> },
            { label: "MapCam", path: "/MapCam", icon: <Map size={20} /> },
            { label: "NfcCam", path: "/NfcCam", icon: <Camera size={20} /> },
            { label: "FinishCam", path: "/FinishCam", icon: <Camera size={20} /> },
            { label: "Ciclistas activos", path: "/activeriders", icon: <Bike size={20} /> },
            { label: "OCR Receiver", path: "ocrreceiver", icon: <Eye size={20} /> },
        ],
    },
    {
        category: "NFC y Tags",
        icon: <TagsIcon size={24} />,
        color: "bg-yellow-500",
        textColor: "text-yellow-500",
        borderColor: "border-yellow-500",
        bgColor: "bg-yellow-50",
        links: [
            { label: "Control de Tags", path: "tagcontrol", icon: <Tag size={20} /> },
            { label: "Mis Tags", path: "/MyTags", icon: <Tag size={20} /> },
            { label: "Inicio NFC", path: "/StartNFC", icon: <Nfc size={20} /> },
            { label: "Final NFC", path: "/finishNFC", icon: <Nfc size={20} /> },
            { label: "Tags de ciclistas", path: "/riderstags", icon: <Tag size={20} /> },
        ],
    },
    {
        category: "Live Stream",
        icon: <Video size={24} />,
        color: "bg-rose-500",
        textColor: "text-rose-500",
        borderColor: "border-rose-500",
        bgColor: "bg-rose-50",
        links: [
            { label: "Selección de ciclista", path: "liveriderselect", icon: <Camera size={20} /> },
        ],
    },
    {
        category: "GiftCards",
        icon: <Gift size={24} />,
        color: "bg-purple-500",
        textColor: "text-purple-500",
        borderColor: "border-purple-500",
        bgColor: "bg-purple-50",
        links: [
            { label: "Control de GiftCards", path: "speedgift", icon: <Gift size={20} /> },
        ],
    },
    {
        category: "Administración",
        icon: <Settings size={24} />,
        color: "bg-slate-500",
        textColor: "text-slate-500",
        borderColor: "border-slate-500",
        bgColor: "bg-slate-50",
        links: [
            { label: "Lector CSV LoRa", path: "signaltest", icon: <Router size={20} /> },
            { label: "Admin de receptor", path: "/ReceiverManager", icon: <Router size={20} /> },
            { label: "Cargador CSV", path: "csv-uploader", icon: <Upload size={20} /> },
            { label: "Tiempos finales", path: "final-times-uploader", icon: <Flag size={20} /> },
            { label: "Control de usuarios", path: "/UsersControl", icon: <Users size={20} /> },
        ],
    },
    {
        category: "Otros",
        icon: <Plus size={24} />,
        color: "bg-gray-500",
        textColor: "text-gray-500",
        borderColor: "border-gray-500",
        bgColor: "bg-gray-50",
        links: [
            { label: "Crear parque", path: "/CreatePark", icon: <TreeDeciduous size={20} /> },
            { label: "Inicio manual", path: "/manualstart", icon: <Calendar size={20} /> },
            { label: "Ciclista en vivo", path: "/ManualLiveRider", icon: <Bike size={20} /> },
            { label: "Cargar tags", path: "/uploadTags", icon: <Upload size={20} /> },
        ],
    },
];

const NewMenu = () => {
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    // Detectar tamaño de pantalla para responsividad
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 640);
        };

        handleResize(); // Comprobar al inicio
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleExpand = (categoryIndex) => {
        setExpandedCategory(expandedCategory === categoryIndex ? null : categoryIndex);
    };

    // Filtrar categorías y enlaces basados en la búsqueda
    const filteredCategoryGroups = categoryGroups.map(group => {
        const filteredCategories = group.categories.filter(category =>
            category.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
            category.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            routes.find(r => r.category === category.category)?.links.some(
                link => link.label.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );

        return {
            ...group,
            categories: filteredCategories
        };
    }).filter(group => group.categories.length > 0);

    // Determinar si mostrar los resultados de búsqueda directamente
    const showDirectSearchResults = searchTerm.length > 0;

    // Obtener todos los enlaces que coinciden con la búsqueda
    const allMatchingLinks = showDirectSearchResults ?
        routes.flatMap(category => {
            const links = category.links.filter(link =>
                link.label.toLowerCase().includes(searchTerm.toLowerCase())
            );
            return links.map(link => ({ ...link, category }));
        }) : [];

    return (
        <div className="w-full bg-white">
            {/* Barra de búsqueda */}
            <div className="sticky top-0 z-10 bg-white p-4 border-b border-gray-200 shadow-sm">
                <div className="relative max-w-lg mx-auto">
                    <input
                        type="text"
                        placeholder="Buscar funciones..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
                    />
                    <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                    {searchTerm && (
                        <button
                            onClick={() => setSearchTerm("")}
                            className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                        >
                            ×
                        </button>
                    )}
                </div>
            </div>

            {/* Contenido principal */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                {showDirectSearchResults && allMatchingLinks.length > 0 ? (
                    // Mostrar resultados de búsqueda como lista
                    <div>
                        <h2 className="text-lg font-medium text-gray-900 mb-4">Resultados de búsqueda</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {allMatchingLinks.map((link, index) => (
                                <Link
                                    key={index}
                                    to={link.path}
                                    className={`flex items-center p-4 rounded-lg ${link.category.bgColor} hover:bg-gray-100 transition-colors`}
                                >
                                    <span className={`flex-shrink-0 ${link.category.textColor}`}>{link.icon}</span>
                                    <span className="ml-3 font-medium text-gray-900">{link.label}</span>
                                    <span className="ml-2 text-xs text-gray-500">({link.category.category})</span>
                                    <ArrowRight className="ml-auto h-4 w-4 text-gray-400" />
                                </Link>
                            ))}
                        </div>
                    </div>
                ) : (
                    // Mostrar categorías normalmente
                    <div className="space-y-8">
                        {filteredCategoryGroups.map((group, groupIndex) => (
                            <div key={groupIndex} className="space-y-4">
                                <h2 className="text-lg font-medium text-gray-900">{group.groupName}</h2>

                                {/* Grid de tarjetas para cada categoría */}
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                    {group.categories.map((category, categoryIndex) => {
                                        const routeIndex = routes.findIndex(r => r.category === category.category);
                                        const categoryData = routes[routeIndex];

                                        return (
                                            <div key={categoryIndex} className="relative">
                                                {/* Tarjeta de categoría */}
                                                <button
                                                    onClick={() => toggleExpand(routeIndex)}
                                                    className={`w-full text-left ${expandedCategory === routeIndex ? `ring-2 ${categoryData.borderColor}` : 'hover:shadow-md'
                                                        } transition-all rounded-lg overflow-hidden`}
                                                >
                                                    <div className={`p-4 ${categoryData.bgColor}`}>
                                                        <div className="flex items-start">
                                                            <div className={`mr-3 ${categoryData.textColor}`}>
                                                                {categoryData.icon}
                                                            </div>
                                                            <div>
                                                                <h3 className="font-medium text-gray-900">{isMobile ? category.shortName : category.category}</h3>
                                                                <p className="text-sm text-gray-500 mt-1 line-clamp-2">{category.description}</p>
                                                            </div>
                                                            <ChevronRight className={`ml-auto h-5 w-5 text-gray-400 transition-transform ${expandedCategory === routeIndex ? 'rotate-90' : ''
                                                                }`} />
                                                        </div>
                                                    </div>
                                                </button>

                                                {/* Panel desplegable con enlaces */}
                                                {expandedCategory === routeIndex && (
                                                    <div className="mt-2 p-2 bg-white rounded-lg shadow-lg border border-gray-200 z-10 absolute w-full">
                                                        <div className="space-y-1">
                                                            {categoryData.links.map((link, linkIndex) => (
                                                                <Link
                                                                    key={linkIndex}
                                                                    to={link.path}
                                                                    className="flex items-center p-2 rounded hover:bg-gray-50 transition-colors"
                                                                >
                                                                    <span className={`${categoryData.textColor} mr-2`}>{link.icon}</span>
                                                                    <span className="text-gray-800 text-sm">{link.label}</span>
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}

                        {/* Enlace para ver todas las funciones */}
                        <div className="text-center pt-4">
                            <Link to="/allfeatures" className="inline-flex items-center text-green-600 hover:text-green-800">
                                Ver todas las funciones
                                <ArrowRight className="ml-1 h-4 w-4" />
                            </Link>
                        </div>
                    </div>
                )}
            </div>

            {/* Footer simple */}
            <div className="border-t border-gray-200 mt-8">
                <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
                    <p className="text-center text-sm text-gray-500">
                        SpeedHub Dashboard v2.0 © {new Date().getFullYear()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NewMenu;