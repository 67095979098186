import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

const CategoryList = ({
  categories,
  subcategories,
  onSelectCategory,
  onSelectSubcategory,
  selectedCategory,
  selectedSubcategory,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="bg-white rounded-2xl shadow-sm p-6 w-full lg:w-1/4">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">
        Shop by Category
      </h2>

      {/* Main Categories */}
      <div className="flex flex-wrap gap-3 mb-6">
        {categories.slice(0, 5).map((category) => (
          <button
            key={category.id}
            onClick={() => onSelectCategory(category)}
            className={`px-4 py-2 rounded-full text-sm font-medium transition-all ${
              selectedCategory.id === category.id
                ? "bg-gray-900 text-white"
                : "bg-gray-100 text-gray-800 hover:bg-gray-200"
            }`}
          >
            {category.name}
          </button>
        ))}

        {/* Dropdown for additional categories */}
        {categories.length > 5 && (
          <div className="relative">
            <button
              className="px-4 py-2 bg-gray-100 text-gray-800 rounded-full text-sm font-medium hover:bg-gray-200 transition-all flex items-center"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              More{" "}
              {isDropdownOpen ? (
                <ChevronUp className="ml-1 w-4 h-4" />
              ) : (
                <ChevronDown className="ml-1 w-4 h-4" />
              )}
            </button>

            {isDropdownOpen && (
              <div className="absolute left-0 right-0 mt-2 bg-white border border-gray-100 rounded-xl shadow-lg max-h-60 overflow-auto z-10">
                {categories.slice(5).map((category) => (
                  <button
                    key={category.id}
                    onClick={() => {
                      onSelectCategory(category);
                      setIsDropdownOpen(false);
                    }}
                    className={`block px-4 py-3 text-sm w-full text-left transition-colors ${
                      selectedCategory.id === category.id
                        ? "bg-gray-100 font-medium"
                        : "text-gray-800 hover:bg-gray-50"
                    }`}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Subcategories */}
      {subcategories.length > 0 && (
        <div>
          <h3 className="text-md font-medium text-gray-900 mb-3">
            {selectedCategory.name} Types
          </h3>
          <div className="flex flex-wrap gap-2">
            {subcategories.map((subcategory) => (
              <button
                key={subcategory.id}
                onClick={() => onSelectSubcategory(subcategory)}
                className={`px-4 py-1.5 rounded-full text-sm transition-all ${
                  selectedSubcategory.id === subcategory.id
                    ? "bg-gray-200 text-gray-900 font-medium"
                    : "bg-white text-gray-600 border border-gray-200 hover:bg-gray-50"
                }`}
              >
                {subcategory.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
