import React, { useState, useEffect, useRef } from "react";
import {
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    doc,
    getDoc,
    onSnapshot,
    addDoc,
    serverTimestamp,
    setDoc,
} from "firebase/firestore";
import { Shield } from "lucide-react";
import { db } from "../../firebase";

// Importar todos los componentes
import ChatSidebar from "./ChatSidebar";
import ChatHeader from "./ChatHeader";
import ChatMessagesArea from "./ChatMessagesArea";
import ChatInput from "./ChatInput";
import QuickResponsePanel from "./QuickResponsePanel";
import MobileChatView from "./MobileChatView";
import ChatInfoPanel from "./ChatInfoPanel";

// Estilos CSS en línea
const styles = {
    tooltipContainer: {
        position: 'relative',
    },
    tooltip: {
        visibility: 'hidden',
        position: 'absolute',
        bottom: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        textAlign: 'center',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        zIndex: 10,
        marginBottom: '5px',
    },
    newMessageAnimation: {
        animation: 'fadeIn 0.3s ease-out',
    },
    unreadIndicator: {
        position: 'relative',
    },
};

// Agregar estilos globales usando una hoja de estilo en el DOM (solo una vez)
const injectGlobalStyles = () => {
    if (!document.getElementById('admin-chat-styles')) {
        const styleSheet = document.createElement('style');
        styleSheet.id = 'admin-chat-styles';
        styleSheet.innerHTML = `
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      .tooltip-container:hover .tooltip {
        visibility: visible !important;
      }
      
      .new-message-animation {
        animation: fadeIn 0.3s ease-out;
      }
      
      .unread-indicator::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 8px;
        background-color: #3b82f6;
        border-radius: 50%;
      }
      
      @media (max-width: 768px) {
        .md-hidden.active {
          display: none;
        }
        
        .md-flex.inactive {
          display: none;
        }
      }
    `;
        document.head.appendChild(styleSheet);
    }
};

const AdminChat = () => {
    // Inyectar estilos globales cuando el componente se monta
    useEffect(() => {
        injectGlobalStyles();
    }, []);

    // Estados
    const [allChats, setAllChats] = useState([]);
    const [filteredChats, setFilteredChats] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [message, setMessage] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [filterType, setFilterType] = useState("all"); // all, user, guest
    const [sortOrder, setSortOrder] = useState("newest"); // newest, oldest
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatCounts, setChatCounts] = useState({
        total: 0,
        user: 0,
        guest: 0,
        unread: 0,
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [debugInfo, setDebugInfo] = useState("Inicializando...");

    // Plantillas de respuestas rápidas
    const [quickResponses] = useState([
        {
            title: "Saludo",
            content: "Hola, soy parte del equipo de soporte de Livespeed. ¿En qué puedo ayudarte hoy?"
        },
        {
            title: "Agradecimiento",
            content: "Gracias por contactar con el soporte de Livespeed. Estamos aquí para ayudarte."
        },
        {
            title: "Despedida",
            content: "Si tienes alguna otra pregunta, no dudes en contactarnos. ¡Que tengas un excelente día!"
        },
        {
            title: "Vinculación brazalete",
            content: "Para vincular tu brazalete ve a la sección 'Mi cuenta' > 'Dispositivos' y selecciona 'Añadir nuevo dispositivo'. Sigue las instrucciones en pantalla para completar la vinculación."
        },
    ]);

    // Estado para mostrar/ocultar respuestas rápidas
    const [showQuickResponses, setShowQuickResponses] = useState(false);

    // Mensajes/chats listener
    const [messageListener, setMessageListener] = useState(null);

    // Notificación de nuevo mensaje
    const [hasNewMessage, setHasNewMessage] = useState(false);

    const chatEndRef = useRef(null);

    // Cargar todos los chats cuando el componente se monta
    useEffect(() => {
        console.log("AdminChat component initialized");
        setDebugInfo("Comenzando carga de chats...");
        loadAllChats();

        // Función de limpieza para remover los listeners
        return () => {
            if (messageListener) {
                messageListener();
            }
        };
    }, []);

    // Cargar todos los chats
    const loadAllChats = async () => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            setDebugInfo("Accediendo a la colección de Chats...");
            console.log("Fetching chats from Firestore");

            const chatsRef = collection(db, "Chats");

            // Usar solo orderBy sin límite para asegurar que se cargan todos los chats
            const q = query(chatsRef, orderBy("updatedAt", "desc"));

            setDebugInfo("Ejecutando consulta...");
            const querySnapshot = await getDocs(q);

            setDebugInfo(`Encontrados ${querySnapshot.docs.length} chats`);
            console.log(`Found ${querySnapshot.docs.length} chats`);

            if (querySnapshot.empty) {
                console.log("No chats found in the database");
                setDebugInfo("No se encontraron chats en la base de datos");
                setAllChats([]);
                setFilteredChats([]);
                setChatCounts({
                    total: 0,
                    user: 0,
                    guest: 0,
                    unread: 0
                });
                setIsLoading(false);
                return;
            }

            const chats = [];
            let userCount = 0;
            let guestCount = 0;
            let unreadCount = 0;

            for (const chatDoc of querySnapshot.docs) {
                setDebugInfo(`Procesando chat ${chatDoc.id}...`);
                const chatData = chatDoc.data();
                console.log("Chat data:", chatData);

                // Obtener el último mensaje para la vista previa
                const messagesRef = collection(db, "Chats", chatDoc.id, "messages");

                try {
                    // Primero intentar con orderBy
                    const messageQuery = query(messagesRef, orderBy("timestamp", "desc"), limit(1));
                    let messageSnapshot = await getDocs(messageQuery);

                    let lastMessage = "Sin mensajes";
                    if (!messageSnapshot.empty) {
                        lastMessage = messageSnapshot.docs[0].data().content;
                    } else {
                        // Si no hay mensajes con el orderBy, intentar sin él
                        const simpleQuery = query(messagesRef, limit(1));
                        messageSnapshot = await getDocs(simpleQuery);
                        if (!messageSnapshot.empty) {
                            lastMessage = messageSnapshot.docs[0].data().content;
                        }
                    }

                    // Verificar mensajes no leídos - simplificado para evitar problemas de índice
                    let unreadMessages = 0;

                    // Consulta simplificada para mensajes de usuario
                    const unreadQuery = query(messagesRef, where("role", "==", "user"));
                    const unreadSnapshot = await getDocs(unreadQuery);

                    // Consulta simplificada para mensajes de admin
                    const adminQuery = query(messagesRef, where("role", "==", "admin"));
                    const adminSnapshot = await getDocs(adminQuery);

                    if (unreadSnapshot.empty) {
                        unreadMessages = 0;
                    } else if (adminSnapshot.empty) {
                        unreadMessages = unreadSnapshot.docs.length;
                    } else {
                        // Procesar manualmente para encontrar mensajes no leídos
                        // Encontrar el mensaje de admin más reciente
                        let lastAdminTime = 0;
                        adminSnapshot.docs.forEach(doc => {
                            const timestamp = doc.data().timestamp;
                            if (timestamp) {
                                const time = timestamp.toMillis ? timestamp.toMillis() : 0;
                                if (time > lastAdminTime) lastAdminTime = time;
                            }
                        });

                        // Contar mensajes de usuario más recientes que el último mensaje de admin
                        unreadMessages = unreadSnapshot.docs.filter(doc => {
                            const timestamp = doc.data().timestamp;
                            if (!timestamp) return false;
                            const time = timestamp.toMillis ? timestamp.toMillis() : 0;
                            return time > lastAdminTime;
                        }).length;
                    }

                    if (unreadMessages > 0) {
                        unreadCount++;
                    }

                    const chat = {
                        id: chatDoc.id,
                        ...chatData,
                        lastMessage,
                        unreadMessages,
                    };

                    chats.push(chat);

                    // Contar chats de usuarios vs invitados
                    if (chatData.isGuest) {
                        guestCount++;
                    } else {
                        userCount++;
                    }
                } catch (messageError) {
                    console.error(`Error processing messages for chat ${chatDoc.id}:`, messageError);
                    setDebugInfo(`Error procesando mensajes para chat ${chatDoc.id}: ${messageError.message}`);

                    // Añadir el chat sin información de mensajes
                    const chat = {
                        id: chatDoc.id,
                        ...chatData,
                        lastMessage: "Error al cargar mensajes",
                        unreadMessages: 0,
                    };

                    chats.push(chat);
                }
            }

            setDebugInfo(`Procesados ${chats.length} chats correctamente`);
            console.log("Processed chats:", chats);

            setAllChats(chats);
            setChatCounts({
                total: chats.length,
                user: userCount,
                guest: guestCount,
                unread: unreadCount,
            });

            // Aplicar filtros
            applyFilters(chats, searchQuery, filterType, sortOrder);

            // Si ya teníamos un chat seleccionado, mantenerlo seleccionado
            if (selectedChatId && chats.some(chat => chat.id === selectedChatId)) {
                setSelectedChat(chats.find(chat => chat.id === selectedChatId));
            } else if (chats.length > 0) {
                // De lo contrario, seleccionar el primer chat con mensajes no leídos, o simplemente el primero
                const firstUnread = chats.find(chat => chat.unreadMessages > 0);
                if (firstUnread) {
                    setSelectedChatId(firstUnread.id);
                    setSelectedChat(firstUnread);
                    loadChatMessages(firstUnread.id);
                } else {
                    setSelectedChatId(chats[0].id);
                    setSelectedChat(chats[0]);
                    loadChatMessages(chats[0].id);
                }
            }
        } catch (error) {
            console.error("Error loading chats:", error);
            setErrorMessage(`Error al cargar los chats: ${error.message}`);
            setDebugInfo(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    // Aplicar filtros y ordenación a los chats
    const applyFilters = (chats, query, type, order) => {
        let filtered = [...chats];

        // Aplicar búsqueda de texto
        if (query) {
            const searchLower = query.toLowerCase();
            filtered = filtered.filter(
                chat =>
                    (chat.sessionName && chat.sessionName.toLowerCase().includes(searchLower)) ||
                    (chat.guestName && chat.guestName.toLowerCase().includes(searchLower)) ||
                    (chat.guestPhone && chat.guestPhone.includes(searchLower)) ||
                    (chat.userName && chat.userName.toLowerCase().includes(searchLower)) ||
                    (chat.lastMessage && chat.lastMessage.toLowerCase().includes(searchLower))
            );
        }

        // Aplicar filtro de tipo
        if (type === "user") {
            filtered = filtered.filter(chat => !chat.isGuest);
        } else if (type === "guest") {
            filtered = filtered.filter(chat => chat.isGuest);
        } else if (type === "unread") {
            filtered = filtered.filter(chat => chat.unreadMessages > 0);
        }

        // Aplicar ordenación
        if (order === "oldest") {
            filtered.sort((a, b) => {
                const timeA = a.updatedAt?.toDate ? a.updatedAt.toDate() : 0;
                const timeB = b.updatedAt?.toDate ? b.updatedAt.toDate() : 0;
                return timeA - timeB;
            });
        }

        setFilteredChats(filtered);
    };

    // Actualizar filtros cuando cambia cualquier filtro
    useEffect(() => {
        applyFilters(allChats, searchQuery, filterType, sortOrder);
    }, [searchQuery, filterType, sortOrder, allChats]);

    // Cargar mensajes para el chat seleccionado
    const loadChatMessages = (chatId) => {
        if (!chatId) return;

        // Limpiar listener existente si lo hay
        if (messageListener) {
            messageListener();
        }

        setSelectedChatId(chatId);
        setSelectedChat(allChats.find(chat => chat.id === chatId));
        // Importante: Limpiamos los mensajes anteriores y mostramos un estado de carga
        setChatMessages([]);
        setDebugInfo(`Cargando mensajes para el chat ${chatId}...`);

        try {
            const messagesRef = collection(db, "Chats", chatId, "messages");

            // Consulta simple sin orderBy para evitar problemas de índice
            const q = query(messagesRef);
            console.log(`Setting up messages listener for chat ${chatId}`);

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                console.log(`Received snapshot with ${querySnapshot.docs.length} messages for chat ${chatId}`);

                if (querySnapshot.empty) {
                    console.log(`No messages found for chat ${chatId}`);
                    setDebugInfo(`No se encontraron mensajes para el chat ${chatId}`);
                    setChatMessages([]);
                } else {
                    // Extraer datos y garantizar propiedades mínimas para cada mensaje
                    const messages = querySnapshot.docs.map(doc => {
                        const data = doc.data();
                        return {
                            id: doc.id,
                            role: data.role || "user",
                            content: data.content || "Mensaje sin contenido",
                            name: data.name || "Usuario",
                            avatar: data.avatar || "/default-user.png",
                            timestamp: data.timestamp || { toDate: () => new Date() },
                            ...data
                        };
                    });

                    // Ordenar por timestamp manualmente
                    const sortedMessages = [...messages].sort((a, b) => {
                        const timeA = a.timestamp?.toDate ? a.timestamp.toDate() : new Date(0);
                        const timeB = b.timestamp?.toDate ? b.timestamp.toDate() : new Date(0);
                        return timeA - timeB;
                    });

                    console.log(`Processed ${sortedMessages.length} messages for chat ${chatId}`);

                    // Imprimir algunos detalles para depuración
                    if (sortedMessages.length > 0) {
                        const first = sortedMessages[0];
                        const last = sortedMessages[sortedMessages.length - 1];
                        console.log(`First message: ${first.role} - ${first.content.substring(0, 30)}`);
                        console.log(`Last message: ${last.role} - ${last.content.substring(0, 30)}`);
                    }

                    setDebugInfo(`${sortedMessages.length} mensajes cargados correctamente.`);
                    setChatMessages(sortedMessages);
                }

                // Actualizar chat en la lista y desplazarse al final
                updateChatInList(chatId);
                setTimeout(() => {
                    if (chatEndRef.current) {
                        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
                    }
                }, 100);
            }, error => {
                console.error(`Error in chat messages listener for ${chatId}:`, error);
                setDebugInfo(`Error en el listener de mensajes: ${error.message}`);
                setErrorMessage(`Error al escuchar mensajes: ${error.message}`);
            });

            // Almacenar el listener para limpieza
            setMessageListener(unsubscribe);
            return unsubscribe;
        } catch (error) {
            console.error("Error loading chat messages:", error);
            setErrorMessage(`Error al cargar los mensajes: ${error.message}`);
            setDebugInfo(`Error al cargar mensajes: ${error.message}`);
            return () => { };
        }
    };

    // Actualizar un chat específico en la lista
    const updateChatInList = async (chatId) => {
        try {
            const chatRef = doc(db, "Chats", chatId);
            const chatDoc = await getDoc(chatRef);

            if (chatDoc.exists()) {
                const chatData = chatDoc.data();

                // Obtener último mensaje
                const messagesRef = collection(db, "Chats", chatId, "messages");

                // Intentar consulta sin orderBy para evitar problemas de índice
                const messageQuery = query(messagesRef, limit(50));
                const messageSnapshot = await getDocs(messageQuery);

                let lastMessage = "Sin mensajes";

                if (!messageSnapshot.empty) {
                    // Ordenar manualmente y obtener el más reciente
                    const sortedMessages = messageSnapshot.docs
                        .filter(doc => doc.data().timestamp)
                        .sort((a, b) => {
                            const timeA = a.data().timestamp?.toDate?.() || 0;
                            const timeB = b.data().timestamp?.toDate?.() || 0;
                            return timeB - timeA; // orden descendente
                        });

                    if (sortedMessages.length > 0) {
                        lastMessage = sortedMessages[0].data().content || "Sin contenido";
                    }
                }

                // Verificar mensajes no leídos - simplificada
                let unreadMessages = 0;

                // Consulta simple para mensajes de usuario
                const userQuery = query(messagesRef, where("role", "==", "user"));
                const userSnapshot = await getDocs(userQuery);

                // Consulta simple para mensajes de admin
                const adminQuery = query(messagesRef, where("role", "==", "admin"));
                const adminSnapshot = await getDocs(adminQuery);

                if (userSnapshot.empty) {
                    unreadMessages = 0;
                } else if (adminSnapshot.empty) {
                    unreadMessages = userSnapshot.docs.length;
                } else {
                    // Procesamiento manual
                    let lastAdminTime = 0;
                    adminSnapshot.docs.forEach(doc => {
                        const timestamp = doc.data().timestamp;
                        if (timestamp) {
                            const time = timestamp.toMillis ? timestamp.toMillis() : 0;
                            if (time > lastAdminTime) lastAdminTime = time;
                        }
                    });

                    unreadMessages = userSnapshot.docs.filter(doc => {
                        const timestamp = doc.data().timestamp;
                        if (!timestamp) return false;
                        const time = timestamp.toMillis ? timestamp.toMillis() : 0;
                        return time > lastAdminTime;
                    }).length;
                }

                // Actualizar chat en el array de todos los chats
                setAllChats(prevChats => {
                    const updatedChats = prevChats.map(chat =>
                        chat.id === chatId
                            ? {
                                ...chat,
                                ...chatData,
                                lastMessage,
                                unreadMessages
                            }
                            : chat
                    );

                    // También actualizar los chats filtrados
                    applyFilters(updatedChats, searchQuery, filterType, sortOrder);

                    // Actualizar el chat seleccionado
                    if (selectedChatId === chatId) {
                        setSelectedChat(updatedChats.find(chat => chat.id === chatId));
                    }

                    return updatedChats;
                });
            }
        } catch (error) {
            console.error("Error updating chat in list:", error);
            setErrorMessage(`Error al actualizar el chat: ${error.message}`);
        }
    };

    // Enviar un mensaje como admin
    const handleSendMessage = async (e) => {
        if (e) e.preventDefault();

        if (!message.trim() || !selectedChatId || isSending) return;

        setIsSending(true);
        setDebugInfo("Enviando mensaje como Livespeed Soporte...");

        try {
            const adminMessage = {
                role: "admin",
                content: message.trim(),
                name: "Livespeed Soporte",
                avatar: "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
                timestamp: serverTimestamp(),
            };

            // Añadir mensaje a la subcolección de mensajes
            const messagesRef = collection(db, "Chats", selectedChatId, "messages");
            await addDoc(messagesRef, adminMessage);

            // Actualizar el documento del chat
            const chatRef = doc(db, "Chats", selectedChatId);
            await setDoc(
                chatRef,
                {
                    updatedAt: serverTimestamp(),
                    lastAdminResponse: serverTimestamp(), // Añadir esto para rastrear cuándo respondió un admin por última vez
                },
                { merge: true }
            );

            setMessage("");
            setShowQuickResponses(false);
            setDebugInfo("Mensaje enviado correctamente.");
        } catch (error) {
            console.error("Error sending message:", error);
            setErrorMessage(`Error al enviar el mensaje: ${error.message}`);
            setDebugInfo(`Error al enviar el mensaje: ${error.message}`);
        } finally {
            setIsSending(false);
        }
    };

    // Manejar cambios de filtro
    const handleFilterChange = (type) => {
        setFilterType(type);
    };

    // Alternar orden de clasificación
    const handleToggleSort = () => {
        setSortOrder(sortOrder === "newest" ? "oldest" : "newest");
    };

    // Actualizar lista de chats
    const handleRefresh = () => {
        setDebugInfo("Actualizando lista de chats...");
        loadAllChats();
    };

    // Formatear timestamp para el encabezado del chat
    const formatChatTime = (timestamp) => {
        if (!timestamp) return "";
        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
        return new Intl.DateTimeFormat("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        }).format(date);
    };

    // Marcar mensajes como leídos cuando se abra un chat
    useEffect(() => {
        const markMessagesAsRead = async () => {
            if (selectedChatId) {
                try {
                    // Actualizar el documento del chat para indicar que el admin lo ha visto
                    const chatRef = doc(db, "Chats", selectedChatId);
                    await setDoc(
                        chatRef,
                        {
                            lastAdminView: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    // Actualizar el estado local para reflejar que los mensajes están leídos
                    setAllChats(prevChats => {
                        const updatedChats = prevChats.map(chat =>
                            chat.id === selectedChatId
                                ? {
                                    ...chat,
                                    unreadMessages: 0
                                }
                                : chat
                        );

                        // También actualizar los chats filtrados
                        applyFilters(updatedChats, searchQuery, filterType, sortOrder);
                        return updatedChats;
                    });

                } catch (error) {
                    console.error("Error marking messages as read:", error);
                }
            }
        };

        markMessagesAsRead();
    }, [selectedChatId]);

    // Si no hay ningún chat cargado y no estamos cargando, mostrar mensaje de depuración
    useEffect(() => {
        if (!isLoading && allChats.length === 0) {
            console.log("No chats found after loading");
            setDebugInfo("No se encontraron chats. Comprueba los permisos de Firestore y la estructura de datos.");
        }
    }, [isLoading, allChats]);

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar con lista de chats */}
            <ChatSidebar
                errorMessage={errorMessage}
                debugInfo={debugInfo}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filterType={filterType}
                handleFilterChange={handleFilterChange}
                sortOrder={sortOrder}
                handleToggleSort={handleToggleSort}
                chatCounts={chatCounts}
                isLoading={isLoading}
                filteredChats={filteredChats}
                handleRefresh={handleRefresh}
                loadChatMessages={loadChatMessages}
                selectedChatId={selectedChatId}
            />

            {/* Área principal del chat */}
            <div className="hidden md:flex md:w-2/3 lg:w-3/4 flex-col">
                {selectedChatId ? (
                    <>
                        {/* Encabezado del chat */}
                        <ChatHeader
                            selectedChat={selectedChat}
                            formatChatTime={formatChatTime}
                            handleRefresh={handleRefresh}
                        />

                        {/* Área de mensajes */}
                        <ChatMessagesArea
                            chatMessages={chatMessages}
                            chatEndRef={chatEndRef}
                            selectedChat={selectedChat}
                            formatChatTime={formatChatTime}
                        />

                        {/* Respuestas rápidas */}
                        {showQuickResponses && (
                            <QuickResponsePanel
                                quickResponses={quickResponses}
                                setMessage={setMessage}
                                setShowQuickResponses={setShowQuickResponses}
                            />
                        )}

                        {/* Input de mensaje */}
                        <ChatInput
                            message={message}
                            setMessage={setMessage}
                            handleSendMessage={handleSendMessage}
                            isSending={isSending}
                            showQuickResponses={showQuickResponses}
                            setShowQuickResponses={setShowQuickResponses}
                        />
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full bg-gray-50 text-gray-500">
                        <Shield size={64} className="mb-4 opacity-30" />
                        <h2 className="text-xl font-semibold mb-2">Panel de Soporte</h2>
                        <p className="mb-4">Selecciona un chat para ver los mensajes</p>
                    </div>
                )}
            </div>

            {/* Vista móvil para lista de chats o detalle de chat */}
            {!selectedChatId ? (
                <div className="flex md:hidden items-center justify-center flex-1 bg-gray-50 text-gray-500">
                    <div className="text-center p-6">
                        <Shield size={48} className="mx-auto mb-4 opacity-30" />
                        <h2 className="text-lg font-semibold mb-2">Panel de Soporte</h2>
                        <p>Selecciona un chat para ver los mensajes</p>
                    </div>
                </div>
            ) : (
                /* Vista de chat móvil - se muestra cuando se selecciona un chat */
                <MobileChatView
                    selectedChatId={selectedChatId}
                    setSelectedChatId={setSelectedChatId}
                    selectedChat={selectedChat}
                    chatMessages={chatMessages}
                    chatEndRef={chatEndRef}
                    message={message}
                    setMessage={setMessage}
                    handleSendMessage={handleSendMessage}
                    isSending={isSending}
                />
            )}
        </div>
    );
};

export default AdminChat;