import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db, storage } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  Camera,
  Calendar,
  Upload,
  DollarSign,
  MapPin,
  AlertCircle,
  Hash,
  Bike,
  X,
  Loader2,
  Mail,
  Phone,
  Save,
  ArrowLeft,
} from "lucide-react";
// Import User icon separately to ensure it's correctly loaded
import { User } from "lucide-react";

const AddBicycleReport = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { uid } = useParams(); // Capturar el uid si está presente
  const isEditMode = Boolean(uid); // Determinar si estamos en modo edición

  const fileInputRef = useRef(null);
  const moreImagesRef = useRef(null);

  const [reportData, setReportData] = useState({
    name: "",
    brand: "",
    model: "",
    serialNumber: "",
    description: "",
    color: "",
    location: "",
    locationCoords: { lat: 19.432608, lng: -99.133209 }, // Default: Mexico City
    reward: false,
    rewardAmount: 0,
    mainImageUrl: "",
    moreImages: [],
    uid: user ? user.uid : null,
    theftDate: new Date().toISOString().split("T")[0],
    status: "reported", // Default status
    contactInfo: {
      name: "",
      phone: "",
      email: "",
      preferredContact: "email",
    },
    reportDate: new Date(),
  });

  const [mainImage, setMainImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [moreImages, setMoreImages] = useState([]);
  const [moreImagesPreview, setMoreImagesPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [originalImageUrls, setOriginalImageUrls] = useState({
    main: "",
    more: [],
  });

  const mapContainerStyle = {
    width: "100%",
    height: "300px",
  };

  // Función para cargar datos de una bicicleta existente en modo edición
  useEffect(() => {
    const fetchBikeData = async () => {
      if (!isEditMode) return;

      setFetchingData(true);
      try {
        // Primero intentamos con getDoc normal
        let bikeRef = doc(db, "Blacklist", uid);
        let bikeDoc = await getDoc(bikeRef);

        // Si no se encuentra, intentamos listar todos los documentos
        if (!bikeDoc.exists()) {
          console.log("Usando método alternativo para encontrar la bicicleta");
          const querySnapshot = await getDocs(collection(db, "Blacklist"));

          querySnapshot.forEach((doc) => {
            if (doc.id === uid) {
              bikeDoc = doc;
            }
          });
        }

        if (!bikeDoc.exists()) {
          alert("No se encontró la bicicleta a editar");
          navigate("/bikefinder");
          return;
        }

        const bikeData = bikeDoc.data();
        console.log("Datos cargados:", bikeData);

        // Guardar las URLs originales de las imágenes
        setOriginalImageUrls({
          main: bikeData.mainImageUrl || "",
          more: bikeData.moreImages || [],
        });

        // Preparar vista previa de imágenes existentes
        if (bikeData.mainImageUrl) {
          setMainImagePreview(bikeData.mainImageUrl);
        }

        if (bikeData.moreImages && bikeData.moreImages.length > 0) {
          setMoreImagesPreview(bikeData.moreImages);
        }

        // Actualizar el estado con los datos de la bicicleta
        setReportData({
          name: bikeData.name || "",
          brand: bikeData.brand || "",
          model: bikeData.model || "",
          serialNumber: bikeData.serialNumber || "",
          description: bikeData.description || "",
          color: bikeData.color || "",
          location: bikeData.location || "",
          locationCoords: bikeData.locationCoords || {
            lat: 19.432608,
            lng: -99.133209,
          },
          reward: bikeData.reward || false,
          rewardAmount: bikeData.rewardAmount || 0,
          mainImageUrl: bikeData.mainImageUrl || "",
          moreImages: bikeData.moreImages || [],
          uid: bikeData.uid || (user ? user.uid : null),
          theftDate:
            bikeData.theftDate || new Date().toISOString().split("T")[0],
          status: bikeData.status || "reported",
          contactInfo: bikeData.contactInfo || {
            name: "",
            phone: "",
            email: "",
            preferredContact: "email",
          },
          reportDate: bikeData.reportDate || new Date(),
        });
      } catch (error) {
        console.error("Error al cargar datos de la bicicleta:", error);
        alert("Error al cargar los datos para editar. Intente nuevamente.");
      } finally {
        setFetchingData(false);
      }
    };

    fetchBikeData();
  }, [uid, isEditMode, user, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setReportData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleMainImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setMainImage(file);
      setMainImagePreview(URL.createObjectURL(file));
    }
  };

  const handleMoreImagesChange = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      setMoreImages((prev) => [...prev, ...files]);

      const newPreviews = files.map((file) => URL.createObjectURL(file));
      setMoreImagesPreview((prev) => [...prev, ...newPreviews]);
    }
  };

  const removeMainImage = () => {
    setMainImage(null);
    // Si estamos en modo edición y hay una URL original, establecemos mainImageUrl a una cadena vacía
    if (isEditMode) {
      setReportData((prev) => ({
        ...prev,
        mainImageUrl: "",
      }));
    }
    setMainImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeMoreImage = (index) => {
    // Si es una imagen nueva (subida durante esta sesión)
    if (index < moreImages.length) {
      setMoreImages((prev) => prev.filter((_, i) => i !== index));
    }

    // Si estamos en modo edición, actualizar el array de URLs de imágenes
    if (isEditMode) {
      setReportData((prev) => {
        const updatedMoreImages = [...prev.moreImages];
        updatedMoreImages.splice(index, 1);
        return {
          ...prev,
          moreImages: updatedMoreImages,
        };
      });
    }

    setMoreImagesPreview((prev) => prev.filter((_, i) => i !== index));
  };

  const handleMapClick = (e) => {
    setReportData((prev) => ({
      ...prev,
      locationCoords: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      alert("Por favor, inicie sesión para reportar una bicicleta robada.");
      return;
    }

    if (!mainImagePreview && !isEditMode) {
      alert("Por favor, sube una imagen principal de la bicicleta.");
      return;
    }

    setLoading(true);

    try {
      let mainImageUrl = reportData.mainImageUrl;

      // Subir nueva imagen principal si se cambió
      if (mainImage) {
        const storageRef = ref(
          storage,
          `bicycles/${Date.now()}_${mainImage.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, mainImage);
        mainImageUrl = await new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, reject, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
          });
        });
      }

      // Subir imágenes adicionales nuevas
      let moreImagesUrls = [...reportData.moreImages]; // Mantener las existentes

      if (moreImages.length > 0) {
        const newUrls = await Promise.all(
          moreImages.map((image) => {
            const storageRef = ref(
              storage,
              `bicycles/additional/${Date.now()}_${image.name}`
            );
            const uploadTask = uploadBytesResumable(storageRef, image);
            return new Promise((resolve, reject) => {
              uploadTask.on("state_changed", null, reject, () => {
                getDownloadURL(uploadTask.snapshot.ref)
                  .then(resolve)
                  .catch(reject);
              });
            });
          })
        );

        // Añadir las nuevas URLs al array existente
        moreImagesUrls = [...moreImagesUrls, ...newUrls];
      }

      const finalReportData = {
        ...reportData,
        mainImageUrl,
        moreImages: moreImagesUrls,
        uid: user.uid,
      };

      if (isEditMode) {
        // Actualizar documento existente
        await updateDoc(doc(db, "Blacklist", uid), finalReportData);
        alert("Bicicleta actualizada con éxito");
      } else {
        // Crear nuevo documento
        await addDoc(collection(db, "Blacklist"), {
          ...finalReportData,
          reportDate: new Date(),
        });
        alert("Bicicleta reportada con éxito");
      }

      navigate("/bikefinder");
    } catch (error) {
      console.error("Error al procesar la bicicleta: ", error);
      alert("Error al procesar. Por favor, inténtelo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  if (fetchingData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <Loader2 className="h-12 w-12 animate-spin text-black mx-auto mb-4" />
          <p className="text-lg text-gray-700">
            Cargando datos de la bicicleta...
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-gray-800 to-black py-6 px-6">
          <div className="flex items-center space-x-3">
            <Bike className="text-white h-8 w-8" />
            <h1 className="text-2xl font-bold text-white">
              {isEditMode
                ? "Editar Bicicleta Reportada"
                : "Reportar Bicicleta Robada"}
            </h1>
          </div>
          <p className="text-gray-300 mt-2">
            {isEditMode
              ? "Actualiza la información de la bicicleta robada para mantener el reporte al día"
              : "Completa el formulario para reportar tu bicicleta robada y aumentar las posibilidades de recuperarla"}
          </p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Basic Info Section */}
            <div className="md:col-span-2">
              <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                <Bike className="mr-2 h-5 w-5 text-black" />
                Información de la Bicicleta
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Nombre/Apodo de la Bicicleta
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={reportData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Ej: Mi MTB Roja"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Marca
                  </label>
                  <input
                    type="text"
                    name="brand"
                    value={reportData.brand}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Ej: Trek, Specialized, Giant..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Modelo
                  </label>
                  <input
                    type="text"
                    name="model"
                    value={reportData.model}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Ej: Marlin 7, Rockhopper..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                    <Hash className="mr-1 h-4 w-4 text-gray-700" />
                    Número de Serie
                  </label>
                  <input
                    type="text"
                    name="serialNumber"
                    value={reportData.serialNumber}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Ej: WTU123456789"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Color
                  </label>
                  <input
                    type="text"
                    name="color"
                    value={reportData.color}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Ej: Roja con detalles negros"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                    <Calendar className="mr-1 h-4 w-4 text-gray-700" />
                    Fecha del Robo
                  </label>
                  <input
                    type="date"
                    name="theftDate"
                    value={reportData.theftDate}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  />
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Descripción Detallada
              </label>
              <textarea
                name="description"
                value={reportData.description}
                onChange={handleChange}
                required
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                placeholder="Describe tu bicicleta: características únicas, accesorios, daños o marcas distintivas..."
              />
            </div>

            {/* Theft Location */}
            <div className="md:col-span-2">
              <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                <MapPin className="mr-2 h-5 w-5 text-black" />
                Ubicación del Robo
              </h2>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Dirección o Referencia
                </label>
                <input
                  type="text"
                  name="location"
                  value={reportData.location}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  placeholder="Ej: Avenida Reforma, cerca del Ángel de la Independencia"
                />
              </div>

              <div className="h-[300px] mb-2 rounded-lg overflow-hidden border border-gray-300">
                <LoadScript
                  googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28"
                  onLoad={() => setMapLoaded(true)}
                >
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={reportData.locationCoords}
                    zoom={13}
                    onClick={handleMapClick}
                  >
                    <Marker position={reportData.locationCoords} />
                  </GoogleMap>
                </LoadScript>
              </div>
              <p className="text-sm text-gray-500 mb-4">
                Haz clic en el mapa para marcar la ubicación aproximada donde
                ocurrió el robo
              </p>
            </div>

            {/* Contact Information Section */}
            <div className="md:col-span-2">
              <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                <User className="mr-2 h-5 w-5 text-black" />
                Información de Contacto
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                    <User className="mr-1 h-4 w-4 text-gray-700" />
                    Nombre Completo
                  </label>
                  <input
                    type="text"
                    name="contactInfo.name"
                    value={reportData.contactInfo.name}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        contactInfo: {
                          ...reportData.contactInfo,
                          name: e.target.value,
                        },
                      })
                    }
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Tu nombre completo"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                    <Phone className="mr-1 h-4 w-4 text-gray-700" />
                    Teléfono
                  </label>
                  <input
                    type="tel"
                    name="contactInfo.phone"
                    value={reportData.contactInfo.phone}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        contactInfo: {
                          ...reportData.contactInfo,
                          phone: e.target.value,
                        },
                      })
                    }
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="Tu número de teléfono"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                    <Mail className="mr-1 h-4 w-4 text-gray-700" />
                    Correo Electrónico
                  </label>
                  <input
                    type="email"
                    name="contactInfo.email"
                    value={reportData.contactInfo.email}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        contactInfo: {
                          ...reportData.contactInfo,
                          email: e.target.value,
                        },
                      })
                    }
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                    placeholder="tu@email.com"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Método de Contacto Preferido
                  </label>
                  <select
                    name="contactInfo.preferredContact"
                    value={reportData.contactInfo.preferredContact}
                    onChange={(e) =>
                      setReportData({
                        ...reportData,
                        contactInfo: {
                          ...reportData.contactInfo,
                          preferredContact: e.target.value,
                        },
                      })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    <option value="email">Correo Electrónico</option>
                    <option value="phone">Teléfono</option>
                    <option value="both">Ambos</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Images Upload */}
            <div className="md:col-span-2">
              <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                <Camera className="mr-2 h-5 w-5 text-black" />
                Fotografías
              </h2>

              {/* Main Image */}
              <div className="mb-6">
                <p className="text-sm text-gray-700 mb-2">
                  Imagen Principal {!isEditMode && "(obligatoria)"}
                </p>

                {!mainImagePreview ? (
                  <div
                    className="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-50 transition-colors"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <Camera className="h-10 w-10 text-gray-400 mb-2" />
                    <p className="text-sm text-gray-500">
                      Haz clic para subir una foto principal de tu bicicleta
                    </p>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleMainImageChange}
                      accept="image/*"
                      className="hidden"
                    />
                  </div>
                ) : (
                  <div className="relative">
                    <img
                      src={mainImagePreview}
                      alt="Vista previa"
                      className="h-64 w-full object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={removeMainImage}
                      className="absolute top-2 right-2 bg-black bg-opacity-60 text-white p-1 rounded-full hover:bg-opacity-80"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                )}
              </div>

              {/* More Images */}
              <div className="mb-4">
                <p className="text-sm text-gray-700 mb-2">
                  Imágenes Adicionales (opcionales)
                </p>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mb-3">
                  {moreImagesPreview.map((preview, index) => (
                    <div key={index} className="relative h-24">
                      <img
                        src={preview}
                        alt={`Vista previa ${index + 1}`}
                        className="h-full w-full object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => removeMoreImage(index)}
                        className="absolute top-1 right-1 bg-black bg-opacity-60 text-white p-1 rounded-full hover:bg-opacity-80"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}

                  <div
                    className="border-2 border-dashed border-gray-300 rounded-lg h-24 flex flex-col items-center justify-center cursor-pointer hover:bg-gray-50 transition-colors"
                    onClick={() => moreImagesRef.current?.click()}
                  >
                    <Upload className="h-6 w-6 text-gray-400 mb-1" />
                    <p className="text-xs text-gray-500">Más fotos</p>
                    <input
                      type="file"
                      ref={moreImagesRef}
                      onChange={handleMoreImagesChange}
                      accept="image/*"
                      multiple
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Reward Section */}
            <div className="md:col-span-2">
              <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                <DollarSign className="mr-2 h-5 w-5 text-black" />
                Recompensa
              </h2>

              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="reward"
                  name="reward"
                  checked={reportData.reward}
                  onChange={handleChange}
                  className="h-4 w-4 text-black focus:ring-black border-gray-300 rounded"
                />
                <label
                  htmlFor="reward"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Ofrecer recompensa por información o recuperación
                </label>
              </div>

              {reportData.reward && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Monto de la Recompensa (MXN)
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500">$</span>
                    </div>
                    <input
                      type="number"
                      name="rewardAmount"
                      value={reportData.rewardAmount}
                      onChange={handleChange}
                      min="0"
                      className="w-full pl-7 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                      placeholder="Cantidad"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Status Section (solo para edición) */}
            {isEditMode && (
              <div className="md:col-span-2">
                <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
                  <AlertCircle className="mr-2 h-5 w-5 text-black" />
                  Estado de la Bicicleta
                </h2>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Estado Actual
                  </label>
                  <select
                    name="status"
                    value={reportData.status}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    <option value="reported">Reportada como Robada</option>
                    <option value="inProgress">Investigación en Curso</option>
                    <option value="sighted">Vista Recientemente</option>
                    <option value="recovered">Recuperada</option>
                    <option value="closed">Caso Cerrado</option>
                  </select>
                </div>

                {reportData.status === "recovered" && (
                  <div className="p-4 bg-green-50 rounded-md mb-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <Bike className="h-5 w-5 text-green-600" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">
                          ¡Bicicleta recuperada!
                        </h3>
                        <div className="mt-2 text-sm text-green-700">
                          <p>
                            Si has recuperado tu bicicleta, ¡felicidades! Tu
                            experiencia puede ayudar a otros. Por favor
                            considera añadir detalles sobre cómo fue recuperada
                            para ayudar a la comunidad.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {reportData.status === "sighted" && (
                  <div className="p-4 bg-blue-50 rounded-md mb-4">
                    <p className="text-sm text-blue-700">
                      Indica que la bicicleta ha sido vista recientemente.
                      Puedes añadir detalles sobre dónde y cuándo fue vista en
                      la descripción.
                    </p>
                  </div>
                )}
              </div>
            )}

            {/* Submit Button */}
            <div className="md:col-span-2 mt-4">
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  onClick={() => navigate("/bikefinder")}
                  className="flex items-center py-2 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                >
                  <ArrowLeft className="h-4 w-4 mr-2" />
                  Volver
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={`flex items-center justify-center py-2 px-6 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
                    loading ? "opacity-70 cursor-not-allowed" : ""
                  }`}
                >
                  {loading ? (
                    <>
                      <Loader2 className="animate-spin h-5 w-5 mr-2" />
                      {isEditMode ? "Actualizando..." : "Enviando..."}
                    </>
                  ) : (
                    <>
                      <Save className="h-5 w-5 mr-2" />
                      {isEditMode
                        ? "Actualizar Bicicleta"
                        : "Reportar Bicicleta"}
                    </>
                  )}
                </button>
              </div>

              <div className="mt-4 p-3 bg-gray-100 rounded-md">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-gray-600 mr-2" />
                  <div className="text-sm text-gray-800">
                    {isEditMode ? (
                      <p>
                        Mantener actualizada la información de tu bicicleta
                        robada aumenta las posibilidades de que alguien la
                        reconozca y pueda ser recuperada.
                      </p>
                    ) : (
                      <p>
                        Reportar una bicicleta robada aumenta las posibilidades
                        de recuperarla. Tu reporte será visible para la
                        comunidad y las autoridades.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {isEditMode && (
                <div className="mt-4 p-3 bg-yellow-50 border border-yellow-100 rounded-md">
                  <div className="flex">
                    <AlertCircle className="h-5 w-5 text-yellow-600 mr-2 flex-shrink-0" />
                    <div className="text-sm text-yellow-800">
                      <p>
                        <strong>Nota:</strong> Si tu bicicleta ha sido
                        recuperada, por favor actualiza su estado usando la
                        opción "Recuperada" en la sección de Estado. Esto ayuda
                        a mantener actualizada la base de datos y permite a
                        otros usuarios enfocarse en bicicletas que siguen
                        desaparecidas.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBicycleReport;
