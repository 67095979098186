import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Instagram,
  Facebook,
  Youtube,
  Twitch,
  Twitter,
  Trophy,
  CircleDot,
} from "lucide-react";

const LiveStream = () => {
  const [leaderboard, setLeaderboard] = useState([
    { id: 1, name: "Pedro Aguirre", time: "3:01.456", previousPos: 1 },
    { id: 2, name: "Gerardo Garcia", time: "3:02.789", previousPos: 2 },
    { id: 3, name: "Billy Alvarez", time: "3:03.123", previousPos: 3 },
  ]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videoRef = useRef(null);
  const componentRef = useRef(null);

  // Video y animaciones solo si el componente es visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);

        if (videoRef.current) {
          if (entry.isIntersecting) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }
      },
      { threshold: 0.5 }
    );

    if (componentRef.current) observer.observe(componentRef.current);

    return () => {
      if (componentRef.current) observer.disconnect();
    };
  }, []);

  // Efecto para actualizar el leaderboard si el componente es visible
  useEffect(() => {
    if (!isVisible) return;

    const timer = setTimeout(() => {
      setIsUpdating(true);
      const newLeader = {
        id: 4,
        name: "Angel Gutierrez",
        time: "2:59.789",
        previousPos: 4,
      };

      setTimeout(() => {
        setLeaderboard((prev) => [newLeader, ...prev.slice(0, 2)]);
        setIsUpdating(false);
      }, 1000);
    }, 27000);

    return () => clearTimeout(timer);
  }, [isVisible]);

  return (
    <section
      ref={componentRef}
      className="relative py-12 w-full bg-slate-900 text-white"
    >
      <div className="container mx-auto px-4">
        {/* Encabezado y texto promocional */}
        <div className="text-center mb-10">
          <h1 className="text-3xl md:text-4xl font-bold mb-6">
            Servicios de Streaming en tu plataforma preferida
          </h1>

          {/* Íconos de plataformas */}
          <div className="flex justify-center gap-6 mb-6">
            <Instagram className="h-6 w-6 text-pink-400 hover:text-pink-300 transition-colors" />
            <Facebook className="h-6 w-6 text-blue-400 hover:text-blue-300 transition-colors" />
            <Youtube className="h-6 w-6 text-red-400 hover:text-red-300 transition-colors" />
            <Twitch className="h-6 w-6 text-purple-400 hover:text-purple-300 transition-colors" />
            <Twitter className="h-6 w-6 text-blue-400 hover:text-blue-300 transition-colors" />
          </div>

          <p className="text-base text-slate-300 max-w-2xl mx-auto">
            Contamos con tecnología avanzada para streamear resultados en tiempo
            real de tu carrera usando drones, cámaras fijas y más.
          </p>
        </div>

        {/* Contenido principal */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Televisión */}
          <div className="lg:col-span-2 rounded-lg overflow-hidden relative bg-black shadow-lg">
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/chava.mp4?alt=media&token=18c1b43b-03ed-44a4-8287-3f49eb0c2212"
              loop
              muted
              playsInline
              className="w-full h-full object-cover aspect-video"
            />

            {/* Indicador de LIVE */}
            <div className="absolute top-3 right-3 flex items-center bg-black/40 backdrop-blur-sm px-3 py-1 rounded-full">
              <CircleDot className="h-4 w-4 text-red-500 animate-pulse" />
              <span className="text-white text-xs font-medium ml-1">
                EN VIVO
              </span>
            </div>
          </div>

          {/* Leaderboard Mejorado */}
          <motion.div
            className="bg-slate-800 rounded-lg shadow-lg overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <div className="p-4 border-b border-slate-700 flex items-center">
              <Trophy className="h-5 w-5 text-yellow-500 mr-2" />
              <h2 className="text-lg font-medium text-white">Leaderboard</h2>
            </div>

            <div className="p-4">
              <AnimatePresence mode="popLayout">
                {leaderboard.map((player, index) => (
                  <motion.div
                    key={player.id}
                    initial={
                      index === 0 && isUpdating
                        ? { y: 100, opacity: 0 }
                        : { opacity: 1 }
                    }
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 500,
                      damping: 30,
                      mass: 1,
                    }}
                    className={`
                      mb-3 p-3 rounded relative overflow-hidden
                      ${
                        index === 0
                          ? "bg-gradient-to-r from-yellow-500/10 to-yellow-600/10 border border-yellow-500/30"
                          : "bg-slate-700/50"
                      }
                    `}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-3">
                        <span
                          className={`
                            text-sm font-bold w-6 h-6 flex items-center justify-center rounded-full
                            ${
                              index === 0
                                ? "bg-yellow-500 text-slate-900"
                                : index === 1
                                ? "bg-slate-400 text-slate-900"
                                : index === 2
                                ? "bg-amber-700 text-white"
                                : "bg-slate-600 text-white"
                            }
                          `}
                        >
                          {index + 1}
                        </span>
                        <div className="flex flex-col">
                          <span className="font-medium text-sm">
                            {player.name}
                          </span>
                          <span className="text-xs text-slate-400">
                            {player.time}
                          </span>
                        </div>
                      </div>

                      {player.previousPos > index + 1 && (
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          className="text-green-500 bg-green-500/10 p-1 rounded-full"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m18 15-6-6-6 6" />
                          </svg>
                        </motion.div>
                      )}
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default LiveStream;
