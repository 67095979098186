import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import {
    collection,
    query,
    where,
    onSnapshot,
    getDocs,
    doc,
    getDoc,
} from "firebase/firestore";
import {
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tabs,
    Tab,
    Chip,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Divider,
    Paper
} from "@mui/material";
import EventLeaderboardUI from "./EventLeaderboardUI";
import ComparePopup from "./ComparePopup";
import EventParticipationTable from "./EventParticipationTable";
import GlobalCategoryLeaderboard from "./GlobalCategoryLeaderboard";
import CategoryLeaderboardTable from "./CategoryLeaderboardTable";

// Importaciones de iconos
import PersonIcon from "@mui/icons-material/Person";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FilterListIcon from "@mui/icons-material/FilterList";
import EventIcon from "@mui/icons-material/Event";
import TableChartIcon from "@mui/icons-material/TableChart";
import CategoryIcon from "@mui/icons-material/Category";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MultiEventLeaderboard = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [runs, setRuns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ownerData, setOwnerData] = useState({});
    const [isCompareOpen, setIsCompareOpen] = useState(false);
    const [showParticipationTable, setShowParticipationTable] = useState(false);
    const [showGlobalLeaderboard, setShowGlobalLeaderboard] = useState(false);
    const [showCategoryTable, setShowCategoryTable] = useState(true);
    const [categories, setCategories] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [currentTrack, setCurrentTrack] = useState(0);
    const [tracks, setTracks] = useState([]);
    const [totalTimes, setTotalTimes] = useState([]);

    // Cargar lista de eventos disponibles
    useEffect(() => {
        const fetchEvents = async () => {
            const eventsCollection = collection(db, "events");
            const eventsSnapshot = await getDocs(eventsCollection);
            const eventsData = eventsSnapshot.docs.map((doc) => ({
                id: doc.id,
                eventName: doc.data().eventName || "Evento sin nombre",
                ...doc.data()
            }));
            setEvents(eventsData);
        };

        fetchEvents();
    }, []);

    // Cuando se seleccionen eventos, cargar sus datos
    useEffect(() => {
        if (selectedEvents.length === 0) return;

        const fetchCategoriesAndParticipants = async () => {
            let allCategories = [];
            let allParticipants = [];

            // Obtener categorías y participantes de todos los eventos seleccionados
            for (const eventId of selectedEvents) {
                const categoriesRef = collection(db, `events/${eventId}/categories`);
                const categoriesSnap = await getDocs(categoriesRef);
                const categoriesData = categoriesSnap.docs.map((doc) => ({
                    id: doc.id,
                    eventId: eventId, // Agregar eventId para identificar a qué evento pertenece
                    ...doc.data(),
                }));
                allCategories = [...allCategories, ...categoriesData];

                const participantsRef = collection(db, `events/${eventId}/participants`);
                const participantsSnap = await getDocs(participantsRef);
                const participantsData = participantsSnap.docs.map((doc) => ({
                    id: doc.id,
                    eventId: eventId, // Agregar eventId para identificar a qué evento pertenece
                    ...doc.data(),
                }));
                allParticipants = [...allParticipants, ...participantsData];
            }

            setCategories(allCategories);
            setParticipants(allParticipants);
        };

        fetchCategoriesAndParticipants();
    }, [selectedEvents]);

    // Cargar pistas y tiempos cuando los participantes estén disponibles
    useEffect(() => {
        if (selectedEvents.length === 0 || participants.length === 0) return;

        const fetchRunsAndTracks = async () => {
            let allRuns = [];
            let allTracks = [{ id: 0, name: "total" }];
            let trackMapping = new Map();

            // Obtener datos de pistas y carreras de todos los eventos seleccionados
            for (const eventId of selectedEvents) {
                const eventRef = doc(db, "events", eventId);
                const eventSnapshot = await getDoc(eventRef);
                if (!eventSnapshot.exists()) continue;

                const eventData = eventSnapshot.data();
                const eventName = eventData.eventName || "Evento sin nombre";

                // Obtener pistas del evento
                if (eventData.stages) {
                    const uniqueTracks = await Promise.all(
                        eventData.stages.map(async (s) => {
                            const { trackId, parkId } = s;
                            if (!trackId || !parkId) return null;

                            const parkRef = doc(db, "Parks", parkId);
                            const parkSnapshot = await getDoc(parkRef);
                            if (!parkSnapshot.exists()) return null;

                            const trackRef = doc(parkRef, "Tracks", trackId);
                            const trackSnapshot = await getDoc(trackRef);
                            if (trackSnapshot.exists()) {
                                // Incluir el nombre del evento en el nombre de la pista
                                return {
                                    id: trackSnapshot.id,
                                    name: `${eventName} - ${trackSnapshot.data().TrackName}`,
                                    eventId: eventId
                                };
                            } else {
                                return null;
                            }
                        })
                    );

                    const validTracks = uniqueTracks.filter(track => track !== null);
                    allTracks = [...allTracks, ...validTracks];

                    // Mapear los IDs de pistas para este evento
                    trackMapping.set(eventId, validTracks.map(t => t.id));
                }

                // Obtener carreras del evento
                const runsQuery = query(
                    collection(db, "Runs"),
                    where("EventID", "==", eventId)
                );

                const runsSnapshot = await getDocs(runsQuery);
                const runsData = runsSnapshot.docs
                    .map((doc) => ({
                        id: doc.id,
                        eventId: eventId, // Agregar eventId para identificar a qué evento pertenece
                        ...doc.data()
                    }))
                    .filter((run) => run.ElapsedTime);

                allRuns = [...allRuns, ...runsData];
            }

            // Ordenar carreras por tiempo
            const sortedRuns = allRuns.sort(
                (a, b) =>
                    convertTimeToMilliseconds(a.ElapsedTime) -
                    convertTimeToMilliseconds(b.ElapsedTime)
            );

            // Obtener mejores tiempos por usuario y pista
            const bestTimesByUser = sortedRuns.reduce((acc, run) => {
                const key = `${run.NFCIdentifier}-${run.TrackID}-${run.eventId}`;
                if (
                    !acc[key] ||
                    convertTimeToMilliseconds(run.ElapsedTime) <
                    convertTimeToMilliseconds(acc[key].ElapsedTime)
                ) {
                    acc[key] = run;
                }
                return acc;
            }, {});

            const uniqueRuns = Object.values(bestTimesByUser);
            setTracks(allTracks);

            // Calcular tiempos totales por evento
            const totalsByEvent = new Map();
            for (const eventId of selectedEvents) {
                const eventTracks = trackMapping.get(eventId) || [];
                const eventRuns = uniqueRuns.filter(run => run.eventId === eventId);

                const eventTotalTimes = calculateTotalTimes(eventRuns, eventTracks);
                if (eventTotalTimes && eventTotalTimes.length > 0) {
                    // Agregar identificador de evento a cada tiempo total
                    eventTotalTimes.forEach(time => {
                        time.eventId = eventId;
                        // Buscar nombre del evento
                        const event = events.find(e => e.id === eventId);
                        time.eventName = event ? event.eventName : "Evento desconocido";
                    });
                    totalsByEvent.set(eventId, eventTotalTimes);
                }
            }

            // Combinar todos los tiempos totales
            const allTotalTimes = Array.from(totalsByEvent.values()).flat();
            setTotalTimes(allTotalTimes);

            // Obtener información de propietarios
            const ownerPromises = uniqueRuns.map(async (run) => {
                const tagsQuery = query(
                    collection(db, "Tags"),
                    where("NFCID", "==", run.NFCIdentifier)
                );
                const tagsSnapshot = await getDocs(tagsQuery);

                if (!tagsSnapshot.empty) {
                    const ownerUID = tagsSnapshot.docs[0].data().Owner;
                    if (ownerUID === "") {
                        return {
                            NFCIdentifier: run.NFCIdentifier,
                            Owner: "Desconocido",
                            PhotoUrl: "/default-avatar.jpg",
                            categoryId: null,
                            eventId: run.eventId
                        };
                    }

                    const eventParticipants = participants.filter(p => p.eventId === run.eventId);
                    const participant = eventParticipants.find((p) => p.userId === ownerUID);
                    const categoryId = participant ? participant.categoryId : null;

                    const userDocRef = doc(db, "Users", ownerUID);
                    const userDoc = await getDoc(userDocRef);

                    if (!userDoc.empty) {
                        const userData = userDoc.data();
                        return {
                            NFCIdentifier: run.NFCIdentifier,
                            Owner: userData.name,
                            PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
                            categoryId: categoryId,
                            eventId: run.eventId
                        };
                    }
                }

                // Verificar si NFCIdentifier es un ID de usuario
                const userDocRef = doc(db, "Users", run.NFCIdentifier);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const ownerUID = userDoc.id;

                    const eventParticipants = participants.filter(p => p.eventId === run.eventId);
                    const participant = eventParticipants.find((p) => p.userId === ownerUID);
                    const categoryId = participant ? participant.categoryId : null;

                    return {
                        NFCIdentifier: run.NFCIdentifier,
                        Owner: userData.name,
                        PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
                        categoryId: categoryId,
                        eventId: run.eventId
                    };
                }

                return {
                    NFCIdentifier: run.NFCIdentifier,
                    Owner: "Desconocido",
                    PhotoUrl: "/default-avatar.jpg",
                    categoryId: null,
                    eventId: run.eventId
                };
            });

            const ownerResults = await Promise.all(ownerPromises);
            const ownerDataMap = ownerResults.reduce((acc, ownerResult) => {
                const key = `${ownerResult.NFCIdentifier}-${ownerResult.eventId}`;
                acc[key] = {
                    Owner: ownerResult.Owner,
                    PhotoUrl: ownerResult.PhotoUrl,
                    categoryId: ownerResult.categoryId,
                    eventId: ownerResult.eventId
                };
                return acc;
            }, {});

            setOwnerData(ownerDataMap);
            setRuns(uniqueRuns);
            setLoading(false);
        };

        fetchRunsAndTracks();
    }, [selectedEvents, participants, events]);

    const convertTimeToMilliseconds = (time) => {
        if (!time || time === "---" || time === "DNF") return null;
        try {
            const [hours, minutes, seconds] = time.split(":");
            const [secs, ms] = seconds.split(".");
            return (
                parseInt(hours) * 3600000 +
                parseInt(minutes) * 60000 +
                parseInt(secs) * 1000 +
                parseInt(ms)
            );
        } catch (error) {
            console.error("Error converting time:", time, error);
            return 0;
        }
    };

    const convertMillisecondsToTime = (ms) => {
        if (!ms || ms === 0) return "---";
        try {
            const hours = Math.floor(ms / 3600000);
            const minutes = Math.floor((ms % 3600000) / 60000);
            const seconds = Math.floor((ms % 60000) / 1000);
            const milliseconds = ms % 1000;

            return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
                    .toString()
                    .padStart(3, "0")}`;
        } catch (error) {
            console.error("Error converting milliseconds:", ms, error);
            return "---";
        }
    };

    const getTimeDifference = (aboveTime, currentTime) => {
        if (
            !aboveTime ||
            !currentTime ||
            aboveTime === "DNF" ||
            currentTime === "DNF"
        ) {
            return null;
        }
        try {
            const aboveInMs = convertTimeToMilliseconds(aboveTime);
            const currentInMs = convertTimeToMilliseconds(currentTime);
            return currentInMs - aboveInMs;
        } catch (error) {
            console.error("Error calculating time difference:", error);
            return null;
        }
    };

    const calculateTotalTimes = (runsData, requiredTrackIds) => {
        if (!runsData.length || !requiredTrackIds.length) return [];

        const totalsByUser = {};

        runsData.forEach((run) => {
            if (!totalsByUser[run.NFCIdentifier]) {
                totalsByUser[run.NFCIdentifier] = {
                    NFCIdentifier: run.NFCIdentifier,
                    ParkID: run.ParkID,
                    EventID: run.EventID,
                    eventId: run.eventId,
                    trackTimes: {},
                    totalTime: 0,
                    complete: true,
                    id: run.id,
                };
            }

            const currentTimeMs = convertTimeToMilliseconds(run.ElapsedTime);
            if (
                !totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID] ||
                currentTimeMs <
                convertTimeToMilliseconds(
                    totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID]
                )
            ) {
                totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID] =
                    run.ElapsedTime;
            }
        });

        const totalsArray = Object.values(totalsByUser).map((userData) => {
            const userTrackIDs = Object.keys(userData.trackTimes);

            requiredTrackIds.forEach((trackID) => {
                if (!userTrackIDs.includes(trackID)) {
                    userData.trackTimes[trackID] = "DNF";
                    userData.complete = false;
                }
            });

            if (userData.complete) {
                const totalMs = Object.values(userData.trackTimes).reduce(
                    (sum, time) => sum + convertTimeToMilliseconds(time),
                    0
                );
                userData.ElapsedTime = convertMillisecondsToTime(totalMs);
            } else {
                userData.ElapsedTime = "DNF";
            }

            return userData;
        });

        return totalsArray.sort((a, b) => {
            if (a.complete && !b.complete) return -1;
            if (!a.complete && b.complete) return 1;
            if (!a.complete && !b.complete) return 0;
            return (
                convertTimeToMilliseconds(a.ElapsedTime) -
                convertTimeToMilliseconds(b.ElapsedTime)
            );
        });
    };

    const handleEventChange = (event) => {
        const {
            target: { value },
        } = event;
        // On autofill we get a stringified value.
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        setSelectedEvents(selectedValues);

        // Reiniciar estado al cambiar eventos
        setCurrentTrack(0);
        setSelectedCategory("all");
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleTrackChange = (event, newValue) => {
        setCurrentTrack(newValue);
    };

    const toggleParticipationTable = () => {
        setShowParticipationTable(!showParticipationTable);
        if (!showParticipationTable) {
            setShowGlobalLeaderboard(false);
            setShowCategoryTable(false);
        }
    };

    const toggleGlobalLeaderboard = () => {
        setShowGlobalLeaderboard(!showGlobalLeaderboard);
        if (!showGlobalLeaderboard) {
            setShowParticipationTable(false);
            setShowCategoryTable(false);
        }
    };

    const toggleCategoryTable = () => {
        setShowCategoryTable(!showCategoryTable);
        if (!showCategoryTable) {
            setShowParticipationTable(false);
            setShowGlobalLeaderboard(false);
        }
    };

    const getFilteredRuns = () => {
        if (currentTrack === 0) {
            return totalTimes.filter((run) => {
                const ownerInfo = ownerData[`${run.NFCIdentifier}-${run.eventId}`];
                return (
                    selectedCategory === "all" ||
                    (ownerInfo && ownerInfo.categoryId === selectedCategory)
                );
            });
        }

        const currentTrackInfo = tracks[currentTrack];
        return runs.filter((run) => {
            const ownerInfo = ownerData[`${run.NFCIdentifier}-${run.eventId}`];
            const matchesCategory =
                selectedCategory === "all" ||
                (ownerInfo && ownerInfo.categoryId === selectedCategory);
            const matchesTrack = run.TrackID === currentTrackInfo.id &&
                run.eventId === currentTrackInfo.eventId;
            return matchesCategory && matchesTrack;
        });
    };

    if (loading && selectedEvents.length > 0) {
        return (
            <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
            </div>
        );
    }

    return (
        <div>
            <Box sx={{ mb: 3, p: 2 }}>
                <FormControl sx={{ width: "100%", mb: 2 }}>
                    <InputLabel id="multiple-event-label">Seleccionar Eventos</InputLabel>
                    <Select
                        labelId="multiple-event-label"
                        id="multiple-event"
                        multiple
                        value={selectedEvents}
                        onChange={handleEventChange}
                        input={<OutlinedInput label="Seleccionar Eventos" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {selected.map((value) => {
                                    const event = events.find(e => e.id === value);
                                    return (
                                        <Chip
                                            key={value}
                                            label={event ? event.eventName : value}
                                            icon={<EventIcon />}
                                        />
                                    );
                                })}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {events.map((event) => (
                            <MenuItem key={event.id} value={event.id}>
                                <Checkbox checked={selectedEvents.indexOf(event.id) > -1} />
                                <ListItemText primary={event.eventName || event.id} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedEvents.length > 1 && (
                    <Box sx={{ mb: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
                        <Button
                            variant="outlined"
                            startIcon={<CategoryIcon />}
                            onClick={toggleCategoryTable}
                            sx={{
                                borderRadius: "30px",
                                textTransform: "none"
                            }}
                            color={showCategoryTable ? "primary" : "inherit"}
                        >
                            Tiempos por Categoría
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<TableChartIcon />}
                            onClick={toggleParticipationTable}
                            sx={{
                                borderRadius: "30px",
                                textTransform: "none"
                            }}
                            color={showParticipationTable ? "primary" : "inherit"}
                        >
                            Tabla de Participación
                        </Button>

                        <Button
                            variant="outlined"
                            startIcon={<EmojiEventsIcon />}
                            onClick={toggleGlobalLeaderboard}
                            sx={{
                                borderRadius: "30px",
                                textTransform: "none"
                            }}
                            color={showGlobalLeaderboard ? "primary" : "inherit"}
                        >
                            Leaderboard Global
                        </Button>

                        <Typography variant="caption" color="text.secondary" sx={{ alignSelf: "center" }}>
                            Compara resultados de múltiples eventos
                        </Typography>
                    </Box>
                )}

                {showParticipationTable && selectedEvents.length > 1 && (
                    <EventParticipationTable
                        users={participants}
                        events={events.filter(event => selectedEvents.includes(event.id))}
                        totalTimes={totalTimes}
                        ownerData={ownerData}
                        categories={categories}
                        convertTimeToMilliseconds={convertTimeToMilliseconds}
                        convertMillisecondsToTime={convertMillisecondsToTime}
                    />
                )}

                {showGlobalLeaderboard && selectedEvents.length > 1 && (
                    <GlobalCategoryLeaderboard
                        events={events.filter(event => selectedEvents.includes(event.id))}
                        categories={categories}
                        totalTimes={totalTimes}
                        ownerData={ownerData}
                        convertTimeToMilliseconds={convertTimeToMilliseconds}
                        convertMillisecondsToTime={convertMillisecondsToTime}
                    />
                )}

                {showCategoryTable && selectedEvents.length > 1 && (
                    <CategoryLeaderboardTable
                        events={events.filter(event => selectedEvents.includes(event.id))}
                        categories={categories}
                        totalTimes={totalTimes}
                        ownerData={ownerData}
                        convertTimeToMilliseconds={convertTimeToMilliseconds}
                    />
                )}

                {selectedEvents.length > 0 && !showCategoryTable && !showParticipationTable && !showGlobalLeaderboard && (
                    <>
                        {tracks.length > 1 && (
                            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
                                <Tabs
                                    value={currentTrack}
                                    onChange={handleTrackChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label="Total" />
                                    {tracks.slice(1).map((t, index) => (
                                        <Tab key={t.id + index} label={t.name} />
                                    ))}
                                </Tabs>
                            </Box>
                        )}

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={3}
                            px={2}
                        >
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel>Categoría</InputLabel>
                                <Select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    label="Categoría"
                                    startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
                                >
                                    <MenuItem value="all">Todas las Categorías</MenuItem>
                                    {categories.map((category) => (
                                        <MenuItem key={category.id} value={category.id}>
                                            {category.name}
                                            {category.eventId && events.find(e => e.id === category.eventId)
                                                ? ` (${events.find(e => e.id === category.eventId).eventName})`
                                                : ""}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    borderRadius: "30px",
                                    textTransform: "none",
                                    "&:hover": { backgroundColor: "#333" },
                                }}
                                onClick={() => setIsCompareOpen(true)}
                                startIcon={<PersonIcon />}
                                endIcon={<PersonIcon />}
                            >
                                <CompareArrowsIcon sx={{ fontSize: "24px", mx: 1 }} />
                                Comparate
                            </Button>
                        </Box>

                        <ComparePopup
                            open={isCompareOpen}
                            onClose={() => setIsCompareOpen(false)}
                            runs={getFilteredRuns()}
                            ownerData={ownerData}
                            getTimeDifference={getTimeDifference}
                            multiEvent={true}
                            events={events}
                        />

                        <Box sx={{ bgcolor: "#F1F1F1", borderRadius: 2, m: 2, p: 2 }}>
                            <Typography variant="h6" fontWeight={600}>
                                {currentTrack === 0
                                    ? "Tiempo Total"
                                    : tracks[currentTrack].name || "Pista desconocida"}
                            </Typography>
                        </Box>

                        <EventLeaderboardUI
                            runs={getFilteredRuns()}
                            ownerData={ownerData}
                            loading={loading}
                            totalTimes={totalTimes}
                            currentTrack={currentTrack}
                            getTimeDifference={getTimeDifference}
                            multiEvent={true}
                            events={events}
                        />
                    </>
                )}
            </Box>
        </div>
    );
};

export default MultiEventLeaderboard;