import React from "react";
import { Shield, ExternalLink } from "lucide-react";

// Chat Message component con depuración
const ChatMessage = ({ message, isNew = false }) => {
    // Depuración para inspeccionar el mensaje recibido
    console.log("Rendering message:", message);

    // Verificar si el mensaje es válido
    if (!message || typeof message !== 'object') {
        console.error("Invalid message object:", message);
        return (
            <div className="p-3 bg-red-100 text-red-600 rounded-lg mb-2">
                Error: Mensaje inválido
            </div>
        );
    }

    const isUser = message.role === "user";
    const isAdmin = message.role === "admin";
    const isAssistant = message.role === "assistant";
    const isGuest = message.isGuest;
    const isSystem = message.role === "system";

    // Contenido por defecto
    const messageContent = message.content || "Mensaje sin contenido";
    const messageName = message.name || "Usuario";
    const messageAvatar = message.avatar || (isUser ? "/default-user.png" : "/default-bot.png");

    return (
        <div
            className={`flex flex-col ${isUser ? "items-end" : "items-start"} mb-4 ${isNew ? "new-message-animation" : ""}`}
        >
            <div
                className={`flex items-center gap-2 mb-1 ${isUser ? "flex-row-reverse" : "flex-row"
                    }`}
            >
                <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
                    <img
                        src={messageAvatar}
                        alt={messageName}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                            console.error("Error loading avatar:", messageAvatar);
                            e.target.src = "/default-user.png";
                        }}
                    />
                </div>
                <span className="text-xs text-gray-500">
                    {messageName}
                    {isAdmin && (
                        <Shield size={12} className="inline ml-1 text-blue-500" />
                    )}
                    {isGuest && (
                        <span className="inline-block ml-1 text-xs text-gray-400">
                            (Invitado)
                        </span>
                    )}
                </span>
            </div>

            <div
                className={`max-w-[80%] p-3 rounded-2xl break-words ${isUser
                        ? "bg-black text-white rounded-tr-none"
                        : isAdmin
                            ? "bg-blue-600 text-white rounded-tl-none"
                            : isSystem
                                ? "bg-indigo-600 text-white rounded-tl-none"
                                : "bg-gray-100 text-gray-800 rounded-tl-none"
                    }`}
            >
                <p className="text-sm whitespace-pre-wrap">{messageContent}</p>

                {/* Mostrar enlaces si existen */}
                {message.hasLink && message.linkRoute && (
                    <div className="mt-2 text-xs">
                        <a href={`/${message.linkRoute}`} className="text-white underline inline-flex items-center" target="_blank" rel="noopener noreferrer">
                            Ver recurso <ExternalLink size={12} className="ml-1" />
                        </a>
                    </div>
                )}
            </div>
            <div className="text-xs text-gray-400 mt-1">
                {message.timestamp?.toDate
                    ? new Date(message.timestamp.toDate()).toLocaleString("es-ES")
                    : "Enviando..."}
            </div>
        </div>
    );
};

export default ChatMessage;