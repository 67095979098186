import React from "react";
import { Linkedin } from "lucide-react";

const TeamMembers = () => {
  const members = [
    {
      name: "Pedro Aguirre",
      role: "Founder & Software Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1684958269703.jpeg?alt=media&token=deb2c747-6d17-445e-9676-32e2172af0f1",
      description:
        "Mi objetivo es crear un entorno donde la tecnología y la creatividad se combinen para superar las expectativas del usuario final.",
      linkedin: "https://www.linkedin.com/in/pedrone/",
    },
    {
      name: "Gerardo Carrizosa",
      role: "CO Founder, Software Engineer & Full-stack Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1675785281492.jpeg?alt=media&token=0c4527f3-67e4-4da5-850f-91d111f4dca9",
      description:
        "Experto en Arquitectura de software. Apasionado por la tecnología y el aprendizaje continuo.",
      linkedin: "https://www.linkedin.com/in/gerardocarrizosa/",
    },
    {
      name: "Guillermo Alvarez",
      role: "CTO & Project Manager",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/WhatsApp%20Image%202024-06-06%20at%2015.52.25.jpeg?alt=media&token=f44268f5-be8f-4e0c-a8ec-0c4de6ffaabf",
      description:
        "Trabajo en la interfaz entre el equipo de desarrollo, los clientes y los socios comerciales para definir las características y la dirección del producto.",
      linkedin: "https://www.linkedin.com/in/guillermoalvarez/",
    },
    {
      name: "Angel Armando Gutierrez",
      role: "Design Engineer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Home%2FScreenshot%202024-10-14%20at%2011.13.54%E2%80%AFa.m..png?alt=media&token=a624db17-be45-4801-b9c5-778194623988",
      description:
        "Me dedico a optimizar productos y crear diseños robustos, fáciles de usar y listos para la fabricación, asegurando eficiencia y calidad en cada etapa del desarrollo.",
      linkedin: "https://www.linkedin.com/in/angelarmando/",
    },
  ];

  return (
    <section className="py-16 px-4 bg-slate-50">
      <div className="container mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-slate-800 mb-2">
            Nuestro Equipo
          </h2>
          <div className="w-20 h-1 bg-slate-700 mx-auto mb-6"></div>
          <p className="text-base text-slate-600 max-w-2xl mx-auto">
            Las mentes detrás de nuestra visión empresarial.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {members.map((member) => (
            <div
              key={member.name}
              className="bg-white rounded-lg shadow-sm border border-slate-200 overflow-hidden transition-shadow hover:shadow-md"
            >
              <div className="p-6 pb-4 text-center border-b border-slate-100">
                <div className="mb-4 mx-auto">
                  <img
                    src={member.avatar}
                    alt={member.name}
                    className="w-24 h-24 rounded-full object-cover mx-auto border-2 border-slate-200"
                  />
                </div>

                <h3 className="text-lg font-semibold text-slate-800 mb-1">
                  {member.name}
                </h3>
                <p className="text-sm text-slate-500">{member.role}</p>
              </div>

              <div className="p-5">
                <p className="text-slate-600 text-sm mb-4 min-h-[80px]">
                  {member.description}
                </p>
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-4 py-2 rounded-md text-white bg-slate-700 hover:bg-slate-800 transition-colors w-full justify-center"
                >
                  <Linkedin size={16} />
                  <span className="text-sm font-medium">LinkedIn</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
