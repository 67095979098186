import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Tag,
  Box,
  DollarSign,
  Package,
  Layers,
  ArrowLeft,
  Plus,
  Minus,
  Star,
  Heart,
  Share2,
  Truck,
  ShoppingBag,
  ChevronRight,
} from "lucide-react";

const ProductDetail = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [product, setProduct] = useState(null);
  const [brandName, setBrandName] = useState("");
  const [modelName, setModelName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [activeImage, setActiveImage] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRef = doc(db, "ShopItems", uid);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          const productData = productSnap.data();
          setProduct(productData);

          if (productData.brand) {
            const brandRef = doc(db, "ShopBrand", productData.brand);
            const brandSnap = await getDoc(brandRef);
            if (brandSnap.exists()) {
              setBrandName(brandSnap.data().name);

              if (productData.model) {
                const modelRef = doc(brandRef, "Models", productData.model);
                const modelSnap = await getDoc(modelRef);
                if (modelSnap.exists()) {
                  setModelName(modelSnap.data().name);
                }
              }
            }
          }

          if (productData.category) {
            const categoryRef = doc(db, "ShopCategories", productData.category);
            const categorySnap = await getDoc(categoryRef);
            if (categorySnap.exists()) {
              setCategoryName(categorySnap.data().name);

              if (productData.subcategory) {
                const subcategoryRef = doc(
                  categoryRef,
                  "subcategory",
                  productData.subcategory
                );
                const subcategorySnap = await getDoc(subcategoryRef);
                if (subcategorySnap.exists()) {
                  setSubcategoryName(subcategorySnap.data().name);
                }
              }
            }
          }
        } else {
          console.log("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [uid]);

  const handleBuyNow = async () => {
    try {
      const orderData = {
        addons: [],
        categoryId: product.category,
        itemId: uid,
        status: "in_process",
        total: product.cost * quantity,
        type: "product",
        title: product.title,
        userId: user?.uid,
      };

      const orderRef = await addDoc(collection(db, "orders"), orderData);
      navigate(`/checkout/${orderRef.id}`);
    } catch (error) {
      console.error("Error creating order:", error);
      alert("There was an error processing your order. Please try again.");
    }
  };

  const handleAddToCart = () => {
    // Implement add to cart functionality
    alert(`Added ${quantity} item(s) to your cart`);
  };

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  // Calculate delivery date (3 days from now)
  const getDeliveryDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-pulse flex flex-col items-center">
          <div className="w-24 h-24 bg-gray-200 rounded-full mb-4"></div>
          <div className="text-gray-500">Loading product details...</div>
        </div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center p-8 bg-white rounded-xl shadow-sm max-w-md">
          <div className="text-5xl mb-4">🔍</div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Product Not Found
          </h2>
          <p className="text-gray-600 mb-6">
            The product you're looking for doesn't seem to exist.
          </p>
          <button
            onClick={() => navigate("/shop")}
            className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition"
          >
            Return to Shop
          </button>
        </div>
      </div>
    );
  }

  // Ensure we have at least one image
  const productImages =
    product.images && product.images.length > 0
      ? product.images
      : ["/placeholder-image.png"];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Breadcrumb Navigation */}
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center text-sm text-gray-500 space-x-1">
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate("/shop")}
          >
            Shop
          </span>
          <ChevronRight className="w-3 h-3" />
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate(`/shop?category=${product.category}`)}
          >
            {categoryName}
          </span>
          {subcategoryName && (
            <>
              <ChevronRight className="w-3 h-3" />
              <span
                className="hover:underline cursor-pointer"
                onClick={() =>
                  navigate(
                    `/shop?category=${product.category}&subcategory=${product.subcategory}`
                  )
                }
              >
                {subcategoryName}
              </span>
            </>
          )}
          <ChevronRight className="w-3 h-3" />
          <span className="font-medium text-gray-800">{product.title}</span>
        </div>
      </div>

      {/* Product Info Section */}
      <div className="max-w-7xl mx-auto px-6 pb-16">
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
          <div className="flex flex-col lg:flex-row">
            {/* Image Gallery Section */}
            <div className="w-full lg:w-1/2 p-8 bg-gray-50">
              <div className="relative mb-6 bg-white rounded-xl overflow-hidden aspect-square flex items-center justify-center p-4">
                <img
                  src={productImages[activeImage]}
                  alt={product.title}
                  className="max-h-full max-w-full object-contain"
                />

                {/* Favorite Button */}
                <button
                  onClick={toggleFavorite}
                  className="absolute top-4 right-4 p-2 bg-white rounded-full shadow hover:bg-gray-100 transition"
                >
                  <Heart
                    className={`w-5 h-5 ${
                      isFavorite ? "text-red-500 fill-red-500" : "text-gray-400"
                    }`}
                  />
                </button>
              </div>

              {/* Thumbnail Gallery */}
              {productImages.length > 1 && (
                <div className="flex space-x-2 justify-center">
                  {productImages.map((img, index) => (
                    <button
                      key={index}
                      onClick={() => setActiveImage(index)}
                      className={`w-16 h-16 rounded-lg overflow-hidden ${
                        activeImage === index
                          ? "ring-2 ring-black"
                          : "ring-1 ring-gray-200"
                      }`}
                    >
                      <img
                        src={img}
                        alt={`${product.title} view ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Product Details Section */}
            <div className="w-full lg:w-1/2 p-8 flex flex-col">
              <div className="flex-grow">
                {/* Brand and Category */}
                <div className="flex items-center space-x-2 text-sm text-gray-500 mb-2">
                  <span className="font-medium">{brandName}</span>
                  <span>•</span>
                  <span>{categoryName}</span>
                  {subcategoryName && (
                    <>
                      <span>•</span>
                      <span>{subcategoryName}</span>
                    </>
                  )}
                </div>

                {/* Product Title */}
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  {product.title}
                </h1>

                {/* Rating Placeholder */}
                <div className="flex items-center mb-4">
                  <div className="flex">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star}
                        className="w-4 h-4"
                        fill={star <= 4 ? "#000" : "none"}
                        stroke={star <= 4 ? "#000" : "#777"}
                      />
                    ))}
                  </div>
                  <span className="ml-2 text-sm text-gray-600">
                    4.0 (24 reviews)
                  </span>
                </div>

                {/* Price */}
                <div className="mb-6">
                  <p className="text-3xl font-bold text-gray-900">
                    ${product.cost}
                  </p>
                </div>

                {/* Description */}
                <div className="mb-6">
                  <p className="text-gray-700">
                    {product.description ||
                      "Premium quality product designed for durability and performance. Perfect for cycling enthusiasts looking for the best equipment."}
                  </p>
                </div>

                {/* Specs and Details */}
                <div className="grid grid-cols-2 gap-4 mb-6 text-sm">
                  <div className="flex items-center">
                    <Box className="w-4 h-4 mr-2 text-gray-500" />
                    <span className="text-gray-600">
                      Model:{" "}
                      <span className="font-medium text-gray-900">
                        {modelName}
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Tag className="w-4 h-4 mr-2 text-gray-500" />
                    <span className="text-gray-600">
                      Brand:{" "}
                      <span className="font-medium text-gray-900">
                        {brandName}
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Layers className="w-4 h-4 mr-2 text-gray-500" />
                    <span className="text-gray-600">
                      Category:{" "}
                      <span className="font-medium text-gray-900">
                        {categoryName}
                      </span>
                    </span>
                  </div>
                  <div className="flex items-center">
                    <Package className="w-4 h-4 mr-2 text-gray-500" />
                    <span className="text-gray-600">
                      Stock:{" "}
                      <span className="font-medium text-gray-900">
                        {product.stock}
                      </span>
                    </span>
                  </div>
                </div>

                {/* Delivery Info */}
                <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-start">
                    <Truck className="w-5 h-5 mr-3 text-gray-600 mt-0.5" />
                    <div>
                      <p className="font-medium text-gray-900">Free delivery</p>
                      <p className="text-sm text-gray-600">
                        Expected by {getDeliveryDate()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Quantity Control and Buttons */}
              <div className="mt-4">
                <p className="text-sm font-medium text-gray-700 mb-2">
                  Quantity
                </p>
                <div className="flex items-center mb-6">
                  <button
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    className="w-10 h-10 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 transition"
                    disabled={quantity <= 1}
                  >
                    <Minus className="w-4 h-4" />
                  </button>
                  <span className="mx-4 w-10 text-center font-medium text-lg">
                    {quantity}
                  </span>
                  <button
                    onClick={() => setQuantity(quantity + 1)}
                    className="w-10 h-10 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gray-100 transition"
                    disabled={quantity >= product.stock}
                  >
                    <Plus className="w-4 h-4" />
                  </button>
                </div>

                {/* Action Buttons */}
                <div className="flex gap-4">
                  <button
                    onClick={handleBuyNow}
                    className="flex-1 bg-black text-white py-3 rounded-full font-medium hover:bg-gray-800 transition flex items-center justify-center"
                  >
                    Buy Now
                  </button>
                  <button
                    onClick={handleAddToCart}
                    className="flex-1 border border-black bg-white text-black py-3 rounded-full font-medium hover:bg-gray-100 transition flex items-center justify-center"
                  >
                    <ShoppingBag className="w-4 h-4 mr-2" />
                    Add to Cart
                  </button>
                </div>

                {/* Share Button */}
                <div className="flex justify-center mt-4">
                  <button className="flex items-center text-gray-600 hover:text-gray-900 text-sm">
                    <Share2 className="w-4 h-4 mr-1" />
                    Share this product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
