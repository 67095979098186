import { addDoc, collection } from "firebase/firestore";
import { Calendar, Clock, MapPin, Tag, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

const MergeRunsTableV2 = ({ receiverData, startData, endData }) => {
  const [runs, setRuns] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (startData && endData && receiverData) {
      const calculatedRuns = processRuns();
      const flatCalculatedRuns = flattenRunsIntoLaps(calculatedRuns);
      setRuns(flatCalculatedRuns);
    }
  }, [startData, endData, receiverData]);

  const handleUpload = async () => {
    if (runs.length === 0) return;

    setUploading(true);
    try {
      const batchUpload = runs.map((run) =>
        addDoc(collection(db, "Runs"), run)
      );
      await Promise.all(batchUpload);
      alert("Tiempos subidos exitosamente.");
      setRuns([]); // Clear runs after upload
    } catch (error) {
      console.error("Error uploading runs:", error);
      alert("Error al subir los tiempos procesados.");
    } finally {
      setUploading(false);
    }
  };

  function getDateDifference(date1, date2) {
    if (date1.getTime() > date2.getTime()) {
      const diffMilliseconds = date1.getTime() - date2.getTime();
      return diffMilliseconds;
    }
    return null;
  }

  function getFastestLap(laps) {
    if (laps.length === 0) return null;
    laps.sort((a, b) => a.elapsed - b.elapsed);
    return laps[0];
  }

  function formatMilliseconds(ms) {
    const date = new Date(ms);

    // Obtener los componentes de la fecha
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 porque los meses son 0-11
    const day = String(date.getDate()).padStart(2, "0");

    // Obtener los componentes de la hora
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

    // Obtener la zona horaria
    const timezoneOffset = -date.getTimezoneOffset();
    const timezoneSign = timezoneOffset >= 0 ? "+" : "-";
    const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60);

    // Formato: YYYY-MM-DD HH:MM:SS.mmm UTC±H
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds} UTC${timezoneSign}${timezoneHours}`;
  }

  const formatElapsedTime = (milliseconds) => {
    // Format milliseconds into HH:mm:ss.sss format properly
    const totalSeconds = Math.floor(milliseconds / 1000);
    const ms = String(milliseconds % 1000).padStart(3, "0");
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      "0"
    );
    const seconds = String(totalSeconds % 60).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}.${ms}`;
  };

  const processRuns = () => {
    const startTimes = [...startData];
    const finishTimes = [...endData];

    const finishTimesNfcIds = [];
    for (const ft of finishTimes) {
      if (!finishTimesNfcIds.includes(ft.UID)) finishTimesNfcIds.push(ft.UID);
    }
    const ridersLaps = [];
    for (let i = 0; i < finishTimesNfcIds.length; i++) {
      const nfcId = finishTimesNfcIds[i];
      console.log("👨‍🏭 Trabajandod con: ", nfcId);
      const laps = [];

      var finishNfcReadings = finishTimes
        .filter((ft) => ft.UID === nfcId)
        .sort((a, b) => a.UID.localeCompare(b.UID));

      var startNfcReadings = startTimes
        .filter((st) => st.UID === nfcId)
        .sort((a, b) => a.UID.localeCompare(b.UID));

      const usedStartTimes = [];
      for (let j = 0; j < finishNfcReadings.length; j++) {
        const possibleLaps = [];
        const f = finishNfcReadings[j];
        const fTime = new Date(f.Time);
        console.log("🏁🏁🏁 fTime =>", fTime.toLocaleTimeString());
        for (let k = 0; k < startNfcReadings.length; k++) {
          const s = startNfcReadings[k];
          const sTime = new Date(s.Time);
          console.log("🏁 sTime =>", sTime);
          if (usedStartTimes.includes(sTime.getTime())) {
            continue;
          }
          const timeDifference = getDateDifference(fTime, sTime);
          console.log("⌛ timeDifference =>", timeDifference);
          if (!timeDifference) {
            continue;
          } else {
            possibleLaps.push({
              nfcId: nfcId,
              start: sTime,
              finish: fTime,
              elapsed: timeDifference,
            });
            usedStartTimes.push(sTime.getTime());
          }
        }
        console.log("⁉️ possibleLaps =>", possibleLaps);
        const fastestLap = getFastestLap(possibleLaps);
        if (fastestLap) laps.push(fastestLap);
        // finishNfcReadings.shift();
      }

      ridersLaps.push({
        nfcId,
        laps,
      });
    }

    return ridersLaps;
  };

  const flattenRunsIntoLaps = (runs) => {
    const flattened = [];

    runs.forEach((run) => {
      run.laps.forEach((lap) => {
        flattened.push({
          RUID: receiverData.RUID || "",
          StartTime: formatMilliseconds(lap.start),
          FinishTime: formatMilliseconds(lap.finish),
          ElapsedTime: lap.elapsed,
          ActiveRun: false,
          EventID: receiverData.EventID || "",
          NFCIdentifier: run.nfcId,
          ParkID: receiverData.ParkID || "",
          TrackID: receiverData.TrackID || "",
        });
      });
    });

    const sorted = flattened.sort((a, b) => a.ElapsedTime - b.ElapsedTime);

    return sorted.map((f) => {
      return {
        ...f,
        ElapsedTime: formatElapsedTime(f.ElapsedTime),
      };
    });
  };

  const formatDateTime = (date) => {
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  const headers = [
    { header: "NFC ID", icon: <Tag size={16} className="text-gray-400" /> },
    {
      header: "Start Time",
      icon: <Clock size={16} className="text-gray-400" />,
    },
    {
      header: "Finish Time",
      icon: <Clock size={16} className="text-gray-400" />,
    },
    {
      header: "Elapsed Time",
      icon: <Clock size={16} className="text-gray-400" />,
    },
    {
      header: "Event ID",
      icon: <Calendar size={16} className="text-gray-400" />,
    },
    { header: "RUID", icon: <User size={16} className="text-gray-400" /> },
    { header: "Track", icon: <MapPin size={16} className="text-gray-400" /> },
    { header: "Park", icon: <MapPin size={16} className="text-gray-400" /> },
  ];

  return (
    <div className="p-6 mx-auto">
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b border-gray-100">
                {headers.map((h) => (
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <div className="flex items-center space-x-2">
                      {h.icon}
                      <span>{h.header}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {runs.map((lap, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  <td className="px-4 py-3 whitespace-nowrap">
                    <span className="font-medium text-gray-900">
                      {lap.NFCIdentifier}
                    </span>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    {formatDateTime(lap.StartTime)}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    {formatDateTime(lap.FinishTime)}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap">
                    <span className="font-mono font-medium text-gray-800">
                      {lap.ElapsedTime}
                    </span>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    {lap.EventID.slice(0, 6)}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    {lap.RUID.slice(0, 6)}
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    <div className="flex items-center space-x-1">
                      <span>{lap.TrackID.slice(0, 6)}</span>
                    </div>
                  </td>
                  <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-600">
                    <div className="flex items-center space-x-1">
                      <span>{lap.ParkID.slice(0, 6)}</span>
                    </div>
                  </td>
                </tr>
              ))}
              {runs.length === 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="px-4 py-8 text-center text-gray-500"
                  >
                    No lap data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-4">
        <button
          className="btn btn-primary"
          onClick={() => {
            const confirmUpload = window.confirm(
              `¿Estás seguro de que deseas subir ${runs.length} tiempos procesados?`
            );
            if (confirmUpload) handleUpload();
          }}
          disabled={uploading || runs.length === 0}
        >
          {uploading ? (
            <div>Loading...</div>
          ) : (
            `Subir ${runs.length} tiempo${
              runs.length !== 1 ? "s" : ""
            } procesado${runs.length !== 1 ? "s" : ""}`
          )}
        </button>
      </div>
    </div>
  );
};

export default MergeRunsTableV2;
