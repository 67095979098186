import React, { useEffect } from "react";
import { MessageSquare } from "lucide-react";
import ChatMessage from "./ChatMessage";
import ChatInfoPanel from "./ChatInfoPanel";

const ChatMessagesArea = ({
    chatMessages,
    chatEndRef,
    selectedChat,
    formatChatTime
}) => {
    // Depuración para inspeccionar los mensajes recibidos
    useEffect(() => {
        console.log("ChatMessagesArea received messages:", chatMessages);
        console.log("Messages count:", chatMessages?.length || 0);
    }, [chatMessages]);

    // Verificar si chatMessages es un array
    if (!Array.isArray(chatMessages)) {
        console.error("chatMessages is not an array:", chatMessages);
        return (
            <div className="flex-1 p-4 overflow-y-auto bg-gray-50">
                <div className="bg-red-100 text-red-700 p-4 rounded-lg">
                    Error: Los mensajes de chat no tienen el formato correcto. Por favor, contacta con soporte.
                </div>
            </div>
        );
    }

    return (
        <div className="flex-1 p-4 overflow-y-auto bg-gray-50">
            {chatMessages.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full text-gray-500">
                    <MessageSquare size={48} className="mb-4 opacity-30" />
                    <p>No hay mensajes en esta conversación</p>
                </div>
            ) : (
                <div>
                    {/* Información de chat y controles */}
                    <ChatInfoPanel
                        selectedChat={selectedChat}
                        formatChatTime={formatChatTime}
                        messagesCount={chatMessages.length}
                    />

                    {/* Muestra información de depuración en desarrollo */}
                    {process.env.NODE_ENV !== 'production' && (
                        <div className="mb-4 p-2 bg-yellow-50 border border-yellow-200 rounded text-xs">
                            <p>Depuración: {chatMessages.length} mensajes cargados</p>
                            <p>Primer mensaje: {chatMessages[0]?.content?.substring(0, 30)}...</p>
                            <p>Último mensaje: {chatMessages[chatMessages.length - 1]?.content?.substring(0, 30)}...</p>
                        </div>
                    )}

                    {/* Lista de mensajes */}
                    {chatMessages.map((message, index) => (
                        <ChatMessage
                            key={`msg-${index}-${message.id || Date.now()}`}
                            message={message}
                            isNew={index === chatMessages.length - 1 && message.role === "user"}
                        />
                    ))}
                </div>
            )}
            <div ref={chatEndRef} />
        </div>
    );
};

export default ChatMessagesArea;