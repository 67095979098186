import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { Search, Edit, MapPin, Users, Heart, Mountain } from "lucide-react";

const Parks = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [parks, setParks] = useState([]);
  const [filteredParks, setFilteredParks] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.uid) {
        try {
          const userDocRef = doc(db, "Users", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setIsAdmin(!!userData.ParkAdmin);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();

    const unsubscribe = onSnapshot(
      collection(db, "Parks"),
      (snapshot) => {
        const parksData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Ordenar los parques por cantidad de seguidores (de más a menos)
        const sortedParks = parksData.sort((a, b) => {
          const followersA = Array.isArray(a.Followers)
            ? a.Followers.length
            : 0;
          const followersB = Array.isArray(b.Followers)
            ? b.Followers.length
            : 0;
          return followersB - followersA; // Orden descendente
        });
        setParks(sortedParks);
        setFilteredParks(sortedParks);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching parks data:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const redirectToPark = (parkId) => {
    navigate(`/park/${parkId}`);
  };

  const redirectToParkManager = () => {
    navigate("/myparks");
  };

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = parks.filter((park) =>
      park.ParkName?.toLowerCase().includes(value)
    );
    // Mantener el ordenamiento por seguidores cuando se filtra
    const sortedFiltered = filtered.sort((a, b) => {
      const followersA = Array.isArray(a.Followers) ? a.Followers.length : 0;
      const followersB = Array.isArray(b.Followers) ? b.Followers.length : 0;
      return followersB - followersA; // Orden descendente
    });
    setFilteredParks(sortedFiltered);
  };

  // Card component for each park
  const ParkCard = ({ park }) => (
    <div className="flex flex-col overflow-hidden rounded-xl bg-white shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
      <div className="cursor-pointer" onClick={() => redirectToPark(park.id)}>
        <div className="h-56 w-full overflow-hidden">
          <img
            src={park.Cover || ""}
            alt={`Foto del parque ${park.ParkName || ""}`}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="p-4">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-bold text-lg truncate mr-2">
              {park.ParkName || "Parque sin nombre"}
            </h3>
            <div className="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
              <img
                src={park.Logo || ""}
                alt={`${park.ParkName || ""} logo`}
                className="h-full w-full object-cover"
              />
            </div>
          </div>

          <div className="mb-2 flex items-center">
            <MapPin className="mr-2 h-4 w-4 text-gray-500" />
            <p className="text-sm text-gray-600 truncate">
              {park.city || ""}, {park.State || ""}, {park.country || ""}
            </p>
          </div>

          <div className="mb-2 flex items-center">
            <Users className="mr-2 h-4 w-4 text-gray-500" />
            <p className="text-sm text-gray-600">
              {Array.isArray(park.Followers) ? park.Followers.length : 0}{" "}
              seguidores
            </p>
          </div>

          {park.location &&
            typeof park.location.latitude === "number" &&
            typeof park.location.longitude === "number" && (
              <div className="mb-2 flex items-center">
                <Mountain className="mr-2 h-4 w-4 text-gray-500" />
                <p className="text-sm text-gray-600 truncate">
                  {park.location.latitude.toFixed(2)},{" "}
                  {park.location.longitude.toFixed(2)}
                </p>
              </div>
            )}

          <div className="mt-4 flex items-center justify-between">
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <svg
                  key={star}
                  className={`h-4 w-4 ${
                    star <= 4
                      ? "text-yellow-400"
                      : star <= 4.5
                      ? "text-yellow-400"
                      : "text-gray-300"
                  }`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
            <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-1 text-xs font-medium text-blue-800">
              <Heart className="mr-1 h-3 w-3 text-blue-500" />
              Favorito
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  // Loading skeletons
  const SkeletonCard = () => (
    <div className="flex flex-col rounded-xl bg-white shadow-lg overflow-hidden">
      <div className="h-56 w-full bg-gray-200 animate-pulse"></div>
      <div className="p-4">
        <div className="h-6 bg-gray-200 rounded w-3/4 mb-4 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-1/2 mb-2 animate-pulse"></div>
        <div className="h-4 bg-gray-200 rounded w-1/3 animate-pulse"></div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <h1 className="text-3xl font-bold text-blue-600 mb-4 sm:mb-0">
          Descubre Parques Increíbles
        </h1>
        {isAdmin && (
          <button
            onClick={redirectToParkManager}
            className="flex items-center rounded-full bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 transition-colors"
          >
            <Edit className="mr-2 h-4 w-4" />
            Gestionar Parques
          </button>
        )}
      </div>

      <div className="mb-8 mx-auto max-w-lg">
        <div className="relative">
          <input
            type="text"
            placeholder="Busca tu próxima aventura..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full rounded-full border border-gray-300 py-3 pl-12 pr-4 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-4">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {loading
          ? Array.from(new Array(6)).map((_, index) => (
              <SkeletonCard key={index} />
            ))
          : filteredParks.map((park) => <ParkCard key={park.id} park={park} />)}
      </div>

      {!loading && filteredParks.length === 0 && (
        <div className="mt-8 text-center">
          <p className="text-xl text-gray-500">
            No se encontraron parques. Intenta con otra búsqueda.
          </p>
        </div>
      )}
    </div>
  );
};

export default Parks;
