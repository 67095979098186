import React, { useState, useEffect, useRef } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Users, Leaf, RotateCw, Map } from "lucide-react";
import CountUp from "react-countup";

const StatItem = ({ icon, count, label, start }) => (
  <div className="flex flex-col items-center p-6 bg-white rounded-lg border border-slate-100 shadow-sm">
    <div className="text-slate-700 mb-3">{icon}</div>
    <h3 className="text-2xl font-semibold text-slate-900">
      <CountUp start={start ? 0 : count} end={count} duration={2} />
    </h3>
    <p className="text-sm text-slate-500 mt-1">{label}</p>
  </div>
);

const LiveStats = () => {
  const [parksCount, setParksCount] = useState(0);
  const [runsCount, setRunsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [tracksCount, setTracksCount] = useState(0);
  const [startCount, setStartCount] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parksCollectionRef = collection(db, "Parks");
        const parksSnapshot = await getDocs(parksCollectionRef);
        setParksCount(parksSnapshot.size);

        let totalTracksCount = 0;
        for (const parkDoc of parksSnapshot.docs) {
          const tracksCollectionRef = collection(parkDoc.ref, "Tracks");
          const tracksSnapshot = await getDocs(tracksCollectionRef);
          totalTracksCount += tracksSnapshot.size;
        }
        setTracksCount(totalTracksCount);

        const runsCollectionRef = collection(db, "Runs");
        const runsSnapshot = await getDocs(runsCollectionRef);
        setRunsCount(runsSnapshot.size);

        const usersCollectionRef = collection(db, "Users");
        const usersSnapshot = await getDocs(usersCollectionRef);
        setUsersCount(usersSnapshot.size);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <section ref={statsRef} className="py-10 bg-slate-50">
      <div className="container mx-auto px-4">
        <h2 className="text-xl font-semibold text-slate-800 text-center mb-6">
          Estadísticas en tiempo real
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <StatItem
            icon={<Leaf size={24} />}
            count={parksCount}
            label="Parques"
            start={startCount}
          />

          <StatItem
            icon={<Map size={24} />}
            count={tracksCount}
            label="Pistas"
            start={startCount}
          />

          <StatItem
            icon={<RotateCw size={24} />}
            count={runsCount}
            label="Vueltas"
            start={startCount}
          />

          <StatItem
            icon={<Users size={24} />}
            count={usersCount}
            label="Usuarios"
            start={startCount}
          />
        </div>

        <p className="text-xs text-slate-400 text-center mt-5">
          Datos actualizados automáticamente
        </p>
      </div>
    </section>
  );
};

export default LiveStats;
