import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import Flag from "react-world-flags";
import {
  ArrowLeft,
  Settings,
  MapPin,
  Users,
  Route,
  Calendar,
  Camera,
  Trophy,
} from "lucide-react";

// Helper function for country codes
const getCountryCode = (country) => {
  const countryCodes = {
    Mexico: "MEX",
    USA: "USA",
    Canada: "CAN",
    // Añadir más países según sea necesario
  };
  return countryCodes[country] || null;
};

const ProfileStats = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [profileUser, setProfileUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ridesCount, setRidesCount] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [eventsCount, setEventsCount] = useState(0);
  const [friendRequestSent, setFriendRequestSent] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [showLoginWarning, setShowLoginWarning] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToUser(uid);
    return () => unsubscribe();
  }, [uid, user?.uid]);

  const subscribeToUser = (userId) => {
    const userRef = doc(db, "Users", userId);
    return onSnapshot(userRef, (userSnap) => {
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setProfileUser(userData);
        setFriendsCount(userData.friends?.length || 0);
        setFriendRequestSent(
          userData.friendRequests?.includes(user?.uid) || false
        );
        setIsFriend(userData.friends?.includes(user?.uid) || false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const fetchRidesData = async () => {
      try {
        const ridesRef = collection(db, "Rides");
        const q = query(ridesRef, where("riders", "array-contains", uid));
        const querySnapshot = await getDocs(q);

        setRidesCount(querySnapshot.size);
      } catch (error) {
        console.error("Error fetching rides data:", error);
      }
    };

    const fetchEventsParticipation = async () => {
      try {
        // Obtener todos los eventos
        const eventsRef = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsRef);

        let participationCount = 0;

        // Para cada evento, verificar si el usuario está en la subcolección de participantes
        for (const eventDoc of eventsSnapshot.docs) {
          const participantsRef = collection(
            db,
            "events",
            eventDoc.id,
            "participants"
          );
          const participantsSnapshot = await getDocs(participantsRef);

          // Verificar cada documento en la subcolección participants
          for (const participantDoc of participantsSnapshot.docs) {
            const participantData = participantDoc.data();
            if (participantData.userId === uid) {
              participationCount++;
              break; // Si encontramos al usuario en este evento, pasamos al siguiente
            }
          }
        }

        setEventsCount(participationCount);
      } catch (error) {
        console.error("Error fetching events participation:", error);
      }
    };

    fetchRidesData();
    fetchEventsParticipation();
  }, [uid]);

  const handleAddFriend = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayUnion(user.uid),
        });
        setFriendRequestSent(true);
      } catch (error) {
        console.error("Error adding friend: ", error);
      }
    }
  };

  const handleCancelFriendRequest = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayRemove(user.uid),
        });
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error cancelling friend request: ", error);
      }
    }
  };

  const handleFollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayUnion(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayUnion(uid),
        });
        setIsFriend(true);
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error following user: ", error);
      }
    }
  };

  const handleUnfollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayRemove(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayRemove(uid),
        });
        setIsFriend(false);
      } catch (error) {
        console.error("Error unfollowing user: ", error);
      }
    }
  };

  const handleSettingsClick = () => {
    navigate("/addProfile");
  };

  if (loading) {
    return (
      <div className="h-screen w-full flex justify-center items-center bg-gray-50">
        <div className="animate-pulse flex flex-col items-center">
          <div className="rounded-full bg-gray-200 h-24 w-24 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-48 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  if (!profileUser) {
    return (
      <div className="h-screen w-full flex justify-center items-center bg-gray-50">
        <div className="text-center p-8 bg-white rounded-xl shadow-sm max-w-md">
          <div className="text-5xl mb-4">🔍</div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            User Not Found
          </h2>
          <p className="text-gray-600 mb-6">
            The profile you're looking for doesn't seem to exist.
          </p>
          <button
            onClick={() => navigate("/home")}
            className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  const countryCode = getCountryCode(profileUser.country);

  return (
    <div className="w-full bg-white pb-4">
      {/* Header with back button */}
      <div className="px-4 py-3 border-b border-gray-100 flex justify-start items-center">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-700 hover:text-black transition-colors"
        >
          <ArrowLeft className="w-5 h-5 mr-1" />
          <span className="font-medium">Back</span>
        </button>
      </div>

      {/* Profile Header Section */}
      <div className="flex flex-col items-center py-6">
        {/* Profile Image - Clickable */}
        <div
          className="mb-3 relative cursor-pointer"
          onClick={() => profileUser.photoUrl && setShowImageModal(true)}
        >
          <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-100 shadow-sm">
            {profileUser.photoUrl ? (
              <img
                src={profileUser.photoUrl}
                alt={profileUser.name}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <Camera className="w-8 h-8 text-gray-400" />
              </div>
            )}
          </div>
        </div>

        {/* Profile Name and Nickname */}
        <h1 className="text-xl font-bold text-gray-900 mb-1">
          {profileUser.name}
        </h1>
        <p className="text-gray-500 text-sm mb-3">@{profileUser.nickName}</p>

        {/* Location with Flag */}
        <div className="flex items-center justify-center mb-1">
          {countryCode && (
            <Flag code={countryCode} height="16" className="inline-block" />
          )}
        </div>

        {/* City and State */}
        <div className="flex items-center justify-center text-sm text-gray-500 mb-4">
          <MapPin className="w-3 h-3 mr-1" />
          <span>
            {profileUser.city}, {profileUser.state}
          </span>
        </div>

        {/* Action Button */}
        {user?.uid === uid ? (
          <button
            onClick={handleSettingsClick}
            className="px-5 py-1.5 bg-white border border-gray-300 text-gray-800 rounded-full hover:bg-gray-100 transition text-sm flex items-center justify-center"
          >
            <Settings className="w-3 h-3 mr-1" />
            Edit Profile
          </button>
        ) : isFriend ? (
          <button
            onClick={handleUnfollow}
            className="px-5 py-1.5 bg-white border border-gray-300 text-gray-800 rounded-full hover:bg-gray-100 transition text-sm"
          >
            Unfollow
          </button>
        ) : friendRequestSent ? (
          <button
            onClick={handleCancelFriendRequest}
            className="px-5 py-1.5 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition text-sm"
          >
            Cancel Request
          </button>
        ) : (
          <button
            onClick={() => {
              if (user) {
                handleAddFriend();
              } else {
                setShowLoginWarning(true);
              }
            }}
            className="px-5 py-1.5 bg-gray-800 text-white rounded-full hover:bg-black transition text-sm"
          >
            Follow
          </button>
        )}
      </div>

      {/* Stats Section */}
      <div className="grid grid-cols-3 gap-2 px-4 py-3 border-t border-gray-100">
        <div className="text-center">
          <div className="flex flex-col items-center">
            <Route className="w-4 h-4 text-gray-500 mb-1" />
            <span className="font-bold">{ridesCount}</span>
            <span className="text-xs text-gray-600">Rides</span>
          </div>
        </div>

        <div
          className="text-center cursor-pointer"
          onClick={() => navigate(`/myfriends/${uid}`)}
        >
          <div className="flex flex-col items-center">
            <Users className="w-4 h-4 text-gray-500 mb-1" />
            <span className="font-bold">{friendsCount}</span>
            <span className="text-xs text-gray-600">Followers</span>
          </div>
        </div>

        <div className="text-center">
          <div className="flex flex-col items-center">
            <Trophy className="w-4 h-4 text-gray-500 mb-1" />
            <span className="font-bold">{eventsCount}</span>
            <span className="text-xs text-gray-600">Events</span>
          </div>
        </div>
      </div>

      {/* Image Zoom Modal */}
      {showImageModal && profileUser.photoUrl && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={() => setShowImageModal(false)}
        >
          <div className="max-w-2xl max-h-screen p-2">
            <img
              src={profileUser.photoUrl}
              alt={profileUser.name}
              className="max-w-full max-h-[90vh] object-contain"
            />
          </div>
        </div>
      )}

      {/* Show login warning modal */}
      {showLoginWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl max-w-sm w-full mx-4">
            <h3 className="text-lg font-semibold mb-2">Login Required</h3>
            <p className="mb-4">You need to login to follow other users.</p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowLoginWarning(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowLoginWarning(false);
                  navigate("/login");
                }}
                className="px-4 py-2 bg-black text-white rounded-lg"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileStats;
