import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import {
    Search,
    Filter,
    Bike,
    Calendar,
    Clock,
    MapPin,
    DollarSign,
    ChevronDown,
    ChevronUp,
    AlertCircle,
    Eye,
    Share2,
    Plus,
    Loader2,
    Hash,
    User
} from "lucide-react";

const BikeFinder = () => {
    const navigate = useNavigate();
    const [bicycles, setBicycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedBike, setSelectedBike] = useState(null);
    const [mapCenter, setMapCenter] = useState({ lat: 19.432608, lng: -99.133209 }); // Default: Mexico City
    const [searchTerm, setSearchTerm] = useState("");
    const [filters, setFilters] = useState({
        brand: "",
        color: "",
        dateRange: "all", // all, week, month, year
        hasReward: false
    });
    const [showFilters, setShowFilters] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [uniqueBrands, setUniqueBrands] = useState([]);
    const [uniqueColors, setUniqueColors] = useState([]);

    const mapContainerStyle = {
        width: "100%",
        height: "400px",
    };

    useEffect(() => {
        const fetchBicycles = async () => {
            try {
                setLoading(true);

                // Create base query
                let bicycleQuery = query(
                    collection(db, "Blacklist"),
                    orderBy("reportDate", "desc")
                );

                // Get documents
                const querySnapshot = await getDocs(bicycleQuery);

                // Process data
                const bikeData = [];
                const brands = new Set();
                const colors = new Set();

                querySnapshot.forEach((doc) => {
                    const bike = { id: doc.id, ...doc.data() };

                    // Ensure locationCoords exists (for the map)
                    if (!bike.locationCoords) {
                        bike.locationCoords = mapCenter;
                    }

                    bikeData.push(bike);

                    // Collect unique values for filters
                    if (bike.brand) brands.add(bike.brand.trim());
                    if (bike.color) colors.add(bike.color.trim());
                });

                setBicycles(bikeData);
                setUniqueBrands([...brands]);
                setUniqueColors([...colors]);

                // Calculate map center based on bicycles if available
                if (bikeData.length > 0) {
                    const validLocations = bikeData.filter(bike =>
                        bike.locationCoords &&
                        bike.locationCoords.lat &&
                        bike.locationCoords.lng
                    );

                    if (validLocations.length > 0) {
                        // Calculate the average position of all bikes
                        const sumLat = validLocations.reduce((sum, bike) => sum + bike.locationCoords.lat, 0);
                        const sumLng = validLocations.reduce((sum, bike) => sum + bike.locationCoords.lng, 0);

                        setMapCenter({
                            lat: sumLat / validLocations.length,
                            lng: sumLng / validLocations.length
                        });
                    }
                }
            } catch (err) {
                console.error("Error fetching bicycles: ", err);
                setError("No se pudieron cargar las bicicletas. Por favor, intenta de nuevo más tarde.");
            } finally {
                setLoading(false);
            }
        };

        fetchBicycles();
    }, []);

    // Filter bicycles based on search and filters
    const filteredBicycles = bicycles.filter(bike => {
        // Search term filter
        const searchLower = searchTerm.toLowerCase();
        const matchesSearch =
            !searchTerm ||
            (bike.name && bike.name.toLowerCase().includes(searchLower)) ||
            (bike.brand && bike.brand.toLowerCase().includes(searchLower)) ||
            (bike.model && bike.model.toLowerCase().includes(searchLower)) ||
            (bike.color && bike.color.toLowerCase().includes(searchLower)) ||
            (bike.serialNumber && bike.serialNumber.toLowerCase().includes(searchLower)) ||
            (bike.description && bike.description.toLowerCase().includes(searchLower));

        if (!matchesSearch) return false;

        // Brand filter
        if (filters.brand && bike.brand !== filters.brand) {
            return false;
        }

        // Color filter
        if (filters.color && bike.color !== filters.color) {
            return false;
        }

        // Date range filter
        if (filters.dateRange !== "all") {
            const now = new Date();
            const theftDate = bike.theftDate ? new Date(bike.theftDate) : null;

            if (!theftDate) return false;

            const diffDays = Math.floor((now - theftDate) / (1000 * 60 * 60 * 24));

            if (
                (filters.dateRange === "week" && diffDays > 7) ||
                (filters.dateRange === "month" && diffDays > 30) ||
                (filters.dateRange === "year" && diffDays > 365)
            ) {
                return false;
            }
        }

        // Reward filter
        if (filters.hasReward && !bike.reward) {
            return false;
        }

        return true;
    });

    const handleMapClick = () => {
        setSelectedBike(null);
    };

    const handleMarkerClick = (bike) => {
        setSelectedBike(bike);
    };

    const clearFilters = () => {
        setFilters({
            brand: "",
            color: "",
            dateRange: "all",
            hasReward: false
        });
        setSearchTerm("");
    };

    const handleViewDetails = (id) => {
        navigate(`/blacklist/${id}`);
    };

    const formatDate = (dateString) => {
        if (!dateString) return "Fecha no disponible";

        const date = new Date(dateString);
        return date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    };

    return (
        <div className="max-w-7xl mx-auto px-4 py-8 bg-gray-50 min-h-screen">
            {/* Header with Title */}
            <div className="mb-8 bg-gradient-to-r from-blue-600 to-purple-600 rounded-xl shadow-lg overflow-hidden">
                <div className="px-6 py-8 text-white">
                    <h1 className="text-3xl font-bold mb-2 flex items-center">
                        <Bike className="mr-3 h-8 w-8" />
                        Buscador de Bicicletas Robadas
                    </h1>
                    <p className="text-blue-100 max-w-3xl">
                        Explora el mapa y la lista para encontrar bicicletas reportadas como robadas en tu zona.
                        ¡Ayuda a la comunidad ciclista a recuperar sus bicicletas!
                    </p>

                    {/* Stats */}
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-6">
                        <div className="bg-white/20 backdrop-blur-sm rounded-lg p-4">
                            <span className="text-blue-100 text-sm">Total Reportadas</span>
                            <span className="text-3xl font-bold block mt-1">{bicycles.length}</span>
                        </div>
                        <div className="bg-white/20 backdrop-blur-sm rounded-lg p-4">
                            <span className="text-blue-100 text-sm">Con Recompensa</span>
                            <span className="text-3xl font-bold block mt-1">{bicycles.filter(b => b.reward).length}</span>
                        </div>
                        <div className="bg-white/20 backdrop-blur-sm rounded-lg p-4">
                            <span className="text-blue-100 text-sm">Este Mes</span>
                            <span className="text-3xl font-bold block mt-1">
                                {bicycles.filter(b => {
                                    const now = new Date();
                                    const theftDate = b.theftDate ? new Date(b.theftDate) : null;
                                    if (!theftDate) return false;
                                    const diffDays = Math.floor((now - theftDate) / (1000 * 60 * 60 * 24));
                                    return diffDays <= 30;
                                }).length}
                            </span>
                        </div>
                        <div className="bg-white/20 backdrop-blur-sm rounded-lg p-4">
                            <span className="text-blue-100 text-sm">Esta Semana</span>
                            <span className="text-3xl font-bold block mt-1">
                                {bicycles.filter(b => {
                                    const now = new Date();
                                    const theftDate = b.theftDate ? new Date(b.theftDate) : null;
                                    if (!theftDate) return false;
                                    const diffDays = Math.floor((now - theftDate) / (1000 * 60 * 60 * 24));
                                    return diffDays <= 7;
                                }).length}
                            </span>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex flex-wrap gap-3 mt-6">
                        <button
                            onClick={() => navigate("/addblacklist")}
                            className="flex items-center px-5 py-2.5 bg-white text-blue-700 rounded-lg shadow hover:bg-blue-50 transition-all font-medium"
                        >
                            <Plus className="h-5 w-5 mr-2" />
                            Reportar Bicicleta
                        </button>
                        <button
                            onClick={() => navigate("/myblacklist")}
                            className="flex items-center px-5 py-2.5 bg-blue-700 text-white rounded-lg shadow hover:bg-blue-800 transition-all font-medium"
                        >
                            <User className="h-5 w-5 mr-2" />
                            Mis Reportes
                        </button>
                    </div>
                </div>
            </div>

            {/* Map Section */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
                <div className="p-4 bg-gradient-to-r from-gray-800 to-black text-white">
                    <h2 className="text-xl font-semibold flex items-center">
                        <MapPin className="mr-2 h-5 w-5" />
                        Mapa de Bicicletas Robadas
                    </h2>
                    <p className="text-sm text-gray-300 mt-1">
                        Haz clic en los marcadores para ver más información
                    </p>
                </div>

                <div className="h-[450px] relative">
                    {loading ? (
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                            <Loader2 className="h-8 w-8 text-blue-600 animate-spin" />
                            <span className="ml-2 text-gray-700">Cargando mapa...</span>
                        </div>
                    ) : (
                        <LoadScript
                            googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28"
                            onLoad={() => setMapLoaded(true)}
                        >
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={mapCenter}
                                zoom={12}
                                onClick={handleMapClick}
                                options={{
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: true
                                }}
                            >
                                {filteredBicycles.map((bike) => (
                                    <Marker
                                        key={bike.id}
                                        position={bike.locationCoords}
                                        onClick={() => handleMarkerClick(bike)}
                                        icon={{
                                            url: bike.reward
                                                ? "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                                : "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                                        }}
                                    />
                                ))}

                                {selectedBike && (
                                    <InfoWindow
                                        position={selectedBike.locationCoords}
                                        onCloseClick={() => setSelectedBike(null)}
                                    >
                                        <div className="p-2 max-w-[250px]">
                                            <div className="font-semibold text-gray-900">{selectedBike.name}</div>
                                            <div className="text-xs text-gray-800">
                                                <p>{selectedBike.brand} {selectedBike.model}</p>
                                                <p className="mt-1 flex items-center">
                                                    <Calendar className="h-3 w-3 mr-1" />
                                                    {formatDate(selectedBike.theftDate)}
                                                </p>
                                                {selectedBike.reward && (
                                                    <p className="mt-1 text-green-600 font-medium flex items-center">
                                                        <DollarSign className="h-3 w-3 mr-1" />
                                                        {selectedBike.rewardAmount ? `$${selectedBike.rewardAmount} MXN` : "Con recompensa"}
                                                    </p>
                                                )}
                                            </div>
                                            <button
                                                onClick={() => handleViewDetails(selectedBike.id)}
                                                className="mt-2 w-full px-2 py-1.5 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 transition-colors"
                                            >
                                                Ver detalles
                                            </button>
                                        </div>
                                    </InfoWindow>
                                )}
                            </GoogleMap>
                        </LoadScript>
                    )}
                </div>
            </div>

            {/* Search and Filters */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
                <div className="p-4 md:p-6">
                    <div className="flex flex-col md:flex-row md:items-center gap-4">
                        {/* Search */}
                        <div className="relative flex-grow">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Search className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                placeholder="Buscar por marca, modelo, color..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2.5 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        {/* Filter Toggle */}
                        <button
                            onClick={() => setShowFilters(!showFilters)}
                            className="flex items-center px-4 py-2.5 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                        >
                            <Filter className="h-5 w-5 mr-2 text-gray-700" />
                            Filtros
                            {showFilters ? (
                                <ChevronUp className="h-4 w-4 ml-2 text-gray-700" />
                            ) : (
                                <ChevronDown className="h-4 w-4 ml-2 text-gray-700" />
                            )}
                        </button>

                        {/* Add Bicycle Button */}
                        <button
                            onClick={() => navigate("/addblacklist")}
                            className="flex items-center px-4 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            <Plus className="h-5 w-5 mr-2" />
                            Reportar Bicicleta
                        </button>

                        {/* My Reports Button */}
                        <button
                            onClick={() => navigate("/myblacklist")}
                            className="flex items-center px-4 py-2.5 bg-gray-800 text-white rounded-lg hover:bg-black transition-colors"
                        >
                            <User className="h-5 w-5 mr-2" />
                            Mis Reportes
                        </button>
                    </div>

                    {/* Expanded Filters */}
                    {showFilters && (
                        <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                            <h3 className="text-lg font-medium text-gray-800 mb-4 flex items-center">
                                <Filter className="h-5 w-5 mr-2 text-blue-600" />
                                Filtrar Resultados
                            </h3>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Marca
                                    </label>
                                    <select
                                        value={filters.brand}
                                        onChange={(e) => setFilters({ ...filters, brand: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                                    >
                                        <option value="">Todas las marcas</option>
                                        {uniqueBrands.map((brand) => (
                                            <option key={brand} value={brand}>{brand}</option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Color
                                    </label>
                                    <select
                                        value={filters.color}
                                        onChange={(e) => setFilters({ ...filters, color: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                                    >
                                        <option value="">Todos los colores</option>
                                        {uniqueColors.map((color) => (
                                            <option key={color} value={color}>{color}</option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Fecha de robo
                                    </label>
                                    <select
                                        value={filters.dateRange}
                                        onChange={(e) => setFilters({ ...filters, dateRange: e.target.value })}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
                                    >
                                        <option value="all">Cualquier fecha</option>
                                        <option value="week">Última semana</option>
                                        <option value="month">Último mes</option>
                                        <option value="year">Último año</option>
                                    </select>
                                </div>

                                <div className="flex flex-col justify-end">
                                    <label className="relative inline-flex items-center cursor-pointer mt-4">
                                        <input
                                            type="checkbox"
                                            id="hasReward"
                                            checked={filters.hasReward}
                                            onChange={(e) => setFilters({ ...filters, hasReward: e.target.checked })}
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium text-gray-700">Solo con recompensa</span>
                                    </label>
                                </div>
                            </div>

                            <div className="flex items-center justify-between mt-6 pt-4 border-t border-gray-200">
                                <div className="text-sm text-gray-600">
                                    Mostrando <span className="font-medium text-blue-600">{filteredBicycles.length}</span> de <span className="font-medium">{bicycles.length}</span> bicicletas
                                </div>
                                <button
                                    onClick={clearFilters}
                                    className="px-4 py-2 text-sm text-blue-600 hover:text-blue-800 bg-blue-50 hover:bg-blue-100 rounded-lg transition-colors"
                                >
                                    Limpiar filtros
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Results Section */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white">
                    <h2 className="text-xl font-semibold flex items-center">
                        <Bike className="mr-2 h-5 w-5" />
                        Bicicletas Reportadas
                    </h2>
                    <p className="text-sm text-blue-100 mt-1">
                        {filteredBicycles.length} {filteredBicycles.length === 1 ? 'bicicleta encontrada' : 'bicicletas encontradas'}
                    </p>
                </div>

                {loading ? (
                    <div className="p-12 flex items-center justify-center">
                        <Loader2 className="h-10 w-10 text-blue-600 animate-spin" />
                        <span className="ml-3 text-gray-700 text-lg">Cargando bicicletas...</span>
                    </div>
                ) : error ? (
                    <div className="p-12 text-center">
                        <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-3" />
                        <p className="text-lg text-gray-700">{error}</p>
                        <button
                            onClick={() => window.location.reload()}
                            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                            Reintentar
                        </button>
                    </div>
                ) : filteredBicycles.length === 0 ? (
                    <div className="p-12 text-center">
                        <div className="w-20 h-20 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
                            <AlertCircle className="h-10 w-10 text-gray-400" />
                        </div>
                        <p className="text-lg text-gray-700">No se encontraron bicicletas con los filtros actuales.</p>
                        <button
                            onClick={clearFilters}
                            className="mt-5 px-5 py-2.5 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            Limpiar filtros
                        </button>
                    </div>
                ) : (
                    <div className="divide-y divide-gray-200">
                        {filteredBicycles.map((bike) => (
                            <div key={bike.id} className="p-6 hover:bg-blue-50 transition-colors">
                                <div className="flex flex-col md:flex-row gap-6">
                                    {/* Bike Image with Reward Badge */}
                                    <div className="w-full md:w-56 h-48 flex-shrink-0 relative rounded-xl overflow-hidden">
                                        {bike.mainImageUrl ? (
                                            <img
                                                src={bike.mainImageUrl}
                                                alt={bike.name}
                                                className="w-full h-full object-cover"
                                            />
                                        ) : (
                                            <div className="w-full h-full bg-blue-100 flex items-center justify-center">
                                                <Bike className="h-16 w-16 text-blue-300" />
                                            </div>
                                        )}
                                        {bike.reward && (
                                            <div className="absolute top-2 right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full shadow-lg">
                                                ${bike.rewardAmount || ""} RECOMPENSA
                                            </div>
                                        )}
                                    </div>

                                    {/* Bike Info */}
                                    <div className="flex-grow">
                                        <div className="flex flex-col md:flex-row md:items-start justify-between">
                                            <div>
                                                <h3 className="text-xl font-bold text-gray-900 line-clamp-1">{bike.name}</h3>
                                                <div className="flex flex-wrap gap-2 mt-2">
                                                    {bike.brand && (
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                            {bike.brand}
                                                        </span>
                                                    )}
                                                    {bike.model && (
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                                            {bike.model}
                                                        </span>
                                                    )}
                                                    {bike.color && (
                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                            {bike.color}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-y-3 text-sm">
                                            <div className="flex items-center text-gray-700">
                                                <Calendar className="h-4 w-4 mr-2 text-blue-500" />
                                                <span className="font-medium">Robada el:</span> {formatDate(bike.theftDate)}
                                            </div>
                                            <div className="flex items-center text-gray-700">
                                                <MapPin className="h-4 w-4 mr-2 text-blue-500" />
                                                <span className="font-medium">Ubicación:</span> {bike.location || "No especificada"}
                                            </div>
                                            <div className="flex items-center text-gray-700">
                                                <Hash className="h-4 w-4 mr-2 text-blue-500" />
                                                <span className="font-medium">Serie:</span> {bike.serialNumber || "Sin número de serie"}
                                            </div>
                                            <div className="flex items-center text-gray-700">
                                                <Clock className="h-4 w-4 mr-2 text-blue-500" />
                                                <span className="font-medium">Reportada hace:</span> {Math.floor((new Date() - new Date(bike.reportDate?.toDate ? bike.reportDate.toDate() : bike.reportDate)) / (1000 * 60 * 60 * 24))} días
                                            </div>
                                        </div>

                                        {bike.description && (
                                            <div className="mt-4 p-3 bg-gray-50 rounded-lg text-sm text-gray-700 border-l-4 border-blue-300">
                                                {bike.description}
                                            </div>
                                        )}

                                        <div className="mt-5 flex flex-wrap gap-3">
                                            <button
                                                onClick={() => handleViewDetails(bike.id)}
                                                className="flex items-center px-4 py-2 bg-blue-600 text-white text-sm rounded-lg hover:bg-blue-700 transition-colors"
                                            >
                                                <Eye className="h-4 w-4 mr-2" />
                                                Ver detalles
                                            </button>
                                            <button
                                                onClick={() => {
                                                    navigator.share({
                                                        title: `Bicicleta robada: ${bike.name}`,
                                                        text: `Ayuda a encontrar esta bicicleta robada: ${bike.brand} ${bike.model} ${bike.color}`,
                                                        url: window.location.origin + `/blacklist/${bike.id}`
                                                    }).catch(err => console.error('Error compartiendo:', err));
                                                }}
                                                className="flex items-center px-4 py-2 bg-gray-100 text-gray-700 text-sm rounded-lg hover:bg-gray-200 transition-colors"
                                            >
                                                <Share2 className="h-4 w-4 mr-2" />
                                                Compartir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Pagination or Load More */}
                {filteredBicycles.length > 0 && (
                    <div className="p-6 bg-gray-50 border-t border-gray-200 flex justify-center">
                        <div className="text-center">
                            <p className="text-gray-600 mb-3">¿No encuentras tu bicicleta?</p>
                            <button
                                onClick={() => navigate("/addblacklist")}
                                className="px-5 py-2.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center mx-auto"
                            >
                                <Plus className="mr-2 h-5 w-5" />
                                Reportar Una Bicicleta
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Footer with Tips */}
            <div className="mt-8 bg-white rounded-xl shadow-lg overflow-hidden p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Consejos para prevenir el robo de bicicletas</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="bg-blue-50 p-4 rounded-xl">
                        <h4 className="font-medium text-blue-700 mb-2">Siempre usa un buen candado</h4>
                        <p className="text-sm text-gray-700">Invierte en un candado de calidad tipo U-lock o cadena gruesa. Si es posible, usa dos candados diferentes.</p>
                    </div>
                    <div className="bg-blue-50 p-4 rounded-xl">
                        <h4 className="font-medium text-blue-700 mb-2">Registra tu número de serie</h4>
                        <p className="text-sm text-gray-700">Anota el número de serie de tu bicicleta y toma fotos de ella para facilitar su identificación.</p>
                    </div>
                    <div className="bg-blue-50 p-4 rounded-xl">
                        <h4 className="font-medium text-blue-700 mb-2">Estaciona en lugares seguros</h4>
                        <p className="text-sm text-gray-700">Deja tu bicicleta en lugares con mucho tránsito de personas y preferiblemente con cámaras de seguridad.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BikeFinder;