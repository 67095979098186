import React from "react";
import { User, Clock, Phone } from "lucide-react";

// Chat Item component in the list
const ChatListItem = ({ chat, isActive, onClick }) => {
    const formattedDate = chat.updatedAt?.toDate
        ? new Date(chat.updatedAt.toDate()).toLocaleString("es-ES", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        })
        : "Sin fecha";

    const isGuestChat = chat.isGuest;
    const hasUnread = chat.unreadMessages > 0;

    return (
        <button
            onClick={() => onClick(chat.id)}
            className={`w-full p-4 flex items-start border-b text-left transition-colors ${isActive
                ? "bg-blue-50 border-l-4 border-l-blue-600"
                : "hover:bg-gray-50"
                }`}
        >
            <div className="flex-1">
                <div className="flex justify-between items-center mb-1">
                    <div className="flex items-center">
                        <span
                            className={`font-medium ${hasUnread ? "text-blue-600 font-bold" : ""
                                }`}
                        >
                            {chat.sessionName || (isGuestChat ? chat.guestName : "Usuario")}
                        </span>
                        {isGuestChat && (
                            <span className="ml-2 text-xs bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full flex items-center">
                                <User size={10} className="mr-1" />
                                Invitado
                            </span>
                        )}
                        {hasUnread && (
                            <span className="ml-2 bg-blue-600 text-white text-xs px-2 py-0.5 rounded-full">
                                {chat.unreadMessages}
                            </span>
                        )}
                    </div>
                    <span className="text-xs text-gray-500 flex items-center">
                        <Clock size={12} className="mr-1" />
                        {formattedDate}
                    </span>
                </div>
                <p className="text-xs text-gray-500 truncate">
                    {isGuestChat && chat.guestPhone && (
                        <span className="flex items-center mr-2">
                            <Phone size={10} className="mr-1" />
                            {chat.guestPhone}
                        </span>
                    )}
                    {chat.lastMessage || "Sin mensajes"}
                </p>
            </div>
        </button>
    );
};

export default ChatListItem;