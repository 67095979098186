import React, { useState, useEffect } from 'react';
import { Trophy, ChevronRight, Award, Clock } from 'lucide-react';

const ResultsAnnouncementButton = ({ route = "/resultados/esmeralda2025", onClick }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    // Efecto de atención periódico
    useEffect(() => {
        const animationInterval = setInterval(() => {
            setIsAnimating(true);

            // Resetear animación después de completarse
            const timeout = setTimeout(() => {
                setIsAnimating(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }, 8000);

        return () => clearInterval(animationInterval);
    }, []);

    // Manejar navegación
    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        } else {
            window.location.href = route;
        }
    };

    return (
        <div className="w-full max-w-md mx-auto my-4 px-4">
            <button
                onClick={handleClick}
                className={`
          relative block w-full overflow-hidden
          bg-gradient-to-r from-gray-800 to-gray-700 
          text-white font-bold rounded-lg shadow-lg
          transform transition-all duration-300
          hover:scale-105 hover:shadow-xl
          ${isAnimating ? 'animate-pulse' : ''}
        `}
            >
                {/* Banner superior */}
                <div className="bg-yellow-500 text-xs text-gray-900 font-bold py-1 px-2 flex justify-center items-center">
                    <Trophy className="w-3 h-3 mr-1" />
                    ¡Presiona para ver los resultados!
                </div>

                {/* Contenido principal */}
                <div className="p-4 flex items-center justify-between">
                    <div className="flex-1 text-left">
                        <div className="flex items-center mb-1">
                            <Award className="w-5 h-5 mr-2 text-yellow-400" />
                            <h3 className="text-lg font-bold">Resultados Oficiales</h3>
                        </div>
                        <p className="text-gray-300 text-sm mb-2">VII Carrera Rancho La Esmeralda</p>
                        <div className="flex items-center text-xs text-gray-400">
                            <Clock className="w-3 h-3 mr-1" />
                            <span>23 de Marzo 2025</span>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center ml-3">
                        <span className="bg-gray-900 text-white rounded-full p-2">
                            <ChevronRight className="w-5 h-5" />
                        </span>
                        <span className="text-xs mt-1 text-gray-300">Ver</span>
                    </div>
                </div>
            </button>

            {/* Estilo para la animación de brillo */}
            <style jsx>{`
        @keyframes shine {
          100% {
            left: 125%;
          }
        }
        
        .animate-shine {
          left: -100%;
          animation: shine 1.5s ease-in-out;
        }
        
        .animate-pulse {
          animation: pulse 1.5s ease-in-out;
        }
        
        @keyframes pulse {
          0%, 100% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.05);
          }
        }
      `}</style>
        </div>
    );
};

export default ResultsAnnouncementButton;