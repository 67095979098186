import React, { useEffect, useState } from "react";
import {
  Bike,
  Tag,
  Ruler,
  CircleOff,
  Plus,
  Camera,
  Settings,
  ChevronRight,
  Shield,
  Cog,
  FileEdit,
} from "lucide-react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

const ProfileBikes = ({ uid }) => {
  const [bikes, setBikes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBike, setSelectedBike] = useState(null);

  useEffect(() => {
    const fetchBikes = async () => {
      const firestore = getFirestore();
      const bikesCollection = collection(firestore, "BikeGarage");
      const q = query(bikesCollection, where("userId", "==", uid));
      const querySnapshot = await getDocs(q);
      const bikesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBikes(bikesList);
      setLoading(false);
    };

    fetchBikes();
  }, [uid]);

  const handleBikeClick = (bike) => {
    setSelectedBike(bike === selectedBike ? null : bike);
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <div className="w-12 h-12 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin mb-4"></div>
        <p className="text-gray-600">Cargando bicicletas...</p>
      </div>
    );
  }

  if (bikes.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12 px-4">
        <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
          <Bike className="w-8 h-8 text-gray-400" />
        </div>
        <h3 className="text-lg font-medium text-gray-900 mb-2 text-center">
          No hay bicicletas registradas
        </h3>
        <p className="text-gray-500 text-center mb-6">
          Agrega tus bicicletas para mostrarlas en tu perfil y utilizarlas en
          tus actividades.
        </p>

        <button className="flex items-center px-5 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition">
          <Plus className="w-4 h-4 mr-2" />
          Agregar bicicleta
        </button>
      </div>
    );
  }

  return (
    <div className="px-1 py-2">
      {/* Bikes Grid/List */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {bikes.map((bike) => (
          <div
            key={bike.id}
            className={`bg-white rounded-xl overflow-hidden transition-all duration-300 ${
              selectedBike === bike ? "ring-2 ring-black" : "hover:shadow-md"
            }`}
          >
            {/* Bike Card */}
            <div
              onClick={() => handleBikeClick(bike)}
              className="cursor-pointer"
            >
              {/* Bike Image */}
              <div className="relative h-48 bg-gray-100">
                {bike.images ? (
                  <img
                    src={bike.images}
                    alt={bike.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex flex-col items-center justify-center">
                    <Camera className="w-10 h-10 text-gray-300 mb-2" />
                    <span className="text-xs text-gray-400">Sin imagen</span>
                  </div>
                )}

                {/* Bike Status Badge */}
                <div className="absolute top-3 right-3 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded-full">
                  {bike.status === "active" ? "Activa" : "Inactiva"}
                </div>
              </div>

              {/* Bike Basic Info */}
              <div className="p-4">
                <h3 className="font-bold text-lg mb-1 flex items-center justify-between">
                  {bike.name}
                  <ChevronRight
                    className={`w-5 h-5 text-gray-400 transition-transform ${
                      selectedBike === bike ? "rotate-90" : ""
                    }`}
                  />
                </h3>
                <p className="text-gray-600 text-sm mb-3">
                  {bike.brand} {bike.model}
                </p>

                {/* Bike Specs (Condensed) */}
                <div className="grid grid-cols-2 gap-2 text-sm">
                  <div className="flex items-center text-gray-600">
                    <Tag className="w-4 h-4 mr-2 text-gray-400" />
                    <span>{bike.type || "MTB"}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Ruler className="w-4 h-4 mr-2 text-gray-400" />
                    <span>Talla {bike.size || "M"}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Expanded Details (conditionally rendered) */}
            {selectedBike === bike && (
              <div className="bg-gray-50 p-4 border-t border-gray-100 animate-fadeIn">
                <div className="grid grid-cols-2 gap-x-4 gap-y-3 mb-4">
                  <div className="col-span-2">
                    <span className="text-xs text-gray-500">Descripción</span>
                    <p className="text-sm text-gray-700">
                      {bike.description || "Sin descripción"}
                    </p>
                  </div>

                  <div>
                    <span className="text-xs text-gray-500">
                      Tamaño de rueda
                    </span>
                    <p className="text-sm font-medium flex items-center">
                      <CircleOff className="w-4 h-4 mr-1 text-gray-400" />
                      {bike.wheelSize || '29"'}
                    </p>
                  </div>

                  <div>
                    <span className="text-xs text-gray-500">Año</span>
                    <p className="text-sm font-medium">{bike.year || "N/A"}</p>
                  </div>

                  <div>
                    <span className="text-xs text-gray-500">Color</span>
                    <p className="text-sm font-medium flex items-center">
                      <span
                        className="w-3 h-3 rounded-full mr-2 inline-block"
                        style={{ backgroundColor: bike.color || "#000" }}
                      ></span>
                      {bike.colorName || "Negro"}
                    </p>
                  </div>

                  <div>
                    <span className="text-xs text-gray-500">Material</span>
                    <p className="text-sm font-medium">
                      {bike.material || "Aluminio"}
                    </p>
                  </div>
                </div>

                {/* Maintenance Section */}
                <div className="border-t border-gray-200 pt-3 mt-2">
                  <h4 className="text-sm font-medium flex items-center mb-2">
                    <Cog className="w-4 h-4 mr-1" />
                    Mantenimiento
                  </h4>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>
                      <span className="text-xs text-gray-500">
                        Último servicio
                      </span>
                      <p className="text-sm">
                        {bike.lastService || "No registrado"}
                      </p>
                    </div>
                    <div>
                      <span className="text-xs text-gray-500">Kilometraje</span>
                      <p className="text-sm font-medium">
                        {bike.mileage ? `${bike.mileage} km` : "0 km"}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end mt-4 space-x-2">
                  <button className="flex items-center py-1.5 px-3 text-xs bg-gray-100 hover:bg-gray-200 transition rounded-full">
                    <Shield className="w-3 h-3 mr-1" />
                    Seguro
                  </button>
                  <button className="flex items-center py-1.5 px-3 text-xs bg-gray-100 hover:bg-gray-200 transition rounded-full">
                    <FileEdit className="w-3 h-3 mr-1" />
                    Editar
                  </button>
                  <button className="flex items-center py-1.5 px-3 text-xs bg-gray-100 hover:bg-gray-200 transition rounded-full">
                    <Settings className="w-3 h-3 mr-1" />
                    Configurar
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Add Bike Button (Desktop) */}
      <div className="flex justify-center mt-6 hidden md:block">
        <button className="flex items-center px-5 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition">
          <Plus className="w-4 h-4 mr-2" />
          Agregar bicicleta
        </button>
      </div>

      {/* Add Bike Button (Mobile - Fixed) */}
      <div className="md:hidden fixed bottom-6 right-6">
        <button className="flex items-center justify-center w-12 h-12 bg-black text-white rounded-full hover:bg-gray-800 transition shadow-lg">
          <Plus className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default ProfileBikes;
