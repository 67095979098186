import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage } from "../../firebase";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { countries, states, cities } from "../CountryStateOptions";
import {
  User,
  Phone,
  AtSign,
  MapPin,
  Globe,
  Building2,
  Lock,
  Save,
  Upload,
  ChevronDown,
  RefreshCw,
  ChevronRight,
} from "lucide-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Generar nickname a partir del nombre y 4 números aleatorios
const generateNickname = (name) => {
  if (!name) return "";

  // Eliminar espacios y caracteres especiales, convertir a minúsculas
  const cleanName = name.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");

  // Generar 4 números aleatorios
  const randomNumbers = Math.floor(1000 + Math.random() * 9000);

  return cleanName + randomNumbers;
};

const UserForm = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [initialData, setInitialData] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [nicknameError, setNicknameError] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [isNicknameEdited, setIsNicknameEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showNationality, setShowNationality] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid) {
        try {
          setIsLoading(true);
          const db = getFirestore();
          const userDoc = doc(db, "Users", user.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setInitialData({
              name: data.name || "",
              phoneNumber: data.phoneNumber || "",
              nickName: data.nickName || "",
              country: data.country || "",
              state: data.state || "",
              city: data.city || "",
              private: data.private || false,
              profileImage: null,
            });

            // Establecer la imagen de perfil si existe
            if (data.photoUrl) {
              console.log("Photo URL from Firebase:", data.photoUrl);
              setProfileImagePreview(data.photoUrl);
            }

            // Si hay datos de país, estado o ciudad, mostrar la sección de nacionalidad
            if (data.country || data.state || data.city) {
              setShowNationality(true);
            }

            setPhoneValid(data.phoneNumber?.length >= 10 || false);
          } else {
            setInitialData({
              name: "",
              phoneNumber: "",
              nickName: "",
              country: "",
              state: "",
              city: "",
              private: false,
              profileImage: null,
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [user]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nombre es requerido"),
    phoneNumber: Yup.string().required("Teléfono es requerido"),
    nickName: Yup.string()
      .matches(/^[a-z0-9]+$/, "Solo minúsculas y números")
      .required("Nickname es requerido"),
    country: Yup.object().nullable(),
    state: Yup.object().nullable(),
    city: Yup.object().nullable(),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const db = getFirestore();
      const userDoc = doc(db, "Users", user.uid);
      let photoUrl = profileImagePreview;

      if (values.profileImage) {
        // Subir nueva imagen si se seleccionó una
        const storageRef = ref(
          storage,
          `${user.uid}/profile_${Date.now()}_${values.profileImage.name}`
        );
        await uploadBytes(storageRef, values.profileImage);
        photoUrl = await getDownloadURL(storageRef);
        console.log("Nueva URL de foto subida:", photoUrl);
      } else if (photoUrl === null) {
        // Si se quitó la foto (botón de quitar)
        photoUrl = "";
      }

      // Modificar datos para extraer los valores de los objetos country, state y city
      const newUserData = {
        name: values.name,
        phoneNumber: values.phoneNumber,
        nickName: values.nickName,
        country: values.country ? values.country.label : "", // Extraer el label en lugar del objeto completo
        state: values.state ? values.state.label : "", // Extraer el label en lugar del objeto completo
        city: values.city ? values.city.label : "", // Extraer el label en lugar del objeto completo
        private: values.private,
        photoUrl,
        newuser: false,
      };

      console.log("Guardando datos de usuario:", newUserData);
      await setDoc(userDoc, newUserData, { merge: true });
      navigate("/home");
    } catch (err) {
      console.error("Error updating user:", err);
      alert("Hubo un error al guardar tus datos. Por favor intenta de nuevo.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      // Establecer el archivo para el envío del formulario
      setFieldValue("profileImage", file);

      // Crear URL para previsualización
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("Imagen cargada:", reader.result);
        setProfileImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateNicknameUniqueness = async (nick) => {
    if (!nick || !/^[a-z0-9]+$/.test(nick)) return;
    try {
      const db = getFirestore();
      const usersRef = collection(db, "Users");
      const q = query(usersRef, where("nickName", "==", nick));
      const snapshot = await getDocs(q);
      if (!snapshot.empty && nick !== initialData?.nickName) {
        setNicknameError("Este nickname ya está en uso");
      } else {
        setNicknameError("");
      }
    } catch (error) {
      console.error("Error validating nickname:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen w-full bg-gradient-to-b from-white to-gray-50">
        <div className="animate-pulse flex flex-col items-center text-center py-8 text-black">
          <div className="w-16 h-16 bg-gray-200 rounded-full mb-4"></div>
          <p className="text-lg font-medium">Cargando perfil...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 w-full pb-8">
      <div
        ref={formRef}
        className="w-full max-w-md mx-auto space-y-6 p-4 sm:p-6"
      >
        <div className="text-center pt-4 pb-2">
          <h1 className="text-2xl font-bold text-gray-900">
            Perfil de Usuario
          </h1>
          <p className="text-gray-500 mt-1">Completa tu información personal</p>
        </div>

        <Formik
          initialValues={initialData}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
          }) => {
            const stateOptions = values.country
              ? states[values.country.value] || []
              : [];
            const cityOptions = values.state
              ? cities[values.state.value] || []
              : [];

            return (
              <Form className="space-y-5">
                <div className="flex flex-col items-center mb-6">
                  <label
                    htmlFor="profileImage"
                    className="relative group cursor-pointer"
                  >
                    <div className="w-28 h-28 md:w-32 md:h-32 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden border-2 border-gray-200 group-hover:border-gray-400 transition-all duration-300 shadow-md">
                      {profileImagePreview ? (
                        <img
                          src={profileImagePreview}
                          alt="Vista previa del perfil"
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <User size={40} className="text-gray-400" />
                      )}
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 flex items-center justify-center rounded-full transition-all duration-300">
                        <Upload
                          size={24}
                          className="text-white opacity-0 group-hover:opacity-100 transition-all duration-300"
                        />
                      </div>
                    </div>
                    <input
                      id="profileImage"
                      name="profileImage"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleImageChange(e, setFieldValue)}
                      aria-label="Subir foto de perfil"
                    />
                  </label>
                  {profileImagePreview ? (
                    <div className="mt-2 flex flex-col items-center">
                      <p className="text-sm text-green-600 font-medium">
                        Foto cargada
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          setProfileImagePreview(null);
                          setFieldValue("profileImage", null);
                        }}
                        className="text-xs text-red-500 mt-1 hover:text-red-700"
                      >
                        Quitar foto
                      </button>
                    </div>
                  ) : (
                    <p className="mt-2 text-sm text-gray-500">
                      Toca para subir foto
                    </p>
                  )}
                </div>

                <div className="space-y-4">
                  {/* Nombre */}
                  <div className="group transition-all duration-300">
                    <div className="relative rounded-xl overflow-hidden shadow-sm hover:shadow transition-shadow duration-300">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <User
                          size={18}
                          className={`${!values.name && "text-red-500"} ${
                            values.name && !errors.name && "text-green-500"
                          } ${
                            !(!values.name || (values.name && !errors.name)) &&
                            "text-gray-500"
                          }`}
                        />
                      </div>
                      <input
                        name="name"
                        type="text"
                        placeholder="Nombre y Apellido *"
                        value={values.name}
                        onChange={(e) => {
                          handleChange(e);
                          // Generar nickname automáticamente si no ha sido editado manualmente
                          if (!isNicknameEdited) {
                            const generatedNick = generateNickname(
                              e.target.value
                            );
                            setFieldValue("nickName", generatedNick);
                            validateNicknameUniqueness(generatedNick);
                          }
                        }}
                        className={`w-full pl-10 pr-4 py-3.5 border rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent transition-all ${
                          !values.name
                            ? "border-red-300"
                            : values.name && !errors.name
                            ? "border-green-300"
                            : "border-gray-200"
                        }`}
                        aria-label="Nombre completo"
                        required
                      />
                    </div>
                    {touched.name && errors.name && (
                      <p className="text-sm text-red-500 mt-1 ml-1">
                        {errors.name}
                      </p>
                    )}
                  </div>

                  {/* Teléfono */}
                  <div className="group transition-all duration-300">
                    <div
                      className={`relative rounded-xl overflow-hidden shadow-sm hover:shadow transition-shadow duration-300 ${
                        !values.phoneNumber
                          ? "border border-red-300"
                          : values.phoneNumber && phoneValid
                          ? "border border-green-300"
                          : "border border-gray-200"
                      }`}
                    >
                      <PhoneInput
                        country={"mx"}
                        value={values.phoneNumber}
                        onChange={(phone) => {
                          setFieldValue("phoneNumber", phone);
                          setPhoneValid(phone.length >= 10);
                        }}
                        inputClass={`w-full pl-12 pr-4 py-3.5 border-0 rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent transition-all`}
                        containerClass="w-full"
                        dropdownClass="bg-white border border-gray-200 rounded-lg shadow-sm max-h-60 overflow-y-auto z-50"
                        buttonClass="absolute left-1 top-0 h-full border-0 bg-transparent z-10"
                        placeholder="Teléfono *"
                        enableSearch={true}
                        searchPlaceholder="Buscar país..."
                        specialLabel=""
                        searchClass="p-2 border-b border-gray-200"
                        aria-label="Número telefónico"
                        required
                      />
                    </div>
                    {touched.phoneNumber && errors.phoneNumber && (
                      <p className="text-sm text-red-500 mt-1 ml-1">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>

                  {/* Nickname */}
                  <div className="group transition-all duration-300">
                    <div className="relative rounded-xl overflow-hidden shadow-sm hover:shadow transition-shadow duration-300">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <AtSign
                          size={18}
                          className={`${
                            !values.nickName || nicknameError
                              ? "text-red-500"
                              : values.nickName &&
                                !errors.nickName &&
                                !nicknameError
                              ? "text-green-500"
                              : "text-gray-500"
                          }`}
                        />
                      </div>
                      <input
                        name="nickName"
                        type="text"
                        placeholder="Nickname *"
                        value={values.nickName}
                        onChange={(e) => {
                          handleChange(e);
                          setIsNicknameEdited(true);
                          validateNicknameUniqueness(e.target.value);
                        }}
                        className={`w-full pl-10 pr-10 py-3.5 border rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent transition-all ${
                          !values.nickName || nicknameError
                            ? "border-red-300"
                            : values.nickName &&
                              !errors.nickName &&
                              !nicknameError
                            ? "border-green-300"
                            : "border-gray-200"
                        }`}
                        aria-label="Nickname"
                        required
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                        <button
                          type="button"
                          onClick={() => {
                            const generatedNick = generateNickname(values.name);
                            setFieldValue("nickName", generatedNick);
                            validateNicknameUniqueness(generatedNick);
                          }}
                          className="text-gray-400 hover:text-black focus:outline-none focus:ring-2 focus:ring-black rounded-full p-1"
                          title="Generar nickname"
                          aria-label="Generar nickname automáticamente"
                        >
                          <RefreshCw size={16} />
                        </button>
                      </div>
                    </div>
                    {((touched.nickName && errors.nickName) ||
                      nicknameError) && (
                      <p className="text-sm text-red-500 mt-1 ml-1">
                        {nicknameError || errors.nickName}
                      </p>
                    )}
                  </div>

                  {/* Botón para mostrar/ocultar sección de nacionalidad */}
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={() => setShowNationality(!showNationality)}
                      className="w-full flex items-center justify-between gap-2 bg-white text-gray-800 py-3.5 px-4 rounded-xl border border-gray-200 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 focus:outline-none transition-all shadow-sm"
                      aria-expanded={showNationality}
                      aria-controls="nationality-section"
                    >
                      <div className="flex items-center gap-2">
                        <Globe size={18} className="text-gray-500" />
                        <span className="font-medium">
                          ¿Quieres agregar tu nacionalidad?
                        </span>
                      </div>
                      <ChevronRight
                        size={18}
                        className={`text-gray-500 transition-transform duration-300 ${
                          showNationality ? "rotate-90" : ""
                        }`}
                      />
                    </button>
                    <p className="text-xs text-gray-500 mt-2 ml-1">
                      Esto no es obligatorio
                    </p>
                  </div>

                  {/* Sección de nacionalidad (mostrar/ocultar) */}
                  {showNationality && (
                    <div
                      id="nationality-section"
                      className="space-y-4 mt-4 animate-fadeIn"
                    >
                      <SelectField
                        name="country"
                        label="País"
                        options={countries}
                        placeholder="Selecciona un país"
                        icon={<Globe size={18} className="text-gray-500" />}
                        touched={touched}
                        errors={errors}
                      />

                      <SelectField
                        name="state"
                        label="Estado"
                        options={stateOptions}
                        isDisabled={!values.country}
                        placeholder={
                          values.country
                            ? "Selecciona un estado"
                            : "Primero selecciona un país"
                        }
                        icon={<MapPin size={18} className="text-gray-500" />}
                        touched={touched}
                        errors={errors}
                      />

                      <SelectField
                        name="city"
                        label="Ciudad"
                        options={cityOptions.map((city) => ({
                          value: city,
                          label: city,
                        }))}
                        isDisabled={!values.state}
                        placeholder={
                          values.state
                            ? "Selecciona una ciudad"
                            : "Primero selecciona un estado"
                        }
                        icon={<Building2 size={18} className="text-gray-500" />}
                        touched={touched}
                        errors={errors}
                      />
                    </div>
                  )}

                  <div className="p-4 bg-gray-50 rounded-xl border border-gray-200 shadow-sm mt-4 transition-all hover:shadow-md duration-300">
                    <label className="flex items-center gap-3 text-sm text-gray-700 cursor-pointer">
                      <div className="relative w-5 h-5">
                        <input
                          name="private"
                          type="checkbox"
                          checked={values.private}
                          onChange={handleChange}
                          className="w-5 h-5 border border-gray-300 rounded appearance-none checked:bg-black checked:border-black focus:outline-none transition-colors"
                          aria-label="Perfil privado"
                        />
                        {values.private && (
                          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                            <svg
                              className="w-3 h-3 text-white"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <Lock size={16} className="text-gray-500" />
                        <span className="font-medium">Perfil privado</span>
                      </div>
                    </label>
                    <p className="text-xs text-gray-500 mt-2 ml-8">
                      Activa esta opción si no quieres que tu perfil sea visible
                      para otros usuarios
                    </p>
                  </div>
                </div>

                {/* Botón de guardar fijo debajo del perfil privado */}
                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !values.name ||
                      !phoneValid ||
                      !values.nickName ||
                      !!nicknameError
                    }
                    className="w-full flex items-center justify-center gap-2 bg-black text-white py-3.5 px-4 rounded-xl hover:bg-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-black focus:outline-none transition-all disabled:opacity-50 disabled:cursor-not-allowed shadow-lg"
                    aria-label="Guardar perfil"
                  >
                    <Save size={18} />
                    <span className="font-medium">
                      {isSubmitting ? "Guardando..." : "Guardar Perfil"}
                    </span>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default UserForm;

const SelectField = ({
  label,
  options,
  isDisabled,
  placeholder,
  name,
  icon,
  touched,
  errors,
}) => {
  const [field, meta, helpers] = useField(name);

  // Determine if field has valid value
  const hasValue = field.value && field.value.value;

  return (
    <div className="group transition-all duration-300">
      <div className="relative rounded-xl overflow-hidden shadow-sm hover:shadow transition-shadow duration-300">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {React.cloneElement(icon, {
            className: hasValue ? "text-green-500" : "text-gray-500",
          })}
        </div>
        <div className="relative">
          <select
            name={name}
            value={field.value?.value || ""}
            onChange={(e) => {
              const selected = options.find(
                (option) => option.value === e.target.value
              );
              helpers.setValue(selected || null);
            }}
            onBlur={() => helpers.setTouched(true)}
            disabled={isDisabled}
            className={`w-full pl-10 pr-10 py-3.5 border ${
              hasValue ? "border-green-300" : "border-gray-200"
            } rounded-xl text-base focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent transition-all appearance-none ${
              isDisabled
                ? "bg-gray-50 text-gray-400 cursor-not-allowed"
                : "bg-white cursor-pointer"
            }`}
            aria-label={label}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ChevronDown
              size={18}
              className={hasValue ? "text-green-500" : "text-gray-500"}
            />
          </div>
        </div>
      </div>
      {touched[name] && errors[name] && (
        <p className="text-sm text-red-500 mt-1 ml-1">{errors[name]}</p>
      )}
    </div>
  );
};

/* Añadir esto al CSS global o al archivo de estilos */
/* 
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}
*/
