import React from "react";
import {
  Calendar,
  Trophy,
  Users,
  Map,
  Trees,
  Users2,
  Bike,
  Hammer,
} from "lucide-react";

const routes = [
  { label: "Eventos", path: "/events", icon: Calendar },
  // { label: "Ranking", path: "/leaderboards", icon: Trophy },
  { label: "Amigos", path: "/friends", icon: Users },
  { label: "Mapa", path: "/livemap", icon: Map },
  { label: "Parques", path: "/parks", icon: Trees },
  { label: "Equipos", path: "/teams", icon: Users2 },
  { label: "Rodar", path: "/rides", icon: Bike },
  // { label: "Workshops", path: "/workshops", icon: Hammer },
];

const SearchPanel = () => {
  return (
    <div className="min-h-screen bg-gray-50 p-6 flex items-start justify-center">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 w-full max-w-4xl">
        {routes.map((route, index) => {
          const Icon = route.icon;
          return (
            <a
              key={index}
              href={route.path}
              className="group flex flex-col items-center justify-center p-5 bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 relative overflow-hidden border border-gray-100"
            >
              {/* Efecto de fondo en hover */}
              <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>

              {/* Contenedor del icono con efecto de elevación */}
              <div className="relative z-10 w-14 h-14 rounded-full bg-gray-50 flex items-center justify-center mb-3 group-hover:shadow-md group-hover:scale-110 transition-all duration-300">
                <Icon className="w-7 h-7 text-gray-700 group-hover:text-black transition-colors duration-300" />
              </div>

              {/* Etiqueta del botón */}
              <span className="relative z-10 text-sm font-medium text-gray-700 group-hover:text-black transition-colors duration-300">
                {route.label}
              </span>

              {/* Línea de acento en la parte inferior */}
              <div className="absolute bottom-0 left-0 right-0 h-1 bg-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SearchPanel;
