import React from 'react';
import { Bug, RefreshCw } from 'lucide-react';

/**
 * Componente para depuración y diagnóstico de comunicación serial
 * 
 * @param {Array} debugLogs - Array de logs de depuración
 * @param {Function} clearDebugLogs - Función para limpiar logs de depuración
 * @param {Array} rawData - Array de datos raw recibidos
 * @param {Function} clearRawData - Función para limpiar datos raw
 * @param {Boolean} initiallyOpen - Si el panel debe estar abierto inicialmente
 */
const DebugPanel = ({
    debugLogs = [],
    clearDebugLogs = () => { },
    rawData = [],
    clearRawData = () => { },
    initiallyOpen = false
}) => {
    return (
        <details className="bg-white rounded-lg shadow-sm border mb-6" open={initiallyOpen}>
            <summary className="p-4 font-medium cursor-pointer flex items-center">
                <Bug className="h-5 w-5 mr-2 text-purple-600" />
                Depuración y diagnóstico
                <span className="ml-3 px-2 py-0.5 bg-purple-100 text-purple-800 rounded-full text-xs">
                    {debugLogs.length > 0 ? `${debugLogs.length} logs` : 'Sin datos'}
                </span>
            </summary>

            <div className="p-4 border-t">
                <div className="grid grid-cols-1 gap-4">
                    {/* Logs de depuración */}
                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="font-medium">Logs de operación</h3>
                            <button
                                onClick={clearDebugLogs}
                                className="text-xs px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                            >
                                Limpiar
                            </button>
                        </div>

                        <div className="border rounded-lg bg-gray-900 text-gray-100 p-3 h-52 overflow-y-auto font-mono text-sm">
                            {debugLogs.length > 0 ? (
                                <div className="space-y-2">
                                    {debugLogs.map(log => (
                                        <div key={log.id} className={`
                                            py-2 px-3 rounded 
                                            ${log.type === 'error' ? 'bg-red-900 text-red-100' :
                                                log.type === 'success' ? 'bg-green-900 text-green-100' :
                                                    log.type === 'warning' ? 'bg-yellow-900 text-yellow-100' : ''}
                                        `}>
                                            <span className="text-gray-400">[{log.timestamp}]</span> {log.message}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center py-10 text-gray-500">
                                    <p>No hay logs de depuración</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Datos raw recibidos */}
                    <div>
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="font-medium">Datos brutos recibidos</h3>
                            <button
                                onClick={clearRawData}
                                className="text-xs px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                            >
                                Limpiar
                            </button>
                        </div>

                        <div className="border rounded-lg bg-gray-900 text-gray-100 p-3 h-40 overflow-y-auto font-mono text-sm">
                            {rawData.length > 0 ? (
                                <div className="space-y-2">
                                    {rawData.map(item => (
                                        <div key={item.id} className="py-2 px-3 border-b border-gray-800">
                                            <div className="text-gray-400 mb-1">[{item.timestamp}]</div>
                                            <div className="text-green-400 break-all whitespace-pre-wrap">
                                                {JSON.stringify(item.text)}
                                            </div>
                                            {item.bytes && (
                                                <div className="mt-1 text-yellow-400 break-all whitespace-pre-wrap text-xs">
                                                    Bytes: {item.bytes}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center py-10 text-gray-500">
                                    <p>No hay datos brutos recibidos</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </details>
    );
};

export default DebugPanel;