import React, { useState, useEffect, useRef } from 'react';
import { Cpu, Scan, Activity, Filter, User, Shield, AlertCircle } from 'lucide-react';
import DebugPanel from './DebugPanel'; // Importamos el componente DebugPanel
import UserTagControlUHF from './UserTagControlUHF';

// Constantes para filtrado de tarjetas
const FORMATO_WIEGAND_26 = "26bit"; // El formato de las tarjetas de Pedro y Billy

const SimpleRFIDScanner = () => {
    // Estados para la conexión y datos
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [lastCard, setLastCard] = useState(null);
    const [cardHistory, setCardHistory] = useState([]);
    const [serialPort, setSerialPort] = useState(null);

    // Estados para filtrado y configuración
    const [showOnlyValidFormat, setShowOnlyValidFormat] = useState(true);
    const [highlightKnownCards, setHighlightKnownCards] = useState(true);

    // Estados para depuración - ahora usados por el componente DebugPanel
    const [debugLogs, setDebugLogs] = useState([]);
    const [rawData, setRawData] = useState([]);

    // Referencias para la comunicación serial
    const reader = useRef(null);
    const textDecoder = useRef(new TextDecoder());
    const lineBuffer = useRef('');
    const readerCancelled = useRef(false);

    // Función para agregar logs de depuración
    const addDebugLog = (message, type = 'info') => {
        const timestamp = new Date().toLocaleTimeString();
        setDebugLogs(prev => [{
            id: Date.now(),
            message,
            type,
            timestamp
        }, ...prev].slice(0, 50)); // Mantener 50 logs más recientes
    };

    // Funciones para limpiar los datos de depuración
    const clearDebugLogs = () => setDebugLogs([]);
    const clearRawData = () => setRawData([]);

    // Conectar al dispositivo ESP32
    const connectToDevice = async () => {
        if (!navigator.serial) {
            addDebugLog("Tu navegador no soporta Web Serial API. Usa Chrome o Edge.", "error");
            alert("Tu navegador no soporta Web Serial API. Por favor usa Chrome o Edge.");
            return;
        }

        try {
            setConnecting(true);
            addDebugLog("Solicitando puerto serial...");

            // Solicitar puerto
            const port = await navigator.serial.requestPort();
            addDebugLog("Puerto seleccionado, abriendo conexión...");

            await port.open({ baudRate: 115200 });
            addDebugLog("Conexión abierta a 115200 baudios", "success");

            setSerialPort(port);
            setConnected(true);

            // Iniciar lectura
            addDebugLog("Iniciando lectura de datos...");
            readFromSerial(port);

            // Enviar comando para asegurar formato 26-bit
            setTimeout(() => {
                sendCommand('2');  // Configurar ESP32 para formato 26-bit
                sendCommand('f');  // Activar filtro de formato
            }, 1000);

        } catch (error) {
            addDebugLog(`Error al conectar: ${error.message}`, "error");
            console.error("Error al conectar:", error);
        } finally {
            setConnecting(false);
        }
    };

    // Desconectar
    const disconnect = async () => {
        if (!serialPort) return;

        try {
            addDebugLog("Desconectando...");

            // Marcar como cancelado antes de intentar cancelar el reader
            readerCancelled.current = true;

            if (reader.current) {
                try {
                    await reader.current.cancel();
                    addDebugLog("Lector cancelado");
                } catch (e) {
                    addDebugLog(`No se pudo cancelar el lector: ${e.message}`, "warning");
                    // Continuamos ya que este error es esperado en algunos casos
                }
                reader.current = null;
            }

            await serialPort.close();
            addDebugLog("Puerto serial cerrado", "success");

            setConnected(false);
            setSerialPort(null);
        } catch (error) {
            addDebugLog(`Error al desconectar: ${error.message}`, "error");
            console.error("Error al desconectar:", error);
        } finally {
            readerCancelled.current = false;
        }
    };

    // Enviar un comando al ESP32
    const sendCommand = async (command) => {
        if (!serialPort || !connected) {
            addDebugLog("No se puede enviar comando: no hay conexión", "error");
            return;
        }

        try {
            addDebugLog(`Enviando comando: '${command}'`);
            const writer = serialPort.writable.getWriter();
            const data = new TextEncoder().encode(command + '\n');
            await writer.write(data);
            writer.releaseLock();
            addDebugLog(`Comando enviado: '${command}'`, "success");
        } catch (error) {
            addDebugLog(`Error al enviar comando: ${error.message}`, "error");
        }
    };

    // Leer datos del serial
    const readFromSerial = async (port) => {
        // Reiniciar el flag de cancelación
        readerCancelled.current = false;

        try {
            // Si no hay puerto o ya está cancelado, no proceder
            if (!port || readerCancelled.current) return;

            // Crear el reader solo si no existe o si ha sido liberado
            if (!reader.current) {
                reader.current = port.readable.getReader();
                addDebugLog("Lector creado correctamente");
            }

            // Bucle de lectura
            while (!readerCancelled.current) {
                try {
                    const { value, done } = await reader.current.read();

                    if (done) {
                        addDebugLog("Lectura finalizada (stream cerrado)", "warning");
                        break;
                    }

                    // Procesar datos
                    processData(value);
                } catch (readError) {
                    if (!readerCancelled.current) {
                        addDebugLog(`Error durante la lectura: ${readError.message}`, "error");
                    }
                    break;
                }
            }
        } catch (error) {
            if (!readerCancelled.current) {
                addDebugLog(`Error iniciando la lectura: ${error.message}`, "error");
                console.error("Error leyendo datos:", error);
            }
        } finally {
            // Solo liberar el reader si no ha sido ya liberado y no estamos en proceso de cancelación
            if (reader.current && !readerCancelled.current) {
                try {
                    reader.current.releaseLock();
                    addDebugLog("Lector liberado normalmente");
                } catch (e) {
                    addDebugLog(`Error al liberar lector: ${e.message}`, "warning");
                }
                reader.current = null;
            }
        }
    };

    // Verifica si una tarjeta cumple con el formato válido (26-bit como Pedro/Billy)
    const isValidCardFormat = (card) => {
        if (!card) return false;
        return card.format === FORMATO_WIEGAND_26;
    };

    // Verifica si es una tarjeta "conocida" (Pedro o Billy)
    const isKnownCard = (card) => {
        if (!card) return false;
        return card.owner && (card.owner.toUpperCase() === 'PEDRO' || card.owner.toUpperCase() === 'BILLY');
    };

    // Procesar datos recibidos
    const processData = (data) => {
        // Convertir a texto
        const text = textDecoder.current.decode(data);

        // Guardar datos raw para depuración con representación adicional
        if (text.trim()) {
            // Crear una representación de los bytes para mejor depuración
            const byteArray = Array.from(data).map(byte =>
                `${byte.toString(16).padStart(2, '0')}(${String.fromCharCode(byte).replace(/[^\x20-\x7E]/g, '.')})`
            ).join(' ');

            setRawData(prev => [
                {
                    id: Date.now(),
                    text,
                    bytes: byteArray,
                    timestamp: new Date().toLocaleTimeString()
                },
                ...prev
            ].slice(0, 20));
        }

        lineBuffer.current += text;

        // Buscar líneas completas
        const lines = lineBuffer.current.split('\n');

        // Si no hay línea completa, esperar más datos
        if (lines.length === 1) return;

        // Guardar el resto incompleto
        lineBuffer.current = lines.pop() || '';

        // Procesar líneas completas
        for (const line of lines) {
            const trimmedLine = line.trim();

            if (trimmedLine) {
                addDebugLog(`Recibido: ${trimmedLine.substring(0, 50)}${trimmedLine.length > 50 ? '...' : ''}`);

                if (trimmedLine.startsWith('{') && trimmedLine.endsWith('}')) {
                    try {
                        const jsonData = JSON.parse(trimmedLine);

                        // Si es una tarjeta
                        if (jsonData.type === 'card') {
                            // Verificar si cumple con el formato deseado
                            const isValid = isValidCardFormat(jsonData);
                            const isKnown = isKnownCard(jsonData);

                            // Solo procesar si cumple con el filtro o si no hay filtro
                            if (!showOnlyValidFormat || isValid) {
                                addDebugLog(`¡Tarjeta detectada! Formato: ${jsonData.format}, Número: ${jsonData.cardNumber || 'N/A'}`,
                                    isKnown ? "success" : (isValid ? "info" : "warning"));

                                const cardData = {
                                    ...jsonData,
                                    timestamp: new Date().toLocaleTimeString(),
                                    isKnown: isKnown,
                                    isValidFormat: isValid
                                };

                                setLastCard(cardData);
                                setCardHistory(prev => [cardData, ...prev].slice(0, 20)); // Mantener últimas 20
                            } else {
                                addDebugLog(`Tarjeta ignorada - Formato no compatible: ${jsonData.format}`, "warning");
                            }
                        } else {
                            // Otro tipo de mensaje (status, command, etc.)
                            addDebugLog(`Mensaje de tipo: ${jsonData.type || 'desconocido'}`);
                        }
                    } catch (error) {
                        addDebugLog(`Error procesando JSON: ${error.message}`, "error");
                    }
                }
            }
        }
    };

    // Enviar ping para comprobar conexión
    const sendPing = () => {
        sendCommand('p');
    };

    // Alternar filtro de formato 26-bit
    const toggleFormatFilter = () => {
        setShowOnlyValidFormat(!showOnlyValidFormat);
        addDebugLog(`Filtro de formato ${!showOnlyValidFormat ? 'ACTIVADO' : 'DESACTIVADO'}`,
            !showOnlyValidFormat ? "success" : "warning");
    };

    // Alternar resaltado de tarjetas conocidas
    const toggleHighlightKnown = () => {
        setHighlightKnownCards(!highlightKnownCards);
    };

    // Gestión del ciclo de vida
    useEffect(() => {
        // Al montar, resetear estados
        readerCancelled.current = false;

        // Al desmontar
        return () => {
            // Marcar como cancelado
            readerCancelled.current = true;

            // Limpiar reader
            if (reader.current) {
                try {
                    reader.current.cancel().catch(() => {
                        // Ignorar errores de cancelación al desmontar
                    });
                } catch (e) {
                    // Ignorar errores
                }
                reader.current = null;
            }

            // Cerrar puerto
            if (serialPort) {
                serialPort.close().catch(() => {
                    // Ignorar errores de cierre al desmontar
                });
            }
        };
    }, []);

    // Filtrar historial de tarjetas basado en el filtro actual
    const filteredCardHistory = cardHistory.filter(card =>
        !showOnlyValidFormat || isValidCardFormat(card)
    );

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="container mx-auto">
                {/* Cabecera */}
                <div className="mb-6 flex justify-between items-center">
                    <h1 className="text-xl font-bold flex items-center">
                        <Scan className="h-6 w-6 mr-2 text-blue-600" />
                        Lector RFID <span className="ml-2 text-sm text-blue-600 font-normal">v2.0</span>
                    </h1>

                    <div className="flex space-x-2">
                        {!connected ? (
                            <button
                                onClick={connectToDevice}
                                disabled={connecting}
                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center"
                            >
                                <Cpu className="h-4 w-4 mr-2" />
                                {connecting ? 'Conectando...' : 'Conectar ESP32'}
                            </button>
                        ) : (
                            <>
                                <button
                                    onClick={sendPing}
                                    className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 flex items-center"
                                >
                                    <Activity className="h-4 w-4 mr-2" />
                                    Ping
                                </button>
                                <button
                                    onClick={disconnect}
                                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 flex items-center"
                                >
                                    <Cpu className="h-4 w-4 mr-2" />
                                    Desconectar
                                </button>
                            </>
                        )}
                    </div>
                </div>

                {/* Estado y opciones de filtrado */}
                <div className="mb-6 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                    {/* Estado de conexión */}
                    <div className="flex-1 flex items-center p-3 rounded-lg bg-white shadow-sm border">
                        <div className={`h-3 w-3 rounded-full mr-2 ${connected ? 'bg-green-500' : 'bg-red-500'}`}></div>
                        <span className="mr-4">{connected ? 'Conectado al lector RFID' : 'Desconectado'}</span>
                    </div>

                    {/* Filtros */}
                    <div className="p-3 rounded-lg bg-white shadow-sm border flex flex-wrap gap-2 items-center">
                        <button
                            onClick={toggleFormatFilter}
                            className={`px-3 py-1 rounded flex items-center text-sm
                                ${showOnlyValidFormat
                                    ? 'bg-blue-100 text-blue-800 hover:bg-blue-200'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                        >
                            <Filter className="h-3 w-3 mr-1" />
                            {showOnlyValidFormat ? 'Formato 26-bit Activo' : 'Mostrar Todos'}
                        </button>

                        <button
                            onClick={toggleHighlightKnown}
                            className={`px-3 py-1 rounded flex items-center text-sm
                                ${highlightKnownCards
                                    ? 'bg-green-100 text-green-800 hover:bg-green-200'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                        >
                            <User className="h-3 w-3 mr-1" />
                            {highlightKnownCards ? 'Resaltado Activo' : 'Sin Resaltar'}
                        </button>
                    </div>

                    {/* Comandos rápidos */}
                    {connected && (
                        <div className="flex flex-wrap gap-2 items-center p-3 rounded-lg bg-white shadow-sm border">
                            <button
                                onClick={() => sendCommand('h')}
                                className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 text-xs"
                            >
                                Ayuda
                            </button>
                            <button
                                onClick={() => sendCommand('m')}
                                className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 text-xs"
                            >
                                Monitor
                            </button>
                            <button
                                onClick={() => sendCommand('t')}
                                className="px-2 py-1 rounded bg-gray-200 hover:bg-gray-300 text-xs"
                            >
                                Filtro ESP
                            </button>
                        </div>
                    )}
                </div>

                {/* Panel principal - Datos de tarjetas */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    {/* Última tarjeta escaneada */}
                    <div className="md:col-span-2">
                        <h2 className="text-lg font-medium mb-3 flex items-center">
                            <Shield className="h-5 w-5 mr-2 text-blue-600" />
                            Última tarjeta
                        </h2>
                        {lastCard ? (
                            <div className={`border rounded-lg p-4 bg-white shadow-sm ${lastCard.isKnown && highlightKnownCards ? 'border-green-500 border-2' : ''}`}>
                                <div className="flex justify-between items-center mb-2">
                                    <div className={`font-medium text-lg ${lastCard.isKnown && highlightKnownCards ? 'text-green-600' : ''}`}>
                                        {lastCard.owner || "Desconocido"}
                                    </div>
                                    <span className="text-xs text-gray-500">{lastCard.timestamp}</span>
                                </div>

                                <div className="grid grid-cols-2 gap-2 text-sm mb-2">
                                    <div className="flex items-center">
                                        <span className="text-gray-500 mr-1">Formato:</span>
                                        <span className="font-mono">{lastCard.format}</span>
                                    </div>
                                    {lastCard.facilityCode != null && (
                                        <div className="flex items-center">
                                            <span className="text-gray-500 mr-1">Facility:</span>
                                            <span className="font-mono">{lastCard.facilityCode}</span>
                                        </div>
                                    )}
                                    <div className="flex items-center">
                                        <span className="text-gray-500 mr-1">Número:</span>
                                        <span className="font-mono">{lastCard.cardNumber}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="text-gray-500 mr-1">Paridad:</span>
                                        <span className={lastCard.paridad ? 'text-green-600' : 'text-yellow-600'}>
                                            {lastCard.paridad ? 'OK' : 'Error'}
                                        </span>
                                    </div>
                                </div>

                                <div className="text-xs font-mono bg-gray-50 p-2 rounded mt-2 overflow-x-auto whitespace-nowrap">
                                    {lastCard.hex}
                                </div>
                            </div>
                        ) : (
                            <div className="border rounded-lg p-6 bg-white shadow-sm text-center text-gray-500">
                                <Scan className="h-10 w-10 mx-auto mb-2 text-gray-300" />
                                <p>Esperando lectura de tarjeta...</p>
                                {connected ? (
                                    <p className="text-xs mt-2">Acerca una tarjeta al lector</p>
                                ) : (
                                    <p className="text-xs mt-2">Conecta el ESP32 para comenzar</p>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Contadores y estadísticas */}
                    <div>
                        <h2 className="text-lg font-medium mb-3">Estadísticas</h2>
                        <div className="border rounded-lg p-4 bg-white shadow-sm mb-3">
                            <div className="grid grid-cols-2 gap-3">
                                <div className="text-center p-3 bg-blue-50 rounded">
                                    <div className="text-2xl font-bold text-blue-600">
                                        {cardHistory.filter(card => isValidCardFormat(card)).length}
                                    </div>
                                    <div className="text-sm text-gray-600">Tarjetas 26-bit</div>
                                </div>
                                <div className="text-center p-3 bg-green-50 rounded">
                                    <div className="text-2xl font-bold text-green-600">
                                        {cardHistory.filter(card => isKnownCard(card)).length}
                                    </div>
                                    <div className="text-sm text-gray-600">Conocidas</div>
                                </div>
                                <div className="text-center p-3 bg-purple-50 rounded">
                                    <div className="text-2xl font-bold text-purple-600">
                                        {cardHistory.length}
                                    </div>
                                    <div className="text-sm text-gray-600">Total leídas</div>
                                </div>
                                <div className="text-center p-3 bg-gray-50 rounded">
                                    <div className="text-2xl font-bold text-gray-600">
                                        {cardHistory.filter(card => !isValidCardFormat(card)).length}
                                    </div>
                                    <div className="text-sm text-gray-600">Otros formatos</div>
                                </div>
                            </div>
                        </div>

                        {/* Estado del lector */}
                        <div className="border rounded-lg p-4 bg-white shadow-sm text-sm">
                            <div className="flex items-center mb-2">
                                <Activity className="h-4 w-4 mr-2 text-blue-600" />
                                <span className="font-medium">Estado del lector</span>
                            </div>
                            <div className="flex items-center justify-between mb-1">
                                <span className="text-gray-600">Filtro formato:</span>
                                <span className={`px-2 py-0.5 rounded ${showOnlyValidFormat ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'}`}>
                                    {showOnlyValidFormat ? 'Activo (26-bit)' : 'Inactivo'}
                                </span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="text-gray-600">Último escaneo:</span>
                                <span className="text-gray-800">{lastCard ? lastCard.timestamp : 'Ninguno'}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Historial de tarjetas */}
                <div className="mb-6">
                    <div className="flex justify-between items-center mb-3">
                        <h2 className="text-lg font-medium flex items-center">
                            <User className="h-5 w-5 mr-2 text-blue-600" />
                            Historial de tarjetas {showOnlyValidFormat ? '(26-bit)' : ''}
                        </h2>
                        <span className="text-sm text-gray-500">
                            {filteredCardHistory.length} {filteredCardHistory.length === 1 ? 'tarjeta' : 'tarjetas'}
                        </span>
                    </div>

                    {filteredCardHistory.length > 0 ? (
                        <div className="border rounded-lg bg-white shadow-sm overflow-hidden">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Propietario
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Número
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Facility
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Formato
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Hora
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredCardHistory.map((card, index) => (
                                            <tr key={index} className={`hover:bg-gray-50 ${card.isKnown && highlightKnownCards ? 'bg-green-50' : ''}`}>
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        {card.isKnown && highlightKnownCards ? (
                                                            <Shield className="h-4 w-4 mr-2 text-green-600" />
                                                        ) : (
                                                            <User className="h-4 w-4 mr-2 text-gray-400" />
                                                        )}
                                                        <span className={`${card.isKnown && highlightKnownCards ? 'font-medium text-green-600' : ''}`}>
                                                            {card.owner || "Desconocido"}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    <span className="font-mono text-sm">{card.cardNumber}</span>
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    <span className="font-mono text-sm">{card.facilityCode || "-"}</span>
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap">
                                                    <span className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800">
                                                        {card.format}
                                                    </span>
                                                </td>
                                                <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                                                    {card.timestamp}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="border rounded-lg p-6 bg-white shadow-sm text-center text-gray-500">
                            <p>No hay tarjetas en el historial</p>
                            {showOnlyValidFormat && cardHistory.length > 0 && (
                                <p className="text-xs mt-2">
                                    Hay {cardHistory.length} tarjetas en total, pero el filtro de formato 26-bit está activo
                                </p>
                            )}
                        </div>
                    )}
                </div>

                {/* Componente de depuración DebugPanel */}
                <DebugPanel
                    debugLogs={debugLogs}
                    clearDebugLogs={clearDebugLogs}
                    rawData={rawData}
                    clearRawData={clearRawData}
                    initiallyOpen={false}
                />

                <UserTagControlUHF lastScannedTag={lastCard} />
                {/* Alerta cuando se pierde la conexión */}
                {!connected && cardHistory.length > 0 && (
                    <div className="fixed bottom-4 right-4">
                        <div className="bg-white rounded-lg shadow-lg border-l-4 border-red-500 p-4 w-72">
                            <div className="flex items-start">
                                <AlertCircle className="h-5 w-5 text-red-500 mr-3 mt-0.5" />
                                <div>
                                    <h3 className="font-medium text-red-800">Dispositivo desconectado</h3>
                                    <p className="text-sm text-gray-600 mt-1">
                                        Reconecta tu ESP32 para continuar escaneando tarjetas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Resumen de información */}
                <div className="text-xs text-center text-gray-500 mt-6">
                    <p>Este componente está configurado para detectar tarjetas RFID en formato 26-bit (como las de Pedro y Billy).</p>
                    <p>Para obtener mejores resultados, mantén el ESP32 conectado y pasa las tarjetas lentamente por el lector.</p>
                </div>
            </div>
        </div>
    );
};

export default SimpleRFIDScanner;