import React from "react";
import { ChevronLeft, Send } from "lucide-react";
import ChatMessage from "./ChatMessage";

const MobileChatView = ({
    selectedChatId,
    setSelectedChatId,
    selectedChat,
    chatMessages,
    chatEndRef,
    message,
    setMessage,
    handleSendMessage,
    isSending
}) => {
    return (
        <div className="flex md:hidden flex-col flex-1">
            {/* Mobile chat header with back button */}
            <div className="bg-white p-4 border-b flex items-center justify-between">
                <div className="flex items-center">
                    <button
                        onClick={() => setSelectedChatId("")}
                        className="mr-2 p-1 rounded-full hover:bg-gray-100"
                    >
                        <ChevronLeft size={20} />
                    </button>
                    <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden mr-2">
                        <img
                            src={
                                selectedChat?.userPhotoURL ||
                                (selectedChat?.isGuest
                                    ? "/default-user.png"
                                    : "/default-user.png")
                            }
                            alt="User"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div>
                        <h2 className="font-semibold text-sm">
                            {selectedChat?.sessionName ||
                                (selectedChat?.isGuest
                                    ? selectedChat?.guestName
                                    : selectedChat?.userName || "Usuario")}
                            <span className="text-xs ml-2 bg-blue-600 text-white px-2 py-0.5 rounded-full">
                                Livespeed
                            </span>
                        </h2>
                        {selectedChat?.isGuest && (
                            <div className="text-xs text-gray-500">
                                {selectedChat.guestPhone}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Messages area for mobile */}
            <div className="flex-1 p-3 overflow-y-auto bg-gray-50">
                {chatMessages.map((message, index) => (
                    <ChatMessage
                        key={index}
                        message={message}
                        isNew={index === chatMessages.length - 1 && message.role === "user"}
                    />
                ))}
                <div ref={chatEndRef} />
            </div>

            {/* Message input for mobile */}
            <form
                onSubmit={handleSendMessage}
                className="bg-white border-t p-3 flex gap-2 items-center"
            >
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Responder como Livespeed..."
                    className="flex-1 rounded-lg px-3 py-2 border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
                />
                <button
                    type="submit"
                    disabled={isSending || !message.trim()}
                    className="bg-blue-600 text-white p-2 rounded-lg transition-colors hover:bg-blue-700 disabled:bg-blue-300 flex items-center justify-center"
                >
                    {isSending ? (
                        <div className="w-5 h-5 border-2 border-gray-300 border-t-white rounded-full animate-spin"></div>
                    ) : (
                        <Send size={18} />
                    )}
                </button>
            </form>
        </div>
    );
};

export default MobileChatView;