import React, { useState, useEffect, useRef } from 'react';
import { Calculator, Edit, Save, Download, Printer, RefreshCw } from 'lucide-react';

const ReciboEsmeralda = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [categorias, setCategorias] = useState([
        { id: 6, nombre: 'E-BIKES 64K', participantes: 10, costo: 380.00, total: 3800.00 },
        { id: 7, nombre: 'XC VARONIL 27K', participantes: 89, costo: 380.00, total: 33820.00 },
        { id: 8, nombre: 'XC VARONIL 27K - 50+', participantes: 33, costo: 380.00, total: 12540.00 },
        { id: 9, nombre: 'MARATIN VARONIL 32K', participantes: 71, costo: 380.00, total: 26980.00 },
        { id: 10, nombre: 'MARATIN VARONIL 15K', participantes: 20, costo: 250.00, total: 5000.00 },
        { id: 11, nombre: 'MARATIN FEMENIL 15K', participantes: 15, costo: 250.00, total: 3750.00 },
        { id: 12, nombre: 'MARATIN FEMENIL 32K', participantes: 17, costo: 380.00, total: 6460.00 },
        { id: 13, nombre: 'XC VARONIL 11K', participantes: 3, costo: 250.00, total: 750.00 },
        { id: 14, nombre: 'XC VARONIL 54K - 50+', participantes: 6, costo: 380.00, total: 2280.00 },
        { id: 15, nombre: 'MARATIN VARONIL 64K', participantes: 25, costo: 380.00, total: 9500.00 },
        { id: 16, nombre: 'XC VARONIL 11K 14 AÑOS O MENOS', participantes: 2, costo: 0, total: 0 },
        { id: 17, nombre: 'XC VARONIL 54K', participantes: 12, costo: 380.00, total: 4560.00 },
        { id: 18, nombre: 'XC FEMENIL 27K - 50+', participantes: 2, costo: 380.00, total: 760.00 },
        { id: 19, nombre: 'XC FEMENIL 11K 14 AÑOS O MENOS', participantes: 2, costo: 0, total: 0 },
        { id: 20, nombre: 'MARATIN FEMENIL 64K', participantes: 5, costo: 380.00, total: 1900.00 },
        { id: 21, nombre: 'XC FEMENIL 27K', participantes: 8, costo: 380.00, total: 3040.00 }
    ]);

    const [serviciosLivespeed, setServiciosLivespeed] = useState([
        { nombre: 'Sistema de inscripciones Costo MercadoPago', cantidad: 300, unidad: 'unidad', precioUnitario: 20.00, total: 6000.00 },
        { nombre: 'Transmisión con dron', cantidad: 1, unidad: 'Hora', precioUnitario: 6000.00, total: 6000.00 },
        { nombre: 'Tiempos XC (punto de detección)', cantidad: 1, unidad: 'punto', precioUnitario: 14800.00, total: 14800.00 }
    ]);

    const [descuentos, setDescuentos] = useState([
        { nombre: 'Inscripciones en efectivo Bicimax', monto: 6450.00 },
        { nombre: 'Inscripciones en efectivo Guaymas', monto: 2150.00 },
        { nombre: 'Inscripción de prueba E-bike (LIVESPEED)', monto: 380.00 },
        { nombre: 'Cortesías a organizadores (2)', monto: 760.00 }
    ]);

    // Cargar datos de localStorage al inicio
    useEffect(() => {
        const savedCategorias = localStorage.getItem('esmeralda_categorias');
        const savedServiciosLivespeed = localStorage.getItem('esmeralda_serviciosLivespeed');
        const savedDescuentos = localStorage.getItem('esmeralda_descuentos');

        if (savedCategorias) setCategorias(JSON.parse(savedCategorias));
        if (savedServiciosLivespeed) setServiciosLivespeed(JSON.parse(savedServiciosLivespeed));
        if (savedDescuentos) setDescuentos(JSON.parse(savedDescuentos));
    }, []);

    // Guardar datos en localStorage cuando cambien
    useEffect(() => {
        localStorage.setItem('esmeralda_categorias', JSON.stringify(categorias));
        localStorage.setItem('esmeralda_serviciosLivespeed', JSON.stringify(serviciosLivespeed));
        localStorage.setItem('esmeralda_descuentos', JSON.stringify(descuentos));
    }, [categorias, serviciosLivespeed, descuentos]);

    // Calcular totales
    const totalBruto = categorias.reduce((sum, cat) => sum + cat.total, 0);
    const totalLivespeed = serviciosLivespeed.reduce((sum, serv) => sum + serv.total, 0);
    const totalDescuentosAdicionales = descuentos.reduce((sum, desc) => sum + desc.monto, 0);
    const totalDescuentos = totalLivespeed + totalDescuentosAdicionales;
    const totalNeto = totalBruto - totalDescuentos;

    const handleCategoriaChange = (id, field, value) => {
        setCategorias(prev => {
            const updated = prev.map(cat => {
                if (cat.id === id) {
                    const updatedCat = { ...cat, [field]: value };
                    // Recalcular el total si cambia participantes o costo
                    if (field === 'participantes' || field === 'costo') {
                        updatedCat.total = updatedCat.participantes * updatedCat.costo;
                    }
                    return updatedCat;
                }
                return cat;
            });
            return updated;
        });
    };

    const handleServicioChange = (index, field, value) => {
        setServiciosLivespeed(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], [field]: value };
            // Recalcular el total si cambia cantidad o precioUnitario
            if (field === 'cantidad' || field === 'precioUnitario') {
                updated[index].total = updated[index].cantidad * updated[index].precioUnitario;
            }
            return updated;
        });
    };

    const handleDescuentoChange = (index, field, value) => {
        setDescuentos(prev => {
            const updated = [...prev];
            updated[index] = { ...updated[index], [field]: value };
            return updated;
        });
    };

    const reciboRef = useRef(null);

    const handlePrint = () => {
        window.print();
    };

    const handleDownloadPDF = () => {
        // Como html2pdf no está disponible en el CDN permitido, mostramos un mensaje al usuario
        alert('Para descargar como PDF, utilice la función de impresión del navegador y seleccione "Guardar como PDF"');

        // Alternativamente, inicie la impresión para que el usuario pueda guardar como PDF
        window.print();
    };

    const resetToDefaults = () => {
        // Eliminar datos del localStorage y recargar desde valores iniciales
        localStorage.removeItem('esmeralda_categorias');
        localStorage.removeItem('esmeralda_serviciosLivespeed');
        localStorage.removeItem('esmeralda_descuentos');
        window.location.reload();
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
    };

    return (
        <div ref={reciboRef} className="max-w-4xl mx-auto my-8 p-6 bg-white rounded-lg shadow-lg print:shadow-none">
            {/* Encabezado con logo */}
            <div className="flex justify-between items-center mb-6 border-b pb-4">
                <div className="flex items-center">
                    <div className="h-16 w-auto mr-4">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
                            alt="LiveSpeed Logo"
                            className="h-16 object-contain"
                        />
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-800">Recibo Esmeralda</h1>
                        <p className="text-gray-600">Fecha: {new Date().toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={() => setIsEditing(!isEditing)}
                        className="flex items-center px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
                    >
                        {isEditing ? <Save size={18} className="mr-1" /> : <Edit size={18} className="mr-1" />}
                        {isEditing ? 'Guardar' : 'Editar'}
                    </button>
                    <button
                        onClick={handlePrint}
                        className="flex items-center px-3 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition"
                    >
                        <Printer size={18} className="mr-1" />
                        Imprimir
                    </button>
                    <button
                        onClick={handleDownloadPDF}
                        className="flex items-center px-3 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition"
                    >
                        <Download size={18} className="mr-1" />
                        Descargar PDF
                    </button>
                    <button
                        onClick={resetToDefaults}
                        className="flex items-center px-3 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
                    >
                        <RefreshCw size={18} className="mr-1" />
                        Reiniciar
                    </button>
                </div>
            </div>

            {/* Detalle de categorías */}
            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-3 flex items-center">
                    <Calculator size={20} className="mr-2 text-green-600" />
                    Detalle de categorías
                </h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-3 border text-left">ID</th>
                                <th className="py-2 px-3 border text-left">Categoría</th>
                                <th className="py-2 px-3 border text-right">Participantes</th>
                                <th className="py-2 px-3 border text-right">Costo</th>
                                <th className="py-2 px-3 border text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categorias.map((cat) => (
                                <tr key={cat.id} className="hover:bg-gray-50">
                                    <td className="py-2 px-3 border">{cat.id}</td>
                                    <td className="py-2 px-3 border">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={cat.nombre}
                                                onChange={(e) => handleCategoriaChange(cat.id, 'nombre', e.target.value)}
                                                className="w-full p-1 border rounded"
                                            />
                                        ) : cat.nombre}
                                    </td>
                                    <td className="py-2 px-3 border text-right">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                value={cat.participantes}
                                                onChange={(e) => handleCategoriaChange(cat.id, 'participantes', Number(e.target.value))}
                                                className="w-20 p-1 border rounded text-right"
                                            />
                                        ) : cat.participantes}
                                    </td>
                                    <td className="py-2 px-3 border text-right">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                value={cat.costo}
                                                onChange={(e) => handleCategoriaChange(cat.id, 'costo', Number(e.target.value))}
                                                className="w-24 p-1 border rounded text-right"
                                                step="0.01"
                                            />
                                        ) : formatCurrency(cat.costo)}
                                    </td>
                                    <td className="py-2 px-3 border text-right font-medium">
                                        {formatCurrency(cat.total)}
                                    </td>
                                </tr>
                            ))}
                            <tr className="bg-gray-100 font-bold">
                                <td colSpan="4" className="py-2 px-3 border text-right">SUBTOTAL:</td>
                                <td className="py-2 px-3 border text-right">{formatCurrency(totalBruto)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Desglose de operaciones LIVESPEED */}
            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-3">Desglose de operaciones LIVESPEED</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-3 border text-left">Servicio</th>
                                <th className="py-2 px-3 border text-right">Cantidad</th>
                                <th className="py-2 px-3 border text-left">Unidad</th>
                                <th className="py-2 px-3 border text-right">Precio unitario</th>
                                <th className="py-2 px-3 border text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviciosLivespeed.map((serv, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="py-2 px-3 border">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={serv.nombre}
                                                onChange={(e) => handleServicioChange(index, 'nombre', e.target.value)}
                                                className="w-full p-1 border rounded"
                                            />
                                        ) : serv.nombre}
                                    </td>
                                    <td className="py-2 px-3 border text-right">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                value={serv.cantidad}
                                                onChange={(e) => handleServicioChange(index, 'cantidad', Number(e.target.value))}
                                                className="w-20 p-1 border rounded text-right"
                                            />
                                        ) : serv.cantidad}
                                    </td>
                                    <td className="py-2 px-3 border">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={serv.unidad}
                                                onChange={(e) => handleServicioChange(index, 'unidad', e.target.value)}
                                                className="w-full p-1 border rounded"
                                            />
                                        ) : serv.unidad}
                                    </td>
                                    <td className="py-2 px-3 border text-right">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                value={serv.precioUnitario}
                                                onChange={(e) => handleServicioChange(index, 'precioUnitario', Number(e.target.value))}
                                                className="w-24 p-1 border rounded text-right"
                                                step="0.01"
                                            />
                                        ) : formatCurrency(serv.precioUnitario)}
                                    </td>
                                    <td className="py-2 px-3 border text-right font-medium">
                                        {formatCurrency(serv.total)}
                                    </td>
                                </tr>
                            ))}
                            <tr className="bg-gray-100 font-bold">
                                <td colSpan="4" className="py-2 px-3 border text-right">SUBTOTAL LIVESPEED:</td>
                                <td className="py-2 px-3 border text-right">{formatCurrency(totalLivespeed)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Descuentos y ajustes */}
            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-3">Descuentos y ajustes</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="py-2 px-3 border text-left">Concepto</th>
                                <th className="py-2 px-3 border text-right">Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover:bg-gray-50">
                                <td className="py-2 px-3 border font-medium">Operaciones LIVESPEED</td>
                                <td className="py-2 px-3 border text-right text-green-600 font-medium">
                                    -{formatCurrency(totalLivespeed)}
                                </td>
                            </tr>
                            {descuentos.map((desc, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="py-2 px-3 border">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                value={desc.nombre}
                                                onChange={(e) => handleDescuentoChange(index, 'nombre', e.target.value)}
                                                className="w-full p-1 border rounded"
                                            />
                                        ) : desc.nombre}
                                    </td>
                                    <td className="py-2 px-3 border text-right text-red-600">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                value={desc.monto}
                                                onChange={(e) => handleDescuentoChange(index, 'monto', Number(e.target.value))}
                                                className="w-24 p-1 border rounded text-right"
                                                step="0.01"
                                            />
                                        ) : <span className="text-green-600">{`-${formatCurrency(desc.monto)}`}</span>}
                                    </td>
                                </tr>
                            ))}
                            <tr className="bg-gray-100 font-bold">
                                <td className="py-2 px-3 border text-right">TOTAL DE DESCUENTOS:</td>
                                <td className="py-2 px-3 border text-right text-green-600">
                                    -{formatCurrency(totalDescuentos)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Resumen final */}
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-3">Resumen final</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200">
                        <tbody>
                            <tr className="hover:bg-gray-50">
                                <td className="py-3 px-4 border font-medium">Total bruto de inscripciones</td>
                                <td className="py-3 px-4 border text-right font-medium">
                                    {formatCurrency(totalBruto)}
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-50">
                                <td className="py-3 px-4 border font-medium">Total de descuentos</td>
                                <td className="py-3 px-4 border text-right text-green-600 font-medium">
                                    -{formatCurrency(totalDescuentos)}
                                </td>
                            </tr>
                            <tr className="bg-green-50 font-bold text-lg">
                                <td className="py-4 px-4 border">TOTAL NETO</td>
                                <td className="py-4 px-4 border text-right text-green-700">
                                    {formatCurrency(totalNeto)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Pie de página */}
            <div className="text-center text-gray-500 text-sm mt-10 pt-4 border-t print:hidden">
                <p>© {new Date().getFullYear()} Livespeed Mexico. Todos los derechos reservados.</p>
            </div>
        </div>
    );
};

export default ReciboEsmeralda;