import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage, auth } from "../../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import {
  Camera,
  Send,
  Trash2,
  Loader2,
  Check,
  X,
  MessageSquare,
  Image as ImageIcon,
  ChevronDown,
  UserCircle,
} from "lucide-react";

const EventChat = ({ uid }) => {
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");
  const [userId, setUserId] = useState(null);
  const [typing, setTyping] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState(0);

  // Monitor scroll position
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = chatContainer;
      const scrollPosition = scrollHeight - scrollTop - clientHeight;
      const threshold = 100; // pixels from bottom to consider "at bottom"

      const bottom = scrollPosition <= threshold;
      setIsAtBottom(bottom);
      setShowScrollDown(!bottom);

      if (bottom) {
        setUnreadMessages(0);
      }
    };

    chatContainer.addEventListener("scroll", handleScroll);
    return () => chatContainer.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to bottom on new messages if already at bottom
  useEffect(() => {
    if (messages.length > 0 && isAtBottom) {
    } else if (messages.length > 0 && !isAtBottom) {
      const lastMsg = messages[messages.length - 1];
      // Don't count own messages as unread
      if (lastMsg.senderId !== userId) {
        setUnreadMessages((prev) => prev + 1);
      }
    }
  }, [messages, isAtBottom, userId]);

  // Initial load and message fetch
  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      const chatRef = collection(db, "events", uid, "chat");
      const q = query(chatRef, orderBy("timestamp", "asc"));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const updatedMessages = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const userInfo = await fetchUserInfo(data.senderId);
            return {
              id: doc.id,
              ...data,
              userInfo,
              imageUrl: data.imageUrl || null,
            };
          })
        );
        setMessages(updatedMessages);
        setLoading(false);
        // After initial load, scroll to bottom
        // setTimeout(() => scrollToBottom("auto"), 100);
      });

      return () => unsubscribe();
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    const listenToTypingStatus = () => {
      const typingRef = doc(db, "events", uid, "typing", "status");
      const unsubscribe = onSnapshot(typingRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsUserTyping(data.isTyping && data.userId !== userId);
        }
      });

      return () => unsubscribe();
    };

    fetchMessages();
    fetchUserId();
    listenToTypingStatus();
  }, [uid, userId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !selectedImage) return;

    let imageUrl = "";
    if (selectedImage) {
      try {
        const imageRef = ref(
          storage,
          `chat_images/${uid}/${Date.now()}_${selectedImage.name}`
        );
        const snapshot = await uploadBytes(imageRef, selectedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }

    try {
      const chatRef = collection(db, "events", uid, "chat");
      const messageData = {
        message: newMessage.trim(),
        senderId: userId,
        timestamp: serverTimestamp(),
        imageUrl: imageUrl || null,
      };

      const docRef = await addDoc(chatRef, messageData);
      await updateDoc(docRef, { sent: true });

      setNewMessage("");
      setSelectedImage(null);
      setPreviewImage(null);
      setTyping(false);

      const typingRef = doc(db, "events", uid, "typing", "status");
      await setDoc(typingRef, { isTyping: false, userId });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleTyping = async (e) => {
    setNewMessage(e.target.value);
    if (!typing) {
      setTyping(true);
      const typingRef = doc(db, "events", uid, "typing", "status");
      await setDoc(typingRef, { isTyping: true, userId });
    }
  };

  const handleStopTyping = async () => {
    setTyping(false);
    const typingRef = doc(db, "events", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleDeleteMessage = async () => {
    if (!messageToDelete) return;

    try {
      if (messageToDelete.imageUrl) {
        const imageRef = ref(storage, messageToDelete.imageUrl);
        await deleteObject(imageRef).catch((error) => {
          console.error("Error deleting image:", error);
        });
      }

      const messageRef = doc(db, "events", uid, "chat", messageToDelete.id);
      await deleteDoc(messageRef);
      setMessageToDelete(null);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const fetchUserInfo = async (userId) => {
    try {
      const userRef = doc(db, "Users", userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists()
        ? userSnap.data()
        : { name: "Unknown", photoUrl: "" };
    } catch (error) {
      console.error("Error fetching user info:", error);
      return { name: "Unknown", photoUrl: "" };
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("La imagen debe ser menor a 5MB");
        return;
      }
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleImageClick = (url, e) => {
    e.stopPropagation();
    setLargeImageUrl(url);
    setOpenImageDialog(true);
  };

  // Nueva función para manejar el zoom en la imagen
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isPanning, setIsPanning] = useState(false);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [startPanPosition, setStartPanPosition] = useState({ x: 0, y: 0 });

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.5, 4));
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.5, 1));
  };

  const handleZoomReset = () => {
    setZoomLevel(1);
    setPanPosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e) => {
    if (zoomLevel > 1) {
      setIsPanning(true);
      setStartPanPosition({
        x: e.clientX - panPosition.x,
        y: e.clientY - panPosition.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (isPanning) {
      setPanPosition({
        x: e.clientX - startPanPosition.x,
        y: e.clientY - startPanPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Reset zoom when closing the dialog
  useEffect(() => {
    if (!openImageDialog) {
      setZoomLevel(1);
      setPanPosition({ x: 0, y: 0 });
    }
  }, [openImageDialog]);

  const navigateToUserProfile = (senderId, e) => {
    e.stopPropagation();
    navigate(`/user/${senderId}`);
  };

  const openImagePicker = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="flex flex-col h-[600px] md:h-[70vh] w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header */}
      <div className="flex items-center justify-between bg-black text-white p-3 md:p-4 sticky top-0 z-10">
        <div className="flex items-center space-x-2">
          <MessageSquare className="w-5 h-5 md:w-6 md:h-6" />
          <h1 className="text-lg md:text-xl font-semibold">Chat del Evento</h1>
        </div>
        {isUserTyping && (
          <span className="text-xs md:text-sm animate-pulse flex items-center">
            <span className="w-2 h-2 bg-green-400 rounded-full mr-2 animate-ping"></span>
            Alguien está escribiendo...
          </span>
        )}
      </div>

      {/* Messages Container */}
      <div
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto p-3 md:p-4 space-y-3 bg-gray-50 scroll-smooth"
      >
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
          </div>
        ) : messages.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <MessageSquare className="w-12 h-12 mb-2 opacity-30" />
            <p className="text-center">
              No hay mensajes aún. ¡Sé el primero en escribir!
            </p>
          </div>
        ) : (
          <>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.senderId === userId ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`group max-w-[80%] md:max-w-[70%] rounded-lg shadow-sm hover:shadow-md transition-all relative
                    ${
                      message.senderId === userId
                        ? "bg-black text-white rounded-tr-none"
                        : "bg-white rounded-tl-none"
                    }
                  `}
                >
                  {/* Botón de eliminar para mensajes propios */}
                  {message.senderId === userId && (
                    <button
                      onClick={() => setMessageToDelete(message)}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 shadow-md opacity-0 group-hover:opacity-100 transition-opacity z-10 hover:bg-red-600"
                      aria-label="Eliminar mensaje"
                    >
                      <Trash2 className="w-3 h-3 md:w-4 md:h-4" />
                    </button>
                  )}
                  <div className="p-2 md:p-3">
                    <div
                      className="flex items-center space-x-2 cursor-pointer"
                      onClick={(e) =>
                        navigateToUserProfile(message.senderId, e)
                      }
                    >
                      <div className="relative">
                        <img
                          src={
                            message.userInfo?.photoUrl ||
                            "/api/placeholder/32/32"
                          }
                          alt={message.userInfo?.name}
                          className="w-6 h-6 md:w-8 md:h-8 rounded-full object-cover bg-gray-200 border border-gray-300"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/api/placeholder/32/32";
                          }}
                        />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-30 rounded-full transition-opacity">
                          <UserCircle className="w-4 h-4 text-white" />
                        </div>
                      </div>
                      <span className="font-medium text-xs md:text-sm hover:underline">
                        {message.userInfo?.name}
                      </span>
                      {message.senderId === userId && (
                        <Trash2 className="w-3 h-3 md:w-4 md:h-4 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity" />
                      )}
                    </div>

                    {message.message && (
                      <p className="mt-1 md:mt-2 text-sm break-words">
                        {message.message}
                      </p>
                    )}

                    {message.imageUrl && (
                      <div
                        className="mt-2 relative group/image"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          src={message.imageUrl}
                          alt="chat"
                          className="max-w-[150px] md:max-w-[200px] h-auto rounded-lg cursor-zoom-in transition-transform hover:scale-105"
                          onClick={(e) => handleImageClick(message.imageUrl, e)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/api/placeholder/200/200";
                          }}
                        />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-70 bg-black bg-opacity-40 rounded-lg transition-opacity">
                          <ImageIcon className="w-6 h-6 text-white" />
                        </div>
                        {/* Botón de eliminar para imágenes propias */}
                        {message.senderId === userId && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setMessageToDelete(message);
                            }}
                            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 shadow-md hover:bg-red-600 transition-colors"
                            aria-label="Eliminar imagen"
                          >
                            <Trash2 className="w-3 h-3 md:w-4 md:h-4" />
                          </button>
                        )}
                      </div>
                    )}

                    <div className="mt-1 md:mt-2 flex items-center justify-between text-xs text-gray-500">
                      <span>
                        {message.timestamp?.seconds
                          ? new Date(
                              message.timestamp?.seconds * 1000
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "Enviando..."}
                      </span>
                      {message.senderId === userId && message.sent && (
                        <Check className="w-3 h-3 md:w-4 md:h-4 text-green-500 ml-2" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      {/* Scroll to bottom button */}
      {showScrollDown && (
        <button className="absolute bottom-20 right-4 bg-black text-white rounded-full p-2 shadow-lg z-10 flex items-center justify-center">
          <ChevronDown className="w-5 h-5" />
          {unreadMessages > 0 && (
            <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {unreadMessages > 9 ? "9+" : unreadMessages}
            </span>
          )}
        </button>
      )}

      {/* Input Area */}
      <div className="border-t bg-white p-3 md:p-4 sticky bottom-0">
        {previewImage && (
          <div className="mb-2 relative inline-block">
            <img
              src={previewImage}
              alt="Preview"
              className="w-16 h-16 md:w-20 md:h-20 object-cover rounded-lg"
            />
            <button
              onClick={() => {
                setSelectedImage(null);
                setPreviewImage(null);
              }}
              className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
            >
              <X className="w-3 h-3 md:w-4 md:h-4" />
            </button>
          </div>
        )}

        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Escribe un mensaje..."
            value={newMessage}
            onChange={handleTyping}
            onBlur={handleStopTyping}
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            className="flex-1 bg-gray-100 rounded-full px-3 md:px-4 py-2 text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-black"
          />

          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />

          <button
            onClick={openImagePicker}
            className="p-2 md:p-2 bg-gray-100 text-gray-700 rounded-full hover:bg-gray-200 transition-colors"
            aria-label="Adjuntar imagen"
          >
            <Camera className="w-5 h-5" />
          </button>

          <button
            onClick={handleSendMessage}
            disabled={!newMessage.trim() && !selectedImage}
            className="bg-black text-white p-2 rounded-full hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Enviar mensaje"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Delete Message Dialog */}
      {messageToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
          <div className="bg-white rounded-lg max-w-xs md:max-w-md w-full p-4 md:p-6 m-4">
            <h3 className="text-base md:text-lg font-semibold mb-2">
              Eliminar Mensaje
            </h3>
            <p className="text-sm md:text-base text-gray-600 mb-4">
              ¿Estás seguro de que quieres eliminar este mensaje? Esta acción no
              se puede deshacer.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setMessageToDelete(null)}
                className="px-3 py-2 text-sm md:text-base text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteMessage}
                className="px-3 py-2 text-sm md:text-base bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Image Preview Dialog con Zoom */}
      {openImageDialog && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center p-4 z-50"
          onClick={(e) => {
            // Solo cerrar si se hace clic en el fondo
            if (e.target === e.currentTarget) {
              setOpenImageDialog(false);
            }
          }}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {/* Controles para cerrar y zoom */}
          <div className="absolute top-4 right-4 flex space-x-2">
            <button
              className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70"
              onClick={handleZoomOut}
              disabled={zoomLevel <= 1}
            >
              <span className="text-xl font-bold">−</span>
            </button>
            <button
              className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70"
              onClick={handleZoomReset}
            >
              <span className="text-xs">{Math.round(zoomLevel * 100)}%</span>
            </button>
            <button
              className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70"
              onClick={handleZoomIn}
            >
              <span className="text-xl font-bold">+</span>
            </button>
            <button
              className="text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70"
              onClick={() => setOpenImageDialog(false)}
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div
            className="max-w-full max-h-[90vh] overflow-hidden cursor-move"
            style={{
              transform: zoomLevel > 1 ? "translate3d(0,0,0)" : "none",
            }}
          >
            <img
              src={largeImageUrl}
              alt="Full size"
              className="max-w-full max-h-full object-contain rounded-lg transition-transform"
              style={{
                transform: `scale(${zoomLevel}) translate(${
                  panPosition.x / zoomLevel
                }px, ${panPosition.y / zoomLevel}px)`,
                transformOrigin: "center center",
                cursor: zoomLevel > 1 ? "grab" : "zoom-in",
              }}
              onMouseDown={handleMouseDown}
              onClick={(e) => {
                e.stopPropagation();
                if (zoomLevel === 1) {
                  handleZoomIn();
                } else {
                  handleZoomReset();
                }
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/api/placeholder/400/400";
              }}
            />
          </div>

          {/* Indicador de instrucción */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-4 py-2 rounded-full text-sm">
            {zoomLevel > 1
              ? "Arrastra para mover • Clic para restaurar"
              : "Clic para hacer zoom"}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventChat;
