import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

// URL del logo de Livespeed
const logoUrl =
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5";

// URL del icono de teléfono
const phoneIconUrl =
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/icons%2Fllamada-telefonica.png?alt=media&token=15f8ec4a-963e-4a9e-ab08-a5a0386863b8";

const EmailSender = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("¡Bienvenido a Livespeed!");
  const [message, setMessage] = useState(
    `Hola y bienvenido a Livespeed,

    Nos alegra que hayas decidido unirte a nuestra comunidad. Aquí en **Livespeed**, queremos ofrecerte lo mejor en ciclismo y aventuras al aire libre.

    **¿Qué puedes hacer ahora?**
    - Agrega a tus amigos y compite juntos.
    - Explora los mejores parques y rutas cerca de ti.
    
    Livespeed es la app para atletas como tú, que buscan experiencias únicas mientras disfrutan de su pasión.

    Si tienes alguna pregunta o necesitas asistencia, no dudes en **contactarnos**.

    **Equipo de Livespeed**
    ¡Nos vemos en la pista!

    **Soporte técnico**:
    `
  );
  const [status, setStatus] = useState("");
  const [htmlTemplate, setHtmlTemplate] = useState("");
  const [date, setDate] = useState("");
  const [sending, setSending] = useState(false);
  const [rawResponse, setRawResponse] = useState(null);

  // Actualiza la fecha actual cuando se carga el componente
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setDate(formattedDate);
  }, []);

  // Actualizar el HTML del template en base al mensaje del usuario
  useEffect(() => {
    const template = `
      <div style="background-color: #f4f4f4; padding: 20px;">
        <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 10px; text-align: center;">
          <a href="https://www.livespeed.mx" target="_blank">
            <img src="${logoUrl}" alt="Livespeed" style="max-width: 200px; margin-bottom: 20px;" />
          </a>
          <h1 style="color: #333;">¡Bienvenido a Livespeed!</h1>
          <p style="color: #555; line-height: 1.6; font-size: 16px;">
            Hola y bienvenido a <strong>Livespeed</strong>,<br/><br/>
            Nos alegra que hayas decidido unirte a nuestra comunidad. Aquí en <strong>Livespeed</strong>, queremos ofrecerte lo mejor en ciclismo y aventuras al aire libre.
            <br/><br/>
            <strong>¿Qué puedes hacer ahora?</strong><br/>
            <ul style="text-align: left; margin-left: 20px;">
              <li>Agrega a tus amigos y compite juntos.</li>
              <li>Explora los mejores parques y rutas cerca de ti.</li>
            </ul>
            <br/>
            <strong>Livespeed</strong> es la app para atletas como tú, que buscan experiencias únicas mientras disfrutan de su pasión.
            <br/><br/>
            Si tienes alguna pregunta o necesitas asistencia, no dudes en <strong>contactarnos</strong>.
            <br/>
            <br/>
            <strong>Soporte técnico</strong>:<br/>
            <img src="${phoneIconUrl}" alt="Teléfono" style="width: 16px; vertical-align: middle;"/> 6622995258
            <br/>
            <br/>
            ¡Nos vemos en la pista!
            <br/>
            <strong> - Livespeed Team</strong><br/>
            <br/>
          </p>
          <p style="color: #555;">Fecha: ${date}</p>
          <a href="https://www.livespeed.mx/login" target="_blank" style="display: inline-block; padding: 10px 20px; background-color: #000; color: #fff; text-decoration: none; border-radius: 5px; margin-top: 20px;">Iniciar Sesión</a>
        </div>
      </div>
    `;
    setHtmlTemplate(template);
  }, [message, date]);

  // Función para enviar el correo
  const sendEmail = async () => {
    // Verificación de campos
    if (!email || email.trim() === "") {
      setStatus("El correo electrónico del destinatario es obligatorio.");
      return;
    }

    if (!subject || subject.trim() === "") {
      setStatus("El asunto del correo es obligatorio.");
      return;
    }

    if (!htmlTemplate || htmlTemplate.trim() === "") {
      setStatus("El contenido del correo no puede estar vacío.");
      return;
    }

    try {
      setSending(true);
      setStatus("Enviando correo...");
      setRawResponse(null);

      const functions = getFunctions();
      const mailSender = httpsCallable(functions, "mailSender");

      // SOLUCIÓN CLAVE: Crear un objeto completamente nuevo, asegurándonos que no haya referencias circulares
      const emailData = {
        email: String(email).trim(),
        subject: String(subject).trim(),
        message: String(htmlTemplate).trim(),
      };

      // Llamada directa con el objeto explícito
      const result = await mailSender(emailData);

      // Guardar la respuesta cruda para debug
      setRawResponse(result);

      if (result.data && result.data.success) {
        setStatus("¡Correo enviado exitosamente!");
      } else {
        setStatus(
          `Error: ${result.data?.message || "No se pudo enviar el correo"}`
        );
      }
    } catch (error) {
      console.error("Error al enviar el correo:", error);
      setRawResponse({ error: error.message });
      setStatus(
        `Error al enviar el correo: ${error.message || "Error desconocido"}`
      );
    } finally {
      setSending(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2>Enviar Correo</h2>
      <div style={styles.inputContainer}>
        <label>
          Correo del destinatario: <span style={styles.required}>*</span>
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Ejemplo: destinatario@example.com"
          style={styles.input}
          required
        />
      </div>

      <div style={styles.inputContainer}>
        <label>
          Asunto: <span style={styles.required}>*</span>
        </label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          style={styles.input}
          required
        />
      </div>

      <div style={styles.inputContainer}>
        <label>Mensaje (se generará una plantilla HTML):</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Escribe tu mensaje aquí"
          rows="5"
          style={styles.textarea}
        />
        <p style={styles.note}>
          Nota: El mensaje se convertirá a un formato HTML bonito
          automáticamente.
        </p>
      </div>

      <button
        onClick={sendEmail}
        style={sending ? styles.buttonDisabled : styles.button}
        disabled={sending}
      >
        {sending ? "Enviando..." : "Enviar Correo"}
      </button>

      {status && (
        <p
          style={
            status.includes("Error")
              ? styles.errorStatus
              : status.includes("exitosamente")
              ? styles.successStatus
              : styles.status
          }
        >
          {status}
        </p>
      )}

      {/* Información de la respuesta (para depuración) */}
      {rawResponse && (
        <div style={styles.debugContainer}>
          <h4>Respuesta del servidor:</h4>
          <pre style={styles.debugInfo}>
            {JSON.stringify(rawResponse.data || rawResponse, null, 2)}
          </pre>
        </div>
      )}

      <h3>Previsualización del template de correo:</h3>
      <div
        dangerouslySetInnerHTML={{ __html: htmlTemplate }}
        style={styles.templatePreview}
      ></div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "center",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#f4f4f4",
  },
  inputContainer: {
    marginBottom: "15px",
    textAlign: "left",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    marginTop: "5px",
  },
  textarea: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    marginTop: "5px",
  },
  button: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#cccccc",
    color: "#666666",
    cursor: "not-allowed",
  },
  status: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#007BFF",
  },
  successStatus: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#28a745",
  },
  errorStatus: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#dc3545",
  },
  templatePreview: {
    marginTop: "20px",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    overflowX: "auto",
  },
  required: {
    color: "red",
  },
  note: {
    fontSize: "12px",
    color: "#666",
    marginTop: "5px",
    fontStyle: "italic",
  },
  debugContainer: {
    marginTop: "20px",
    padding: "10px",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "4px",
    textAlign: "left",
  },
  debugInfo: {
    fontSize: "12px",
    margin: 0,
    padding: "10px",
    backgroundColor: "#f1f1f1",
    borderRadius: "4px",
    overflow: "auto",
    maxHeight: "200px",
  },
};

export default EmailSender;
