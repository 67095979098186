import React, { useEffect, useState } from "react";
import axios from "axios";
import { Droplets, Cloud, Wind, Clock, Calendar, MapPin } from "lucide-react";
import GoogleMapSection from "./GoogleMapSection";

const WeatherStat = ({ title, value, icon, delay }) => {
  return (
    <div
      className="flex flex-col items-center gap-1 transition-transform duration-300 hover:-translate-y-1"
      style={{
        animation: `fadeIn 0.6s ease-out forwards`,
        animationDelay: `${delay}ms`,
        opacity: 0,
      }}
    >
      {React.cloneElement(icon, {
        className: "text-blue-500 mb-1 w-6 h-6 md:w-7 md:h-7",
      })}
      <p className="font-semibold text-lg md:text-xl">{value}</p>
      <p className="text-gray-500 text-xs md:text-sm">{title}</p>
    </div>
  );
};

const EventStats = ({ latitude, longitude, eventDate }) => {
  const [weatherData, setWeatherData] = useState({
    temperature: null,
    humidity: null,
    cloud: null,
    windKph: null,
    conditionText: "",
    conditionIcon: "",
    localTime: "",
  });
  const [loading, setLoading] = useState(true);
  const apiKey = "a9c224289afe436db8c182531240310";

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}&aqi=no`
        );
        const { current, location } = response.data;

        setWeatherData({
          temperature: current.temp_c,
          humidity: current.humidity,
          cloud: current.cloud,
          windKph: current.wind_kph,
          conditionText: current.condition.text,
          conditionIcon: current.condition.icon,
          localTime: location.localtime,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching weather data:", error);
        setLoading(false);
      }
    };

    fetchWeather();
  }, [latitude, longitude]);

  const getTemperatureColor = (temp) => {
    if (temp >= 38) return "text-red-500";
    if (temp <= 20) return "text-blue-500";
    return "text-gray-800";
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-14 w-14 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div
      className="p-4 md:p-6 lg:p-8 bg-white rounded-2xl shadow-lg animate-fadeIn"
      style={{ animation: "fadeIn 1s ease-out" }}
    >
      <div className="flex flex-col md:flex-row items-center justify-between mb-8">
        <div className="flex items-center gap-4 mb-4 md:mb-0">
          <img
            src={weatherData.conditionIcon}
            alt={weatherData.conditionText}
            className="w-20 h-20 md:w-24 md:h-24"
          />
          <div>
            <h1
              className={`text-5xl md:text-6xl font-bold transition-colors duration-300 ${getTemperatureColor(
                weatherData.temperature
              )}`}
            >
              {weatherData.temperature}°C
            </h1>
            <h3 className="text-xl md:text-2xl text-gray-600">
              {weatherData.conditionText}
            </h3>
          </div>
        </div>

        {/* Event Date and Time */}
        <div className="flex flex-col items-center">
          <h3 className="text-xl text-gray-800 mb-1">Fecha del evento</h3>
          <div className="flex items-center gap-2 text-blue-500">
            <Calendar className="w-5 h-5" />
            <p className="text-lg font-medium">
              {new Date(eventDate).toLocaleDateString()}
            </p>
          </div>
          <div className="flex items-center gap-2 text-gray-600 mt-1">
            <Clock className="w-4 h-4" />
            <p className="text-sm">
              {new Date(eventDate).toLocaleTimeString()}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 justify-center">
        <WeatherStat
          title="Humedad"
          value={`${weatherData.humidity}%`}
          icon={<Droplets />}
          delay={200}
        />
        <WeatherStat
          title="Nubosidad"
          value={`${weatherData.cloud}%`}
          icon={<Cloud />}
          delay={400}
        />
        <WeatherStat
          title="Viento"
          value={`${weatherData.windKph} km/h`}
          icon={<Wind />}
          delay={600}
        />
        <WeatherStat
          title="Hora local"
          value={weatherData.localTime.split(" ")[1]}
          icon={<Clock />}
          delay={800}
        />
      </div>

      {/* Map section */}
      <div className="mt-8">
        <div className="flex items-center gap-2 mb-3">
          <MapPin className="w-5 h-5 text-blue-500" />
          <h3 className="text-lg font-medium text-gray-800">
            Ubicación del evento
          </h3>
        </div>
        <GoogleMapSection location={{ lat: latitude, lng: longitude }} />
      </div>
    </div>
  );
};

// CSS para la animación de fadeIn
const injectGlobalStyles = () => {
  const style = document.createElement("style");
  style.textContent = `
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  document.head.appendChild(style);
};

// Inyectar estilos globales cuando el componente se monte por primera vez
if (typeof window !== "undefined") {
  injectGlobalStyles();
}

export default EventStats;
