import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MapPin, Rocket, Heart, User } from "lucide-react";

const MiniProduct = ({ product, handleCategoryChange, handleFavorite }) => {
  const navigate = useNavigate();

  const formatPrice = (price) => {
    const options = {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: price % 1 === 0 ? 0 : 2,
    };
    return new Intl.NumberFormat("es-MX", options).format(price);
  };

  return (
    <div className="rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 bg-white flex flex-col h-full relative">
      {/* Favorite button */}
      <button
        onClick={() => handleFavorite(product.id)}
        className="absolute top-2 left-2 bg-white/90 hover:bg-white p-2 rounded-full z-10 shadow-md"
      >
        <Heart
          className={`h-5 w-5 ${
            product.isFavorite ? "fill-red-500 text-red-500" : "text-gray-500"
          }`}
        />
      </button>

      {/* Sponsored tag */}
      {product.sponsored && (
        <div className="absolute top-2 right-2 bg-white/90 px-3 py-1 rounded-full z-10 shadow-md flex items-center">
          <Rocket className="h-3 w-3 text-blue-600 mr-1" />
          <span className="text-xs font-semibold text-blue-600">
            Patrocinado
          </span>
        </div>
      )}

      {/* Product image */}
      <div
        className="cursor-pointer h-56"
        onClick={() => navigate(`/product/${product.id}`)}
      >
        <img
          src={product.mainImageUrl}
          alt={product.name}
          className="h-full w-full object-cover"
        />
      </div>

      {/* Product content */}
      <div
        className="p-4 flex flex-col flex-grow cursor-pointer"
        onClick={() => navigate(`/product/${product.id}`)}
      >
        {/* User info */}
        {product.user && (
          <div className="flex items-center mb-3">
            <div className="h-8 w-8 rounded-full overflow-hidden mr-2 bg-gray-200 flex-shrink-0">
              {product.user.photoUrl ? (
                <img
                  src={product.user.photoUrl}
                  alt={product.user.nickname || ""}
                  className="h-full w-full object-cover"
                />
              ) : (
                <div className="h-full w-full flex items-center justify-center bg-gray-300">
                  <User className="h-4 w-4 text-gray-500" />
                </div>
              )}
            </div>
            <div>
              <p className="text-sm font-medium leading-tight">
                {product.user.name || "Unknown User"}
              </p>
              <p className="text-xs text-gray-500 italic">
                {product.user.nickname || ""}
              </p>
            </div>
          </div>
        )}

        {/* Product name */}
        <h3 className="text-lg font-bold mb-2 line-clamp-2">{product.name}</h3>

        {/* Location */}
        <div className="flex items-center text-gray-600 mb-2">
          <MapPin className="h-4 w-4 mr-1 flex-shrink-0" />
          <p className="text-sm truncate">
            {product.city}, {product.state}, {product.country}
          </p>
        </div>

        {/* Category */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleCategoryChange(product.category);
          }}
          className="inline-block bg-gray-100 hover:bg-gray-200 text-xs font-medium px-3 py-1 rounded-full mb-3 transition-colors duration-200"
        >
          {product.categoryName}
        </button>

        {/* Footer with date and price */}
        <div className="flex justify-between items-center mt-auto pt-2">
          <p className="text-xs text-gray-500">
            {moment(product.postDate).fromNow()}
          </p>
          <p className="text-lg font-bold text-blue-600">
            {formatPrice(product.price)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MiniProduct;
