import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Calendar,
  Clock,
  MapPin,
  DollarSign,
  Users,
  Share2,
  Lock,
  MessageCircle,
  ChevronLeft,
  Heart,
  ExternalLink,
} from "lucide-react";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";
import EventStats from "./EventStats";
import EventChat from "./EventChat";
import GoogleMapSection from "./GoogleMapSection";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);
  const [showSupportBubble, setShowSupportBubble] = useState(false);
  const [liked, setLiked] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  // Este useEffect se ejecuta una sola vez al montar el componente
  useEffect(() => {
    // Resetear posición de scroll solo si es necesario, sin auto-scroll
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }

    // Detector de cambio de tamaño para modo responsive
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventData = await eventsApi.getEventById(id, {
          categories: true,
          creator: true,
          participants: true,
        });

        if (user && eventData.participants) {
          const isParticipant = eventData.participants.some(
            (p) => p.user.uid === user.uid
          );
          setUserIsParticipant(isParticipant);
        }

        setEvent(eventData);

        // Una vez que los datos están cargados, habilitamos el mapa sin forzar scroll
        setTimeout(() => {
          setShowMap(true);
        }, 500);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEventData();
  }, [id, user]);

  // Mostrar la burbuja después de un pequeño delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSupportBubble(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleJoinEvent = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
    } catch (error) {
      console.error("Error leaving event:", error);
    }
  };

  const handleShare = () => {
    const eventUrl = window.location.href;
    const shareData = {
      title: event.eventName,
      text: `${event.eventName}\nFecha: ${new Date(
        event.dateTime
      ).toLocaleDateString()} a las ${new Date(
        event.dateTime
      ).toLocaleTimeString()}\nCosto: ${
        event.price > 0 ? `$${event.price}` : "Gratis"
      }`,
      url: eventUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Enlace y detalles copiados al portapapeles!");
    }
  };

  const handleSupport = () => {
    setShowSupportBubble(false);
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  const ActionButtons = ({ className = "" }) => (
    <div className={`flex gap-3 ${className}`}>
      {!userIsParticipant ? (
        <button
          onClick={userIsParticipant ? handleLeaveEvent : handleJoinEvent}
          className="flex-1 py-3 px-4 rounded-xl font-medium transition-all duration-200 transform active:scale-95 shadow-md bg-gray-900 hover:bg-gray-800 text-white"
          disabled={event.closed}
        >
          Unirse al evento
        </button>
      ) : null}
      <button
        onClick={() => setLiked(!liked)}
        className={`p-3 rounded-xl shadow-md transition-all duration-200 transform active:scale-95 ${
          liked ? "bg-red-500 text-white" : "bg-gray-100 text-gray-700"
        }`}
      >
        <Heart className={`w-6 h-6 ${liked ? "fill-current" : ""}`} />
      </button>
      <div className="relative">
        <button
          onClick={handleSupport}
          className="p-3 bg-green-500 hover:bg-green-600 text-white rounded-xl font-medium transition-all duration-200 transform active:scale-95 shadow-md relative"
        >
          <MessageCircle className="w-6 h-6" />
        </button>
        {showSupportBubble && (
          <div className="absolute bottom-full right-0 mb-2 transform -translate-y-1 animate-pulse">
            <div className="relative bg-white text-gray-800 rounded-xl px-4 py-2 shadow-lg text-sm whitespace-nowrap">
              <span>¿Necesitas soporte?</span>
              <div className="absolute bottom-0 right-4 transform translate-y-full">
                <div className="border-8 border-transparent border-t-white"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (!event) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-white">
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
          <p className="text-gray-600 font-medium">Cargando evento...</p>
        </div>
      </div>
    );
  }

  // Comprobar si el evento tiene datos de ubicación válidos
  const hasValidLocation =
    event.location &&
    typeof event.location.lat === "number" &&
    typeof event.location.lng === "number";

  return (
    <div className="min-h-screen w-full bg-gray-50 flex flex-col">
      {/* Header Sticky */}
      <header className="sticky top-0 left-0 right-0 z-50 bg-white shadow-md">
        <div className="w-full max-w-6xl mx-auto px-4 py-3 flex items-center justify-between">
          <button
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Volver"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold truncate max-w-xs lg:max-w-lg">
            {event.eventName}
          </h1>
          <div className="flex items-center gap-2">
            <button
              onClick={handleShare}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Compartir"
            >
              <Share2 className="w-6 h-6" />
            </button>
          </div>
        </div>
      </header>

      {/* Contenido principal con scroll */}
      <main className="flex-1 w-full overflow-y-auto">
        <div className="max-w-6xl mx-auto">
          <div className={`${isDesktop ? "lg:flex lg:gap-8" : ""}`}>
            {/* Columna izquierda en desktop */}
            <div className={`${isDesktop ? "lg:w-2/3" : "w-full"}`}>
              {/* Imagen del evento */}
              <div className="w-full">
                <img
                  src={event.photo}
                  alt={event.eventName}
                  className="w-full h-auto object-contain"
                />
              </div>

              {/* Estado del evento */}
              <div className="flex flex-wrap gap-2 px-4 mt-4">
                {event.price > 0 && (
                  <span className="bg-gray-900 text-white px-4 py-1 rounded-full text-lg font-semibold shadow-md">
                    ${event.price}
                  </span>
                )}
                {event.price === 0 && (
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-lg font-semibold shadow-md">
                    Gratis
                  </span>
                )}
                {event.closed && (
                  <div className="flex items-center gap-2 bg-red-600 text-white px-4 py-1 rounded-full shadow-md">
                    <Lock className="w-4 h-4" />
                    <span className="text-sm font-medium">Cerrado</span>
                  </div>
                )}
              </div>

              {/* Título y organizador */}
              <div className="px-4 mt-4">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
                  {event.eventName}
                </h1>
                <OrganizerInfo creator={event.creator} />
              </div>

              {/* Acciones principales (solo visible en móvil o al principio en desktop) */}
              {!isDesktop && (
                <div className="px-4 mt-6">
                  <ActionButtons />
                </div>
              )}

              {/* Tarjetas de Información */}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-3 px-4 mt-6">
                <InfoCard
                  icon={<Calendar className="w-5 h-5 text-gray-900" />}
                  label="Fecha"
                  value={new Date(event.dateTime).toLocaleDateString()}
                />
                <InfoCard
                  icon={<Clock className="w-5 h-5 text-gray-900" />}
                  label="Hora"
                  value={new Date(event.dateTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                />
                <InfoCard
                  icon={<Users className="w-5 h-5 text-gray-900" />}
                  label="Participantes"
                  value={`${event.participants.length} inscritos`}
                />
                <InfoCard
                  icon={<DollarSign className="w-5 h-5 text-gray-900" />}
                  label="Precio"
                  value={event.price > 0 ? `$${event.price}` : "Gratis"}
                />
              </div>

              {/* Participantes */}
              <div className="px-4 mt-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-2">
                    <Users className="w-5 h-5 text-gray-900" />
                    Participantes
                  </h2>
                  <span className="text-sm font-medium px-3 py-1 bg-gray-100 text-gray-800 rounded-full">
                    {event.participants.length} inscritos
                  </span>
                </div>
                <EventParticipants
                  participants={event.participants.map((p) => ({
                    id: p.id,
                    joinedAt: p.joinedAt,
                    category: event.categories.find(
                      (c) => c.id === p.categoryId
                    )?.name,
                    user: p.user,
                  }))}
                />
              </div>

              {/* Chat Section */}
              <div className="px-4 mt-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                  <MessageCircle className="w-5 h-5 text-gray-900" />
                  Chat del evento
                </h2>
                <EventChat uid={id} />
              </div>

              {/* Stats Section */}
              <div className="px-4 mt-6 mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
                  <ExternalLink className="w-5 h-5 text-gray-900" />
                  Estadísticas
                </h2>
                {/* Pasar latitude y longitude al componente EventStats */}
                {hasValidLocation ? (
                  <EventStats
                    latitude={event.location.lat}
                    longitude={event.location.lng}
                    eventDate={event.dateTime}
                  />
                ) : (
                  <div className="text-center p-4 bg-gray-100 rounded-xl">
                    <p className="text-gray-500">
                      No hay datos de ubicación disponibles
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Columna derecha en desktop - Sidebar fijo */}
            {isDesktop && (
              <div className="hidden lg:block lg:w-1/3 mt-4">
                <div className="sticky top-20 bg-white rounded-xl shadow-md p-6">
                  <h2 className="text-xl font-bold mb-4">Únete al evento</h2>
                  <div className="mb-6">
                    <p className="text-gray-600 mb-2">
                      Fecha: {new Date(event.dateTime).toLocaleDateString()}
                    </p>
                    <p className="text-gray-600 mb-2">
                      Hora:{" "}
                      {new Date(event.dateTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p>
                    <p className="text-gray-600 mb-2">
                      Precio: {event.price > 0 ? `$${event.price}` : "Gratis"}
                    </p>
                    <p className="text-gray-600">
                      Participantes: {event.participants.length} inscritos
                    </p>
                  </div>
                  <ActionButtons className="flex-col" />

                  {/* Solo mostrar el mapa si hay datos de ubicación */}
                  {showMap && hasValidLocation && (
                    <div className="mt-6">
                      <h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
                        <MapPin className="w-5 h-5" />
                        Ubicación
                      </h3>
                      <div className="h-40 rounded-xl overflow-hidden">
                        {/* Pasar location como un objeto al GoogleMapSection */}
                        <GoogleMapSection
                          location={{
                            lat: event.location.lat,
                            lng: event.location.lng,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      {/* Register Dialog */}
      {showRegisterWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
          <div className="bg-white rounded-2xl max-w-md w-full p-6 m-4 animate-fadeIn">
            <h3 className="text-xl font-semibold text-center text-gray-900 mb-4">
              Bienvenido
            </h3>
            <p className="text-gray-600 text-center mb-6">
              Regístrate o inicia sesión para unirte o pagar por el evento.
            </p>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => navigate("/login")}
                className="w-full py-3 px-4 bg-gray-900 text-white rounded-xl font-medium hover:bg-gray-800 transition-colors shadow-md"
              >
                Iniciar sesión
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="w-full py-3 px-4 border border-gray-300 text-gray-700 rounded-xl font-medium hover:bg-gray-50 transition-colors shadow-sm"
              >
                Crear cuenta
              </button>
              <button
                onClick={() => setShowRegisterWarning(false)}
                className="w-full py-3 px-4 text-gray-500 hover:bg-gray-100 transition-colors rounded-xl font-medium"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizerInfo = ({ creator }) => (
  <div className="flex items-center space-x-4">
    <div className="relative w-12 h-12 rounded-full overflow-hidden border-2 border-gray-900">
      <img
        src={creator?.photoUrl || "https://via.placeholder.com/100"}
        alt={creator?.name}
        className="object-cover w-full h-full"
      />
    </div>
    <div>
      <p className="text-sm text-gray-500">Organizado por</p>
      <p className="font-semibold text-gray-900">
        {creator?.name || "Organizador"}
      </p>
    </div>
  </div>
);

const InfoCard = ({ icon, label, value, className = "" }) => (
  <div
    className={`flex flex-col items-center justify-center p-4 bg-gray-50 rounded-xl hover:shadow-md transition-shadow ${className}`}
  >
    <div className="mb-2">{icon}</div>
    <p className="text-sm text-gray-500 text-center">{label}</p>
    <p className="font-medium text-gray-900 text-center">{value}</p>
  </div>
);

export default EventDetail;
