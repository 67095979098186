import React, { useState, useEffect } from "react";
import CategoryList from "./CategoryList";
import ProductList from "./ProductList";
import Cart from "./Cart";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

const Shop = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "all",
    name: "All",
  });
  const [selectedSubcategory, setSelectedSubcategory] = useState({
    id: "all",
    name: "All",
  });
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    fetchCategoriesAndProducts();
  }, []);

  const fetchCategoriesAndProducts = async () => {
    try {
      const categorySnapshot = await getDocs(collection(db, "ShopCategories"));
      const categoriesData = categorySnapshot.docs.map((categoryDoc) => ({
        id: categoryDoc.id,
        ...categoryDoc.data(),
      }));
      setCategories([{ id: "all", name: "All" }, ...categoriesData]);

      const productsSnapshot = await getDocs(collection(db, "ShopItems"));
      const productsData = productsSnapshot.docs.map((productDoc) => ({
        id: productDoc.id,
        ...productDoc.data(),
      }));
      setProducts(productsData);
    } catch (error) {
      console.error("Error fetching categories and products:", error);
    }
  };

  const handleCategorySelect = async (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory({ id: "all", name: "All" });

    if (category.id !== "all") {
      try {
        const subcategorySnapshot = await getDocs(
          collection(db, "ShopCategories", category.id, "subcategory")
        );
        const subcategoriesData = subcategorySnapshot.docs.map(
          (subcategoryDoc) => ({
            id: subcategoryDoc.id,
            ...subcategoryDoc.data(),
          })
        );
        setSubcategories([{ id: "all", name: "All" }, ...subcategoriesData]);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    } else {
      setSubcategories([]);
    }
  };

  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleAddToCart = (product) => {
    setCartItems((prevItems) => {
      const itemExists = prevItems.find((item) => item.id === product.id);

      if (itemExists) {
        return prevItems.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
  };

  const handleRemoveFromCart = (productId, removeAll = false) => {
    setCartItems((prevItems) => {
      const itemExists = prevItems.find((item) => item.id === productId);

      if (itemExists && itemExists.quantity > 1 && !removeAll) {
        return prevItems.map((item) =>
          item.id === productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      } else {
        return prevItems.filter((item) => item.id !== productId);
      }
    });
  };

  const handleClearCart = () => {
    setCartItems([]);
  };

  const filteredProducts = products.filter((product) => {
    const matchesCategory =
      selectedCategory.id === "all" ||
      product.category === selectedCategory.name;
    const matchesSubcategory =
      selectedSubcategory.id === "all" ||
      product.subcategory === selectedSubcategory.name;

    return matchesCategory && matchesSubcategory;
  });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Banner de la tienda de bicicletas */}
      <div className="bg-gradient-to-r from-black to-gray-800 text-white py-12 px-4">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-3">BikePro Shop</h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Todo lo que necesitas para tu bicicleta. Descubre nuestras partes,
            repuestos y accesorios premium.
          </p>
          <div className="flex justify-center mt-6 space-x-4">
            <button className="bg-white text-black px-6 py-2 rounded-full font-medium hover:bg-gray-100 transition">
              Nuevos productos
            </button>
            <button className="bg-transparent border border-white text-white px-6 py-2 rounded-full font-medium hover:bg-white hover:text-black transition">
              Ofertas especiales
            </button>
          </div>
        </div>
      </div>

      {/* Contenido principal */}
      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <CategoryList
            categories={categories}
            subcategories={subcategories}
            onSelectCategory={handleCategorySelect}
            onSelectSubcategory={handleSubcategorySelect}
            selectedCategory={selectedCategory}
            selectedSubcategory={selectedSubcategory}
          />
          <ProductList
            products={filteredProducts}
            onAddToCart={handleAddToCart}
          />
        </div>
      </div>

      {/* Carrito flotante */}
      <Cart
        cartItems={cartItems}
        onAddToCart={handleAddToCart}
        onRemoveFromCart={handleRemoveFromCart}
        onClearCart={handleClearCart}
      />
    </div>
  );
};

export default Shop;
