import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  Heart,
  MessageSquare,
  Users,
  Share2,
  Bookmark,
  Clock,
  Trophy,
  MapPin,
  ChevronRight,
  Calendar,
  DollarSign,
  Layers,
  Info,
  Eye,
  Bookmark as BookmarkIcon,
  ChevronDown,
  ArrowRight,
} from "lucide-react";

const UpcomingEventsBanner = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [userId, setUserId] = useState(null);
  const [expandedEvent, setExpandedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [savedEvents, setSavedEvents] = useState([]);

  const formatLocation = (location) => {
    if (!location) return "";
    if (typeof location === "string") return location;
    if (location.lat && location.lng) {
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    }
    return "";
  };

  const getTimeRemaining = (eventDate) => {
    const total = eventDate - new Date();
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((total / (1000 * 60)) % 60);
    return { total, days, hours, minutes };
  };

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleDateString("es-ES", options);
  };

  const formatTimeRemaining = (timeRemaining) => {
    if (timeRemaining.days > 0) {
      return `${timeRemaining.days}d ${timeRemaining.hours}h`;
    } else if (timeRemaining.hours > 0) {
      return `${timeRemaining.hours}h ${timeRemaining.minutes}m`;
    } else {
      return `${timeRemaining.minutes}m`;
    }
  };

  useEffect(() => {
    const fetchEventsWithOrganizers = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);

        const eventsData = await Promise.all(
          eventsSnapshot.docs.map(async (eventDoc) => {
            const eventData = eventDoc.data();
            const organizerId = eventData.creatorUID;

            let organizerData = {
              name: "Organizador Desconocido",
              photoUrl: null,
            };

            if (organizerId) {
              try {
                const organizerDocRef = doc(db, "Users", organizerId);
                const organizerDoc = await getDoc(organizerDocRef);
                if (organizerDoc.exists()) {
                  organizerData = {
                    id: organizerId,
                    name: organizerDoc.data().name,
                    photoUrl: organizerDoc.data().photoUrl,
                  };
                }
              } catch (error) {
                console.error("Error fetching organizer data:", error);
              }
            }

            const participantsCollection = collection(
              eventDoc.ref,
              "participants"
            );
            const participantsSnapshot = await getDocs(participantsCollection);
            const participantsCount = participantsSnapshot.size;

            const chatCollection = collection(eventDoc.ref, "chat");
            const chatSnapshot = await getDocs(chatCollection);
            const commentsCount = chatSnapshot.size;

            return {
              id: eventDoc.id,
              ...eventData,
              dateTime: eventData.dateTime
                ? new Date(eventData.dateTime)
                : null,
              organizer: {
                id: organizerData.id,
                name: organizerData.name,
                photoUrl: organizerData.photoUrl,
              },
              participants: participantsCount,
              comments: commentsCount,
              likes: eventData.likes || [],
              location: eventData.location || null,
            };
          })
        );

        const upcomingEvents = eventsData
          .filter((event) => event.dateTime && event.dateTime > new Date())
          .sort((a, b) => a.dateTime - b.dateTime);

        setEvents(upcomingEvents);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events with organizers:", error);
        setLoading(false);
      }
    };

    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);

        // Fetch saved events
        try {
          const userDocRef = doc(db, "Users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().savedEvents) {
            setSavedEvents(userDoc.data().savedEvents);
          }
        } catch (error) {
          console.error("Error fetching saved events:", error);
        }
      }
    };

    fetchEventsWithOrganizers();
    fetchUserData();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleLike = async (eventId, likes) => {
    if (!userId) return;

    const eventRef = doc(db, "events", eventId);
    try {
      if (likes.includes(userId)) {
        await updateDoc(eventRef, {
          likes: arrayRemove(userId),
        });
      } else {
        await updateDoc(eventRef, {
          likes: arrayUnion(userId),
        });
      }
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId
            ? {
                ...event,
                likes: likes.includes(userId)
                  ? likes.filter((id) => id !== userId)
                  : [...likes, userId],
              }
            : event
        )
      );
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleSaveEvent = async (eventId) => {
    if (!userId) return;

    const userRef = doc(db, "Users", userId);
    try {
      if (savedEvents.includes(eventId)) {
        await updateDoc(userRef, {
          savedEvents: arrayRemove(eventId),
        });
        setSavedEvents((prev) => prev.filter((id) => id !== eventId));
      } else {
        await updateDoc(userRef, {
          savedEvents: arrayUnion(eventId),
        });
        setSavedEvents((prev) => [...prev, eventId]);
      }
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const handleOrganizerClick = (organizerId, e) => {
    e.stopPropagation();
    if (organizerId) {
      navigate(`/user/${organizerId}`);
    }
  };

  const handleShare = (event) => {
    const eventUrl = `${window.location.origin}/event/${event.id}`;

    if (navigator.share) {
      navigator.share({
        title: event.eventName,
        text: `¡Mira este evento: ${event.eventName}!`,
        url: eventUrl,
      });
    } else {
      navigator.clipboard.writeText(eventUrl);
      alert("Enlace copiado al portapapeles");
    }
  };

  if (loading) {
    return (
      <div className="max-w-3xl mx-auto min-h-screen flex items-center justify-center p-4">
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
          <p className="text-neutral-700">Cargando eventos próximos...</p>
        </div>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="max-w-3xl mx-auto min-h-screen flex items-center justify-center p-4">
        <div className="bg-white rounded-2xl shadow-lg p-8 text-center max-w-md">
          <Calendar className="w-14 h-14 mx-auto mb-4 text-neutral-700" />
          <h3 className="text-xl font-bold text-neutral-900 mb-2">
            No hay eventos próximos
          </h3>
          <p className="text-neutral-600 mb-6">
            Vuelve más tarde para descubrir nuevos eventos
          </p>
          <button
            onClick={() => navigate("/create-event")}
            className="bg-black text-white rounded-xl py-3 px-6 font-medium inline-flex items-center gap-2"
          >
            <span>Crear un evento</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto bg-neutral-50 min-h-screen p-4 md:p-6">
      <div className="flex flex-col gap-8">
        {events.map((event) => {
          const timeRemaining = getTimeRemaining(event.dateTime);
          const locationText = formatLocation(event.location);
          const isExpanded = expandedEvent === event.id;
          const isEventSaved = savedEvents.includes(event.id);

          return (
            <article
              key={event.id}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl"
            >
              {/* Event Type Banner */}
              <div className="bg-black text-white px-4 sm:px-6 py-3 flex items-center justify-between">
                <div className="flex items-center gap-2 sm:gap-3">
                  <Trophy className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="font-medium tracking-wide text-sm sm:text-base">
                    Evento
                  </span>
                </div>
                {timeRemaining.total > 0 && (
                  <div className="flex items-center gap-2 bg-white/10 px-2 py-1 rounded-full">
                    <Clock className="w-3 h-3 sm:w-4 sm:h-4" />
                    <span className="text-xs sm:text-sm font-medium">
                      {formatTimeRemaining(timeRemaining)}
                    </span>
                  </div>
                )}
              </div>

              {/* Header */}
              <div className="flex items-center justify-between px-4 sm:px-6 py-4">
                <div
                  className="flex items-center gap-3 sm:gap-4 cursor-pointer"
                  onClick={(e) => handleOrganizerClick(event.organizer.id, e)}
                >
                  <div className="w-10 h-10 sm:w-14 sm:h-14 rounded-full bg-gradient-to-tr from-neutral-900 to-neutral-700 p-0.5 hover:from-neutral-700 hover:to-neutral-500 transition-all">
                    {event.organizer.photoUrl ? (
                      <img
                        src={event.organizer.photoUrl}
                        alt={event.organizer.name}
                        className="w-full h-full object-cover rounded-full border-2 border-white"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/api/placeholder/56/56";
                        }}
                      />
                    ) : (
                      <div className="w-full h-full rounded-full bg-neutral-800 flex items-center justify-center text-white font-medium">
                        {event.organizer.name.substring(0, 2).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="font-semibold text-sm sm:text-lg text-neutral-900 hover:underline">
                      {event.organizer.name}
                    </p>
                    <p className="text-xs sm:text-sm text-neutral-500">
                      Organizador
                    </p>
                  </div>
                </div>
                <button
                  className="text-neutral-700 hover:text-neutral-900 transition-colors p-2 rounded-full hover:bg-neutral-100"
                  onClick={() => handleShare(event)}
                  aria-label="Compartir evento"
                >
                  <Share2 className="w-5 h-5 sm:w-6 sm:h-6" />
                </button>
              </div>

              {/* Image - Mejorada para mostrar imagen completa */}
              <div
                className="relative cursor-pointer overflow-hidden bg-neutral-100"
                onClick={() => handleEventClick(event.id)}
              >
                <img
                  src={event.photo}
                  alt={event.eventName}
                  className="w-full h-auto object-contain max-h-96"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/api/placeholder/600/400";
                  }}
                />
                {event.price > 0 && (
                  <div className="absolute top-4 right-4 bg-black/80 text-white px-3 py-1 rounded-full flex items-center gap-1">
                    <DollarSign className="w-4 h-4" />
                    <span className="font-medium">{event.price}</span>
                  </div>
                )}
                {event.featured && (
                  <div className="absolute top-4 left-4 bg-yellow-500 text-white px-3 py-1 rounded-full flex items-center gap-1">
                    <Trophy className="w-4 h-4" />
                    <span className="font-medium text-sm">Destacado</span>
                  </div>
                )}
              </div>

              {/* Date and Location */}
              <div className="px-4 sm:px-6 py-4 space-y-3">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2 sm:gap-3">
                    <Clock className="w-4 h-4 sm:w-5 sm:h-5 text-neutral-700" />
                    <span className="text-xs sm:text-sm font-medium text-neutral-800">
                      {formatDate(event.dateTime)}
                    </span>
                  </div>
                  {locationText && (
                    <div className="flex items-center gap-2 sm:gap-3">
                      <MapPin className="w-4 h-4 sm:w-5 sm:h-5 text-neutral-700" />
                      <span className="text-xs sm:text-sm text-neutral-600 truncate">
                        {locationText}
                      </span>
                    </div>
                  )}
                </div>

                {/* Event Title and Description */}
                <div className="space-y-2">
                  <h3 className="text-lg sm:text-xl font-bold text-neutral-900">
                    {event.eventName}
                  </h3>
                  {event.description && (
                    <>
                      <p
                        className={`text-neutral-600 text-xs sm:text-sm ${
                          isExpanded ? "" : "line-clamp-2"
                        }`}
                      >
                        {event.description}
                      </p>
                      {event.description.length > 150 && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedEvent(isExpanded ? null : event.id);
                          }}
                          className="text-neutral-700 text-xs font-medium flex items-center gap-1 hover:underline"
                        >
                          {isExpanded ? "Mostrar menos" : "Mostrar más"}
                          <ChevronDown
                            className={`w-4 h-4 transition-transform ${
                              isExpanded ? "rotate-180" : ""
                            }`}
                          />
                        </button>
                      )}
                    </>
                  )}
                </div>

                {/* Stats and Actions */}
                <div className="flex items-center justify-between pt-2">
                  <div className="flex items-center gap-3 sm:gap-6">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(event.id, event.likes);
                      }}
                      className="flex items-center gap-1 sm:gap-2 group"
                      aria-label={`Me gusta (${event.likes.length})`}
                    >
                      <Heart
                        className={`w-5 h-5 sm:w-6 sm:h-6 transition-colors ${
                          event.likes.includes(userId)
                            ? "fill-red-500 text-red-500"
                            : "text-neutral-700 group-hover:text-neutral-900"
                        }`}
                      />
                      <span className="text-xs sm:text-sm font-medium text-neutral-700">
                        {event.likes.length}
                      </span>
                    </button>
                    <button
                      className="flex items-center gap-1 sm:gap-2 group"
                      onClick={() => handleEventClick(event.id)}
                      aria-label={`Comentarios (${event.comments})`}
                    >
                      <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6 text-neutral-700 group-hover:text-neutral-900" />
                      <span className="text-xs sm:text-sm font-medium text-neutral-700">
                        {event.comments}
                      </span>
                    </button>
                    <button
                      className="flex items-center gap-1 sm:gap-2 group"
                      onClick={() => handleEventClick(event.id)}
                      aria-label={`Participantes (${event.participants})`}
                    >
                      <Users className="w-5 h-5 sm:w-6 sm:h-6 text-neutral-700 group-hover:text-neutral-900" />
                      <span className="text-xs sm:text-sm font-medium text-neutral-700">
                        {event.participants}
                      </span>
                    </button>
                  </div>
                  <button
                    className="text-neutral-700 hover:text-neutral-900 transition-colors p-1 rounded-full hover:bg-neutral-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSaveEvent(event.id);
                    }}
                    aria-label={isEventSaved ? "Guardado" : "Guardar evento"}
                  >
                    <BookmarkIcon
                      className={`w-5 h-5 sm:w-6 sm:h-6 ${
                        isEventSaved ? "fill-black text-black" : ""
                      }`}
                    />
                  </button>
                </div>
              </div>

              {/* CTA Button */}
              <div className="px-4 sm:px-6 pb-6">
                <button
                  onClick={() => handleEventClick(event.id)}
                  className="w-full bg-black text-white rounded-xl py-3 sm:py-4 px-6 text-sm font-semibold flex items-center justify-center gap-2 transition-all hover:bg-neutral-800 focus:ring-2 focus:ring-neutral-900 focus:ring-offset-2"
                >
                  <span>Participar en el evento</span>
                  <ChevronRight className="w-4 h-4" />
                </button>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingEventsBanner;
