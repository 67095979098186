import React from "react";
import { Link } from "react-router-dom";
import { MessageSquare } from "lucide-react";

const GetInTouch = () => {
  return (
    <div className="w-full py-12 px-4">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md border border-slate-200 overflow-hidden">
        <div className="p-6 md:p-8 flex flex-col items-center text-center">
          <h2 className="text-2xl md:text-3xl font-bold text-slate-800 mb-4">
            Contáctanos para vivir una nueva experiencia
          </h2>

          <p className="text-slate-600 max-w-xl mx-auto mb-8">
            Nos comprometemos a ofrecerte una experiencia profesional y
            satisfactoria para mejorar tu rendimiento y disfrutar de tu pasión.
            Si deseas obtener más información sobre nuestros productos y
            servicios, haz clic en el botón de abajo.
          </p>

          <Link
            to="/contact"
            className="inline-flex items-center gap-2 bg-slate-800 hover:bg-slate-700 text-white px-6 py-3 rounded-lg font-medium transition-colors"
          >
            <MessageSquare size={18} />
            Enviar Mensaje
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
