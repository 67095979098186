import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Search,
  Calendar,
  User,
  DollarSign,
  Coffee,
  MapPin,
  Clock,
  Trophy,
  Info,
  Loader2,
  X,
  ArrowRight,
  Eye,
  ChevronRight,
} from "lucide-react";

const EventHome = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const filterParam = query.get("filter");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("upcoming");
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));
        const sortedEvents = eventsData.sort((a, b) => b.dateTime - a.dateTime);
        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    if (filterParam) {
      setFilter(filterParam);
    }
    fetchEvents();
  }, [filterParam]);

  const handleEventClick = (eventId) => navigate(`/event/${eventId}`);
  const handleResultsClick = (eventId) => navigate(`/leaderboard/${eventId}`);

  const isEventPast = (eventDate) => eventDate < new Date();
  const isEventToday = (eventDate) =>
    eventDate.toDateString() === new Date().toDateString();

  const formatDate = (date) =>
    new Intl.DateTimeFormat("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const getCountdown = (eventDate) => {
    const diff = eventDate - new Date();
    if (diff <= 0) return null;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return { days, hours, minutes };
  };

  const getLocationString = (location) => {
    if (!location) return null;
    if (typeof location === "string") return location;
    if (location.address) return location.address;
    if (location.lat && location.lng)
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    return null;
  };

  const filteredEvents = events
    .filter((event) => !event.invisible)
    .filter((event) =>
      filter === "upcoming"
        ? !isEventPast(event.dateTime) && !isEventToday(event.dateTime)
        : filter === "ongoing"
        ? isEventToday(event.dateTime)
        : isEventPast(event.dateTime)
    )
    .filter((event) => event.eventName.toLowerCase().includes(searchTerm))
    .sort((a, b) =>
      filter === "upcoming" ? a.dateTime - b.dateTime : b.dateTime - a.dateTime
    );

  // Modal for full-size image preview
  const EventPreviewModal = ({ event, onClose }) => {
    if (!event) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm transition-opacity duration-300">
        <div className="relative w-full max-w-6xl max-h-[90vh] overflow-hidden bg-black rounded-2xl shadow-2xl">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 z-10 p-2 bg-black/50 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <X size={24} />
          </button>

          {/* Full-size image */}
          <div className="w-full h-[90vh]">
            <img
              src={event.photo}
              alt={event.eventName}
              className="w-full h-full object-contain"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>

          {/* Event info overlay at the bottom */}
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/90 to-transparent p-6">
            <h2 className="text-2xl font-bold text-white mb-2">
              {event.eventName}
            </h2>
            <div className="flex items-center gap-3 text-white/90">
              <Calendar className="w-4 h-4" />
              <span>{formatDate(event.dateTime)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-white to-gray-100">
      <div className="container mx-auto px-4 py-6">
        {/* Simple Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Descubre Eventos</h1>
        </div>

        {/* Compact Search and Filter Section */}
        <div className="max-w-3xl mx-auto mb-8">
          <div className="flex flex-col gap-4">
            {/* Simplified Search Bar */}
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <Search className="text-gray-400" size={18} />
              </div>
              {searchTerm && (
                <button
                  onClick={() => setSearchTerm("")}
                  className="absolute inset-y-0 right-3 flex items-center"
                >
                  <X className="text-gray-400 hover:text-gray-600" size={18} />
                </button>
              )}
              <input
                type="text"
                placeholder="Buscar eventos..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full pl-10 pr-10 py-3 rounded-lg border border-gray-200 focus:ring-1 focus:ring-gray-800 focus:border-transparent outline-none shadow-sm text-gray-900"
              />
            </div>

            {/* Modern Filter Pills - Better Mobile Layout */}
            <div className="grid grid-cols-3 gap-2">
              <button
                onClick={() => handleFilterChange("upcoming")}
                className={`px-2 py-3 rounded-xl flex flex-col items-center justify-center transition-all duration-200 ${
                  filter === "upcoming"
                    ? "bg-black text-white shadow-md transform scale-105"
                    : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-50"
                }`}
              >
                <Calendar size={18} className="mb-1" />
                <span className="text-xs font-medium">Próximos</span>
              </button>
              <button
                onClick={() => handleFilterChange("ongoing")}
                className={`px-2 py-3 rounded-xl flex flex-col items-center justify-center transition-all duration-200 ${
                  filter === "ongoing"
                    ? "bg-black text-white shadow-md transform scale-105"
                    : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-50"
                }`}
              >
                <Clock size={18} className="mb-1" />
                <span className="text-xs font-medium">En Curso</span>
              </button>
              <button
                onClick={() => handleFilterChange("past")}
                className={`px-2 py-3 rounded-xl flex flex-col items-center justify-center transition-all duration-200 ${
                  filter === "past"
                    ? "bg-black text-white shadow-md transform scale-105"
                    : "bg-white border border-gray-200 text-gray-700 hover:bg-gray-50"
                }`}
              >
                <Trophy size={18} className="mb-1" />
                <span className="text-xs font-medium">Finalizados</span>
              </button>
            </div>
          </div>
        </div>

        {/* Events Grid */}
        {loading ? (
          <div className="flex items-center justify-center min-h-[400px]">
            <div className="text-center space-y-4">
              <Loader2 className="w-12 h-12 animate-spin text-gray-800 mx-auto" />
              <p className="text-gray-600">Cargando eventos...</p>
            </div>
          </div>
        ) : filteredEvents.length === 0 ? (
          <div className="text-center py-16">
            <div className="bg-white rounded-3xl p-12 max-w-md mx-auto shadow-xl border border-gray-100">
              <Info className="w-16 h-16 mx-auto mb-6 text-gray-800" />
              <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                No se encontraron eventos
              </h3>
              <p className="text-gray-600 mb-6">
                Intenta ajustar los filtros o realizar una nueva búsqueda
              </p>
              <button
                onClick={() => setSearchTerm("")}
                className="px-6 py-3 bg-gradient-to-r from-gray-700 to-black text-white rounded-xl font-medium hover:shadow-lg transition-all duration-300"
              >
                Mostrar todos los eventos
              </button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredEvents.map((event) => {
              const isPast = isEventPast(event.dateTime);
              const isToday = isEventToday(event.dateTime);
              const countdown = getCountdown(event.dateTime);
              const locationString = getLocationString(event.location);

              return (
                <div
                  key={event.id}
                  className="group bg-white rounded-3xl shadow-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 border border-gray-100"
                >
                  {/* Image Container with full-size capability */}
                  <div className="relative aspect-[9/16] overflow-hidden">
                    <img
                      src={event.photo}
                      alt={event.eventName}
                      className="w-full h-full object-cover transform transition-transform duration-700 group-hover:scale-110"
                      onClick={() => setSelectedEvent(event)}
                    />

                    {/* Overlay gradient */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-70 group-hover:opacity-60 transition-opacity" />

                    {/* View full image button */}
                    <button
                      onClick={() => setSelectedEvent(event)}
                      className="absolute top-4 left-4 bg-black/50 backdrop-blur-sm text-white px-3 py-2 rounded-full flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 hover:bg-black/70"
                    >
                      <Eye size={16} />
                      <span className="text-sm font-medium">Ver imagen</span>
                    </button>

                    {/* Event Status Badge */}
                    <div className="absolute top-4 right-4">
                      {isPast ? (
                        <span className="px-4 py-2 rounded-full bg-gray-900/80 backdrop-blur-sm text-white text-sm font-medium shadow-lg">
                          Finalizado
                        </span>
                      ) : isToday ? (
                        <span className="px-4 py-2 rounded-full bg-green-500/80 backdrop-blur-sm text-white text-sm font-medium shadow-lg animate-pulse">
                          En curso
                        </span>
                      ) : countdown ? (
                        <div className="px-4 py-2 rounded-full bg-black/80 backdrop-blur-sm text-white text-sm font-medium shadow-lg">
                          <div className="flex items-center gap-1">
                            <Clock size={14} />
                            {`${countdown.days}d ${countdown.hours}h ${countdown.minutes}m`}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {/* Title overlay on image for better visibility */}
                    <div className="absolute bottom-0 left-0 right-0 p-6">
                      <h3 className="text-2xl font-bold text-white line-clamp-2 text-shadow">
                        {event.eventName}
                      </h3>
                    </div>
                  </div>

                  {/* Content with glass-like effect */}
                  <div className="p-6 space-y-5">
                    <div className="space-y-3 text-gray-600">
                      <div className="flex items-center gap-3">
                        <Calendar className="w-5 h-5 flex-shrink-0 text-gray-800" />
                        <span className="text-sm font-medium">
                          {formatDate(event.dateTime)}
                        </span>
                      </div>
                      <div className="flex items-center gap-3">
                        <User className="w-5 h-5 flex-shrink-0 text-indigo-600" />
                        <span className="text-sm font-medium">
                          {event.organization}
                        </span>
                      </div>
                      {locationString && (
                        <div className="flex items-center gap-3">
                          <MapPin className="w-5 h-5 flex-shrink-0 text-indigo-600" />
                          <span
                            className="text-sm font-medium truncate"
                            title={locationString}
                          >
                            {locationString}
                          </span>
                        </div>
                      )}
                    </div>

                    {/* Price Tag with better styling */}
                    <div className="flex items-center gap-2">
                      <span
                        className={`px-4 py-2 rounded-full text-sm font-medium ${
                          event.price > 0
                            ? "bg-gray-100 text-gray-900"
                            : "bg-green-100 text-green-700"
                        }`}
                      >
                        {event.price > 0 ? (
                          <span className="flex items-center">
                            <DollarSign className="w-4 h-4 mr-1" />
                            {event.price}
                          </span>
                        ) : (
                          <span className="flex items-center">
                            <Coffee className="w-4 h-4 mr-1" />
                            Gratuito
                          </span>
                        )}
                      </span>
                    </div>

                    {/* Action Buttons optimized for mobile */}
                    <div className="grid grid-cols-2 gap-2 pt-2">
                      {(isPast || isToday) && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleResultsClick(event.id);
                          }}
                          className="w-full px-3 py-3 rounded-xl bg-gray-100 text-gray-900 font-medium hover:bg-gray-200 transition-all duration-300 text-sm flex items-center justify-center gap-1"
                        >
                          <Trophy className="w-4 h-4" />
                          <span>Resultados</span>
                        </button>
                      )}
                      <button
                        onClick={() => handleEventClick(event.id)}
                        className={`w-full px-3 py-3 rounded-xl bg-black text-white font-medium hover:shadow-md transition-all duration-300 text-sm flex items-center justify-center gap-1 ${
                          !(isPast || isToday) ? "col-span-2" : ""
                        }`}
                      >
                        {isPast ? (
                          <>
                            <Info className="w-4 h-4" />
                            <span>Detalles</span>
                          </>
                        ) : (
                          <>
                            <Calendar className="w-4 h-4" />
                            <span>Inscribirme</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Full-size image modal */}
        {selectedEvent && (
          <EventPreviewModal
            event={selectedEvent}
            onClose={() => setSelectedEvent(null)}
          />
        )}
      </div>
    </div>
  );
};

export default EventHome;
