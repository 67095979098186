import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Edit, Trash } from "lucide-react";

const NumberCaptureInterface = () => {
  const [entries, setEntries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Load entries from localStorage on initial render
  useEffect(() => {
    const storedEntries = localStorage.getItem("numberEntries");
    if (storedEntries) {
      setEntries(JSON.parse(storedEntries));
    }
  }, []);

  // Save entries to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("numberEntries", JSON.stringify(entries));
  }, [entries]);

  // Validation schema
  const validationSchema = Yup.object({
    number: Yup.number()
      .required("Number is required")
      .typeError("Must be a valid number"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const now = new Date();
    const timestamp = now.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    if (editingIndex !== null) {
      // Edit existing entry
      const updatedEntries = [...entries];
      updatedEntries[editingIndex] = {
        ...updatedEntries[editingIndex],
        value: Number(values.number),
      };
      setEntries(updatedEntries);
      setEditingIndex(null);
    } else {
      // Add new entry
      const newEntry = {
        value: Number(values.number),
        timestamp,
      };
      setEntries([newEntry, ...entries]);
    }

    resetForm();
  };

  const handleEdit = (index, setFieldValue) => {
    setFieldValue("number", entries[index].value);
    setEditingIndex(index);
  };

  const handleCancelEdit = (resetForm) => {
    setEditingIndex(null);
    resetForm();
  };

  const handleDelete = (index) => {
    // If deleting the entry we're currently editing, exit edit mode
    if (index === editingIndex) {
      setEditingIndex(null);
    } else if (index < editingIndex) {
      // If deleting an entry before the one we're editing, adjust the editing index
      setEditingIndex(editingIndex - 1);
    }

    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
  };

  // Filter entries based on search query
  const filteredEntries = entries.filter((entry) =>
    entry.value.toString().includes(searchQuery)
  );

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
      <h2 className="text-xl font-bold mb-4">
        {editingIndex !== null ? "Edit Number" : "Number Capture"}
      </h2>

      <Formik
        initialValues={{ number: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, resetForm }) => (
          <Form>
            <div className="mb-4">
              <label
                htmlFor="number"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Enter a number:
              </label>
              <Field
                id="number"
                name="number"
                type="number"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter number"
              />
              <ErrorMessage
                name="number"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="flex space-x-2">
              <button
                type="submit"
                className="flex-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
              >
                {editingIndex !== null ? "Update" : "Enter"}
              </button>

              {editingIndex !== null && (
                <button
                  type="button"
                  onClick={() => handleCancelEdit(resetForm)}
                  className="bg-gray-300 text-gray-800 py-2 px-4 rounded hover:bg-gray-400 transition-colors"
                >
                  Cancel
                </button>
              )}
            </div>

            <div className="mt-4">
              {/* <label
                htmlFor="scanner"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Scanner input:
              </label> */}
              <Field
                disabled
                id="scanner"
                name="scanner"
                type="number"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Scanner input"
              />
              <ErrorMessage
                name="scanner"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </Form>
        )}
      </Formik>

      {entries.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Entries</h3>

          {/* Search bar */}
          <div className="mb-3">
            <input
              type="text"
              placeholder="Search numbers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {filteredEntries.length > 0 ? (
            <ul className="space-y-2">
              {filteredEntries.map((entry, index) => {
                // Find the original index in the entries array
                const originalIndex = entries.findIndex(
                  (e) =>
                    e.timestamp === entry.timestamp && e.value === entry.value
                );

                return (
                  <li
                    key={originalIndex}
                    className={`${
                      index === editingIndex ? "border-2 border-green-300" : ""
                    } p-3 bg-gray-100 rounded flex justify-between items-center`}
                  >
                    <div>
                      <div className="font-medium">{entry.value}</div>
                      <div className="text-xs text-gray-500">
                        {entry.timestamp}
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <Formik
                        initialValues={{ number: entry.value }}
                        validationSchema={validationSchema}
                      >
                        {({ setFieldValue }) => (
                          <Edit
                            className="text-blue-500 hover:text-blue-700"
                            disabled={editingIndex !== null}
                            onClick={() =>
                              handleEdit(originalIndex, setFieldValue)
                            }
                          />
                        )}
                      </Formik>
                      <Trash
                        onClick={() => handleDelete(originalIndex)}
                        className="text-red-500 hover:text-red-700"
                        disabled={editingIndex !== null}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-gray-500 italic">
              No entries found matching your search.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default NumberCaptureInterface;
