import { doc, getDoc } from "firebase/firestore";
import { useState } from "react";
import { db } from "../../../firebase";
import Papa from "papaparse";
import { CsvDataTable } from "./CsvDataTable";
import MergeRunsTableV2 from "./MergeRunsTableV2";

function MultiLapsTimesUploader() {
  const [startData, setStartData] = useState([]);
  const [endData, setEndData] = useState([]);
  const [receiverData, setReceiverData] = useState(null);

  const DataType = {
    start: "start",
    end: "end",
  };

  const fetchReceiver = async (receiverId) => {
    if (!receiverId) return;

    try {
      const docRef = doc(db, "Receivers", receiverId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setReceiverData({ id: docSnap.id, ...docSnap.data() });
      } else {
        return;
      }
    } catch (err) {
      console.error("Error fetching receiver:", err);
      return;
    }
  };

  const handleFileChange = (type, event) => {
    if (!type) return;
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const sortedResult = result.data.sort((a, b) =>
            a.Time.localeCompare(b.Time)
          );
          if (type === DataType.start) {
            fetchReceiver(sortedResult[0].RUID);
            setStartData(sortedResult); // Store parsed data in state
          }
          if (type === DataType.end) {
            setEndData(sortedResult); // Store parsed data in state
          }
        },
        error: (error) => {
          if (type === DataType.start) {
            console.error("Error parsing start CSV:", error);
            alert("Error al leer csv de salida");
          }
          if (type === DataType.end) {
            console.error("Error parsing end CSV:", error);
            alert("Error al leer csv de llegada");
          }
        },
      });
    } else {
      setStartData([]);
      setEndData([]);
    }
  };

  return (
    <div>
      <div className="flex gap-2 flex-wrap justify-between">
        <div>
          <h2 className="text-lg font-semibold mb-2">
            Subir archivo CSV de Inicio
          </h2>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileChange(DataType.start, e)}
            className="mb-2 p-2 border rounded-lg text-sm text-gray-700 bg-white cursor-pointer focus:outline-none"
          />
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-2">
            Subir archivo CSV de Llegada
          </h2>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileChange(DataType.end, e)}
            className="mb-2 p-2 border rounded-lg text-sm text-gray-700 bg-white cursor-pointer focus:outline-none"
          />
        </div>
      </div>
      <div className="flex gap-2 max-h-[500px] overflow-scroll">
        <div className="flex-1">
          <CsvDataTable data={startData} setData={setStartData} />
        </div>
        <div className="flex-1">
          <CsvDataTable data={endData} setData={setEndData} />
        </div>
      </div>
      {/* <div>
        <div>
          <button
            className="btn"
            onClick={() => console.log("startData =>", startData)}
          >
            ver start times
          </button>
          <button
            className="btn"
            onClick={() => console.log("endData =>", endData)}
          >
            ver finish times
          </button>
        </div>
      </div> */}
      <div>
        {startData.length > 0 && endData.length > 0 && (
          <div className="pt-8">
            <p className="text-xl font-semibold">Tiempos procesados</p>
            <MergeRunsTableV2
              receiverData={receiverData}
              startData={startData}
              endData={endData}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MultiLapsTimesUploader;
