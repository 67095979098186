import React, { useState, useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Chip,
    Avatar,
    Tooltip,
    Tabs,
    Tab,
    Badge,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import CategoryIcon from "@mui/icons-material/Category";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";

const medalColors = {
    gold: "linear-gradient(45deg, #FFD700, #FFA500)",
    silver: "linear-gradient(45deg, #C0C0C0, #A9A9A9)",
    bronze: "linear-gradient(45deg, #CD7F32, #8B4513)",
};

const GlobalCategoryLeaderboard = ({
    events,
    categories,
    totalTimes,
    ownerData,
    convertTimeToMilliseconds,
    convertMillisecondsToTime
}) => {
    // Crear un mapa para consolidar información de usuarios por categoría
    const usersByCategoryData = useMemo(() => {
        const categoriesMap = new Map();

        // Usar un Set para rastrear IDs de categorías ya procesadas
        const processedCategoryIds = new Set();

        // Primero, crear una entrada para cada categoría (sin duplicados)
        categories.forEach(category => {
            if (!processedCategoryIds.has(category.id)) {
                processedCategoryIds.add(category.id);

                // Buscar evento asociado para obtener el nombre
                const event = events.find(e => e.id === category.eventId);
                categoriesMap.set(category.id, {
                    id: category.id,
                    name: category.name,
                    eventId: category.eventId,
                    eventName: event ? event.eventName : "Evento desconocido",
                    users: []
                });
            }
        });

        // Mapa para rastrear a qué usuario pertenece cada NFCIdentifier
        const nfcToUserMap = {};

        // Procesar ownerData para identificar usuarios y sus categorías
        Object.entries(ownerData).forEach(([key, userData]) => {
            const [nfcId, eventId] = key.split('-');

            if (userData.categoryId) {
                const categoryId = userData.categoryId;

                // Verificar que la categoría exista en nuestro mapa
                if (categoriesMap.has(categoryId)) {
                    const categoryData = categoriesMap.get(categoryId);

                    // Verificar si el usuario ya existe en esta categoría
                    let userFound = categoryData.users.find(u => u.name === userData.Owner);

                    if (!userFound) {
                        // Crear nuevo usuario
                        userFound = {
                            name: userData.Owner,
                            photoUrl: userData.PhotoUrl,
                            runsByEvent: {},
                            events: new Set(),
                            participatedEvents: new Set(),
                            allEventIds: new Set(events.map(e => e.id)),
                            bestTime: null,
                            bestTimeMs: Number.MAX_SAFE_INTEGER,
                            totalTimeMs: 0,
                            totalTime: null,
                            isDNF: false
                        };

                        categoryData.users.push(userFound);
                    }

                    // Registrar NFC ID para este usuario
                    nfcToUserMap[nfcId] = {
                        userName: userData.Owner,
                        categoryId: categoryId
                    };
                }
            }
        });

        // Procesar tiempos totales y asignarlos a los usuarios correspondientes
        totalTimes.forEach(time => {
            const nfcId = time.NFCIdentifier;

            // Verificar si conocemos a qué usuario pertenece este NFC ID
            if (nfcToUserMap[nfcId]) {
                const { userName, categoryId } = nfcToUserMap[nfcId];

                // Si la categoría existe
                if (categoriesMap.has(categoryId)) {
                    // Encontrar el usuario en esta categoría
                    const categoryData = categoriesMap.get(categoryId);
                    const user = categoryData.users.find(u => u.name === userName);

                    if (user) {
                        // Guardar el tiempo para este evento
                        if (!user.runsByEvent[time.eventId]) {
                            user.events.add(time.eventId);
                            user.participatedEvents.add(time.eventId);
                            user.runsByEvent[time.eventId] = time;

                            // Solo sumar tiempos válidos para el total
                            if (time.ElapsedTime !== "DNF") {
                                const timeMs = convertTimeToMilliseconds(time.ElapsedTime);
                                if (timeMs) {
                                    user.totalTimeMs += timeMs;

                                    // Actualizar el mejor tiempo individual
                                    if (timeMs < user.bestTimeMs) {
                                        user.bestTime = time.ElapsedTime;
                                        user.bestTimeMs = timeMs;
                                    }
                                }
                            } else {
                                // Si algún evento es DNF, marcar al usuario como DNF
                                user.isDNF = true;
                            }
                        }
                    }
                }
            }
        });

        // Finalizar procesamiento y ordenar usuarios por tiempo total
        categoriesMap.forEach((categoryData) => {
            categoryData.users.forEach(user => {
                // Verificar si el usuario participó en todos los eventos
                const participatedInAllEvents = events.every(event =>
                    user.participatedEvents.has(event.id)
                );

                // Si no participó en todos los eventos, marcar como DNF
                if (!participatedInAllEvents) {
                    user.isDNF = true;
                }

                // Si no es DNF, convertir el tiempo total de ms a formato legible
                if (!user.isDNF) {
                    // Formato manual para la conversión
                    const ms = user.totalTimeMs;
                    const hours = Math.floor(ms / 3600000);
                    const minutes = Math.floor((ms % 3600000) / 60000);
                    const seconds = Math.floor((ms % 60000) / 1000);
                    const milliseconds = ms % 1000;

                    user.totalTime = `${hours.toString().padStart(2, "0")}:${minutes
                        .toString()
                        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
                            .toString()
                            .padStart(3, "0")}`;
                } else {
                    user.totalTime = "DNF";
                }

                // Agregar conteo de eventos
                user.eventCount = user.participatedEvents.size;
            });

            // Ordenar usuarios: primero los que no son DNF (por tiempo total), luego los DNF
            categoryData.users.sort((a, b) => {
                if (a.isDNF && !b.isDNF) return 1;
                if (!a.isDNF && b.isDNF) return -1;
                if (a.isDNF && b.isDNF) {
                    return b.eventCount - a.eventCount;
                }
                return a.totalTimeMs - b.totalTimeMs;
            });
        });

        return Array.from(categoriesMap.values());
    }, [categories, events, ownerData, totalTimes, convertTimeToMilliseconds, convertMillisecondsToTime]);

    // Agrupar categorías por evento para mejor organización
    const categoriesByEvent = useMemo(() => {
        const eventGroups = {};

        usersByCategoryData.forEach(category => {
            const eventId = category.eventId;
            if (!eventGroups[eventId]) {
                const event = events.find(e => e.id === eventId);
                eventGroups[eventId] = {
                    id: eventId,
                    name: event ? event.eventName : "Evento desconocido",
                    categories: []
                };
            }

            eventGroups[eventId].categories.push(category);
        });

        return Object.values(eventGroups);
    }, [usersByCategoryData, events]);

    // Crear una tabla por categoría
    const renderCategoryTable = (category) => {
        return (
            <Box sx={{ mb: 4 }} key={category.id}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <CategoryIcon sx={{ mr: 1, color: "primary.main" }} />
                    <Typography variant="h6" fontWeight={600}>
                        {category.name}
                        <Typography component="span" variant="subtitle1" color="text.secondary" sx={{ ml: 1 }}>
                            ({category.eventName})
                        </Typography>
                    </Typography>
                </Box>

                <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                <TableCell sx={{ fontWeight: "bold", width: "50px" }}>Pos.</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>Corredor</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Eventos</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Tiempo Total</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Mejor Tiempo</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">Detalle Eventos</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {category.users.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography variant="body2" sx={{ py: 2 }}>
                                            No hay participantes en esta categoría
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                category.users.map((user, index) => {
                                    const position = index + 1;
                                    const isMedalPosition = position <= 3 && !user.isDNF;
                                    const medalColor = isMedalPosition ?
                                        Object.values(medalColors)[position - 1] :
                                        undefined;

                                    // Mostrar la posición solo para tiempos válidos
                                    const showPosition = !user.isDNF;

                                    return (
                                        <TableRow key={`${category.id}-${user.name}`}
                                            sx={{
                                                '&:nth-of-type(odd)': { bgcolor: 'rgba(0, 0, 0, 0.02)' },
                                                ...(isMedalPosition ? {
                                                    position: 'relative',
                                                    '&::before': {
                                                        content: '""',
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        bottom: 0,
                                                        width: '4px',
                                                        background: medalColor
                                                    }
                                                } : {}),
                                                ...(user.isDNF ? { opacity: 0.7 } : {})
                                            }}
                                        >
                                            <TableCell>
                                                {showPosition ? (
                                                    isMedalPosition ? (
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            background: medalColor,
                                                            borderRadius: '50%',
                                                            width: 26,
                                                            height: 26,
                                                            color: '#000',
                                                            fontWeight: 'bold'
                                                        }}>
                                                            {position}
                                                        </Box>
                                                    ) : (
                                                        <Typography variant="body2">{position}</Typography>
                                                    )
                                                ) : (
                                                    <Typography variant="body2" color="text.secondary">DNF</Typography>
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Avatar src={user.photoUrl} sx={{ width: 30, height: 30, mr: 1 }} />
                                                    <Typography variant="body2" fontWeight={600}>
                                                        {user.name}
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                            <TableCell align="center">
                                                <Badge
                                                    badgeContent={user.eventCount}
                                                    color={user.eventCount === events.length ? "success" : "primary"}
                                                    sx={{ '& .MuiBadge-badge': { fontSize: '0.7rem', fontWeight: 'bold' } }}
                                                >
                                                    <Chip
                                                        label={`${user.eventCount}/${events.length}`}
                                                        size="small"
                                                        color={user.eventCount === events.length ? "success" : "primary"}
                                                        sx={{
                                                            fontWeight: "bold",
                                                            minWidth: "50px",
                                                            fontSize: "0.7rem",
                                                            height: "20px"
                                                        }}
                                                    />
                                                </Badge>
                                            </TableCell>

                                            <TableCell align="center">
                                                {user.isDNF ? (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <CancelIcon color="error" sx={{ mr: 0.5, fontSize: "1rem" }} />
                                                        <Typography variant="body2" color="error" fontWeight={600}>
                                                            DNF
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                        <Typography
                                                            variant="body2"
                                                            fontWeight={600}
                                                            sx={{
                                                                ...(isMedalPosition ? {
                                                                    color: position === 1 ? '#FFD700' : position === 2 ? '#C0C0C0' : '#CD7F32'
                                                                } : {})
                                                            }}
                                                        >
                                                            {user.totalTime}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </TableCell>

                                            <TableCell align="center">
                                                {user.bestTime ? (
                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                        <Typography variant="body2">
                                                            {user.bestTime}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Typography variant="body2" color="text.secondary">
                                                        N/A
                                                    </Typography>
                                                )}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap", justifyContent: "center" }}>
                                                    {Array.from(user.events).map(eventId => {
                                                        const event = events.find(e => e.id === eventId);
                                                        const eventName = event ? event.eventName : "Evento desconocido";
                                                        const time = user.runsByEvent[eventId];

                                                        return (
                                                            <Tooltip
                                                                key={eventId}
                                                                title={`${eventName}: ${time.ElapsedTime}`}
                                                            >
                                                                <Chip
                                                                    size="small"
                                                                    label={event ? event.eventName.substring(0, 8) + (event.eventName.length > 8 ? "..." : "") : "?"}
                                                                    sx={{
                                                                        fontSize: '0.65rem',
                                                                        height: 20,
                                                                        ...(time.ElapsedTime === "DNF" ? {
                                                                            backgroundColor: "#FFEBEE",
                                                                            color: "#D32F2F"
                                                                        } : {})
                                                                    }}
                                                                    variant={time.ElapsedTime === "DNF" ? "outlined" : "filled"}
                                                                />
                                                            </Tooltip>
                                                        );
                                                    })}

                                                    {/* Mostrar eventos en los que no participó */}
                                                    {events.filter(event => !user.participatedEvents.has(event.id)).map(event => (
                                                        <Tooltip
                                                            key={`missing-${event.id}`}
                                                            title={`No participó en: ${event.eventName}`}
                                                        >
                                                            <Chip
                                                                size="small"
                                                                label={event.eventName.substring(0, 8) + (event.eventName.length > 8 ? "..." : "")}
                                                                sx={{
                                                                    fontSize: '0.65rem',
                                                                    height: 20,
                                                                    opacity: 0.5,
                                                                    borderStyle: 'dashed'
                                                                }}
                                                                variant="outlined"
                                                            />
                                                        </Tooltip>
                                                    ))}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {category.users.some(user => !user.isDNF) && (
                    <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                <EmojiEventsIcon sx={{ fontSize: 16, verticalAlign: 'middle', color: '#FFD700', mr: 0.5 }} />
                                Ganador:
                                <span style={{ marginLeft: 8 }}>
                                    {category.users.find(user => !user.isDNF).name} ({category.users.find(user => !user.isDNF).totalTime})
                                </span>
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2">
                                Total: {category.users.length} | Completaron todos: {category.users.filter(u => !u.isDNF).length}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" fontWeight={600} sx={{ mb: 1 }}>
                    Leaderboard Global por Categorías
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    Ranking de corredores separados por categoría, ordenados por tiempo total acumulado. Para tener un tiempo válido, se debe participar en todos los eventos seleccionados.
                </Typography>
            </Box>

            {categoriesByEvent.map((eventGroup) => (
                <Accordion defaultExpanded key={eventGroup.id} sx={{ mb: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.07)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                            bgcolor: "rgba(0, 0, 0, 0.03)",
                            borderBottom: 1,
                            borderColor: "divider"
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <PeopleIcon sx={{ mr: 1 }} />
                            <Typography variant="subtitle1" fontWeight={600}>
                                {eventGroup.name}
                                <Chip
                                    label={`${eventGroup.categories.length} categorías`}
                                    size="small"
                                    sx={{ ml: 2, fontSize: "0.7rem", height: "20px" }}
                                />
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 2 }}>
                        {eventGroup.categories.map((category, index) => (
                            <React.Fragment key={category.id}>
                                {index > 0 && <Divider sx={{ my: 3 }} />}
                                {renderCategoryTable(category)}
                            </React.Fragment>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}

            {categoriesByEvent.length === 0 && (
                <Box sx={{ p: 4, textAlign: "center", bgcolor: "background.paper", borderRadius: 2 }}>
                    <Typography variant="body1">
                        No hay datos de categorías disponibles para los eventos seleccionados.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default GlobalCategoryLeaderboard;