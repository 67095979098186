import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, db } from "../../firebase"; // Asegúrate de importar db
import { doc, getDoc } from "firebase/firestore"; // Importar getDoc
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Mail, Send, Check, AlertCircle, Download, User } from "lucide-react";
import qrcode from "qrcode";
import { storage } from "../../firebase";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { generateTicketPDF } from "./ticketPdfGenerator"; // Importar la función de generación de PDF

const SendSuccess = ({ order, eventName, categoryName, eventDate }) => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [additionalEmail, setAdditionalEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");
  const [showAdditionalEmail, setShowAdditionalEmail] = useState(false);
  const [additionalEmailSent, setAdditionalEmailSent] = useState(false);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [autoSendCompleted, setAutoSendCompleted] = useState(false);
  const [qrGenerating, setQrGenerating] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);

  // URL del logo de Livespeed
  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5";

  // URL del icono de teléfono
  const phoneIconUrl =
    "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/icons%2Fllamada-telefonica.png?alt=media&token=15f8ec4a-963e-4a9e-ab08-a5a0386863b8";

  // Obtener el correo del usuario actual
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserEmail(user.email);
    }
  }, []);

  // Obtener el nombre del usuario desde Firestore
  useEffect(() => {
    const getUserName = async () => {
      if (!order?.userId || loadingUserData) return;

      try {
        setLoadingUserData(true);
        console.log("Buscando usuario con ID:", order.userId);

        const userRef = doc(db, "users", order.userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          console.log("Datos del usuario encontrados:", userData);

          // Obtener el nombre del usuario del campo 'name'
          if (userData.name) {
            setUserName(userData.name);

            // Añadir nombre a la orden para usarlo en el PDF
            order.userName = userData.name;
            console.log("Nombre de usuario establecido:", userData.name);
          } else {
            console.log(
              "El campo 'name' no existe en el documento del usuario"
            );
          }
        } else {
          console.log("No se encontró el documento del usuario");
        }
      } catch (error) {
        console.error("Error al obtener información del usuario:", error);
      } finally {
        setLoadingUserData(false);
      }
    };

    getUserName();
  }, [order]);

  // Generar QR code como URL de Firebase Storage
  useEffect(() => {
    const generateAndUploadQR = async () => {
      if (!order?.id || qrGenerating) return;

      try {
        setQrGenerating(true);

        // Generar QR code como Base64
        const dataUrl = await new Promise((resolve, reject) => {
          qrcode.toDataURL(
            order.id,
            {
              errorCorrectionLevel: "H",
              margin: 2,
              width: 200,
              color: {
                dark: "#000000",
                light: "#FFFFFF",
              },
            },
            (err, url) => {
              if (err) reject(err);
              else resolve(url);
            }
          );
        });

        setQrCodeDataUrl(dataUrl);

        // Subir QR code a Firebase Storage
        const storageRef = ref(storage, `qrcodes/${order.id}.png`);

        // Remover la parte "data:image/png;base64," del dataURL
        const base64Content = dataUrl.split(",")[1];

        // Subir como string base64
        await uploadString(storageRef, base64Content, "base64", {
          contentType: "image/png",
        });

        // Obtener URL de descarga
        const downloadUrl = await getDownloadURL(storageRef);
        setQrCodeUrl(downloadUrl);
      } catch (error) {
        console.error("Error al generar o subir QR code:", error);
      } finally {
        setQrGenerating(false);
      }
    };

    generateAndUploadQR();
  }, [order]);

  // Generar PDF cuando tengamos el QR y los datos del pedido
  useEffect(() => {
    const generatePdf = async () => {
      if (!order || !eventName || generatingPdf || loadingUserData) return;

      try {
        setGeneratingPdf(true);
        console.log("Generando PDF con datos:", { userName, eventDate });

        // Formatear la fecha del evento si existe
        const formattedEventDate = eventDate
          ? formatDate(eventDate)
          : order.eventDate
          ? formatDate(order.eventDate)
          : null;

        // Generar el PDF
        const pdfData = await generateTicketPDF(
          order,
          eventName,
          categoryName,
          formattedEventDate
        );

        // Guardar el PDF en Base64
        setPdfBase64(pdfData);
      } catch (error) {
        console.error("Error al generar PDF:", error);
      } finally {
        setGeneratingPdf(false);
      }
    };

    // Solo generamos el PDF cuando no estemos cargando datos del usuario
    if (!loadingUserData) {
      generatePdf();
    }
  }, [
    order,
    eventName,
    categoryName,
    qrCodeDataUrl,
    loadingUserData,
    userName,
    eventDate,
  ]);

  // Formatear fecha del evento
  const formatDate = (dateString) => {
    if (!dateString) return "Fecha por confirmar";
    try {
      const date = new Date(dateString);
      return format(date, "d 'de' MMMM 'de' yyyy", { locale: es });
    } catch (e) {
      console.error("Error al formatear fecha:", e);
      return "Fecha por confirmar";
    }
  };

  // Generar el template HTML del correo
  const generateEmailTemplate = () => {
    if (!order) return "";

    const addonsSection =
      order.addons && order.addons.length > 0
        ? `
        <tr>
          <td style="padding: 20px;">
            <h3 style="color: #333; font-size: 18px; margin-bottom: 15px; text-align: center;">Add-ons Incluidos</h3>
            <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; background-color: #f9f9f9; border-radius: 8px;">
              ${order.addons
                .map(
                  (addon) => `
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid #eee;">
                    <p style="font-weight: bold; margin: 0; color: #333;">${
                      addon.name
                    } - $${addon.price}</p>
                    <p style="margin: 5px 0 0; color: #666; font-size: 14px;">${
                      addon.description || ""
                    }</p>
                    ${
                      addon.selectedVariant
                        ? `<p style="margin: 5px 0 0; color: #666; font-size: 14px;">
                             <strong>${addon.selectedVariant.name}:</strong> ${addon.selectedVariant.option}
                           </p>`
                        : ""
                    }
                  </td>
                </tr>
              `
                )
                .join("")}
            </table>
          </td>
        </tr>
      `
        : "";

    // Determinar la fecha del evento
    const eventDateStr = eventDate
      ? formatDate(eventDate)
      : order.eventDate
      ? formatDate(order.eventDate)
      : "Fecha por confirmar";

    // Sección de participante
    const participantSection = userName
      ? `
        <tr>
          <td style="padding: 10px; border-bottom: 1px solid #eee;">
            <span style="color: #666; font-size: 14px;">Participante</span>
            <p style="font-weight: bold; margin: 0; color: #333;">${userName}</p>
          </td>
        </tr>
      `
      : "";

    // QR Code section - Usando URL de Firebase Storage o alternativa de texto
    const qrCodeSection = qrCodeUrl
      ? `<img src="${qrCodeUrl}" alt="QR Code" style="width: 150px; height: 150px; display: block; margin: 0 auto; padding: 5px; background-color: #fff; border: 1px solid #eee;">`
      : `<div style="width: 150px; height: 150px; border: 1px solid #eee; padding: 10px; margin: 0 auto; background-color: #fff; text-align: center;">
          <strong style="display: block; margin-bottom: 10px;">Tu código de acceso:</strong>
          <p style="font-size: 14px;">Presenta este ID en el evento</p>
          <p style="font-size: 16px; font-weight: bold; word-break: break-all;">${order.id}</p>
        </div>`;

    return `
      <div style="background-color: #f4f4f4; padding: 20px; font-family: Arial, sans-serif;">
        <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 10px; overflow: hidden; box-shadow: 0 4px 6px rgba(0,0,0,0.1);">
          <!-- Header con fondo gris claro -->
          <div style="background-color: #f0f0f0; padding: 20px; text-align: center;">
            <img src="${logoUrl}" alt="Livespeed" style="max-width: 180px; height: auto;">
          </div>
          
          <!-- Content -->
          <div style="padding: 30px 20px; text-align: center;">
            <h1 style="color: #333; font-size: 24px; margin: 0 0 10px;">¡Gracias por tu inscripción!</h1>
            <p style="color: #666; font-size: 16px; margin-bottom: 10px;">Tu aventura con Livespeed está a punto de comenzar</p>
            <p style="color: #666; font-size: 14px; margin-bottom: 30px;">Adjunto encontrarás tu ticket en formato PDF para descargar e imprimir</p>
            
            <!-- Ticket -->
            <div style="border: 2px dashed #ccc; border-radius: 8px; padding: 20px; margin-bottom: 30px; background-color: #fff;">
              <h2 style="color: #333; font-size: 20px; margin: 0 0 20px; text-transform: uppercase;">VISTA PREVIA DE TU TICKET</h2>
              
              <!-- QR Code con imagen hospedada en Firebase -->
              <div style="margin: 20px 0; text-align: center;">
                ${qrCodeSection}
                <p style="color: #666; font-size: 14px; margin-top: 10px;">ID: ${
                  order.id
                }</p>
              </div>
              
              <!-- Event Details -->
              <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; background-color: #f9f9f9; border-radius: 8px;">
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid #eee;">
                    <span style="color: #666; font-size: 14px;">Evento</span>
                    <p style="font-weight: bold; margin: 0; color: #333;">${eventName}</p>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid #eee;">
                    <span style="color: #666; font-size: 14px;">Fecha</span>
                    <p style="font-weight: bold; margin: 0; color: #333;">${eventDateStr}</p>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; border-bottom: 1px solid #eee;">
                    <span style="color: #666; font-size: 14px;">Categoría</span>
                    <p style="font-weight: bold; margin: 0; color: #333;">${categoryName}</p>
                  </td>
                </tr>
                ${participantSection}
                <tr>
                  <td style="padding: 10px;">
                    <span style="color: #666; font-size: 14px;">Total Pagado</span>
                    <p style="font-weight: bold; margin: 0; color: #333;">$${
                      order.total
                    }</p>
                  </td>
                </tr>
              </table>
            </div>
            
            <!-- Instructions -->
            <div style="background-color: #f9f9f9; padding: 20px; border-radius: 8px; margin-bottom: 30px; text-align: left;">
              <h3 style="color: #333; font-size: 18px; margin-bottom: 15px;">Instrucciones importantes:</h3>
              <ul style="color: #666; padding-left: 20px; margin: 0;">
                <li style="margin-bottom: 10px;">Puedes usar el ticket PDF adjunto o guardar este correo.</li>
                <li style="margin-bottom: 10px;">Si no puedes ver el código QR, presenta el ID mostrado debajo del mismo.</li>
                <li style="margin-bottom: 10px;">Llega al menos 30 minutos antes para el registro.</li>
                <li style="margin-bottom: 10px;">Recuerda traer tu identificación oficial para validar tu inscripción.</li>
              </ul>
            </div>
            
            <!-- CTA Button -->
            <a href="https://www.livespeed.mx/event/${
              order.itemId
            }" style="display: inline-block; background-color: #000; color: #fff; text-decoration: none; padding: 12px 30px; border-radius: 30px; font-weight: bold; font-size: 16px; margin-bottom: 30px;">Ver detalles del evento</a>
          </div>
          
          <!-- Footer -->
          <div style="background-color: #f4f4f4; padding: 20px; text-align: center; border-top: 1px solid #eee;">
            <p style="color: #666; font-size: 14px; margin-bottom: 10px;">¿Tienes alguna pregunta?</p>
            <p style="color: #666; font-size: 14px; margin-bottom: 20px;">
              <img src="${phoneIconUrl}" alt="Teléfono" style="width: 14px; vertical-align: middle; margin-right: 5px;"> 
              Contáctanos: 6622995258
            </p>
            <p style="color: #999; font-size: 12px; margin: 0;">© ${new Date().getFullYear()} Livespeed. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    `;
  };

  // Descargar PDF
  const downloadPdf = () => {
    if (!pdfBase64) return;

    const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    const downloadLink = document.createElement("a");
    const fileName = `Ticket_${eventName}_${order.id}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  // Enviar el correo con PDF adjunto
  const sendEmail = async (email) => {
    if (!email || !order) return;

    try {
      setSending(true);
      setError("");

      const functions = getFunctions();
      const mailSender = httpsCallable(functions, "mailSender");

      // Generar el HTML del correo
      const emailHtml = generateEmailTemplate();

      // Configurar el adjunto del PDF
      const attachments = [];

      if (pdfBase64) {
        attachments.push({
          filename: `Ticket_${eventName}_${order.id}.pdf`,
          content: pdfBase64,
          encoding: "base64",
          contentType: "application/pdf",
        });
      }

      const result = await mailSender({
        email: email,
        subject: `¡Inscripción confirmada! Tu ticket para ${eventName}`,
        message: emailHtml,
        attachments: attachments,
      });

      if (result.data.success) {
        if (email === userEmail) {
          setSent(true);
        } else {
          setAdditionalEmailSent(true);
        }
        setTimeout(() => {
          if (email === userEmail) {
            setSent(false);
          } else {
            setAdditionalEmailSent(false);
          }
        }, 5000);
      } else {
        throw new Error(result.data.message || "Error al enviar el correo");
      }
    } catch (error) {
      console.error("Error al enviar el correo:", error);
      setError(
        error.message || "Error al enviar el correo. Intenta nuevamente."
      );
    } finally {
      setSending(false);
    }
  };

  // Enviar el correo automáticamente al usuario actual cuando se carga el componente
  useEffect(() => {
    const autoSendEmail = async () => {
      // Esperar a que tengamos todo listo: el email del usuario, orden, y PDF generado
      if (
        userEmail &&
        order &&
        !autoSendCompleted &&
        !sending &&
        !sent &&
        !generatingPdf &&
        !loadingUserData &&
        pdfBase64
      ) {
        // Marcar como completado antes de enviar para evitar llamadas múltiples
        setAutoSendCompleted(true);
        await sendEmail(userEmail);
      }
    };

    autoSendEmail();
  }, [
    userEmail,
    order,
    pdfBase64,
    generatingPdf,
    loadingUserData,
    autoSendCompleted,
    sending,
    sent,
  ]);

  // Enviar correo a email adicional
  const sendToAdditionalEmail = async () => {
    if (!additionalEmail) {
      setError("Por favor ingresa un correo electrónico válido");
      return;
    }

    // Validación simple de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(additionalEmail)) {
      setError("Por favor ingresa un correo electrónico válido");
      return;
    }

    await sendEmail(additionalEmail);
  };

  return (
    <div className="max-w-lg w-full mx-auto mb-8">
      {/* Usuario actual */}
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Mail className="text-gray-500" size={20} />
            <span className="text-gray-700">Ticket enviado a:</span>
            <span className="font-semibold">{userEmail || "Cargando..."}</span>
          </div>

          {(generatingPdf || qrGenerating || sending || loadingUserData) &&
          !sent ? (
            <span className="text-sm text-blue-500 flex items-center">
              <div className="w-4 h-4 border-2 border-blue-500 rounded-full border-t-transparent animate-spin mr-2"></div>
              {loadingUserData
                ? "Cargando datos..."
                : generatingPdf
                ? "Generando PDF..."
                : qrGenerating
                ? "Generando QR..."
                : "Enviando..."}
            </span>
          ) : sent || autoSendCompleted ? (
            <span className="text-sm text-green-500 flex items-center">
              <Check className="mr-1" size={16} />
              Enviado
            </span>
          ) : null}
        </div>
      </div>

      {/* Información del participante (cuando esté disponible) */}
      {userName && (
        <div className="bg-white rounded-lg shadow p-4 mb-4">
          <div className="flex items-center space-x-2">
            <User className="text-gray-500" size={20} />
            <span className="text-gray-700">Participante:</span>
            <span className="font-semibold">{userName}</span>
          </div>
        </div>
      )}

      {/* PDF Download Button */}
      {pdfBase64 && (
        <div className="bg-white rounded-lg shadow p-4 mb-4">
          <button
            onClick={downloadPdf}
            className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 text-white rounded flex items-center justify-center transition"
          >
            <Download className="mr-2" size={18} />
            Descargar Ticket PDF
          </button>
        </div>
      )}

      {/* Opción para enviar a un correo adicional */}
      <div className="bg-white rounded-lg shadow p-4">
        {!showAdditionalEmail ? (
          <button
            onClick={() => setShowAdditionalEmail(true)}
            className="w-full py-2 px-4 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded flex items-center justify-center transition"
          >
            <Mail className="mr-2" size={18} />
            Enviar ticket a otro correo
          </button>
        ) : (
          <div>
            <h3 className="font-medium text-gray-800 mb-3">
              Enviar a otro correo:
            </h3>
            <div className="flex space-x-2">
              <input
                type="email"
                value={additionalEmail}
                onChange={(e) => setAdditionalEmail(e.target.value)}
                placeholder="correo@ejemplo.com"
                className="flex-1 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={sendToAdditionalEmail}
                disabled={
                  sending || generatingPdf || !pdfBase64 || loadingUserData
                }
                className={`px-4 py-2 rounded flex items-center justify-center ${
                  sending || generatingPdf || !pdfBase64 || loadingUserData
                    ? "bg-gray-400"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white transition`}
              >
                {sending ? (
                  <div className="w-5 h-5 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
                ) : (
                  <>
                    <Send className="mr-2" size={16} />
                    Enviar
                  </>
                )}
              </button>
            </div>

            {additionalEmailSent && (
              <div className="mt-2 text-sm text-green-600 flex items-center">
                <Check className="mr-1" size={16} />
                Ticket enviado exitosamente a {additionalEmail}
              </div>
            )}

            {error && (
              <div className="mt-2 text-sm text-red-600 flex items-center">
                <AlertCircle className="mr-1" size={16} />
                {error}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SendSuccess;
