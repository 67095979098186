import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import moment from "moment";
import {
  Search,
  Edit,
  Trash2,
  MapPin,
  User,
  Tag,
  Calendar,
  DollarSign,
  ChevronUp,
  ChevronDown,
} from "lucide-react";

const MarketDashboard = () => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (user) {
      fetchProducts(user.uid);
    }
  }, [user]);

  const fetchProducts = async (uid) => {
    const productsRef = collection(db, "Products");
    const q = query(productsRef, where("uid", "==", uid));
    onSnapshot(q, async (snapshot) => {
      const loadedProducts = await Promise.all(
        snapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();
          const userDoc = await getDoc(doc(db, "Users", productData.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};
          const categoryDoc = await getDoc(
            doc(db, "Categories", productData.category)
          );
          const categoryData = categoryDoc.exists() ? categoryDoc.data() : {};
          return {
            id: productDoc.id,
            ...productData,
            user: userData,
            categoryName: categoryData.type || "Unknown Category",
          };
        })
      );
      setProducts(loadedProducts);
    });
  };

  const handleEditClick = (productId) => {
    navigate(`/createproduct?edit=${productId}`);
  };

  const handleDeleteClick = async (productId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este anuncio?")) {
      try {
        const productRef = doc(db, "Products", productId);
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();

          // Delete main image
          if (productData.mainImageUrl) {
            const mainImageRef = ref(storage, productData.mainImageUrl);
            await deleteObject(mainImageRef);
          }

          // Delete additional images
          if (productData.moreImages && productData.moreImages.length > 0) {
            for (const imageUrl of productData.moreImages) {
              const imageRef = ref(storage, imageUrl);
              await deleteObject(imageRef);
            }
          }

          // Delete the product document
          await deleteDoc(productRef);
        }

        fetchProducts(user.uid); // Refresh products list after deletion
      } catch (error) {
        console.error("Error deleting product and images: ", error);
      }
    }
  };

  const handleToggleAvailability = async (productId, currentStatus) => {
    const newStatus = !currentStatus;
    try {
      const productRef = doc(db, "Products", productId);
      await updateDoc(productRef, {
        active: newStatus,
      });
      fetchProducts(user.uid); // Refresh products list after status change
    } catch (error) {
      console.error("Error updating product status: ", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-6">Mis Anuncios</h1>
        <div className="relative max-w-2xl mx-auto">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Buscar productos..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="pl-10 pr-4 py-3 w-full rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent shadow-sm"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className={`rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl flex flex-col justify-between ${
              product.active ? "bg-white" : "bg-gray-100"
            }`}
          >
            <div
              className={`cursor-pointer ${
                product.active ? "opacity-100" : "opacity-60"
              }`}
              onClick={() => navigate(`/product/${product.id}`)}
            >
              <div className="relative h-52 w-full">
                <img
                  src={product.mainImageUrl}
                  alt={product.name}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="p-4">
                {product.user && (
                  <div className="flex items-center mb-4">
                    <div className="h-10 w-10 rounded-full overflow-hidden mr-3 bg-gray-200 flex-shrink-0">
                      {product.user.photoUrl ? (
                        <img
                          src={product.user.photoUrl}
                          alt={product.user.nickname || ""}
                          className="h-full w-full object-cover"
                        />
                      ) : (
                        <div className="h-full w-full flex items-center justify-center bg-gray-300">
                          <User className="h-6 w-6 text-gray-500" />
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="font-medium">
                        {product.user.name || "Unknown User"}
                      </p>
                      <p className="text-sm text-gray-600 italic">
                        {product.user.nickname || ""}
                      </p>
                    </div>
                  </div>
                )}

                <h2 className="text-xl font-bold mb-2 line-clamp-2">
                  {product.name}
                </h2>

                <div className="flex items-center text-gray-600 mb-2">
                  <MapPin className="h-4 w-4 mr-1 flex-shrink-0" />
                  <p className="text-sm truncate">
                    {product.city}, {product.state}, {product.country}
                  </p>
                </div>

                <div className="inline-block bg-black text-white text-xs font-semibold px-3 py-1 rounded-full mb-3">
                  <div className="flex items-center">
                    <Tag className="h-3 w-3 mr-1" />
                    {product.categoryName}
                  </div>
                </div>

                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex items-center text-gray-500 text-xs">
                    <Calendar className="h-3 w-3 mr-1" />
                    {moment(product.postDate).format("LL")}
                  </div>

                  <div className="bg-black text-white text-xs font-semibold px-3 py-1 rounded-full flex items-center">
                    <DollarSign className="h-3 w-3 mr-1" />
                    {product.price}
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 grid grid-cols-3 gap-2">
              <button
                onClick={() => handleEditClick(product.id)}
                className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white py-2 px-3 rounded-lg text-sm transition-colors duration-200"
              >
                <Edit className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">Editar</span>
              </button>

              <button
                onClick={() => handleDeleteClick(product.id)}
                className="flex items-center justify-center bg-red-600 hover:bg-red-700 text-white py-2 px-3 rounded-lg text-sm transition-colors duration-200"
              >
                <Trash2 className="h-4 w-4 mr-1" />
                <span className="hidden sm:inline">Eliminar</span>
              </button>

              <button
                onClick={() =>
                  handleToggleAvailability(product.id, product.active)
                }
                className={`flex items-center justify-center text-white py-2 px-3 rounded-lg text-sm transition-colors duration-200 ${
                  product.active
                    ? "bg-amber-600 hover:bg-amber-700"
                    : "bg-green-600 hover:bg-green-700"
                }`}
              >
                {product.active ? (
                  <>
                    <ChevronDown className="h-4 w-4 mr-1" />
                    <span className="hidden sm:inline">Desactivar</span>
                  </>
                ) : (
                  <>
                    <ChevronUp className="h-4 w-4 mr-1" />
                    <span className="hidden sm:inline">Activar</span>
                  </>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>

      {filteredProducts.length === 0 && (
        <div className="text-center py-10">
          <p className="text-gray-500 text-lg">
            No hay anuncios que coincidan con tu búsqueda
          </p>
        </div>
      )}
    </div>
  );
};

export default MarketDashboard;
