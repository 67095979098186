import React, { useEffect, useState, useCallback } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import QRCode from "qrcode.react";
import { Wallet, Send, Info, Copy, Loader2, X } from "lucide-react";
import SendSpeedCoins from "./SendSpeedCoins";
import SpeedCoinHistory from "./SpeedCoinHistory";

const WalletComponent = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState(null);
  const [sendCoinsOpen, setSendCoinsOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);

  const fetchBalance = useCallback(() => {
    if (!user) return;
    setLoading(true);
    const userDocRef = doc(db, "Users", user.uid);
    return onSnapshot(
      userDocRef,
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          if (userData.balance !== undefined) {
            setBalance(Number(userData.balance) || 0);
          } else {
            updateDoc(userDocRef, { balance: 0 });
            setBalance(0);
          }
        } else {
          setError("No se encontró el documento del usuario.");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error al obtener el balance del usuario:", error);
        setError(
          "Error al obtener el balance del usuario. Intente nuevamente."
        );
        setLoading(false);
      }
    );
  }, [user]);

  useEffect(() => {
    let unsubscribe;
    if (user?.uid) {
      unsubscribe = fetchBalance();
    }
    return () => unsubscribe && unsubscribe();
  }, [user, fetchBalance]);

  const handleSendCoinsToggle = () => setSendCoinsOpen((prev) => !prev);
  const handleInfoToggle = () => setInfoOpen((prev) => !prev);
  const handleQrOpen = () => setQrOpen(true);
  const handleQrClose = () => setQrOpen(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(user.uid);
    alert("UID copiado al portapapeles");
  };

  if (!user) {
    return (
      <div className="container mx-auto px-4 mt-8">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">
            Usuario no autenticado. Por favor, inicia sesión.
          </span>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 mt-8">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <div className="bg-white rounded-xl shadow-lg p-6 space-y-6 animate-fadeIn">
        {/* Header */}
        <div className="flex items-center justify-center space-x-2">
          <Wallet className="h-8 w-8 text-blue-500" />
          <h1 className="text-3xl font-bold text-blue-500">Mi Billetera</h1>
        </div>

        <div className="h-px bg-gray-200" />

        {/* Balance */}
        <div className="text-center space-y-2">
          <p className="text-gray-600">Balance</p>
          <p className="text-4xl font-bold text-blue-500">
            ${balance.toFixed(2)}
          </p>
        </div>

        {/* Send Button */}
        <div className="flex justify-center">
          <button
            onClick={handleSendCoinsToggle}
            className="bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 inline-flex items-center px-6 py-3 rounded-lg"
          >
            <Send className="h-5 w-5 mr-2" />
            Enviar SpeedCoins
          </button>
        </div>

        <div className="h-px bg-gray-200" />

        {/* QR Code Section */}
        <div className="space-y-4">
          <p className="text-center text-lg text-gray-600">Mi Código QR</p>
          <div
            className="flex justify-center cursor-pointer"
            onClick={handleQrOpen}
          >
            <div className="hover:opacity-80 transition-opacity">
              <QRCode
                value={user.uid}
                size={128}
                level="H"
                includeMargin={true}
                className="rounded-lg"
              />
            </div>
          </div>

          <div className="flex justify-center items-center space-x-2">
            <p className="text-sm text-gray-600 select-all">UID: {user.uid}</p>
            <button
              onClick={copyToClipboard}
              className="p-1 hover:bg-gray-100 rounded-md transition-colors"
            >
              <Copy className="h-4 w-4 text-gray-600" />
            </button>
          </div>
        </div>

        <div className="h-px bg-gray-200" />

        {/* Info Section */}
        <div className="space-y-4">
          <button
            onClick={handleInfoToggle}
            className="w-full bg-gray-100 hover:bg-gray-200 transition-colors duration-200 inline-flex items-center justify-center px-4 py-3 rounded-lg text-gray-700"
          >
            <Info className="h-5 w-5 mr-2" />
            ¿Qué son Los SpeedCoins?
          </button>

          {infoOpen && (
            <div className="bg-gray-50 rounded-lg p-4 space-y-4">
              <p className="text-sm text-gray-600">
                Los SpeedCoins son una moneda única desarrollada por Livespeed,
                diseñada para mejorar la experiencia del usuario proporcionando
                una forma de recompensa y medio de transacción dentro de la
                plataforma. Con cada inscripción a una carrera, el 10% de la
                cuota de inscripción se devuelve al participante en forma de
                SpeedCoins.
              </p>
              <p className="text-sm text-gray-600">
                Esta moneda puede utilizarse para diversos fines, incluyendo la
                inscripción en carreras adicionales, la compra de artículos en
                el mercado de Livespeed, o la realización de apuestas contra
                otros competidores. Los usuarios obtienen SpeedCoins
                exclusivamente a través de su participación en las carreras.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Send Coins Modal */}
      {sendCoinsOpen && (
        <SendSpeedCoins onClose={handleSendCoinsToggle} user={user} />
      )}

      {/* Transaction History */}
      <SpeedCoinHistory user={user} />

      {/* QR Code Modal */}
      {qrOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl p-6 max-w-md w-full">
            <div className="flex justify-end mb-2">
              <button
                onClick={handleQrClose}
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-6 w-6 text-gray-500" />
              </button>
            </div>
            <div className="flex justify-center mb-6">
              <QRCode
                value={user.uid}
                size={256}
                level="H"
                includeMargin={true}
                className="rounded-lg"
              />
            </div>
            <button
              onClick={handleQrClose}
              className="w-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 py-2 rounded-lg"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletComponent;
