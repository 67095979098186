import React, { useState, useRef, useCallback, useMemo } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  MapPin,
  Satellite,
  Mountain,
  Map,
  Navigation,
  Plus,
  Minus,
} from "lucide-react";

const GoogleMapSection = ({ location }) => {
  const [mapType, setMapType] = useState("satellite");
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null);

  const toggleMapType = (type) => {
    setMapType(type);
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
  }, []);

  const goToLocation = () => {
    // Abre Google Maps en una nueva pestaña con las coordenadas especificadas
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  const zoomIn = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() + 1);
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapRef.current.getZoom() - 1);
    }
  };

  // Estilos personalizados para el mapa
  const customMapStyles = [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [{ color: "#444444" }],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [{ color: "#c2e4fb" }, { visibility: "on" }],
    },
  ];

  // Custom marker icon - only create it when Google Maps is fully loaded
  const markerIcon = useMemo(() => {
    if (!window.google || !window.google.maps) return null;

    return {
      path: "M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z",
      fillColor: "#3B82F6",
      fillOpacity: 0.9,
      strokeWeight: 2,
      strokeColor: "#1E40AF",
      scale: 2,
      anchor: new window.google.maps.Point(12, 22),
    };
  }, [mapLoaded]);

  return (
    <div className="relative w-full h-full rounded-xl overflow-hidden shadow-md border border-gray-100">
      {/* Botón para ir a la ubicación del evento */}
      <div className="absolute top-4 left-4 z-10">
        <button
          onClick={goToLocation}
          className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg shadow-md flex items-center gap-2 transition-all duration-200 transform hover:scale-105"
        >
          <MapPin className="w-4 h-4" />
          <span>ir al evento</span>
        </button>
      </div>

      {/* Selector de tipo de mapa */}
      <div className="absolute top-4 right-4 z-10 bg-white rounded-lg shadow-md flex divide-x divide-gray-200">
        <button
          className={`p-2 flex items-center gap-1 ${
            mapType === "roadmap"
              ? "bg-blue-50 text-blue-600"
              : "text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => toggleMapType("roadmap")}
          title="Mapa estándar"
        >
          <Map className="w-4 h-4" />
        </button>
        <button
          className={`p-2 flex items-center gap-1 ${
            mapType === "satellite"
              ? "bg-blue-50 text-blue-600"
              : "text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => toggleMapType("satellite")}
          title="Vista satélite"
        >
          <Satellite className="w-4 h-4" />
        </button>
        <button
          className={`p-2 flex items-center gap-1 ${
            mapType === "terrain"
              ? "bg-blue-50 text-blue-600"
              : "text-gray-700 hover:bg-gray-50"
          }`}
          onClick={() => toggleMapType("terrain")}
          title="Terreno"
        >
          <Mountain className="w-4 h-4" />
        </button>
      </div>

      {/* Controles personalizados de zoom */}
      <div className="absolute top-20 right-4 z-10 flex flex-col bg-white rounded-lg overflow-hidden shadow-md">
        <button
          onClick={zoomIn}
          className="p-2 text-gray-700 hover:bg-gray-50 border-b border-gray-200"
          title="Acercar"
        >
          <Plus className="w-4 h-4" />
        </button>
        <button
          onClick={zoomOut}
          className="p-2 text-gray-700 hover:bg-gray-50"
          title="Alejar"
        >
          <Minus className="w-4 h-4" />
        </button>
      </div>

      <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            borderRadius: "12px",
          }}
          center={{
            lat: location.lat,
            lng: location.lng,
          }}
          zoom={14}
          mapTypeId={mapType}
          onLoad={onMapLoad}
          options={{
            disableDefaultUI: true, // Desactivamos todos los controles para usar los nuestros
            styles: mapType === "roadmap" ? customMapStyles : [],
            gestureHandling: "greedy", // Permite zoom con un solo dedo en móviles
            minZoom: 3,
            maxZoom: 20,
            tilt: 45, // Ángulo de inclinación para vista 3D en satélite
            rotateControl: true, // Permite rotar el mapa
          }}
        >
          {mapLoaded && (
            <Marker
              position={{
                lat: location.lat,
                lng: location.lng,
              }}
              animation={2} // Drop animation
              icon={markerIcon}
            />
          )}
        </GoogleMap>
      </LoadScript>

      {/* Brújula / indicador de dirección */}
      <div className="absolute bottom-16 right-4 z-10">
        <div className="bg-white p-2 rounded-full shadow-md">
          <Navigation className="w-6 h-6 text-gray-600" />
        </div>
      </div>

      {/* Atribución */}
      <div className="absolute bottom-4 right-4 bg-white py-1 px-3 rounded-full shadow-md text-xs text-gray-600">
        © Google Maps
      </div>
    </div>
  );
};

export default GoogleMapSection;
