import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    onSnapshot,
} from "firebase/firestore";
import format from "date-fns/format";
import { parseISO, differenceInDays, subDays } from "date-fns";
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, AreaChart, Area } from 'recharts';
import { TrendingUp, TrendingDown, DollarSign, ShoppingBag, Calendar, BarChart2, PieChart as PieChartIcon, Activity, Filter, Clock } from 'lucide-react';

const PaymentMethods = Object.freeze({
    1: "SpeedCoins",
    2: "Stripe",
    3: "MercadoPago",
});

const OrderStats = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeRange, setTimeRange] = useState("week"); // week, month, year
    const [chartType, setChartType] = useState("line"); // line, bar, pie, area
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [progressMetrics, setProgressMetrics] = useState({
        ordersToday: 0,
        ordersTodayChangePercent: 0,
        revenueToday: 0,
        revenueTodayChangePercent: 0,
        dailyProgress: []
    });
    const [stats, setStats] = useState({
        totalOrders: 0,
        totalRevenue: 0,
        ordersByDate: [],
        ordersByEvent: [],
        ordersByPaymentMethod: []
    });

    // Colors for different events and charts
    const COLORS = [
        "#8884d8", "#82ca9d", "#ffc658", "#ff8042", "#0088FE",
        "#00C49F", "#FFBB28", "#FF8042", "#a4de6c", "#d0ed57"
    ];

    // Gradient colors for AreaChart
    const gradientOffset = () => {
        const dataMax = Math.max(...stats.ordersByDate.map((i) => i.revenue));
        const dataMin = Math.min(...stats.ordersByDate.map((i) => i.revenue));

        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "orders"), (snapshot) => {
            fetchOrdersWithDetails(snapshot);
        });

        return () => unsubscribe();
    }, [timeRange, selectedEvents, selectedStatuses]);

    const fetchOrdersWithDetails = async (snapshot) => {
        setLoading(true);
        try {
            const ordersData = await Promise.all(
                snapshot.docs.map(async (orderDoc) => {
                    const orderData = { id: orderDoc.id, ...orderDoc.data() };

                    // Retrieve category and item names for display purposes
                    const categoryRef = doc(
                        db,
                        "events",
                        orderData.itemId,
                        "categories",
                        orderData.categoryId
                    );
                    const categorySnap = await getDoc(categoryRef);
                    orderData.categoryName = categorySnap.exists()
                        ? categorySnap.data().name
                        : "No disponible";

                    const itemRef = doc(db, "events", orderData.itemId);
                    const itemSnap = await getDoc(itemRef);
                    orderData.itemName = itemSnap.exists()
                        ? itemSnap.data().eventName
                        : "Evento no disponible";

                    // Get user email and name from Users/(UID)
                    const userRef = doc(db, "Users", orderData.userId);
                    const userSnap = await getDoc(userRef);
                    if (userSnap.exists()) {
                        const userData = userSnap.data();
                        orderData.userEmail = userData.email || "Correo no disponible";
                        orderData.userName = userData.name || "Nombre no disponible";
                    } else {
                        orderData.userEmail = "Correo no disponible";
                        orderData.userName = "Nombre no disponible";
                    }

                    // Format creation date
                    orderData.createdAtFormatted = orderData.createdAt
                        ? format(orderData.createdAt.toDate(), "dd/MM/yyyy")
                        : "Fecha no disponible";

                    // Add hour for daily progress tracking
                    orderData.createdAtTime = orderData.createdAt
                        ? format(orderData.createdAt.toDate(), "HH:mm")
                        : "00:00";

                    orderData.createdAtHour = orderData.createdAt
                        ? format(orderData.createdAt.toDate(), "HH")
                        : "00";

                    return orderData;
                })
            );

            // Extract available events
            const eventsMap = new Map();
            ordersData.forEach(order => {
                if (order.itemId && order.itemName) {
                    eventsMap.set(order.itemId, order.itemName);
                }
            });
            setAvailableEvents(Array.from(eventsMap.entries()).map(([id, name]) => ({ id, name })));

            // Apply all filters
            const filteredOrders = ordersData.filter(order => {
                // Time filter
                if (!order.createdAt) return false;
                const orderDate = order.createdAt.toDate();
                const now = new Date();
                let startDate;

                switch (timeRange) {
                    case "week":
                        startDate = new Date(now);
                        startDate.setDate(now.getDate() - 7);
                        break;
                    case "month":
                        startDate = new Date(now);
                        startDate.setMonth(now.getMonth() - 1);
                        break;
                    case "year":
                        startDate = new Date(now);
                        startDate.setFullYear(now.getFullYear() - 1);
                        break;
                    default:
                        startDate = new Date(now);
                        startDate.setDate(now.getDate() - 7);
                }

                const timeMatch = orderDate >= startDate;

                // Event filter
                const eventMatch = selectedEvents.length === 0 || selectedEvents.includes(order.itemId);

                // Status filter
                const statusMatch = selectedStatuses.length === 0 || selectedStatuses.includes(order.status);

                return timeMatch && eventMatch && statusMatch;
            });

            setOrders(filteredOrders);

            // Calculate statistics
            calculateStats(filteredOrders);

            // Calculate daily progress metrics
            calculateDailyProgress(filteredOrders);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle event selection
    const toggleEventSelection = (eventId) => {
        setSelectedEvents(prev =>
            prev.includes(eventId)
                ? prev.filter(id => id !== eventId)
                : [...prev, eventId]
        );
    };

    // Function to handle status selection
    const toggleStatusSelection = (status) => {
        setSelectedStatuses(prev =>
            prev.includes(status)
                ? prev.filter(s => s !== status)
                : [...prev, status]
        );
    };

    // Calculate daily progress metrics
    const calculateDailyProgress = (filteredOrders) => {
        const today = new Date();
        const yesterday = subDays(today, 1);

        const todayStr = format(today, "dd/MM/yyyy");
        const yesterdayStr = format(yesterday, "dd/MM/yyyy");

        // Filter orders for today and yesterday
        const todayOrders = filteredOrders.filter(order =>
            order.createdAtFormatted === todayStr
        );

        const yesterdayOrders = filteredOrders.filter(order =>
            order.createdAtFormatted === yesterdayStr
        );

        // Calculate totals
        const ordersToday = todayOrders.length;
        const ordersYesterday = yesterdayOrders.length;

        const revenueToday = todayOrders.reduce((sum, order) => sum + (order.total || 0), 0);
        const revenueYesterday = yesterdayOrders.reduce((sum, order) => sum + (order.total || 0), 0);

        // Calculate percent changes
        const ordersTodayChangePercent = ordersYesterday === 0
            ? 100
            : ((ordersToday - ordersYesterday) / ordersYesterday * 100);

        const revenueTodayChangePercent = revenueYesterday === 0
            ? 100
            : ((revenueToday - revenueYesterday) / revenueYesterday * 100);

        // Group orders by hour for today
        const hourlyOrdersMap = new Map();

        // Initialize all hours
        for (let i = 0; i < 24; i++) {
            const hourStr = i.toString().padStart(2, '0');
            hourlyOrdersMap.set(hourStr, {
                hour: hourStr,
                hourFormatted: `${hourStr}:00`,
                orders: 0,
                revenue: 0
            });
        }

        // Fill in actual data
        todayOrders.forEach(order => {
            const hour = order.createdAtHour;

            if (hourlyOrdersMap.has(hour)) {
                const hourData = hourlyOrdersMap.get(hour);
                hourData.orders += 1;
                hourData.revenue += order.total || 0;
            }
        });

        // Convert to array and sort by hour
        const dailyProgress = Array.from(hourlyOrdersMap.values())
            .sort((a, b) => a.hour.localeCompare(b.hour));

        setProgressMetrics({
            ordersToday,
            ordersTodayChangePercent,
            revenueToday,
            revenueTodayChangePercent,
            dailyProgress
        });
    };

    const calculateStats = (filteredOrders) => {
        // Total orders
        const totalOrders = filteredOrders.length;

        // Total revenue
        const totalRevenue = filteredOrders.reduce((sum, order) => sum + (order.total || 0), 0);

        // Group orders by date
        const ordersByDateMap = new Map();
        filteredOrders.forEach(order => {
            if (!order.createdAt) return;

            const date = format(order.createdAt.toDate(), "dd/MM/yyyy");
            if (!ordersByDateMap.has(date)) {
                ordersByDateMap.set(date, {
                    date,
                    orders: 0,
                    revenue: 0,
                    events: new Map()
                });
            }

            const dateEntry = ordersByDateMap.get(date);
            dateEntry.orders += 1;
            dateEntry.revenue += order.total || 0;

            // Count by event
            if (order.itemId) {
                if (!dateEntry.events.has(order.itemId)) {
                    dateEntry.events.set(order.itemId, {
                        id: order.itemId,
                        name: order.itemName || "Evento desconocido",
                        count: 0,
                        revenue: 0
                    });
                }

                const eventEntry = dateEntry.events.get(order.itemId);
                eventEntry.count += 1;
                eventEntry.revenue += order.total || 0;
            }
        });

        // Convert to array for chart and sort by date
        const ordersByDate = Array.from(ordersByDateMap.values()).map(entry => ({
            date: entry.date,
            orders: entry.orders,
            revenue: entry.revenue,
            events: Array.from(entry.events.values())
        })).sort((a, b) => {
            const dateA = new Date(a.date.split('/').reverse().join('-'));
            const dateB = new Date(b.date.split('/').reverse().join('-'));
            return dateA - dateB;
        });

        // Group orders by event
        const ordersByEventMap = new Map();
        filteredOrders.forEach(order => {
            if (!order.itemId) return;

            const eventId = order.itemId;
            if (!ordersByEventMap.has(eventId)) {
                ordersByEventMap.set(eventId, {
                    id: eventId,
                    name: order.itemName || "Evento desconocido",
                    orders: 0,
                    revenue: 0
                });
            }

            const eventEntry = ordersByEventMap.get(eventId);
            eventEntry.orders += 1;
            eventEntry.revenue += order.total || 0;
        });

        // Convert to array for chart
        const ordersByEvent = Array.from(ordersByEventMap.values());

        // Group orders by payment method
        const ordersByPaymentMethodMap = new Map();
        filteredOrders.forEach(order => {
            if (!order.paymentMethod) return;

            const paymentMethod = order.paymentMethod.toString();
            const methodName = PaymentMethods[paymentMethod] || "Desconocido";

            if (!ordersByPaymentMethodMap.has(methodName)) {
                ordersByPaymentMethodMap.set(methodName, {
                    name: methodName,
                    orders: 0,
                    revenue: 0
                });
            }

            const methodEntry = ordersByPaymentMethodMap.get(methodName);
            methodEntry.orders += 1;
            methodEntry.revenue += order.total || 0;
        });

        // Convert to array for chart
        const ordersByPaymentMethod = Array.from(ordersByPaymentMethodMap.values());

        setStats({
            totalOrders,
            totalRevenue,
            ordersByDate,
            ordersByEvent,
            ordersByPaymentMethod
        });
    };

    const renderLineChart = () => (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart
                data={stats.ordersByDate}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip
                    contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        borderRadius: '8px',
                        borderColor: '#f0f0f0',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <Legend />
                <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="orders"
                    name="Órdenes"
                    stroke="#8884d8"
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8, stroke: '#8884d8', strokeWidth: 2, fill: '#fff' }}
                />
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="revenue"
                    name="Ingresos ($)"
                    stroke="#82ca9d"
                    strokeWidth={2}
                    dot={{ r: 4 }}
                    activeDot={{ r: 8, stroke: '#82ca9d', strokeWidth: 2, fill: '#fff' }}
                />
            </LineChart>
        </ResponsiveContainer>
    );

    const renderAreaChart = () => (
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart
                data={stats.ordersByDate}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <defs>
                    <linearGradient id="colorOrders" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1} />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip
                    contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        borderRadius: '8px',
                        borderColor: '#f0f0f0',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <Legend />
                <Area
                    yAxisId="left"
                    type="monotone"
                    dataKey="orders"
                    name="Órdenes"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorOrders)"
                />
                <Area
                    yAxisId="right"
                    type="monotone"
                    dataKey="revenue"
                    name="Ingresos ($)"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#colorRevenue)"
                />
            </AreaChart>
        </ResponsiveContainer>
    );

    const renderBarChart = () => (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={stats.ordersByEvent}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip
                    contentStyle={{
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                        borderRadius: '8px',
                        borderColor: '#f0f0f0',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                    }}
                />
                <Legend />
                <Bar dataKey="orders" name="Cantidad de Órdenes" fill="#8884d8" radius={[4, 4, 0, 0]} />
                <Bar dataKey="revenue" name="Ingresos ($)" fill="#82ca9d" radius={[4, 4, 0, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );

    const renderPieChart = () => (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow-md p-4">
                <h3 className="text-center text-gray-700 font-medium mb-4 flex items-center justify-center">
                    <PieChartIcon size={18} className="mr-2 text-indigo-500" />
                    Distribución por Evento
                </h3>
                <ResponsiveContainer width="100%" height={280}>
                    <PieChart>
                        <Pie
                            data={stats.ordersByEvent}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="orders"
                            nameKey="name"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        >
                            {stats.ordersByEvent.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value, name, props) => [`${value} órdenes`, props.payload.name]}
                            contentStyle={{
                                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                borderRadius: '8px',
                                borderColor: '#f0f0f0',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4">
                <h3 className="text-center text-gray-700 font-medium mb-4 flex items-center justify-center">
                    <DollarSign size={18} className="mr-2 text-green-500" />
                    Distribución por Método de Pago
                </h3>
                <ResponsiveContainer width="100%" height={280}>
                    <PieChart>
                        <Pie
                            data={stats.ordersByPaymentMethod}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="orders"
                            nameKey="name"
                            label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        >
                            {stats.ordersByPaymentMethod.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[(index + 5) % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip
                            formatter={(value, name, props) => [`${value} órdenes`, props.payload.name]}
                            contentStyle={{
                                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                borderRadius: '8px',
                                borderColor: '#f0f0f0',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    );

    const renderDailyProgressChart = () => (
        <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                <Activity size={20} className="mr-2 text-blue-500" />
                Progreso Diario (Hoy)
            </h2>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    data={progressMetrics.dailyProgress}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <defs>
                        <linearGradient id="colorDailyOrders" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
                        </linearGradient>
                        <linearGradient id="colorDailyRevenue" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                    <XAxis dataKey="hourFormatted" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            borderRadius: '8px',
                            borderColor: '#f0f0f0',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                        }}
                    />
                    <Legend />
                    <Area
                        yAxisId="left"
                        type="monotone"
                        dataKey="orders"
                        name="Órdenes por Hora"
                        stroke="#8884d8"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorDailyOrders)"
                    />
                    <Area
                        yAxisId="right"
                        type="monotone"
                        dataKey="revenue"
                        name="Ingresos por Hora ($)"
                        stroke="#82ca9d"
                        strokeWidth={2}
                        fillOpacity={1}
                        fill="url(#colorDailyRevenue)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );

    const renderEventColorLegend = () => (
        <div className="bg-white p-4 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
                <Filter size={20} className="mr-2 text-purple-500" />
                Leyenda de Eventos
            </h2>
            <div className="flex flex-wrap gap-3">
                {stats.ordersByEvent.map((event, index) => (
                    <div key={index} className="flex items-center bg-gray-50 px-3 py-2 rounded-md">
                        <div
                            className="w-4 h-4 mr-2 rounded-full"
                            style={{ backgroundColor: COLORS[index % COLORS.length] }}
                        ></div>
                        <span className="text-sm font-medium">{event.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            {/* Header */}
            <div className="flex flex-col md:flex-row justify-between items-center mb-6 bg-white p-5 rounded-xl shadow-md">
                <h1 className="text-2xl font-bold text-gray-800 mb-4 md:mb-0 flex items-center">
                    <BarChart2 size={24} className="mr-2 text-indigo-600" />
                    Estadísticas de Órdenes
                </h1>
                <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-4 w-full md:w-auto">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <Calendar size={18} className="text-gray-500" />
                        </div>
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                            value={timeRange}
                            onChange={(e) => setTimeRange(e.target.value)}
                        >
                            <option value="week">Última Semana</option>
                            <option value="month">Último Mes</option>
                            <option value="year">Último Año</option>
                        </select>
                    </div>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BarChart2 size={18} className="text-gray-500" />
                        </div>
                        <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                            value={chartType}
                            onChange={(e) => setChartType(e.target.value)}
                        >
                            <option value="line">Gráfico Lineal</option>
                            <option value="bar">Gráfico de Barras</option>
                            <option value="area">Gráfico de Área</option>
                            <option value="pie">Gráfico Circular</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Filters */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 bg-white p-5 rounded-xl shadow-md">
                {/* Event filter */}
                <div>
                    <h3 className="font-medium text-gray-800 mb-3 flex items-center">
                        <Filter size={18} className="mr-2 text-indigo-500" />
                        Filtrar por Eventos
                    </h3>
                    <div className="flex flex-wrap gap-2">
                        {availableEvents.map((event) => (
                            <button
                                key={event.id}
                                onClick={() => toggleEventSelection(event.id)}
                                className={`px-3 py-1.5 rounded-full text-xs font-medium transition-all ${selectedEvents.includes(event.id)
                                        ? "bg-indigo-500 text-white shadow-sm"
                                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                    }`}>
                                {event.name}
                            </button>
                        ))}
                        {selectedEvents.length > 0 && (
                            <button
                                onClick={() => setSelectedEvents([])}
                                className="px-3 py-1.5 rounded-full text-xs font-medium bg-red-500 text-white hover:bg-red-600 transition-all shadow-sm"
                            >
                                Limpiar filtros
                            </button>
                        )}
                    </div>
                </div>

                {/* Status filter */}
                <div>
                    <h3 className="font-medium text-gray-800 mb-3 flex items-center">
                        <Filter size={18} className="mr-2 text-green-500" />
                        Filtrar por Estado
                    </h3>
                    <div className="flex gap-2">
                        <button
                            onClick={() => toggleStatusSelection("approved")}
                            className={`px-3 py-1.5 rounded-full text-xs font-medium transition-all ${selectedStatuses.includes("approved")
                                    ? "bg-green-500 text-white shadow-sm"
                                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            Aprobado
                        </button>
                        <button
                            onClick={() => toggleStatusSelection("in_process")}
                            className={`px-3 py-1.5 rounded-full text-xs font-medium transition-all ${selectedStatuses.includes("in_process")
                                    ? "bg-blue-500 text-white shadow-sm"
                                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            En Proceso
                        </button>
                        {selectedStatuses.length > 0 && (
                            <button
                                onClick={() => setSelectedStatuses([])}
                                className="px-3 py-1.5 rounded-full text-xs font-medium bg-red-500 text-white hover:bg-red-600 transition-all shadow-sm"
                            >
                                Limpiar filtros
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {/* Stat Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <div className="bg-white p-5 rounded-xl shadow-md transform transition-all hover:scale-105">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="text-sm text-gray-500 mb-1">Total de Órdenes</p>
                            <h2 className="text-3xl font-bold text-indigo-600">{stats.totalOrders}</h2>
                        </div>
                        <div className="p-2 rounded-lg bg-indigo-100">
                            <ShoppingBag size={24} className="text-indigo-600" />
                        </div>
                    </div>
                </div>

                <div className="bg-white p-5 rounded-xl shadow-md transform transition-all hover:scale-105">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="text-sm text-gray-500 mb-1">Ingresos Totales</p>
                            <h2 className="text-3xl font-bold text-green-600">${stats.totalRevenue.toFixed(2)}</h2>
                        </div>
                        <div className="p-2 rounded-lg bg-green-100">
                            <DollarSign size={24} className="text-green-600" />
                        </div>
                    </div>
                </div>

                <div className="bg-white p-5 rounded-xl shadow-md transform transition-all hover:scale-105">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="text-sm text-gray-500 mb-1">Órdenes Hoy</p>
                            <h2 className="text-3xl font-bold text-blue-600">{progressMetrics.ordersToday}</h2>
                            <p className={`text-sm mt-1 flex items-center ${progressMetrics.ordersTodayChangePercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                {progressMetrics.ordersTodayChangePercent >= 0 ?
                                    <TrendingUp size={16} className="mr-1" /> :
                                    <TrendingDown size={16} className="mr-1" />
                                }
                                {Math.abs(progressMetrics.ordersTodayChangePercent).toFixed(1)}% vs ayer
                            </p>
                        </div>
                        <div className="p-2 rounded-lg bg-blue-100">
                            <Clock size={24} className="text-blue-600" />
                        </div>
                    </div>
                </div>

                <div className="bg-white p-5 rounded-xl shadow-md transform transition-all hover:scale-105">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="text-sm text-gray-500 mb-1">Ingresos Hoy</p>
                            <h2 className="text-3xl font-bold text-purple-600">${progressMetrics.revenueToday.toFixed(2)}</h2>
                            <p className={`text-sm mt-1 flex items-center ${progressMetrics.revenueTodayChangePercent >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                {progressMetrics.revenueTodayChangePercent >= 0 ?
                                    <TrendingUp size={16} className="mr-1" /> :
                                    <TrendingDown size={16} className="mr-1" />
                                }
                                {Math.abs(progressMetrics.revenueTodayChangePercent).toFixed(1)}% vs ayer
                            </p>
                        </div>
                        <div className="p-2 rounded-lg bg-purple-100">
                            <Activity size={24} className="text-purple-600" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Daily Progress Chart */}
            {renderDailyProgressChart()}

            {/* Event Color Legend */}
            {stats.ordersByEvent.length > 0 && renderEventColorLegend()}

            {/* Charts */}
            <div className="bg-white p-5 rounded-xl shadow-md mb-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                    {chartType === "line" ? (
                        <><TrendingUp size={20} className="mr-2 text-indigo-500" />Tendencia de Órdenes e Ingresos</>
                    ) : chartType === "bar" ? (
                        <><BarChart2 size={20} className="mr-2 text-indigo-500" />Órdenes e Ingresos por Evento</>
                    ) : chartType === "area" ? (
                        <><Activity size={20} className="mr-2 text-indigo-500" />Área de Órdenes e Ingresos</>
                    ) : (
                        <><PieChartIcon size={20} className="mr-2 text-indigo-500" />Distribución de Órdenes</>
                    )}
                </h2>

                {loading ? (
                    <div className="flex justify-center items-center p-16">
                        <div className="loader relative w-16 h-16">
                            <div className="absolute border-4 border-solid border-gray-200 rounded-full h-16 w-16"></div>
                            <div className="absolute border-4 border-solid border-indigo-500 border-t-transparent rounded-full h-16 w-16 animate-spin"></div>
                        </div>
                    </div>
                ) : (
                    <>
                        {chartType === "line" && renderLineChart()}
                        {chartType === "bar" && renderBarChart()}
                        {chartType === "area" && renderAreaChart()}
                        {chartType === "pie" && renderPieChart()}
                    </>
                )}
            </div>

            {/* Order Details by Date */}
            {(chartType === "line" || chartType === "area") && (
                <div className="bg-white p-5 rounded-xl shadow-md overflow-auto mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                        <Calendar size={20} className="mr-2 text-indigo-500" />
                        Detalle de Órdenes por Fecha
                    </h2>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-100 text-gray-700 text-sm leading-normal rounded-lg">
                                    <th className="py-3 px-6 text-left font-semibold">Fecha</th>
                                    <th className="py-3 px-6 text-left font-semibold">Total de Órdenes</th>
                                    <th className="py-3 px-6 text-left font-semibold">Ingresos</th>
                                    <th className="py-3 px-6 text-left font-semibold">Eventos</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700 text-sm">
                                {stats.ordersByDate.map((dateData, index) => (
                                    <tr key={index} className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                                        <td className="py-3 px-6 font-medium">{dateData.date}</td>
                                        <td className="py-3 px-6">{dateData.orders}</td>
                                        <td className="py-3 px-6 font-medium text-green-600">${dateData.revenue.toFixed(2)}</td>
                                        <td className="py-3 px-6">
                                            <div className="flex flex-wrap gap-1">
                                                {dateData.events.map((event, idx) => (
                                                    <span
                                                        key={idx}
                                                        className="inline-block px-2 py-1 rounded-full text-xs text-white shadow-sm"
                                                        style={{ backgroundColor: COLORS[idx % COLORS.length] }}
                                                    >
                                                        {event.name.length > 15 ? `${event.name.substring(0, 15)}...` : event.name} ({event.count})
                                                    </span>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {/* All Orders Table */}
            <div className="bg-white p-5 rounded-xl shadow-md overflow-auto">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
                    <h2 className="text-xl font-semibold text-gray-800 flex items-center mb-3 md:mb-0">
                        <ShoppingBag size={20} className="mr-2 text-indigo-500" />
                        Todas las Órdenes <span className="ml-2 px-2 py-1 bg-indigo-100 text-indigo-700 rounded-lg text-sm">{orders.length}</span>
                    </h2>
                    <div className="flex flex-wrap gap-2">
                        {selectedEvents.length > 0 && (
                            <span className="px-3 py-1 bg-indigo-100 text-indigo-700 rounded-full text-sm flex items-center">
                                <Filter size={14} className="mr-1" />
                                Eventos: {selectedEvents.length}
                            </span>
                        )}
                        {selectedStatuses.length > 0 && (
                            <span className="px-3 py-1 bg-green-100 text-green-700 rounded-full text-sm flex items-center">
                                <Filter size={14} className="mr-1" />
                                Estados: {selectedStatuses.map(s => s === "approved" ? "Aprobado" : "En Proceso").join(", ")}
                            </span>
                        )}
                    </div>
                </div>
                <div className="overflow-x-auto rounded-lg border border-gray-200">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-gray-100 text-gray-700 text-sm leading-normal">
                                <th className="py-3 px-6 text-left font-semibold">ID</th>
                                <th className="py-3 px-6 text-left font-semibold">Usuario</th>
                                <th className="py-3 px-6 text-left font-semibold">Evento</th>
                                <th className="py-3 px-6 text-left font-semibold">Categoría</th>
                                <th className="py-3 px-6 text-left font-semibold">Método de Pago</th>
                                <th className="py-3 px-6 text-left font-semibold">Estado</th>
                                <th className="py-3 px-6 text-left font-semibold">Total</th>
                                <th className="py-3 px-6 text-left font-semibold">Fecha</th>
                                <th className="py-3 px-6 text-left font-semibold">Hora</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700 text-sm">
                            {orders.map((order, index) => {
                                // Determine color index for this event
                                const eventIndex = stats.ordersByEvent.findIndex(e => e.id === order.itemId);
                                const colorIndex = eventIndex >= 0 ? eventIndex : index;

                                return (
                                    <tr key={order.id} className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                                        <td className="py-3 px-6 font-medium">{order.id.slice(0, 5)}...</td>
                                        <td className="py-3 px-6">{order.userName}</td>
                                        <td className="py-3 px-6">
                                            <span
                                                className="inline-block px-2 py-1 rounded-full text-xs text-white shadow-sm"
                                                style={{ backgroundColor: COLORS[colorIndex % COLORS.length] }}
                                            >
                                                {order.itemName}
                                            </span>
                                        </td>
                                        <td className="py-3 px-6">{order.categoryName}</td>
                                        <td className="py-3 px-6">
                                            {PaymentMethods[order.paymentMethod] || "No disponible"}
                                        </td>
                                        <td className="py-3 px-6">
                                            <span
                                                className={`px-3 py-1 rounded-full text-xs font-medium ${order.status === "approved"
                                                        ? "bg-green-100 text-green-700"
                                                        : "bg-blue-100 text-blue-700"
                                                    }`}
                                            >
                                                {order.status === "approved" ? "Aprobado" : "En Proceso"}
                                            </span>
                                        </td>
                                        <td className="py-3 px-6 font-bold text-green-600">${order.total}</td>
                                        <td className="py-3 px-6 text-gray-600">
                                            {order.createdAtFormatted}
                                        </td>
                                        <td className="py-3 px-6 text-gray-600">
                                            {order.createdAtTime}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OrderStats;