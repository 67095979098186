import React from "react";
import { MessageSquare, Search, RefreshCw, X, ArrowUpDown, AlertCircle } from "lucide-react";
import ChatListItem from "./ChatListItem";

const ChatSidebar = ({
    errorMessage,
    debugInfo,
    searchQuery,
    setSearchQuery,
    filterType,
    handleFilterChange,
    sortOrder,
    handleToggleSort,
    chatCounts,
    isLoading,
    filteredChats,
    handleRefresh,
    loadChatMessages,
    selectedChatId
}) => {
    return (
        <div className="w-full md:w-1/3 lg:w-1/4 bg-white overflow-hidden flex flex-col border-r">
            <div className="p-4 border-b">
                <div className="flex justify-between items-center mb-2">
                    <h1 className="text-xl font-bold">Chats de Soporte</h1>
                    <button
                        onClick={handleRefresh}
                        className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full"
                        aria-label="Actualizar chats"
                    >
                        <RefreshCw size={18} />
                    </button>
                </div>

                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-2 rounded-lg mb-2 text-sm flex items-center">
                        <AlertCircle size={16} className="mr-1 flex-shrink-0" />
                        <span>{errorMessage}</span>
                    </div>
                )}

                {/* Debug info */}
                <div className="bg-gray-100 p-2 rounded-lg mb-2 text-xs text-gray-700">
                    <div className="font-semibold">Estado:</div>
                    <div>{debugInfo}</div>
                </div>

                {/* Search bar */}
                <div className="relative mb-3">
                    <input
                        type="text"
                        placeholder="Buscar chats..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full py-2 pl-10 pr-4 rounded-lg border focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
                    />
                    <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
                    {searchQuery && (
                        <button
                            onClick={() => setSearchQuery("")}
                            className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                        >
                            <X size={18} />
                        </button>
                    )}
                </div>

                {/* Filters */}
                <div className="flex items-center justify-between mb-2">
                    <div className="flex space-x-1">
                        <button
                            onClick={() => handleFilterChange("all")}
                            className={`px-2 py-1 text-xs rounded-md ${filterType === "all"
                                ? "bg-blue-600 text-white"
                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            Todos ({chatCounts.total})
                        </button>
                        <button
                            onClick={() => handleFilterChange("user")}
                            className={`px-2 py-1 text-xs rounded-md ${filterType === "user"
                                ? "bg-blue-600 text-white"
                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            Usuarios ({chatCounts.user})
                        </button>
                        <button
                            onClick={() => handleFilterChange("guest")}
                            className={`px-2 py-1 text-xs rounded-md ${filterType === "guest"
                                ? "bg-blue-600 text-white"
                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            Invitados ({chatCounts.guest})
                        </button>
                        <button
                            onClick={() => handleFilterChange("unread")}
                            className={`px-2 py-1 text-xs rounded-md ${filterType === "unread"
                                ? "bg-blue-600 text-white"
                                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                }`}
                        >
                            No leídos ({chatCounts.unread})
                        </button>
                    </div>

                    <div className="flex items-center">
                        <button
                            onClick={handleToggleSort}
                            className="p-1 text-gray-600 hover:text-gray-900 tooltip-container"
                            aria-label="Cambiar orden"
                        >
                            <ArrowUpDown size={18} />
                            <span className="tooltip">
                                {sortOrder === "newest" ? "Más recientes primero" : "Más antiguos primero"}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Chat list */}
            <div className="overflow-y-auto flex-1">
                {isLoading ? (
                    <div className="flex items-center justify-center h-40">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                    </div>
                ) : filteredChats.length === 0 ? (
                    <div className="text-center p-8 text-gray-500">
                        <MessageSquare size={48} className="mx-auto mb-4 opacity-30" />
                        <p>No se encontraron chats</p>
                        {searchQuery && (
                            <p className="text-sm mt-2">
                                Intenta con otra búsqueda o elimina los filtros
                            </p>
                        )}
                        {!searchQuery && (
                            <div>
                                <p className="text-sm mt-2">
                                    No hay chats disponibles en la base de datos
                                </p>
                                <button
                                    onClick={handleRefresh}
                                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                                >
                                    Intentar nuevamente
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    filteredChats.map((chat) => (
                        <ChatListItem
                            key={chat.id}
                            chat={chat}
                            isActive={chat.id === selectedChatId}
                            onClick={loadChatMessages}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default ChatSidebar;