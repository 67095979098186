import React, { useState, useEffect, useRef } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Categories = ({ onSelectCategory }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    fetchCategories();

    // Set up responsive check
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };

    // Initial check
    checkIfMobile();

    // Add event listener for resize
    window.addEventListener("resize", checkIfMobile);

    // Clean up
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const fetchCategories = async () => {
    const categoriesRef = collection(db, "Categories");
    onSnapshot(categoriesRef, (snapshot) => {
      const categoriesList = [];
      snapshot.docs.forEach((doc) => {
        const { type, catPic } = doc.data();
        categoriesList.push({ id: doc.id, type, catPic });
      });
      setCategories(categoriesList);
    });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
    onSelectCategory(category.id);
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 3 : 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 10,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  return (
    <div className="container mx-auto px-4 mb-8 relative">
      <h2 className="text-xl font-bold mb-4">Categorías</h2>
      <div className="relative">
        {/* Left arrow */}
        <button
          onClick={previous}
          className="absolute -left-5 sm:-left-10 top-1/2 -translate-y-1/2 z-10 w-8 h-8 sm:w-10 sm:h-10 bg-white rounded-full shadow-md flex items-center justify-center border border-gray-200 hover:bg-black hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-black"
          aria-label="Previous categories"
        >
          <ChevronLeft className="w-5 h-5 text-gray-700" />
        </button>

        <Slider ref={sliderRef} {...settings}>
          {categories.map((category) => (
            <div key={category.id} className="px-1 sm:px-2">
              <button
                onClick={() => handleCategoryClick(category)}
                className={`w-full aspect-square max-w-[110px] mx-auto rounded-xl shadow-md flex flex-col items-center justify-center transition-all duration-300 hover:-translate-y-1 hover:shadow-lg ${
                  selectedCategory === category.id
                    ? "bg-black text-white"
                    : "bg-gray-100 text-gray-800 hover:bg-gray-200"
                }`}
              >
                <div className="relative w-12 h-12 sm:w-14 sm:h-14 mb-1 flex items-center justify-center">
                  <img
                    src={category.catPic}
                    alt={category.type}
                    className={`w-full h-full object-contain transition-all duration-300 ${
                      selectedCategory === category.id
                        ? "filter brightness-0 invert"
                        : ""
                    }`}
                  />
                </div>
                <p
                  className={`text-xs sm:text-sm font-medium text-center truncate w-full px-2 ${
                    selectedCategory === category.id ? "font-bold" : ""
                  }`}
                >
                  {category.type}
                </p>
              </button>
            </div>
          ))}
        </Slider>

        {/* Right arrow */}
        <button
          onClick={next}
          className="absolute -right-5 sm:-right-10 top-1/2 -translate-y-1/2 z-10 w-8 h-8 sm:w-10 sm:h-10 bg-white rounded-full shadow-md flex items-center justify-center border border-gray-200 hover:bg-black hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-black"
          aria-label="Next categories"
        >
          <ChevronRight className="w-5 h-5 text-gray-700" />
        </button>
      </div>
    </div>
  );
};

export default Categories;
