import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Avatar,
  Chip,
  Paper,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TimerIcon from "@mui/icons-material/Timer";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import EventIcon from "@mui/icons-material/Event";
import { styled } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 16,
    maxWidth: 600,
    width: "100%",
  },
}));

const TimeBox = styled(Box)(({ theme, type }) => ({
  display: "inline-flex",
  alignItems: "center",
  padding: "4px 8px",
  borderRadius: 4,
  backgroundColor:
    type === "faster"
      ? "#E8F5E9"
      : type === "slower"
      ? "#FFEBEE"
      : "transparent",
  color: type === "faster" ? "#2E7D32" : type === "slower" ? "#C62828" : "inherit",
}));

const formatTimeDifference = (differenceInMs) => {
  if (differenceInMs === null || isNaN(differenceInMs)) return null;
  
  const absMs = Math.abs(differenceInMs);
  const sign = differenceInMs >= 0 ? "+" : "-";
  
  const ms = absMs % 1000;
  const seconds = Math.floor((absMs / 1000) % 60);
  const minutes = Math.floor((absMs / (1000 * 60)) % 60);

  if (minutes > 0) {
    return `${sign}${minutes}m ${seconds}s ${ms}ms`;
  } else if (seconds > 0) {
    return `${sign}${seconds}s ${ms}ms`;
  } else {
    return `${sign}${ms}ms`;
  }
};

const ComparePopup = ({
  open,
  onClose,
  runs,
  ownerData,
  getTimeDifference,
  multiEvent = false,
  events = []
}) => {
  const [selectedRun1, setSelectedRun1] = useState("");
  const [selectedRun2, setSelectedRun2] = useState("");
  const [comparison, setComparison] = useState(null);

  useEffect(() => {
    if (runs.length >= 2) {
      setSelectedRun1(runs[0].id);
      setSelectedRun2(runs[1].id);
    }
  }, [runs]);

  useEffect(() => {
    if (selectedRun1 && selectedRun2) {
      compareRuns();
    } else {
      setComparison(null);
    }
  }, [selectedRun1, selectedRun2]);

  const compareRuns = () => {
    const run1 = runs.find((run) => run.id === selectedRun1);
    const run2 = runs.find((run) => run.id === selectedRun2);

    if (!run1 || !run2) {
      setComparison(null);
      return;
    }

    // Si alguna de las carreras tiene DNF, no podemos comparar tiempos
    if (run1.ElapsedTime === "DNF" || run2.ElapsedTime === "DNF") {
      setComparison({
        run1,
        run2,
        timeDifference: null,
        hasDNF: true
      });
      return;
    }

    const timeDifference = getTimeDifference(run1.ElapsedTime, run2.ElapsedTime);
    setComparison({
      run1,
      run2,
      timeDifference,
      hasDNF: false
    });
  };

  const getEventName = (eventId) => {
    if (!multiEvent) return null;
    const event = events.find(e => e.id === eventId);
    return event ? event.eventName : "Evento desconocido";
  };

  const getOwnerInfo = (run) => {
    if (!run) return { Owner: "Desconocido", PhotoUrl: "/default-avatar.jpg" };
    
    const ownerKey = multiEvent 
      ? `${run.NFCIdentifier}-${run.eventId}`
      : run.NFCIdentifier;
    
    return ownerData[ownerKey] || { 
      Owner: "Desconocido", 
      PhotoUrl: "/default-avatar.jpg" 
    };
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle sx={{ pb: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" fontWeight={600}>
            Comparar Tiempos
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Corredor 1</InputLabel>
                <Select
                  value={selectedRun1}
                  onChange={(e) => setSelectedRun1(e.target.value)}
                  label="Corredor 1"
                >
                  {runs.map((run) => {
                    const owner = getOwnerInfo(run);
                    return (
                      <MenuItem key={`run1-${run.id}`} value={run.id}>
                        {owner.Owner}{multiEvent && run.eventId && ` (${getEventName(run.eventId)})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Corredor 2</InputLabel>
                <Select
                  value={selectedRun2}
                  onChange={(e) => setSelectedRun2(e.target.value)}
                  label="Corredor 2"
                >
                  {runs.map((run) => {
                    const owner = getOwnerInfo(run);
                    return (
                      <MenuItem key={`run2-${run.id}`} value={run.id}>
                        {owner.Owner}{multiEvent && run.eventId && ` (${getEventName(run.eventId)})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {comparison && (
          <>
            <Divider sx={{ mb: 3 }} />
            
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                  <CompareArrowsIcon sx={{ fontSize: 40, color: "text.secondary", mx: 2 }} />
                </Box>
              </Grid>
              
              {/* Corredor 1 */}
              <Grid item xs={5}>
                <Paper sx={{ p: 2, borderRadius: 2, height: "100%" }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      src={getOwnerInfo(comparison.run1).PhotoUrl}
                      sx={{ width: 50, height: 50, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {getOwnerInfo(comparison.run1).Owner}
                      </Typography>
                      {multiEvent && comparison.run1.eventId && (
                        <Chip
                          icon={<EventIcon />}
                          label={getEventName(comparison.run1.eventId)}
                          size="small"
                          sx={{ mt: 0.5, height: 24 }}
                        />
                      )}
                    </Box>
                  </Box>
                  
                  <Box display="flex" alignItems="center" mt={2}>
                    <TimerIcon sx={{ mr: 1 }} />
                    <Typography variant="h6" fontWeight={600}>
                      {comparison.run1.ElapsedTime}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              
              {/* Diferencia */}
              <Grid item xs={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  {comparison.hasDNF ? (
                    <Typography variant="subtitle2" fontWeight={600} color="error">
                      No comparable
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      fontWeight={600}
                      color={comparison.timeDifference < 0 ? "success.main" : "error.main"}
                    >
                      {formatTimeDifference(comparison.timeDifference)}
                    </Typography>
                  )}
                </Box>
              </Grid>
              
              {/* Corredor 2 */}
              <Grid item xs={5}>
                <Paper sx={{ p: 2, borderRadius: 2, height: "100%" }}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Avatar
                      src={getOwnerInfo(comparison.run2).PhotoUrl}
                      sx={{ width: 50, height: 50, mr: 2 }}
                    />
                    <Box>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {getOwnerInfo(comparison.run2).Owner}
                      </Typography>
                      {multiEvent && comparison.run2.eventId && (
                        <Chip
                          icon={<EventIcon />}
                          label={getEventName(comparison.run2.eventId)}
                          size="small"
                          sx={{ mt: 0.5, height: 24 }}
                        />
                      )}
                    </Box>
                  </Box>
                  
                  <Box display="flex" alignItems="center" mt={2}>
                    <TimerIcon sx={{ mr: 1 }} />
                    <Typography variant="h6" fontWeight={600}>
                      {comparison.run2.ElapsedTime}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              
              {!comparison.hasDNF && (
                <Grid item xs={12}>
                  <Box mt={2} textAlign="center">
                    <TimeBox type={comparison.timeDifference < 0 ? "faster" : "slower"}>
                      <Typography variant="body1" fontWeight={600}>
                        {comparison.timeDifference < 0 
                          ? `${getOwnerInfo(comparison.run2).Owner} es ${formatTimeDifference(Math.abs(comparison.timeDifference))} más rápido que ${getOwnerInfo(comparison.run1).Owner}`
                          : `${getOwnerInfo(comparison.run2).Owner} es ${formatTimeDifference(comparison.timeDifference)} más lento que ${getOwnerInfo(comparison.run1).Owner}`
                        }
                      </Typography>
                    </TimeBox>
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default ComparePopup;