import React from "react";
import UserCount from "./Stats/UserCount";
import EventCount from "./Stats/EventCount";
import OrderCount from "./Stats/OrderCount";
import Revenue from "./Stats/Revenue";
import NewMenu from "./NewMenu";

function ContainerHome() {
  return (
    <div className="flex flex-col w-full">
      {/* Estadísticas en 4 columnas */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-6 md:mb-10">
        <UserCount />
        <EventCount />
        <OrderCount />
        <Revenue />
      </div>

      {/* NewMenu a ancho completo */}
      <div className="w-full">
        <NewMenu />
      </div>
    </div>
  );
}

export default ContainerHome;