import React from "react";
import UpcomingEventsBanner from "./Events/UpcomingEventsBanner";
import Stories from "./Stories/Stories";
import BikeFinderBanner from "./BlackList/BikeFinderBanner";
import BotonEsmeralda from "../components/Leaderboards/BotonEsmeralda";
import GoogleAds from "./GoogleAds";

const Home = () => {
  return (
    <div>
      <Stories />
      <GoogleAds />
      <BikeFinderBanner />
      <BotonEsmeralda />
      <UpcomingEventsBanner />
    </div>
  );
};

export default Home;
