import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const EventOrdersComparison = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventDetails, setEventDetails] = useState(null);
  const [orders, setOrders] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [creator, setCreator] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({
    userName: "",
    sortBy: "date",
    sortDirection: "desc",
    status: "all",
    participant: "all",
    category: "all",
  });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsSnapshot = await getDocs(collection(db, "events"));
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    if (!selectedEvent) return;

    const fetchEventDetails = async () => {
      setLoading(true);
      try {
        const eventDoc = await getDoc(doc(db, "events", selectedEvent));
        if (eventDoc.exists()) {
          const eventData = { id: eventDoc.id, ...eventDoc.data() };
          setEventDetails(eventData);

          if (eventData.creatorUID) {
            const creatorDoc = await getDoc(
              doc(db, "Users", eventData.creatorUID)
            );
            if (creatorDoc.exists()) {
              setCreator(creatorDoc.data());
            }
          }

          const categoriesSnapshot = await getDocs(
            collection(db, "events", selectedEvent, "categories")
          );
          const categoriesData = categoriesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCategories(categoriesData);

          const participantsSnapshot = await getDocs(
            collection(db, "events", selectedEvent, "participants")
          );
          const participantsData = participantsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setParticipants(participantsData);

          const ordersQuery = query(
            collection(db, "orders"),
            where("itemId", "==", selectedEvent)
          );
          const ordersSnapshot = await getDocs(ordersQuery);
          const ordersData = ordersSnapshot.docs.map((doc) => {
            const docObj = {
              id: doc.id,
              timeStamp: doc.data().createdAt.toDate(),
              ...doc.data(),
            };
            return docObj;
          });

          const ordersWithUserDetails = await Promise.all(
            ordersData.map(async (order, i) => {
              if (order.userId) {
                const userDoc = await getDoc(doc(db, "Users", order.userId));
                if (userDoc.exists()) {
                  return {
                    ...order,
                    user: { id: userDoc.id, ...userDoc.data() },
                  };
                }
              }
              return order;
            })
          );

          setOrders(ordersWithUserDetails);
        }
      } catch (error) {
        console.error("Error fetching event details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [selectedEvent]);

  const filteredOrders = orders
    .filter((order) => {
      if (
        filters.userName &&
        (!order.user ||
          !order.user.name ||
          !order.user.name
            .toLowerCase()
            .includes(filters.userName.toLowerCase()))
      ) {
        return false;
      }

      if (filters.status !== "all" && order.status !== filters.status) {
        return false;
      }

      if (filters.category !== "all" && order.categoryId !== filters.category) {
        return false;
      }

      const isParticipant = participants.some((p) => p.userId === order.userId);
      if (filters.participant === "true" && !isParticipant) {
        return false;
      } else if (filters.participant === "false" && isParticipant) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      if (filters.sortBy === "date") {
        const dateA = a.timeStamp;
        const dateB = b.timeStamp;
        return filters.sortDirection === "asc" ? dateA - dateB : dateB - dateA;
      } else {
        const nameA = a.user?.name || "";
        const nameB = b.user?.name || "";
        return filters.sortDirection === "asc"
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
    });

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category ? category.name : "Unknown";
  };

  return (
    <div className="container mx-auto p-4 bg-gray-50 rounded-lg border">
      {/* Event Selection */}
      <div className="mb-6">
        <label
          htmlFor="event-select"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Select Event
        </label>
        <select
          id="event-select"
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          onChange={(e) => setSelectedEvent(e.target.value)}
          value={selectedEvent || ""}
        >
          <option value="">-- Select an Event --</option>
          {events.map((event) => (
            <option key={event.id} value={event.id}>
              {event.eventName || "Unnamed Event"}
            </option>
          ))}
        </select>
      </div>

      {loading && (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
        </div>
      )}

      {selectedEvent && eventDetails && !loading && (
        <>
          {/* Event Details */}
          <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
            <h2 className="text-xl font-semibold mb-4 text-indigo-700">
              {eventDetails.eventName}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Description</p>
                <p className="text-gray-800">
                  {eventDetails.description || "No description"}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Date & Time</p>
                <p className="text-gray-800">
                  {eventDetails.dateTime
                    ? new Date(eventDetails.dateTime).toLocaleString()
                    : "Not specified"}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Creator</p>
                <p className="text-gray-800">{creator?.name || "Unknown"}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Total Orders</p>
                <p className="text-gray-800">{orders.length}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Total Participants</p>
                <p className="text-gray-800">{participants.length}</p>
              </div>
            </div>
          </div>

          {/* Filters */}
          <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
            <h3 className="text-lg font-medium mb-4 text-gray-700">Filters</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="user-filter"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  User Name
                </label>
                <input
                  id="user-filter"
                  type="text"
                  placeholder="Filter by user name"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={filters.userName}
                  onChange={(e) =>
                    handleFilterChange("userName", e.target.value)
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="sort-by"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Sort By
                </label>
                <div className="flex">
                  <select
                    id="sort-by"
                    className="w-full p-2 border border-gray-300 rounded-l-md"
                    value={filters.sortBy}
                    onChange={(e) =>
                      handleFilterChange("sortBy", e.target.value)
                    }
                  >
                    <option value="date">Date</option>
                    <option value="name">User Name</option>
                  </select>
                  <button
                    className="bg-gray-200 px-3 rounded-r-md border-t border-r border-b border-gray-300"
                    onClick={() =>
                      handleFilterChange(
                        "sortDirection",
                        filters.sortDirection === "asc" ? "desc" : "asc"
                      )
                    }
                  >
                    {filters.sortDirection === "asc" ? "↑" : "↓"}
                  </button>
                </div>
              </div>

              <div>
                <label
                  htmlFor="status-filter"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Order Status
                </label>
                <select
                  id="status-filter"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={filters.status}
                  onChange={(e) => handleFilterChange("status", e.target.value)}
                >
                  <option value="all">All Statuses</option>
                  <option value="approved">Approved</option>
                  <option value="in_process">In Process</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="participant-filter"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Participant Status
                </label>
                <select
                  id="participant-filter"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={filters.participant}
                  onChange={(e) =>
                    handleFilterChange("participant", e.target.value)
                  }
                >
                  <option value="all">All</option>
                  <option value="true">Is Participant</option>
                  <option value="false">Not Participant</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="category-filter"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Category
                </label>
                <select
                  id="category-filter"
                  className="w-full p-2 border border-gray-300 rounded-md"
                  value={filters.category}
                  onChange={(e) =>
                    handleFilterChange("category", e.target.value)
                  }
                >
                  <option value="all">All Categories</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex items-end">
                <button
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={() =>
                    setFilters({
                      userName: "",
                      sortBy: "date",
                      sortDirection: "desc",
                      status: "all",
                      participant: "all",
                      category: "all",
                    })
                  }
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>

          {/* Orders Table */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <h3 className="text-lg font-medium p-4 border-b text-gray-700">
              Orders & Participants Comparison ({filteredOrders.length} orders)
            </h3>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Order ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Participant
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredOrders.length > 0 ? (
                    filteredOrders.map((order) => {
                      const isParticipant = participants.some(
                        (p) => p.userId === order.userId
                      );
                      return (
                        <tr key={order.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {order.id.substring(0, 8)}...
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {order.user?.name || "Unknown User"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {getCategoryName(order.categoryId)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                order.status === "approved"
                                  ? "bg-green-100 text-green-800"
                                  : "bg-yellow-100 text-yellow-800"
                              }`}
                            >
                              {order.status || "Unknown"}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {order.timeStamp
                              ? order.timeStamp.toLocaleString()
                              : "Unknown"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                isParticipant
                                  ? "bg-green-100 text-green-800"
                                  : "bg-red-100 text-red-800"
                              }`}
                            >
                              {isParticipant ? "Yes" : "No"}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="px-6 py-4 text-center text-sm text-gray-500"
                      >
                        No orders found matching the current filters
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventOrdersComparison;
