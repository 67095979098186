import React, { useState, useEffect } from 'react';
import {
    Search, Filter, ArrowUpNarrowWide, ArrowDownNarrowWide, Edit, Upload,
    Check, Clock, Award, Trash2, X, AlertCircle, ListFilter
} from 'lucide-react';
import { collection, getDocs, doc, getDoc, updateDoc, query, where, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Papa from 'papaparse';

const MultiCategoryUploader = () => {
    const [file, setFile] = useState(null);
    const [allResults, setAllResults] = useState([]);
    const [categorizedResults, setCategorizedResults] = useState({});
    const [message, setMessage] = useState({ text: '', type: '' });
    const [uploadedCategories, setUploadedCategories] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [currentProgress, setCurrentProgress] = useState({ current: 0, total: 0 });
    const [selectedCategory, setSelectedCategory] = useState('');
    const [previewMode, setPreviewMode] = useState('table');
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    // Cargar categorías existentes
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const esmeraldaRef = doc(db, 'Esmeralda', 'categories');
            const esmeraldaDoc = await getDoc(esmeraldaRef);

            if (esmeraldaDoc.exists() && esmeraldaDoc.data().list) {
                setUploadedCategories(esmeraldaDoc.data().list);
            }
        } catch (error) {
            console.error('Error al obtener categorías:', error);
            setMessage({ text: 'Error al cargar categorías existentes', type: 'error' });
        }
    };

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
        setMessage({ text: '', type: '' });

        if (uploadedFile) {
            // Parsear CSV
            Papa.parse(uploadedFile, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: (results) => {
                    if (results.data.length > 0) {
                        // Verificar si el CSV tiene la estructura correcta
                        if (!results.meta.fields.includes('categoria')) {
                            setMessage({
                                text: 'El CSV debe contener una columna "categoria"',
                                type: 'error'
                            });
                            return;
                        }

                        setAllResults(results.data);

                        // Agrupar resultados por categoría
                        const grouped = results.data.reduce((acc, result) => {
                            const category = result.categoria;
                            if (!acc[category]) {
                                acc[category] = [];
                            }
                            acc[category].push(result);
                            return acc;
                        }, {});

                        // Ordenar resultados por posición dentro de cada categoría
                        Object.keys(grouped).forEach(category => {
                            grouped[category].sort((a, b) => a.posicion - b.posicion);
                        });

                        setCategorizedResults(grouped);

                        // Si hay categorías, seleccionar la primera para previsualización
                        const categories = Object.keys(grouped);
                        if (categories.length > 0) {
                            setSelectedCategory(categories[0]);
                        }

                        setMessage({
                            text: `CSV cargado con éxito. Se encontraron ${categories.length} categorías.`,
                            type: 'success'
                        });
                    } else {
                        setMessage({ text: 'El archivo CSV está vacío', type: 'error' });
                    }
                },
                error: (error) => {
                    console.error('Error al parsear CSV:', error);
                    setMessage({ text: 'Error al leer el archivo CSV', type: 'error' });
                }
            });
        }
    };

    const uploadToFirebase = async () => {
        if (Object.keys(categorizedResults).length === 0) {
            setMessage({ text: 'No hay resultados para subir', type: 'error' });
            return;
        }

        setIsUploading(true);
        setMessage({ text: 'Subiendo resultados...', type: 'info' });

        const totalCategories = Object.keys(categorizedResults).length;
        setCurrentProgress({ current: 0, total: totalCategories });

        try {
            // Lista para almacenar todas las categorías que se van a subir
            const categoriesToUpdate = [];

            // Subir cada categoría por separado
            for (const [categoryName, results] of Object.entries(categorizedResults)) {
                setCurrentProgress(prev => ({ ...prev, current: prev.current + 1 }));
                setMessage({
                    text: `Subiendo categoría ${categoryName} (${currentProgress.current}/${totalCategories})...`,
                    type: 'info'
                });

                // Guardar resultados en un documento con el nombre de la categoría
                const categoryRef = doc(db, 'Esmeralda', categoryName.replace(/\s+/g, '_'));

                // Formatear datos
                const formattedResults = results.map(result => ({
                    posicion: result.posicion,
                    nombre: result.nombre,
                    tiempo: result.tiempo,
                    categoria: categoryName
                }));

                // Guardar en Firestore
                await setDoc(categoryRef, {
                    categoria: categoryName,
                    resultados: formattedResults,
                    fechaActualizacion: new Date()
                });

                categoriesToUpdate.push(categoryName);
            }

            // Actualizar lista de categorías
            const categoriesRef = doc(db, 'Esmeralda', 'categories');
            const categoriesDoc = await getDoc(categoriesRef);

            if (categoriesDoc.exists()) {
                // Actualizar lista sin duplicados
                const currentList = categoriesDoc.data().list || [];
                const newCategories = categoriesToUpdate.filter(cat => !currentList.includes(cat));

                if (newCategories.length > 0) {
                    const updatedList = [...currentList, ...newCategories];
                    await updateDoc(categoriesRef, {
                        list: updatedList
                    });
                    setUploadedCategories(updatedList);
                }
            } else {
                // Crear nueva lista
                await setDoc(categoriesRef, {
                    list: categoriesToUpdate
                });
                setUploadedCategories(categoriesToUpdate);
            }

            setMessage({
                text: `Se subieron ${totalCategories} categorías correctamente`,
                type: 'success'
            });
        } catch (error) {
            console.error('Error al subir resultados:', error);
            setMessage({ text: `Error al subir los resultados: ${error.message}`, type: 'error' });
        } finally {
            setIsUploading(false);
            setCurrentProgress({ current: 0, total: 0 });
        }
    };

    // Función para eliminar una categoría completa
    const deleteCategory = async (categoryName) => {
        if (!categoryName) return;

        setIsDeleting(true);
        setMessage({ text: `Eliminando categoría ${categoryName}...`, type: 'info' });

        try {
            // 1. Eliminar el documento de la categoría
            const categoryDocId = categoryName.replace(/\s+/g, '_');
            await deleteDoc(doc(db, 'Esmeralda', categoryDocId));

            // 2. Actualizar la lista de categorías
            const categoriesRef = doc(db, 'Esmeralda', 'categories');
            const categoriesDoc = await getDoc(categoriesRef);

            if (categoriesDoc.exists()) {
                const currentList = categoriesDoc.data().list || [];
                const updatedList = currentList.filter(cat => cat !== categoryName);

                await updateDoc(categoriesRef, {
                    list: updatedList
                });

                // Actualizar estado local
                setUploadedCategories(updatedList);
            }

            setMessage({ text: `Categoría ${categoryName} eliminada correctamente`, type: 'success' });

            // Si la categoría actual es la que estamos eliminando, seleccionar otra
            if (selectedCategory === categoryName) {
                const remainingCategories = Object.keys(categorizedResults).filter(cat => cat !== categoryName);
                setSelectedCategory(remainingCategories.length > 0 ? remainingCategories[0] : '');
            }
        } catch (error) {
            console.error('Error al eliminar categoría:', error);
            setMessage({ text: `Error al eliminar categoría: ${error.message}`, type: 'error' });
        } finally {
            setIsDeleting(false);
            setDeleteConfirmOpen(false);
            setCategoryToDelete(null);
        }
    };

    // Abrir el diálogo de confirmación para eliminar
    const openDeleteConfirm = (categoryName) => {
        setCategoryToDelete(categoryName);
        setDeleteConfirmOpen(true);
    };

    // Renderiza el podio con los primeros 3 resultados
    const renderPodium = () => {
        const results = selectedCategory ? categorizedResults[selectedCategory] || [] : [];
        if (results.length < 1) return null;

        // Ordenar resultados por posición
        const sortedResults = [...results].sort((a, b) => a.posicion - b.posicion);
        const top3 = sortedResults.slice(0, 3);

        return (
            <div className="flex justify-center items-end gap-4 my-6 h-64">
                {top3.map((result, idx) => {
                    // Determinar altura del podio según posición
                    const heights = {
                        0: "h-full", // Primer lugar
                        1: "h-4/5", // Segundo lugar
                        2: "h-3/5"  // Tercer lugar
                    };

                    const colors = {
                        0: "bg-yellow-500", // Oro
                        1: "bg-gray-300",   // Plata
                        2: "bg-amber-700"   // Bronce
                    };

                    return (
                        <div key={idx} className="flex flex-col items-center">
                            <div className="mb-2 text-center">
                                <p className="font-bold truncate max-w-xs">{result.nombre}</p>
                                <p className="text-sm text-gray-600">{result.tiempo}</p>
                            </div>
                            <div className={`w-24 flex flex-col items-center justify-end ${heights[idx]}`}>
                                <div className={`w-full ${colors[idx]} text-white font-bold text-center py-2 rounded-t-lg`}>
                                    {result.posicion}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    // Modal de confirmación para eliminar categoría
    const DeleteConfirmationModal = () => {
        if (!deleteConfirmOpen) return null;

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg max-w-md w-full p-6 shadow-xl">
                    <div className="flex justify-between items-start mb-4">
                        <h3 className="text-lg font-bold text-red-600 flex items-center">
                            <AlertCircle className="w-5 h-5 mr-2" />
                            Eliminar categoría
                        </h3>
                        <button
                            onClick={() => setDeleteConfirmOpen(false)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    <div className="mb-6">
                        <p className="text-gray-700 mb-2">¿Estás seguro de eliminar la categoría <strong>{categoryToDelete}</strong>?</p>
                        <p className="text-red-500 text-sm">Esta acción no se puede deshacer y eliminará todos los resultados asociados.</p>
                    </div>

                    <div className="flex justify-end space-x-3">
                        <button
                            onClick={() => setDeleteConfirmOpen(false)}
                            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={() => deleteCategory(categoryToDelete)}
                            disabled={isDeleting}
                            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 flex items-center"
                        >
                            {isDeleting ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Eliminando...
                                </>
                            ) : (
                                <>
                                    <Trash2 className="w-4 h-4 mr-2" />
                                    Eliminar definitivamente
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-xl shadow-lg">
            <h2 className="text-3xl font-bold mb-6 text-center text-blue-800">Carga Múltiple de Resultados</h2>

            {message.text && (
                <div className={`p-4 mb-6 rounded-lg ${message.type === 'error' ? 'bg-red-100 text-red-700 border-l-4 border-red-500' :
                    message.type === 'success' ? 'bg-green-100 text-green-700 border-l-4 border-green-500' :
                        'bg-blue-100 text-blue-700 border-l-4 border-blue-500'
                    }`}>
                    <div className="flex items-center">
                        {message.type === 'error' && <AlertCircle className="w-5 h-5 mr-2" />}
                        {message.type === 'success' && <Check className="w-5 h-5 mr-2" />}
                        {message.type === 'info' && <Upload className="w-5 h-5 mr-2" />}
                        <p className="font-medium">{message.text}</p>
                    </div>
                </div>
            )}

            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
                <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                    <Upload className="w-4 h-4 mr-2" /> Archivo CSV con múltiples categorías
                </label>
                <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    className="block w-full text-sm text-gray-500
                        file:mr-4 file:py-3 file:px-4
                        file:rounded-md file:border-0
                        file:text-sm file:font-semibold
                        file:bg-blue-50 file:text-blue-700
                        hover:file:bg-blue-100 
                        focus:outline-none"
                />
                <p className="mt-1 text-xs text-gray-500">Formato esperado: posicion, nombre, tiempo, categoria</p>
            </div>

            {/* Resumen de categorías encontradas */}
            {Object.keys(categorizedResults).length > 0 && (
                <div className="mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <h3 className="text-md font-medium text-gray-700 mb-3 flex items-center">
                        <ListFilter className="w-4 h-4 mr-2" />
                        Categorías encontradas en el CSV ({Object.keys(categorizedResults).length})
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                        {Object.entries(categorizedResults).map(([category, results], index) => (
                            <button
                                key={index}
                                onClick={() => setSelectedCategory(category)}
                                className={`flex items-center justify-between px-3 py-2 rounded text-left
                                   ${selectedCategory === category
                                        ? 'bg-blue-100 border border-blue-300'
                                        : 'bg-white border border-gray-200 hover:bg-gray-50'}`}
                            >
                                <span className="text-sm font-medium truncate flex-1">{category}</span>
                                <span className="text-xs bg-gray-100 px-2 py-1 rounded-full ml-2">
                                    {results.length}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {/* Lista de categorías existentes con opción de eliminar */}
            {uploadedCategories.length > 0 && (
                <div className="mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <h3 className="text-md font-medium text-gray-700 mb-3 flex items-center">
                        <Award className="w-4 h-4 mr-2" />
                        Categorías existentes en la base de datos
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                        {uploadedCategories.map((cat, index) => (
                            <div key={index} className="flex items-center justify-between px-3 py-2 rounded bg-white border border-gray-200 hover:bg-gray-50">
                                <span className="text-sm font-medium text-gray-700 truncate">{cat}</span>
                                <button
                                    onClick={() => openDeleteConfirm(cat)}
                                    className="text-red-500 hover:text-red-700 ml-2 p-1 rounded hover:bg-red-50"
                                    title="Eliminar categoría"
                                >
                                    <Trash2 className="w-4 h-4" />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Vista previa de la categoría seleccionada */}
            {selectedCategory && categorizedResults[selectedCategory] && categorizedResults[selectedCategory].length > 0 && (
                <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <div className="border-b border-gray-200 bg-gray-50 px-4 py-3 rounded-t-lg flex justify-between items-center">
                        <h3 className="text-lg font-medium text-gray-800">
                            Vista previa: {selectedCategory} ({categorizedResults[selectedCategory].length} participantes)
                        </h3>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setPreviewMode('table')}
                                className={`px-3 py-1 rounded text-sm ${previewMode === 'table' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Tabla
                            </button>
                            <button
                                onClick={() => setPreviewMode('podium')}
                                className={`px-3 py-1 rounded text-sm ${previewMode === 'podium' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Podio
                            </button>
                        </div>
                    </div>

                    {previewMode === 'table' ? (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Posición</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tiempo</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {categorizedResults[selectedCategory].map((result, index) => (
                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                                <div className="flex items-center">
                                                    {result.posicion <= 3 && (
                                                        <span className={`w-6 h-6 rounded-full flex items-center justify-center mr-2 ${result.posicion === 1 ? 'bg-yellow-500' :
                                                            result.posicion === 2 ? 'bg-gray-300' :
                                                                'bg-amber-700'
                                                            } text-white font-bold`}>
                                                            {result.posicion}
                                                        </span>
                                                    )}
                                                    {result.posicion > 3 && result.posicion}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{result.nombre}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <div className="flex items-center">
                                                    <Clock className="w-4 h-4 mr-1 text-gray-400" />
                                                    {result.tiempo}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        renderPodium()
                    )}
                </div>
            )}

            {/* Botón para subir todas las categorías */}
            <button
                onClick={uploadToFirebase}
                disabled={isUploading || Object.keys(categorizedResults).length === 0}
                className={`w-full py-3 px-4 border border-transparent rounded-lg shadow-md text-base font-medium text-white 
                    ${isUploading || Object.keys(categorizedResults).length === 0
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    } transition duration-200`}
            >
                {isUploading ? (
                    <span className="flex items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {currentProgress.total > 0
                            ? `Subiendo categorías (${currentProgress.current}/${currentProgress.total})...`
                            : 'Subiendo...'}
                    </span>
                ) : (
                    <span className="flex items-center justify-center">
                        <Upload className="w-5 h-5 mr-2" />
                        {Object.keys(categorizedResults).length > 0
                            ? `Subir ${Object.keys(categorizedResults).length} categorías a Esmeralda`
                            : 'Subir Resultados a Esmeralda'}
                    </span>
                )}
            </button>

            {/* Modal de confirmación de eliminación */}
            <DeleteConfirmationModal />
        </div>
    );
};

export default MultiCategoryUploader;