import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import {
    Bike,
    Calendar,
    Clock,
    MapPin,
    Eye,
    Edit,
    Trash2,
    AlertCircle,
    Loader2,
    Plus,
    Check,
    X as XIcon,
    Search,
    DollarSign,
    Filter,
    AlertTriangle
} from "lucide-react";

const MyBlacklist = () => {
    const navigate = useNavigate();
    const { user } = useUserAuth();
    const [bicycles, setBicycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterStatus, setFilterStatus] = useState("all");
    const [sortBy, setSortBy] = useState("date");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [bikeToDelete, setBikeToDelete] = useState(null);
    const [deletingBike, setDeletingBike] = useState(false);

    useEffect(() => {
        const fetchMyBicycles = async () => {
            if (!user) {
                setError("Debes iniciar sesión para ver tus bicicletas reportadas");
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                // Crear consulta para obtener solo las bicicletas del usuario actual
                const bicycleQuery = query(
                    collection(db, "Blacklist"),
                    where("uid", "==", user.uid)
                );

                const querySnapshot = await getDocs(bicycleQuery);
                const bikeData = [];

                querySnapshot.forEach((doc) => {
                    bikeData.push({
                        id: doc.id,
                        ...doc.data(),
                        // Asegurar que reportDate es un objeto Date
                        reportDate: doc.data().reportDate instanceof Date ?
                            doc.data().reportDate :
                            (doc.data().reportDate?.toDate ? doc.data().reportDate.toDate() : new Date())
                    });
                });

                setBicycles(bikeData);
            } catch (err) {
                console.error("Error fetching your bicycles:", err);
                setError("No se pudieron cargar tus bicicletas. Por favor, intenta de nuevo más tarde.");
            } finally {
                setLoading(false);
            }
        };

        fetchMyBicycles();
    }, [user]);

    // Función para eliminar una bicicleta
    const deleteBicycle = async () => {
        if (!bikeToDelete) return;

        try {
            setDeletingBike(true);
            await deleteDoc(doc(db, "Blacklist", bikeToDelete.id));

            // Actualizar el estado local sin necesidad de recargar
            setBicycles(prevBikes =>
                prevBikes.filter(bike => bike.id !== bikeToDelete.id)
            );

            setShowDeleteModal(false);
            setBikeToDelete(null);
        } catch (error) {
            console.error("Error al eliminar la bicicleta:", error);
            alert("Error al eliminar la bicicleta. Por favor, intenta de nuevo.");
        } finally {
            setDeletingBike(false);
        }
    };

    const confirmDelete = (bike) => {
        setBikeToDelete(bike);
        setShowDeleteModal(true);
    };

    const formatDate = (dateString) => {
        if (!dateString) return "Fecha no disponible";

        const date = new Date(dateString);
        return date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    };

    const calculateDaysSince = (date) => {
        if (!date) return 0;

        return Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
    };

    const getStatusBadge = (status) => {
        const statusConfig = {
            reported: {
                bg: "bg-red-100",
                text: "text-red-800",
                label: "Reportada"
            },
            inProgress: {
                bg: "bg-yellow-100",
                text: "text-yellow-800",
                label: "En Investigación"
            },
            sighted: {
                bg: "bg-blue-100",
                text: "text-blue-800",
                label: "Vista Recientemente"
            },
            recovered: {
                bg: "bg-green-100",
                text: "text-green-800",
                label: "Recuperada"
            },
            closed: {
                bg: "bg-gray-100",
                text: "text-gray-800",
                label: "Caso Cerrado"
            }
        };

        const config = statusConfig[status] || statusConfig.reported;

        return (
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${config.bg} ${config.text}`}>
                {config.label}
            </span>
        );
    };

    // Filtrar y ordenar bicicletas
    const filteredAndSortedBicycles = bicycles
        .filter(bike => {
            // Filtro por estado
            if (filterStatus !== "all" && bike.status !== filterStatus) {
                return false;
            }

            // Filtro por término de búsqueda
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                return (
                    (bike.name && bike.name.toLowerCase().includes(searchLower)) ||
                    (bike.brand && bike.brand.toLowerCase().includes(searchLower)) ||
                    (bike.model && bike.model.toLowerCase().includes(searchLower)) ||
                    (bike.serialNumber && bike.serialNumber.toLowerCase().includes(searchLower)) ||
                    (bike.color && bike.color.toLowerCase().includes(searchLower))
                );
            }

            return true;
        })
        .sort((a, b) => {
            // Ordenar por fecha (más reciente primero)
            if (sortBy === "date") {
                return new Date(b.reportDate) - new Date(a.reportDate);
            }
            // Ordenar por estado
            else if (sortBy === "status") {
                const statusOrder = {
                    reported: 1,
                    inProgress: 2,
                    sighted: 3,
                    recovered: 4,
                    closed: 5
                };
                return (statusOrder[a.status] || 99) - (statusOrder[b.status] || 99);
            }
            // Ordenar por nombre
            else if (sortBy === "name") {
                return (a.name || "").localeCompare(b.name || "");
            }

            return 0;
        });

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="flex flex-col items-center">
                    <Loader2 className="h-10 w-10 text-black animate-spin" />
                    <p className="mt-4 text-gray-700">Cargando tus bicicletas reportadas...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-6 text-center">
                    <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">Error</h2>
                    <p className="text-gray-700 mb-6">{error}</p>
                    <button
                        onClick={() => navigate("/bikefinder")}
                        className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                    >
                        Ir al Buscador
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-2">Mis Bicicletas Reportadas</h1>
                <p className="text-gray-600">
                    Gestiona tus reportes de bicicletas robadas, actualiza su información o marca como recuperadas.
                </p>
            </div>

            {/* Filter and Search Section */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
                <div className="p-4 md:p-6">
                    <div className="flex flex-col md:flex-row md:items-center gap-4">
                        {/* Search */}
                        <div className="relative flex-grow">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Search className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                placeholder="Buscar por marca, modelo, color..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                            />
                        </div>

                        {/* Status Filter */}
                        <div className="flex-shrink-0">
                            <select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                            >
                                <option value="all">Todos los estados</option>
                                <option value="reported">Reportadas</option>
                                <option value="inProgress">En investigación</option>
                                <option value="sighted">Vistas recientemente</option>
                                <option value="recovered">Recuperadas</option>
                                <option value="closed">Casos cerrados</option>
                            </select>
                        </div>

                        {/* Sort By */}
                        <div className="flex-shrink-0">
                            <select
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-black"
                            >
                                <option value="date">Ordenar por fecha</option>
                                <option value="status">Ordenar por estado</option>
                                <option value="name">Ordenar por nombre</option>
                            </select>
                        </div>

                        {/* Add New Bike Button */}
                        <button
                            onClick={() => navigate("/blacklist/add")}
                            className="flex items-center px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                        >
                            <Plus className="h-5 w-5 mr-2" />
                            Reportar Nueva
                        </button>
                    </div>
                </div>
            </div>

            {/* Bicycles List */}
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 bg-gradient-to-r from-gray-800 to-black text-white">
                    <h2 className="text-xl font-semibold flex items-center">
                        <Bike className="mr-2 h-5 w-5" />
                        Mis Bicicletas
                    </h2>
                    <p className="text-sm text-gray-300 mt-1">
                        {filteredAndSortedBicycles.length} {filteredAndSortedBicycles.length === 1 ? 'bicicleta encontrada' : 'bicicletas encontradas'}
                    </p>
                </div>

                {filteredAndSortedBicycles.length === 0 ? (
                    <div className="p-8 text-center">
                        <AlertCircle className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                        <h3 className="text-lg font-medium text-gray-900 mb-2">No has reportado ninguna bicicleta</h3>
                        <p className="text-gray-500 mb-6">
                            {searchTerm || filterStatus !== "all"
                                ? "No hay bicicletas que coincidan con los filtros seleccionados."
                                : "¿Has perdido una bicicleta? Reporta tu bicicleta robada para aumentar las posibilidades de recuperarla."}
                        </p>
                        <button
                            onClick={() => navigate("/blacklist/add")}
                            className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                        >
                            <Plus className="h-5 w-5 mr-2 inline-block" />
                            Reportar Bicicleta
                        </button>
                    </div>
                ) : (
                    <div className="divide-y divide-gray-200">
                        {filteredAndSortedBicycles.map((bike) => (
                            <div key={bike.id} className="p-4 md:p-6 hover:bg-gray-50 transition-colors">
                                <div className="flex flex-col md:flex-row gap-4">
                                    {/* Bike Image */}
                                    <div className="w-full md:w-48 h-40 flex-shrink-0">
                                        {bike.mainImageUrl ? (
                                            <img
                                                src={bike.mainImageUrl}
                                                alt={bike.name}
                                                className="w-full h-full object-cover rounded-md"
                                            />
                                        ) : (
                                            <div className="w-full h-full bg-gray-200 rounded-md flex items-center justify-center">
                                                <Bike className="h-12 w-12 text-gray-400" />
                                            </div>
                                        )}
                                    </div>

                                    {/* Bike Info */}
                                    <div className="flex-grow">
                                        <div className="flex flex-col md:flex-row md:items-start justify-between mb-2">
                                            <div>
                                                <h3 className="text-lg font-semibold text-gray-900">{bike.name}</h3>
                                                <div className="text-sm text-gray-600 mt-1">
                                                    <span className="font-medium">{bike.brand}</span>
                                                    {bike.model && <span> · {bike.model}</span>}
                                                    {bike.color && <span> · {bike.color}</span>}
                                                </div>
                                            </div>
                                            <div className="mt-2 md:mt-0">
                                                {getStatusBadge(bike.status)}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 text-sm mt-2">
                                            <div className="flex items-center text-gray-700">
                                                <Calendar className="h-4 w-4 mr-2 text-gray-500" />
                                                Robada el: {formatDate(bike.theftDate)}
                                            </div>
                                            <div className="flex items-center text-gray-700">
                                                <Clock className="h-4 w-4 mr-2 text-gray-500" />
                                                Reportada hace {calculateDaysSince(bike.reportDate)} días
                                            </div>
                                            <div className="flex items-center text-gray-700">
                                                <MapPin className="h-4 w-4 mr-2 text-gray-500" />
                                                {bike.location || "Ubicación no especificada"}
                                            </div>
                                            {bike.reward && (
                                                <div className="flex items-center text-green-700">
                                                    <DollarSign className="h-4 w-4 mr-1 text-green-600" />
                                                    Recompensa: ${bike.rewardAmount || 0}
                                                </div>
                                            )}
                                        </div>

                                        {/* Actions */}
                                        <div className="mt-4 flex flex-wrap gap-2">
                                            <button
                                                onClick={() => navigate(`/blacklist/${bike.id}`)}
                                                className="flex items-center px-3 py-1.5 bg-black text-white text-sm rounded-md hover:bg-gray-800 transition-colors"
                                            >
                                                <Eye className="h-4 w-4 mr-1.5" />
                                                Ver detalles
                                            </button>
                                            <button
                                                onClick={() => navigate(`/blacklist/add/${bike.id}`)}
                                                className="flex items-center px-3 py-1.5 bg-gray-200 text-gray-800 text-sm rounded-md hover:bg-gray-300 transition-colors"
                                            >
                                                <Edit className="h-4 w-4 mr-1.5" />
                                                Editar
                                            </button>
                                            <button
                                                onClick={() => confirmDelete(bike)}
                                                className="flex items-center px-3 py-1.5 bg-red-100 text-red-700 text-sm rounded-md hover:bg-red-200 transition-colors"
                                            >
                                                <Trash2 className="h-4 w-4 mr-1.5" />
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Tips Card */}
            <div className="mt-8 bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 bg-gray-50">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">Consejos para recuperar tu bicicleta</h3>
                    <ul className="text-sm text-gray-700 space-y-2">
                        <li className="flex items-start">
                            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span>Mantén actualizada la información de tu bicicleta, especialmente si ha sido vista recientemente.</span>
                        </li>
                        <li className="flex items-start">
                            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span>Comparte el enlace de tu bicicleta en redes sociales para aumentar la visibilidad.</span>
                        </li>
                        <li className="flex items-start">
                            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span>Considera ofrecer una recompensa para motivar a las personas a reportar si ven tu bicicleta.</span>
                        </li>
                        <li className="flex items-start">
                            <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span>Visita regularmente tiendas de segunda mano y revisa mercados en línea donde podrían intentar venderla.</span>
                        </li>
                        <li className="flex items-start">
                            <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span>Importante: Si encuentras tu bicicleta, no intentes recuperarla por tu cuenta. Contacta a las autoridades.</span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg max-w-md w-full p-6">
                        <div className="text-center">
                            <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                            <h3 className="text-lg font-medium text-gray-900 mb-2">¿Eliminar esta bicicleta?</h3>
                            <p className="text-gray-500 mb-6">
                                ¿Estás seguro de que quieres eliminar el reporte de <span className="font-medium">{bikeToDelete?.name}</span>? Esta acción no se puede deshacer.
                            </p>
                            <div className="flex gap-3 justify-center">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                                    disabled={deletingBike}
                                >
                                    <XIcon className="h-4 w-4 mr-1.5 inline-block" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={deleteBicycle}
                                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                                    disabled={deletingBike}
                                >
                                    {deletingBike ? (
                                        <Loader2 className="h-4 w-4 mr-1.5 inline-block animate-spin" />
                                    ) : (
                                        <Trash2 className="h-4 w-4 mr-1.5 inline-block" />
                                    )}
                                    {deletingBike ? "Eliminando..." : "Eliminar"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyBlacklist;