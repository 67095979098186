import React from "react";
import { X } from "lucide-react";

const QuickResponsePanel = ({ quickResponses, setMessage, setShowQuickResponses }) => {
    return (
        <div className="bg-white border-t p-3">
            <div className="flex justify-between items-center mb-2">
                <h3 className="text-sm font-medium text-gray-700">Respuestas rápidas</h3>
                <button
                    onClick={() => setShowQuickResponses(false)}
                    className="text-gray-400 hover:text-gray-600"
                >
                    <X size={16} />
                </button>
            </div>
            <div className="grid grid-cols-2 gap-2">
                {quickResponses.map((template, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            setMessage(template.content);
                            setShowQuickResponses(false);
                        }}
                        className="text-left p-2 bg-gray-50 rounded border border-gray-200 hover:bg-gray-100 transition-colors"
                    >
                        <p className="text-xs font-medium mb-1 text-blue-600">{template.title}</p>
                        <p className="text-xs text-gray-600 line-clamp-2">{template.content}</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuickResponsePanel;