import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bike, Shield, X, Bell, ArrowRight } from 'lucide-react';

const BikeFinderBanner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();

    // Mostrar el banner después de un pequeño retraso para el efecto de animación
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsBannerVisible(true);
        }, 500);

        // Iniciar la animación pulsante
        const animationTimer = setInterval(() => {
            setIsAnimating(prev => !prev);
        }, 2000);

        // Limpiar timers
        return () => {
            clearTimeout(timer);
            clearInterval(animationTimer);
        };
    }, []);

    const handleBannerClick = () => {
        navigate('/bikefinder');
    };

    const closeBanner = (e) => {
        e.stopPropagation();
        setIsBannerVisible(false);
    };

    if (!isBannerVisible) return null;

    return (
        <div className="flex justify-center items-center w-full py-3 px-4 relative">
            <div
                className={`
          relative max-w-3xl mx-auto bg-gradient-to-r from-black to-gray-800 shadow-lg
          rounded-full py-2.5 px-6 border border-gray-700
          transition-all duration-300 transform hover:scale-[1.02] cursor-pointer
          hover:shadow-xl
          ${isAnimating ? 'shadow-green-400/20' : ''}
        `}
                onClick={handleBannerClick}
            >
                <div className="flex items-center justify-between">
                    {/* Lado izquierdo - Iconos y Badge */}
                    <div className="flex items-center">
                        <div className={`
              flex items-center justify-center p-1 bg-white rounded-full
              mr-3 transition-transform duration-500
              ${isAnimating ? 'scale-110' : ''}
            `}>
                            <Bike className="h-5 w-5 text-black" />
                        </div>

                        <div className="flex items-center">
                            <div className="relative">
                                <span className="flex h-2 w-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
                                </span>
                            </div>
                            <span className="ml-2 text-xs uppercase font-bold tracking-wider bg-gradient-to-r from-green-400 to-emerald-500 bg-clip-text text-transparent">
                                ¡Nuevo!
                            </span>
                        </div>
                    </div>

                    {/* Parte central - Mensaje */}
                    <div className="mx-3 text-sm font-medium text-white">
                        Protege tu bicicleta con nuestro registro de bicicletas robadas
                    </div>

                    {/* Lado derecho - Call to action */}
                    <div className="flex items-center">
                        <span className="hidden sm:inline-block mr-2 text-xs bg-green-500 text-black font-bold py-1 px-2 rounded-full">
                            Ver ahora
                        </span>
                        <ArrowRight className={`
              h-4 w-4 text-green-400
              transition-transform duration-300
              ${isAnimating ? 'translate-x-1' : ''}
            `} />
                    </div>
                </div>

                {/* Botón de cerrar */}
                <button
                    onClick={closeBanner}
                    className="absolute -right-2 -top-2 bg-gray-800 text-gray-300 hover:text-white p-1 rounded-full hover:bg-gray-700 border border-gray-600 shadow-md"
                    aria-label="Cerrar banner"
                >
                    <X className="h-3.5 w-3.5" />
                </button>
            </div>
        </div>
    );
};

export default BikeFinderBanner;