import React from "react";
import { Outlet } from "react-router-dom";

const OfflineAccess = () => {
  return (
    <div className="container mx-auto">
      <h1 className="font-semibold text-gray-700">Offline</h1>
      <Outlet />
    </div>
  );
};

export default OfflineAccess;
