import React from "react";
import { Send, MessageSquare } from "lucide-react";

const ChatInput = ({
    message,
    setMessage,
    handleSendMessage,
    isSending,
    showQuickResponses,
    setShowQuickResponses
}) => {
    return (
        <form
            onSubmit={handleSendMessage}
            className="bg-white border-t p-4 flex flex-col gap-2"
        >
            <div className="flex gap-2 w-full">
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Responder como Livespeed..."
                    className="flex-1 rounded-lg px-4 py-2 border border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    disabled={isSending}
                />
                <button
                    type="button"
                    onClick={() => setShowQuickResponses(!showQuickResponses)}
                    className="p-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
                    title="Respuestas rápidas"
                >
                    <MessageSquare size={18} />
                </button>
                <button
                    type="submit"
                    disabled={isSending || !message.trim()}
                    className="bg-blue-600 text-white p-3 rounded-lg transition-colors hover:bg-blue-700 disabled:bg-blue-300 flex items-center justify-center"
                >
                    {isSending ? (
                        <div className="w-5 h-5 border-2 border-gray-300 border-t-white rounded-full animate-spin"></div>
                    ) : (
                        <Send size={18} />
                    )}
                </button>
            </div>
            <div className="text-xs text-gray-500 italic pl-2">
                Respondiendo como Livespeed Soporte
            </div>
        </form>
    );
};

export default ChatInput;