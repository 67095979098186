import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
    Bike,
    Calendar,
    Clock,
    MapPin,
    DollarSign,
    Hash,
    ChevronLeft,
    User,
    Phone,
    Mail,
    Share2,
    AlertCircle,
    Loader2,
    Camera,
    ArrowLeft,
    ArrowRight,
    X
} from "lucide-react";

const BikeDetails = () => {
    const { uid } = useParams(); // Cambiado de 'id' a 'uid' para coincidir con App.js
    const navigate = useNavigate();

    const [bike, setBike] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeImage, setActiveImage] = useState(0);
    const [allImages, setAllImages] = useState([]);
    const [showGallery, setShowGallery] = useState(false);

    // Helper function to safely calculate days since report
    const calculateDaysSince = (reportDate) => {
        if (!reportDate) return 0;

        try {
            let date;
            if (reportDate?.toDate && typeof reportDate.toDate === 'function') {
                // Firestore Timestamp
                date = reportDate.toDate();
            } else if (reportDate instanceof Date) {
                // JS Date
                date = reportDate;
            } else {
                // String or number
                date = new Date(reportDate);
            }

            if (isNaN(date.getTime())) {
                return 0;
            }

            return Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
        } catch (err) {
            console.error("Error calculating days:", err);
            return 0;
        }
    };

    const mapContainerStyle = {
        width: "100%",
        height: "300px",
    };

    useEffect(() => {
        const fetchBikeDetails = async () => {
            try {
                setLoading(true);

                if (!uid) {
                    setError("ID de bicicleta no válido.");
                    setLoading(false);
                    return;
                }

                console.log("Intentando obtener bicicleta con ID:", uid);

                // Enfoque alternativo: obtener todos los documentos y buscar el que coincida
                const querySnapshot = await getDocs(collection(db, "Blacklist"));
                console.log("Total de documentos en Blacklist:", querySnapshot.size);

                let bikeDoc = null;
                querySnapshot.forEach((doc) => {
                    console.log("Revisando documento con ID:", doc.id);
                    if (doc.id === uid) {
                        console.log("¡Documento encontrado!", doc.id);
                        bikeDoc = doc;
                    }
                });

                if (!bikeDoc) {
                    console.log("No se encontró documento con ID:", uid);
                    setError(`No se pudo encontrar la bicicleta con ID ${uid}. Por favor, verifica que el ID sea correcto.`);
                    setLoading(false);
                    return;
                }

                const bikeData = {
                    id: bikeDoc.id,
                    ...bikeDoc.data(),
                    // Aseguramos que estos campos siempre existan
                    locationCoords: bikeDoc.data().locationCoords || { lat: 19.432608, lng: -99.133209 },
                    moreImages: bikeDoc.data().moreImages || []
                };

                console.log("Datos de bicicleta obtenidos:", bikeData);

                setBike(bikeData);

                // Prepare all images array for gallery
                const images = [];
                if (bikeData.mainImageUrl) images.push(bikeData.mainImageUrl);
                if (bikeData.moreImages && bikeData.moreImages.length > 0) {
                    images.push(...bikeData.moreImages);
                }

                setAllImages(images);
            } catch (err) {
                console.error("Error detallado al cargar bicicleta:", err);
                setError(`No se pudo cargar la información de la bicicleta: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchBikeDetails();
    }, [uid]);

    const formatDate = (dateString) => {
        if (!dateString) return "Fecha no disponible";

        const date = new Date(dateString);
        return date.toLocaleDateString("es-MX", {
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: `Bicicleta robada: ${bike.name}`,
                text: `Ayuda a encontrar esta bicicleta robada: ${bike.brand} ${bike.model} ${bike.color}`,
                url: window.location.href
            }).catch(err => console.error('Error compartiendo:', err));
        } else {
            // Fallback for browsers that don't support the Web Share API
            navigator.clipboard.writeText(window.location.href);
            alert("Enlace copiado al portapapeles");
        }
    };

    const nextImage = () => {
        setActiveImage((prev) => (prev === allImages.length - 1 ? 0 : prev + 1));
    };

    const prevImage = () => {
        setActiveImage((prev) => (prev === 0 ? allImages.length - 1 : prev - 1));
    };

    const openGallery = (index = 0) => {
        setActiveImage(index);
        setShowGallery(true);
        document.body.style.overflow = 'hidden';
    };

    const closeGallery = () => {
        setShowGallery(false);
        document.body.style.overflow = 'auto';
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="flex flex-col items-center">
                    <Loader2 className="h-10 w-10 text-black animate-spin" />
                    <p className="mt-4 text-gray-700">Cargando información de la bicicleta...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center p-4">
                <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-6 text-center">
                    <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">Bicicleta no encontrada</h2>
                    <p className="text-gray-700 mb-6">{error}</p>
                    <button
                        onClick={() => navigate("/bikefinder")}
                        className="flex items-center justify-center px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors w-full"
                    >
                        <ChevronLeft className="h-5 w-5 mr-2" />
                        Volver al listado
                    </button>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="max-w-5xl mx-auto px-4 py-8">
                {/* Back button */}
                <button
                    onClick={() => navigate("/bikefinder")}
                    className="flex items-center text-gray-600 hover:text-black mb-6 transition-colors"
                >
                    <ChevronLeft className="h-5 w-5 mr-1" />
                    Volver al listado
                </button>

                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    {/* Header */}
                    <div className="bg-gradient-to-r from-gray-800 to-black py-6 px-6">
                        <h1 className="text-2xl font-bold text-white mb-1 flex items-center">
                            <Bike className="mr-3 h-6 w-6" />
                            {bike.name}
                        </h1>
                        <div className="flex flex-wrap items-center gap-3 text-gray-300">
                            <div className="flex items-center">
                                <Calendar className="h-4 w-4 mr-1.5" />
                                <span>Robada el {formatDate(bike.theftDate)}</span>
                            </div>
                            <span className="text-gray-400">•</span>
                            <div className="flex items-center">
                                <MapPin className="h-4 w-4 mr-1.5" />
                                <span>{bike.location || "Ubicación no especificada"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="p-6">
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                            {/* Left Column: Images */}
                            <div className="lg:col-span-2">
                                {/* Main Image */}
                                <div className="mb-4 relative">
                                    {bike.mainImageUrl ? (
                                        <div
                                            className="aspect-video bg-gray-100 rounded-lg overflow-hidden cursor-pointer"
                                            onClick={() => openGallery(0)}
                                        >
                                            <img
                                                src={bike.mainImageUrl}
                                                alt={bike.name}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    ) : (
                                        <div className="aspect-video bg-gray-100 rounded-lg flex items-center justify-center">
                                            <div className="text-center">
                                                <Camera className="h-12 w-12 text-gray-400 mx-auto mb-2" />
                                                <p className="text-gray-500">Sin imagen disponible</p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Thumbnails */}
                                {bike.moreImages && bike.moreImages.length > 0 && (
                                    <div className="grid grid-cols-5 gap-2">
                                        {bike.mainImageUrl && (
                                            <div
                                                className={`aspect-square bg-gray-100 rounded-md overflow-hidden cursor-pointer ${activeImage === 0 ? 'ring-2 ring-black' : ''}`}
                                                onClick={() => openGallery(0)}
                                            >
                                                <img
                                                    src={bike.mainImageUrl}
                                                    alt={`${bike.name} - Principal`}
                                                    className="w-full h-full object-cover"
                                                />
                                            </div>
                                        )}
                                        {bike.moreImages.map((image, index) => (
                                            <div
                                                key={index}
                                                className={`aspect-square bg-gray-100 rounded-md overflow-hidden cursor-pointer ${activeImage === index + 1 ? 'ring-2 ring-black' : ''}`}
                                                onClick={() => openGallery(index + 1)}
                                            >
                                                <img
                                                    src={image}
                                                    alt={`${bike.name} - ${index + 1}`}
                                                    className="w-full h-full object-cover"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Details Section */}
                                <div className="mt-8">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4">Características</h2>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-sm font-medium text-gray-500 mb-2">Marca</h3>
                                            <p className="text-gray-900">{bike.brand || "No especificada"}</p>
                                        </div>
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-sm font-medium text-gray-500 mb-2">Modelo</h3>
                                            <p className="text-gray-900">{bike.model || "No especificado"}</p>
                                        </div>
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-sm font-medium text-gray-500 mb-2">Color</h3>
                                            <p className="text-gray-900">{bike.color || "No especificado"}</p>
                                        </div>
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-sm font-medium text-gray-500 mb-2 flex items-center">
                                                <Hash className="h-4 w-4 mr-1" />
                                                Número de Serie
                                            </h3>
                                            <p className="text-gray-900 font-mono">{bike.serialNumber || "No disponible"}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Description */}
                                <div className="mt-8">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4">Descripción</h2>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <p className="text-gray-700 whitespace-pre-line">
                                            {bike.description || "No hay descripción disponible para esta bicicleta."}
                                        </p>
                                    </div>
                                </div>

                                {/* Location Map */}
                                <div className="mt-8">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                                        <MapPin className="h-5 w-5 mr-2 text-gray-700" />
                                        Ubicación del Robo
                                    </h2>

                                    <div className="bg-gray-50 p-4 rounded-lg mb-4">
                                        <p className="text-gray-700">
                                            {bike.location || "Ubicación exacta no especificada"}
                                        </p>
                                    </div>

                                    {bike.locationCoords && (
                                        <div className="h-[300px] rounded-lg overflow-hidden border border-gray-300">
                                            <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    center={bike.locationCoords}
                                                    zoom={15}
                                                >
                                                    <Marker position={bike.locationCoords} />
                                                </GoogleMap>
                                            </LoadScript>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Right Column: Actions and Contact */}
                            <div>
                                {/* Status Card */}
                                <div className="bg-gray-50 p-5 rounded-xl mb-6">
                                    <h3 className="text-lg font-semibold text-gray-900 mb-3">Estado</h3>
                                    <div className="flex items-center bg-yellow-100 text-yellow-800 px-3 py-2 rounded-md">
                                        <AlertCircle className="h-5 w-5 mr-2" />
                                        <span>Reportada como robada</span>
                                    </div>

                                    {bike.reportDate && (
                                        <div className="mt-4 flex items-center text-gray-600 text-sm">
                                            <Clock className="h-4 w-4 mr-2" />
                                            <span>
                                                Reportada hace {calculateDaysSince(bike.reportDate)} días
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {/* Reward Card */}
                                {bike.reward && (
                                    <div className="bg-green-50 p-5 rounded-xl mb-6 border border-green-100">
                                        <h3 className="text-lg font-semibold text-gray-900 mb-2">Recompensa</h3>
                                        <div className="flex items-center">
                                            <DollarSign className="h-6 w-6 text-green-600 mr-2" />
                                            <span className="text-2xl font-bold text-green-600">
                                                ${bike.rewardAmount || ""}
                                            </span>
                                        </div>
                                        <p className="mt-2 text-sm text-gray-600">
                                            Se ofrece recompensa por información que lleve a la recuperación de esta bicicleta.
                                        </p>
                                    </div>
                                )}

                                {/* Contact Card */}
                                {bike.contactInfo && (
                                    <div className="bg-gray-50 p-5 rounded-xl mb-6">
                                        <h3 className="text-lg font-semibold text-gray-900 mb-3 flex items-center">
                                            <User className="h-5 w-5 mr-2" />
                                            Información de Contacto
                                        </h3>

                                        {bike.contactInfo.name && (
                                            <div className="mb-3">
                                                <h4 className="text-sm font-medium text-gray-500 mb-1">Nombre</h4>
                                                <p className="text-gray-900">{bike.contactInfo.name}</p>
                                            </div>
                                        )}

                                        {bike.contactInfo.phone && (
                                            <div className="mb-3">
                                                <h4 className="text-sm font-medium text-gray-500 mb-1 flex items-center">
                                                    <Phone className="h-4 w-4 mr-1" />
                                                    Teléfono
                                                </h4>
                                                <a href={`tel:${bike.contactInfo.phone}`} className="text-gray-900 hover:text-black">
                                                    {bike.contactInfo.phone}
                                                </a>
                                            </div>
                                        )}

                                        {bike.contactInfo.email && (
                                            <div className="mb-3">
                                                <h4 className="text-sm font-medium text-gray-500 mb-1 flex items-center">
                                                    <Mail className="h-4 w-4 mr-1" />
                                                    Correo Electrónico
                                                </h4>
                                                <a href={`mailto:${bike.contactInfo.email}`} className="text-gray-900 hover:text-black break-all">
                                                    {bike.contactInfo.email}
                                                </a>
                                            </div>
                                        )}

                                        {bike.contactInfo.preferredContact && (
                                            <div className="mt-4 text-sm text-gray-600 italic">
                                                {bike.contactInfo.preferredContact === 'email' && 'Prefiere ser contactado por correo electrónico.'}
                                                {bike.contactInfo.preferredContact === 'phone' && 'Prefiere ser contactado por teléfono.'}
                                                {bike.contactInfo.preferredContact === 'both' && 'Puede ser contactado por correo o teléfono.'}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Action Buttons */}
                                <div className="space-y-3">
                                    <button
                                        onClick={handleShare}
                                        className="flex items-center justify-center w-full px-4 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                                    >
                                        <Share2 className="h-5 w-5 mr-2" />
                                        Compartir anuncio
                                    </button>

                                    {bike.contactInfo && bike.contactInfo.phone && (
                                        <a
                                            href={`tel:${bike.contactInfo.phone}`}
                                            className="flex items-center justify-center w-full px-4 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                                        >
                                            <Phone className="h-5 w-5 mr-2" />
                                            Llamar al propietario
                                        </a>
                                    )}

                                    {bike.contactInfo && bike.contactInfo.email && (
                                        <a
                                            href={`mailto:${bike.contactInfo.email}?subject=Información sobre bicicleta robada: ${bike.name}&body=Hola, vi tu anuncio de la bicicleta ${bike.name} en SpeedHub y tengo información que podría ayudarte.`}
                                            className="flex items-center justify-center w-full px-4 py-3 bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 transition-colors"
                                        >
                                            <Mail className="h-5 w-5 mr-2" />
                                            Enviar correo
                                        </a>
                                    )}
                                </div>

                                {/* Report time */}
                                <div className="mt-6 text-center text-xs text-gray-500">
                                    ID: {uid}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Fullscreen Gallery */}
            {showGallery && allImages.length > 0 && (
                <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
                    <button
                        className="absolute top-4 right-4 text-white p-2 rounded-full hover:bg-gray-800 z-10"
                        onClick={closeGallery}
                    >
                        <X className="h-8 w-8" />
                    </button>

                    <button
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full hover:bg-gray-800 z-10"
                        onClick={prevImage}
                    >
                        <ArrowLeft className="h-8 w-8" />
                    </button>

                    <div className="w-full max-w-4xl p-4 h-full flex items-center justify-center">
                        <img
                            src={allImages[activeImage]}
                            alt={`${bike.name} - Imagen ${activeImage + 1}`}
                            className="max-h-full max-w-full object-contain"
                        />
                    </div>

                    <button
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full hover:bg-gray-800 z-10"
                        onClick={nextImage}
                    >
                        <ArrowRight className="h-8 w-8" />
                    </button>

                    <div className="absolute bottom-4 left-0 right-0 text-center text-white">
                        {activeImage + 1} / {allImages.length}
                    </div>
                </div>
            )}
        </>
    );
};

export default BikeDetails;