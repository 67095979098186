import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { routes } from "./ControlLinks";
import {
  Home,
  Menu,
  X,
  ChevronLeft,
  ChevronRight,
  LogOut,
  User,
  Bell,
  Settings,
} from "lucide-react";

const ControlPanel = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      if (mobile) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Execute on start
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleGoHome = () => {
    navigate("/home");
    if (isMobile) {
      setMobileMenuOpen(false);
    }
  };

  const navigateTo = (path) => {
    navigate(path);
    if (isMobile) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <div className="flex h-screen bg-slate-50">
      {/* Sidebar - Desktop */}
      <div className="hidden md:flex flex-col bg-slate-900 text-white transition-all duration-300 ease-in-out">
        {/* Sidebar Header */}
        <div className="flex items-center justify-between h-16 px-4 border-b border-slate-800/50">
          {sidebarOpen && (
            <div className="text-xl font-bold bg-gradient-to-r from-blue-400 to-indigo-500 bg-clip-text text-transparent">
              SpeedHub
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className="p-1.5 rounded-lg bg-slate-800/60 hover:bg-slate-700 focus:outline-none transition-colors"
          >
            {sidebarOpen ? (
              <ChevronLeft size={18} />
            ) : (
              <ChevronRight size={18} />
            )}
          </button>
        </div>

        {/* Sidebar Content */}
        <div className="flex-1 overflow-y-auto py-4">
          {routes.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6">
              {sidebarOpen && (
                <div className="px-4 mb-2 flex items-center">
                  <span className="mr-2">{section.icon || null}</span>
                  <h3 className="uppercase text-xs font-semibold text-slate-400">
                    {section.category}
                  </h3>
                </div>
              )}
              <div>
                {section.links.map((link, index) => (
                  <button
                    key={index}
                    onClick={() => navigateTo(link.path)}
                    className={`
                      w-full flex items-center py-2 px-4 text-slate-300 transition-colors
                      ${!sidebarOpen ? "justify-center" : ""}
                      ${index === 0 ? "mt-1" : ""}
                      hover:bg-slate-800/70 hover:text-white rounded-md mx-2
                    `}
                  >
                    <span
                      className={`text-slate-400 ${sidebarOpen ? "mr-3" : ""}`}
                    >
                      {link.icon}
                    </span>
                    {sidebarOpen && (
                      <span className="truncate text-sm">{link.label}</span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Quick Actions */}
        {sidebarOpen && (
          <div className="p-4 border-t border-slate-800/50">
            <button
              onClick={handleGoHome}
              className="w-full flex items-center justify-center py-2 px-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-md hover:from-blue-600 hover:to-indigo-700 transition-all"
            >
              <Home size={18} className="mr-2" />
              <span className="text-sm font-medium">Home</span>
            </button>
          </div>
        )}
      </div>

      {/* Mobile Menu Overlay */}
      {isMobile && mobileMenuOpen && (
        <div
          className="fixed inset-0 bg-slate-900/70 backdrop-blur-sm z-40"
          onClick={toggleMobileMenu}
        />
      )}

      {/* Mobile Sidebar */}
      <div
        className={`
          fixed top-0 left-0 h-screen bg-slate-900 text-white z-50 transition-all duration-300 ease-in-out transform
          ${mobileMenuOpen ? "translate-x-0 shadow-2xl" : "-translate-x-full"}
          md:hidden
        `}
      >
        {/* Mobile Sidebar Header */}
        <div className="flex items-center justify-between h-16 px-4 border-b border-slate-800/50">
          <div className="text-xl font-bold bg-gradient-to-r from-blue-400 to-indigo-500 bg-clip-text text-transparent">
            SpeedHub
          </div>
          <button
            onClick={toggleMobileMenu}
            className="p-1.5 rounded-lg bg-slate-800/60 hover:bg-slate-700 focus:outline-none"
          >
            <X size={18} />
          </button>
        </div>

        {/* Mobile Sidebar Content */}
        <div className="overflow-y-auto h-[calc(100vh-4rem)]">
          {routes.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
              className="py-2 border-b border-slate-800/30"
            >
              <div className="px-4 py-2 flex items-center">
                <span className="mr-2">{section.icon || null}</span>
                <h3 className="text-xs font-medium text-slate-300 uppercase">
                  {section.category}
                </h3>
              </div>
              <div className="px-2">
                {section.links.map((link, index) => (
                  <button
                    key={index}
                    onClick={() => navigateTo(link.path)}
                    className="w-full flex items-center py-2 px-3 text-slate-300 hover:bg-slate-800/70 rounded-md transition-colors text-sm"
                  >
                    <span className="text-slate-400 mr-3">{link.icon}</span>
                    <span>{link.label}</span>
                  </button>
                ))}
              </div>
            </div>
          ))}

          {/* Mobile Quick Actions */}
          <div className="p-4 mt-2">
            <button
              onClick={handleGoHome}
              className="w-full flex items-center justify-center py-2 px-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded-md hover:from-blue-600 hover:to-indigo-700 transition-all"
            >
              <Home size={18} className="mr-2" />
              <span className="text-sm font-medium">Home</span>
            </button>
          </div>
        </div>
      </div>

      {/* Main Content Area with Mobile Menu Toggle */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Small navbar for mobile menu toggle only */}
        <div className="bg-white shadow-sm z-10 flex md:hidden h-14 items-center px-4">
          <button
            onClick={toggleMobileMenu}
            className="p-2 rounded-md text-slate-600 hover:bg-slate-100 focus:outline-none"
          >
            <Menu size={22} />
          </button>
          <div className="ml-3 text-lg font-semibold text-slate-800 bg-gradient-to-r from-blue-600 to-indigo-700 bg-clip-text text-transparent">
            SpeedHub
          </div>

          <div className="ml-auto flex space-x-2">
            <button className="p-2 rounded-full text-slate-600 hover:bg-slate-100 focus:outline-none">
              <Bell size={18} />
            </button>
            <button className="p-2 rounded-full text-slate-600 hover:bg-slate-100 focus:outline-none">
              <User size={18} />
            </button>
          </div>
        </div>

        {/* Main Content */}
        <main className="flex-1 overflow-y-auto bg-slate-50 p-4 md:p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default ControlPanel;
