import React, { useState } from "react";
import {
  Typography,
  Container,
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Collapse,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import TimerIcon from "@mui/icons-material/Timer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorIcon from "@mui/icons-material/Error"; // Icono rojo para DNF

const medalColors = {
  gold: "linear-gradient(45deg, #FFD700, #FFA500)",
  silver: "linear-gradient(45deg, #C0C0C0, #A9A9A9)",
  bronze: "linear-gradient(45deg, #CD7F32, #8B4513)",
  default: "linear-gradient(45deg, #FFFFFF, #F0F0F0)",
};

const TimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.85)",
  color: "#fff",
  fontWeight: "bold",
  fontSize: "0.75rem",
  padding: theme.spacing(0.5),
}));

const AdditionalRunItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0.5, 0),
  backgroundColor: "rgba(0, 0, 0, 0.03)",
  borderRadius: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const formatTimeDifference = (differenceInMs) => {
  if (!differenceInMs) return null;

  const ms = differenceInMs % 1000;
  const seconds = Math.floor((differenceInMs / 1000) % 60);
  const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);

  if (minutes > 0) {
    return `${minutes}m ${seconds}s ${ms}ms`;
  } else if (seconds > 0) {
    return `${seconds}s ${ms}ms`;
  } else {
    return `${ms}ms`;
  }
};

const EventLeaderboardUI = ({
  loading,
  runs,
  ownerData,
  getTimeDifference,
  allowMultilaps,
  getAdditionalRunsForRider,
  currentTrackId,
}) => {
  const [expandedRiders, setExpandedRiders] = useState({});

  const toggleRiderExpand = (riderId) => {
    setExpandedRiders((prev) => ({
      ...prev,
      [riderId]: !prev[riderId],
    }));
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3, mb: 6 }}>
        {[...Array(6)].map((_, index) => (
          <Box
            key={index}
            sx={{ height: 80, bgcolor: "grey.200", mb: 1, borderRadius: 1 }}
          />
        ))}
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <List>
        {runs.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ width: "100%" }}>
            No hay resultados disponibles para este evento.
          </Typography>
        ) : (
          runs.map((run, index) => {
            const position = index + 1;
            const backgroundColor =
              position <= 3
                ? medalColors[Object.keys(medalColors)[position - 1]]
                : medalColors.default;

            let timeDifference = null;
            if (position > 1) {
              const previousRun = runs[index - 1];
              const differenceInMs = getTimeDifference(
                previousRun.ElapsedTime,
                run.ElapsedTime
              );
              timeDifference = formatTimeDifference(differenceInMs);
            }

            const owner = ownerData[run.NFCIdentifier] || {};
            const isExpanded = !!expandedRiders[run.NFCIdentifier];

            // Obtener todas las carreras adicionales para este corredor si allowMultilaps es true
            const additionalRuns = allowMultilaps
              ? getAdditionalRunsForRider(
                  run.NFCIdentifier,
                  currentTrackId
                ).slice(1) // Omitir la primera que ya se muestra
              : [];

            const hasAdditionalRuns = additionalRuns.length > 0;

            return (
              <React.Fragment key={run.id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    background: backgroundColor,
                    mb: 1,
                    borderRadius: hasAdditionalRuns ? "8px 8px 0 0" : 1,
                    padding: "10px 15px",
                    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                    "@media (max-width: 600px)": {
                      padding: "8px",
                    },
                  }}
                >
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <Avatar
                      src={owner.PhotoUrl}
                      alt={owner.Owner || "Desconocido"}
                      sx={{
                        width: 50,
                        height: 50,
                        border: "2px solid #FFF",
                        "@media (max-width: 600px)": {
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          "@media (max-width: 600px)": {
                            fontSize: "0.85rem",
                          },
                        }}
                      >
                        {owner.Owner || "Desconocido"}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TimerIcon
                            sx={{
                              mr: 1,
                              color: "#000",
                              fontSize: 25,
                              "@media (max-width: 600px)": {
                                fontSize: 18,
                              },
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              color: "#000",
                              fontSize: "1.1rem",
                              "@media (max-width: 600px)": {
                                fontSize: "0.95rem",
                              },
                            }}
                          >
                            {run.ElapsedTime === "DNF" ? (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <ErrorIcon sx={{ color: "red", mr: 0.5 }} />
                                <span>DNF</span>
                              </Box>
                            ) : run.ElapsedTime === "00:00:00.001" ? (
                              <Tooltip title="Categoría exenta de esta pista">
                                <Chip
                                  label="Exento"
                                  size="small"
                                  sx={{
                                    backgroundColor: "rgba(25, 118, 210, 0.8)",
                                    color: "white",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              run.ElapsedTime
                            )}
                          </Typography>
                          {timeDifference && (
                            <TimeChip
                              label={`+${timeDifference}`}
                              sx={{ ml: 2 }}
                            />
                          )}
                        </Box>
                      </>
                    }
                  />
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        alignSelf: "center",
                        mr: hasAdditionalRuns ? 1 : 0,
                        color: "#000",
                        "@media (max-width: 600px)": {
                          fontSize: "1.5rem",
                        },
                      }}
                    >
                      #{position}
                    </Typography>

                    {hasAdditionalRuns && (
                      <IconButton
                        size="small"
                        onClick={() => toggleRiderExpand(run.NFCIdentifier)}
                        sx={{
                          backgroundColor: "rgba(0,0,0,0.05)",
                          "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                        }}
                      >
                        {isExpanded ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </ListItem>

                {/* Collapse panel para mostrar las vueltas adicionales */}
                {hasAdditionalRuns && (
                  <Collapse
                    in={isExpanded}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      mb: 1,
                      backgroundColor: "rgba(255,255,255,0.9)",
                      borderRadius: "0 0 8px 8px",
                      border: "1px solid rgba(0,0,0,0.05)",
                      borderTop: "none",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 1, fontWeight: "bold" }}
                      >
                        Todas las vueltas:
                      </Typography>

                      {/* La primera vuelta (mejor tiempo) */}
                      <AdditionalRunItem
                        sx={{ backgroundColor: "rgba(255, 215, 0, 0.1)" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Vuelta 1 (mejor tiempo)
                        </Typography>
                        <Typography>
                          {run.ElapsedTime === "00:00:00.001" ? (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Chip
                                label="Exento"
                                size="small"
                                sx={{
                                  backgroundColor: "rgba(25, 118, 210, 0.8)",
                                  color: "white",
                                  fontSize: "0.7rem",
                                }}
                              />
                            </Box>
                          ) : (
                            run.ElapsedTime
                          )}
                        </Typography>
                      </AdditionalRunItem>

                      {/* Vueltas adicionales */}
                      {additionalRuns.map((additionalRun, runIndex) => {
                        // Calcular diferencia con respecto al mejor tiempo
                        const diffWithBest = getTimeDifference(
                          run.ElapsedTime,
                          additionalRun.ElapsedTime
                        );

                        return (
                          <AdditionalRunItem key={additionalRun.id}>
                            <Typography>Vuelta {runIndex + 2}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ mr: 1 }}>
                                {additionalRun.ElapsedTime === "DNF" ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ErrorIcon
                                      sx={{
                                        color: "red",
                                        mr: 0.5,
                                        fontSize: "0.9rem",
                                      }}
                                    />
                                    <span>DNF</span>
                                  </Box>
                                ) : (
                                  additionalRun.ElapsedTime
                                )}
                              </Typography>
                              {diffWithBest &&
                                additionalRun.ElapsedTime !== "DNF" && (
                                  <TimeChip
                                    label={`+${formatTimeDifference(
                                      diffWithBest
                                    )}`}
                                    size="small"
                                    sx={{
                                      height: "20px",
                                      fontSize: "0.7rem",
                                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                                    }}
                                  />
                                )}
                            </Box>
                          </AdditionalRunItem>
                        );
                      })}
                    </Box>
                  </Collapse>
                )}

                {index !== runs.length - 1 && !hasAdditionalRuns && (
                  <Divider variant="inset" />
                )}
              </React.Fragment>
            );
          })
        )}
      </List>
    </Container>
  );
};

export default EventLeaderboardUI;
