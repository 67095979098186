import React, { useState, useRef, useEffect } from 'react';
import { Camera, RefreshCw, Check, ListFilter, Filter, Clock, User, Award } from 'lucide-react';
import * as Tesseract from 'tesseract.js';

const OCRReceiver = () => {
    // Camera and capture states
    const [stream, setStream] = useState(null);
    const [cameraActive, setCameraActive] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [autoCapture, setAutoCapture] = useState(false);
    const [captureInterval, setCaptureInterval] = useState(2000); // 2 seconds
    const [lastCapture, setLastCapture] = useState(null);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false);
    const [availableCameras, setAvailableCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');

    // OCR states
    const [ocrResult, setOcrResult] = useState(null);
    const [ocrWorker, setOcrWorker] = useState(null);
    const [ocrReady, setOcrReady] = useState(false);
    const [ocrProgress, setOcrProgress] = useState(0);

    // Data and configuration states
    const [detectedNumbers, setDetectedNumbers] = useState([]);
    const [filterDuplicates, setFilterDuplicates] = useState(true);
    const [filterNonNumeric, setFilterNonNumeric] = useState(true);
    const [numberMinLength, setNumberMinLength] = useState(2);
    const [numberMaxLength, setNumberMaxLength] = useState(4);
    const [showingRunnerDetails, setShowingRunnerDetails] = useState(false);
    const [selectedRunner, setSelectedRunner] = useState(null);

    // References
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const intervalRef = useRef(null);

    // Initialize Tesseract worker
    useEffect(() => {
        const initWorker = async () => {
            try {
                console.log("Inicializando Tesseract...");

                // Using the Tesseract worker API for version 2.x/3.x
                // Instead of passing callbacks, we'll handle progress with promises
                const worker = Tesseract.createWorker();

                // Initialize and set up progress tracking separately
                await worker.load();
                await worker.loadLanguage('eng');
                await worker.initialize('eng');

                // For version 2.x, we don't need a scheduler for a single worker
                console.log("Tesseract inicializado correctamente");

                setOcrWorker(worker);
                setOcrReady(true);

                console.log("OCR está listo, esperando que el usuario active la cámara");
            } catch (error) {
                console.error("Error al inicializar Tesseract:", error);
            }
        };

        initWorker();

        // Cleanup at unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            if (ocrWorker) {
                // Limpiar trabajadores de Tesseract
                try {
                    ocrWorker.terminate();
                } catch (e) {
                    console.error("Error al terminar workers de OCR:", e);
                }
            }

            stopCamera();
        };
    }, []);

    // Get available cameras
    const getAvailableCameras = async () => {
        try {
            // First request user permission explicitly
            await navigator.mediaDevices.getUserMedia({ video: true });

            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            setAvailableCameras(videoDevices);

            if (videoDevices.length > 0) {
                setSelectedCamera(videoDevices[0].deviceId);
                // Explicitly start camera with the first device
                startCamera(videoDevices[0].deviceId);
            }
        } catch (error) {
            console.error("Error getting camera devices:", error);
            alert("No se pudo acceder a la cámara. Por favor, verifica los permisos del navegador.");
            setCameraPermissionDenied(true);
        }
    };

    // Start camera
    const startCamera = async (deviceId) => {
        try {
            setCameraPermissionDenied(false);

            // Force the video element to be visible
            if (videoRef.current) {
                videoRef.current.style.display = 'block';
            }

            const constraints = {
                audio: false,
                video: {
                    deviceId: deviceId ? { exact: deviceId } : undefined,
                    facingMode: deviceId ? undefined : "environment", // Use back camera if available and no specific device is selected
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                }
            };

            console.log("Requesting camera access with constraints:", constraints);

            // Explicitly request permissions with a more visible alert for the user
            alert("La aplicación necesita acceder a tu cámara. Por favor, acepta el permiso cuando el navegador lo solicite.");

            const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            console.log("Camera access granted, stream obtained:", mediaStream);

            setStream(mediaStream);
            setCameraActive(true);

            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                videoRef.current.muted = true; // Ensure video is muted

                // Explicitly play the video with error handling
                try {
                    await videoRef.current.play();
                    console.log("Video playback started successfully");
                } catch (e) {
                    console.error("Video play error:", e);
                    alert("Error al reproducir el video. Intenta recargar la página.");
                }
            }

            if (autoCapture) {
                startAutoCapture();
            }
        } catch (error) {
            console.error("Error accessing camera:", error);
            setCameraPermissionDenied(true);
            alert("No se pudo acceder a la cámara. Por favor verifica los permisos del navegador y recarga la página.");
        }
    };

    // Handle camera change
    const handleCameraChange = (e) => {
        const newDeviceId = e.target.value;
        setSelectedCamera(newDeviceId);

        if (cameraActive) {
            stopCamera();
            setTimeout(() => startCamera(newDeviceId), 500);
        } else {
            startCamera(newDeviceId);
        }
    };

    // Stop camera
    const stopCamera = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
            setCameraActive(false);

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
    };

    // Start automatic capture
    const startAutoCapture = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            captureImage();
        }, captureInterval);
    };

    // Toggle automatic capture
    const toggleAutoCapture = () => {
        const newAutoCapture = !autoCapture;
        setAutoCapture(newAutoCapture);

        if (newAutoCapture && cameraActive) {
            startAutoCapture();
        } else if (!newAutoCapture && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    // Capture image
    const captureImage = async () => {
        if (!cameraActive || !videoRef.current || !canvasRef.current || !ocrReady || processing) {
            return;
        }

        setProcessing(true);

        try {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            // Adjust canvas size to video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw current video frame to canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Get image as data URL
            const imageData = canvas.toDataURL('image/png');
            setLastCapture(imageData);

            // Process image with OCR
            await processImageWithOCR(canvas, imageData);
        } catch (error) {
            console.error("Error capturing image:", error);
        } finally {
            setProcessing(false);
        }
    };

    // Process image with OCR
    const processImageWithOCR = async (canvas, imageData) => {
        if (!ocrWorker || !ocrReady) return;

        try {
            // Set processing state first
            setOcrProgress(0);

            // Convert canvas to blob to make sure we're passing recognized data formats
            const blob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/png');
            });

            // Progress monitor
            let lastProgress = 0;
            const progressInterval = setInterval(() => {
                // Simple progress simulation
                if (lastProgress < 95) {
                    lastProgress += 5;
                    setOcrProgress(lastProgress);
                }
            }, 200);

            // Use direct recognize method for Tesseract.js v2.x/v3.x
            const result = await ocrWorker.recognize(blob);

            // Clear progress interval
            clearInterval(progressInterval);

            console.log("OCR Result:", result);

            setOcrResult(result);
            setOcrProgress(100); // Set to 100% when complete

            // Extract numbers from recognized text
            const text = result.data.text.trim();
            processRecognizedText(text, imageData);
        } catch (error) {
            console.error("OCR Error:", error);
            setOcrProgress(0);
        }
    };

    // Process recognized text
    const processRecognizedText = (text, imageData) => {
        if (!text) return;

        // Split text into lines
        const lines = text.split('\n');

        // Process each line
        let newNumbers = [];

        lines.forEach(line => {
            // Clean the line
            const cleanedLine = line.trim();

            // If numeric filter is on, remove non-numeric characters
            let numberCandidate = filterNonNumeric
                ? cleanedLine.replace(/[^\d]/g, '')
                : cleanedLine;

            // Check minimum and maximum length
            if (numberCandidate.length >= numberMinLength &&
                numberCandidate.length <= numberMaxLength) {

                // Check for duplicates if filter is on
                const isDuplicate = filterDuplicates &&
                    (detectedNumbers.some(item => item.number === numberCandidate) ||
                        newNumbers.some(item => item.number === numberCandidate));

                if (!isDuplicate) {
                    newNumbers.push({
                        id: Date.now() + Math.random(), // Unique ID
                        number: numberCandidate,
                        timestamp: new Date().toISOString(),
                        confidence: calculateConfidence(numberCandidate, ocrResult),
                        image: imageData,
                        notes: ""
                    });
                }
            }
        });

        // If new numbers found, update state
        if (newNumbers.length > 0) {
            setDetectedNumbers(prev => [...newNumbers, ...prev]);

            // Play a success sound if available
            try {
                const audio = new Audio('/beep.mp3');
                audio.play().catch(e => console.log('Audio play not allowed'));
            } catch (e) {
                // Silent fail if audio can't play
            }
        }
    };

    // Calculate confidence level for a number
    const calculateConfidence = (number, result) => {
        if (!result || !result.data) return 0;

        // Get average confidence of characters in the number
        try {
            // For Tesseract.js v2.x/v3.x, confidence can be accessed differently

            // If the result provides a direct confidence value
            if (typeof result.data.confidence === 'number') {
                return Math.round(result.data.confidence);
            }

            // Otherwise try to calculate from words/symbols if available
            let totalConfidence = 0;
            let charCount = 0;

            // Process words if available
            if (Array.isArray(result.data.words)) {
                result.data.words.forEach(word => {
                    if (word.text && word.text.includes(number)) {
                        if (word.confidence) {
                            totalConfidence += word.confidence;
                            charCount++;
                        } else if (Array.isArray(word.symbols)) {
                            word.symbols.forEach(symbol => {
                                if (symbol.confidence) {
                                    totalConfidence += symbol.confidence;
                                    charCount++;
                                }
                            });
                        }
                    }
                });
            }

            // If we have confidence data, use it, otherwise return a default
            return charCount > 0 ? Math.round((totalConfidence / charCount) * 100) : 70;
        } catch (error) {
            console.error("Error calculating confidence:", error);
            return 70; // Default value
        }
    };

    // Format timestamp for display
    const formatTimestamp = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleTimeString();
    };

    // Remove a detected number
    const removeNumber = (id) => {
        setDetectedNumbers(prev => prev.filter(item => item.id !== id));
    };

    // Clear all detected numbers
    const clearAllNumbers = () => {
        setDetectedNumbers([]);
    };

    // Export detected numbers as CSV
    const exportAsCSV = () => {
        if (detectedNumbers.length === 0) return;

        // Create CSV content
        const headers = "Number,Timestamp,Confidence,Notes\n";
        const rows = detectedNumbers.map(item =>
            `${item.number},${item.timestamp},${item.confidence}%,"${item.notes}"`
        ).join("\n");

        const csvContent = "data:text/csv;charset=utf-8," + headers + rows;
        const encodedUri = encodeURI(csvContent);

        // Create link to download
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `runners_${new Date().toISOString().slice(0, 10)}.csv`);
        document.body.appendChild(link);

        // Simulate click
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    // Export all images and data
    const exportFullData = () => {
        if (detectedNumbers.length === 0) return;

        // Create a zip file with all images and data (in a real app)
        alert("In a complete implementation, this would create a ZIP file with all runner images and data.");

        // For this demo we'll just show how it would work
        console.log("Would export full data package with images for", detectedNumbers.length, "runners");
    };

    // Show runner details
    const showRunnerDetails = (runner) => {
        setSelectedRunner(runner);
        setShowingRunnerDetails(true);
    };

    // Update runner notes
    const updateRunnerNotes = (id, notes) => {
        setDetectedNumbers(prev =>
            prev.map(item =>
                item.id === id ? { ...item, notes } : item
            )
        );
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <div className="mb-6 flex justify-between items-center">
                    <h1 className="text-xl font-bold flex items-center">
                        <Award className="h-6 w-6 mr-2 text-blue-600" />
                        Marathon Runner Number OCR
                    </h1>

                    <div className="flex space-x-2">
                        {!cameraActive ? (
                            <button
                                onClick={() => getAvailableCameras()}
                                disabled={!ocrReady}
                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center text-lg font-bold animate-pulse"
                            >
                                <Camera className="h-5 w-5 mr-2" />
                                {ocrReady ? 'ACTIVAR CÁMARA' : 'Cargando OCR...'}
                            </button>
                        ) : (
                            <button
                                onClick={stopCamera}
                                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 flex items-center"
                            >
                                <Camera className="h-4 w-4 mr-2" />
                                Detener Cámara
                            </button>
                        )}
                    </div>
                </div>

                {/* Camera view and controls */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    {/* Camera panel */}
                    <div>
                        <div className="rounded-lg overflow-hidden shadow-md bg-black relative">
                            {availableCameras.length > 1 && (
                                <div className="absolute top-2 right-2 z-10 bg-black bg-opacity-50 rounded p-1">
                                    <select
                                        value={selectedCamera}
                                        onChange={handleCameraChange}
                                        className="text-xs bg-transparent text-white border border-gray-600 rounded p-1"
                                    >
                                        {availableCameras.map((camera, index) => (
                                            <option key={camera.deviceId} value={camera.deviceId}>
                                                Camera {index + 1} {camera.label ? `(${camera.label})` : ''}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <video
                                ref={videoRef}
                                autoPlay
                                playsInline
                                className="w-full h-64 object-cover"
                                style={{ display: cameraActive ? 'block' : 'none' }}
                            />

                            {!cameraActive && (
                                <div className="w-full h-64 flex items-center justify-center bg-gray-800 text-white">
                                    <div className="text-center">
                                        <Camera className="h-12 w-12 mx-auto mb-2 text-gray-300" />
                                        <p className="text-lg">{cameraPermissionDenied
                                            ? "Acceso a la cámara denegado. Verifica los permisos."
                                            : "Presiona \"ACTIVAR CÁMARA\" para comenzar"}</p>
                                    </div>
                                </div>
                            )}

                            {processing && (
                                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                    <div className="text-white text-center">
                                        <RefreshCw className="h-8 w-8 mx-auto mb-2 animate-spin" />
                                        <p>Processing... {ocrProgress}%</p>
                                    </div>
                                </div>
                            )}

                            {/* Hidden canvas for capture */}
                            <canvas ref={canvasRef} className="hidden" />
                        </div>

                        {/* Capture controls */}
                        <div className="mt-4 flex flex-wrap gap-3">
                            <button
                                onClick={captureImage}
                                disabled={!cameraActive || processing}
                                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 flex-1 flex items-center justify-center"
                            >
                                <Camera className="h-4 w-4 mr-2" />
                                Capture
                            </button>

                            <button
                                onClick={toggleAutoCapture}
                                disabled={!cameraActive}
                                className={`px-4 py-2 rounded flex-1 flex items-center justify-center ${autoCapture
                                    ? 'bg-yellow-500 text-white hover:bg-yellow-600'
                                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                                    }`}
                            >
                                <Clock className="h-4 w-4 mr-2" />
                                {autoCapture ? 'Disable Auto' : 'Enable Auto'}
                            </button>
                        </div>

                        {/* Configuration */}
                        <div className="mt-4 bg-white p-4 rounded-lg shadow-sm">
                            <h3 className="font-medium mb-3 flex items-center">
                                <Filter className="h-4 w-4 mr-2 text-gray-500" />
                                Configuration
                            </h3>

                            <div className="space-y-3">
                                <div className="flex items-center justify-between">
                                    <label className="text-sm text-gray-700">Filter duplicates</label>
                                    <div
                                        onClick={() => setFilterDuplicates(!filterDuplicates)}
                                        className={`w-10 h-5 rounded-full p-1 cursor-pointer transition-colors ${filterDuplicates ? 'bg-blue-500' : 'bg-gray-300'
                                            }`}
                                    >
                                        <div className={`w-3 h-3 rounded-full bg-white transform transition-transform ${filterDuplicates ? 'translate-x-5' : ''
                                            }`}></div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <label className="text-sm text-gray-700">Numbers only</label>
                                    <div
                                        onClick={() => setFilterNonNumeric(!filterNonNumeric)}
                                        className={`w-10 h-5 rounded-full p-1 cursor-pointer transition-colors ${filterNonNumeric ? 'bg-blue-500' : 'bg-gray-300'
                                            }`}
                                    >
                                        <div className={`w-3 h-3 rounded-full bg-white transform transition-transform ${filterNonNumeric ? 'translate-x-5' : ''
                                            }`}></div>
                                    </div>
                                </div>

                                <div>
                                    <label className="text-sm text-gray-700 block mb-1">
                                        Capture interval: {(captureInterval / 1000).toFixed(1)}s
                                    </label>
                                    <input
                                        type="range"
                                        min="500"
                                        max="5000"
                                        step="500"
                                        value={captureInterval}
                                        onChange={(e) => setCaptureInterval(Number(e.target.value))}
                                        className="w-full"
                                    />
                                </div>

                                <div className="grid grid-cols-2 gap-3">
                                    <div>
                                        <label className="text-sm text-gray-700 block mb-1">
                                            Min. digits
                                        </label>
                                        <input
                                            type="number"
                                            min="1"
                                            max="10"
                                            value={numberMinLength}
                                            onChange={(e) => setNumberMinLength(Number(e.target.value))}
                                            className="w-full border rounded px-2 py-1"
                                        />
                                    </div>

                                    <div>
                                        <label className="text-sm text-gray-700 block mb-1">
                                            Max. digits
                                        </label>
                                        <input
                                            type="number"
                                            min={numberMinLength}
                                            max="10"
                                            value={numberMaxLength}
                                            onChange={(e) => setNumberMaxLength(Number(e.target.value))}
                                            className="w-full border rounded px-2 py-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Results panel */}
                    <div>
                        {showingRunnerDetails && selectedRunner ? (
                            <div className="bg-white rounded-lg shadow-sm p-4 h-full">
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-lg font-medium flex items-center">
                                        <User className="h-5 w-5 mr-2 text-blue-600" />
                                        Runner #{selectedRunner.number} Details
                                    </h2>
                                    <button
                                        onClick={() => setShowingRunnerDetails(false)}
                                        className="text-gray-500 hover:text-gray-700"
                                    >
                                        ← Back to list
                                    </button>
                                </div>

                                <div className="mb-4">
                                    <img
                                        src={selectedRunner.image}
                                        alt={`Runner ${selectedRunner.number}`}
                                        className="w-full max-h-64 object-contain rounded border"
                                    />
                                </div>

                                <div className="space-y-4">
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500">Runner Number</h3>
                                        <p className="text-3xl font-bold">{selectedRunner.number}</p>
                                    </div>

                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500">Capture Time</h3>
                                        <p>{new Date(selectedRunner.timestamp).toLocaleString()}</p>
                                    </div>

                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500">Confidence</h3>
                                        <div className="flex items-center">
                                            <div className="w-full bg-gray-200 rounded-full h-2.5 mr-2">
                                                <div
                                                    className={`h-2.5 rounded-full ${selectedRunner.confidence > 80 ? 'bg-green-600' :
                                                        selectedRunner.confidence > 60 ? 'bg-yellow-500' : 'bg-red-500'
                                                        }`}
                                                    style={{ width: `${selectedRunner.confidence}%` }}
                                                ></div>
                                            </div>
                                            <span>{selectedRunner.confidence}%</span>
                                        </div>
                                    </div>

                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500">Notes</h3>
                                        <textarea
                                            value={selectedRunner.notes || ''}
                                            onChange={(e) => updateRunnerNotes(selectedRunner.id, e.target.value)}
                                            className="w-full border rounded p-2 mt-1 h-32"
                                            placeholder="Add notes about this runner..."
                                        ></textarea>
                                    </div>

                                    <div className="flex justify-end space-x-2 pt-4">
                                        <button
                                            onClick={() => removeNumber(selectedRunner.id)}
                                            className="px-4 py-2 bg-red-100 text-red-700 rounded hover:bg-red-200"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="bg-white rounded-lg shadow-sm p-4 h-full">
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-lg font-medium flex items-center">
                                        <ListFilter className="h-5 w-5 mr-2 text-blue-600" />
                                        Detected Runners
                                    </h2>

                                    <div className="flex space-x-2">
                                        <button
                                            onClick={clearAllNumbers}
                                            disabled={detectedNumbers.length === 0}
                                            className="px-2 py-1 bg-red-100 text-red-700 rounded text-xs hover:bg-red-200"
                                        >
                                            Clear All
                                        </button>

                                        <button
                                            onClick={exportAsCSV}
                                            disabled={detectedNumbers.length === 0}
                                            className="px-2 py-1 bg-blue-100 text-blue-700 rounded text-xs hover:bg-blue-200"
                                        >
                                            Export CSV
                                        </button>

                                        <button
                                            onClick={exportFullData}
                                            disabled={detectedNumbers.length === 0}
                                            className="px-2 py-1 bg-purple-100 text-purple-700 rounded text-xs hover:bg-purple-200"
                                        >
                                            Export Full
                                        </button>
                                    </div>
                                </div>

                                <div className="h-96 overflow-y-auto">
                                    {detectedNumbers.length === 0 ? (
                                        <div className="flex flex-col items-center justify-center h-full text-gray-500">
                                            <User className="h-12 w-12 mb-2 text-gray-300" />
                                            <p>No runner numbers detected</p>
                                            <p className="text-xs mt-1">Capture an image to begin</p>
                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-2 gap-3">
                                            {detectedNumbers.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="border rounded hover:shadow-md cursor-pointer transition-shadow"
                                                    onClick={() => showRunnerDetails(item)}
                                                >
                                                    <div className="h-24 bg-gray-100 relative">
                                                        <img
                                                            src={item.image}
                                                            alt={`Runner ${item.number}`}
                                                            className="h-full w-full object-cover rounded-t"
                                                        />
                                                        <div className="absolute top-0 right-0 bg-black bg-opacity-70 text-white text-xs px-1 py-0.5 rounded-bl">
                                                            {item.confidence}%
                                                        </div>
                                                    </div>
                                                    <div className="p-2 flex justify-between items-center">
                                                        <div>
                                                            <div className="text-xl font-mono font-bold">{item.number}</div>
                                                            <div className="text-xs text-gray-500">
                                                                {formatTimestamp(item.timestamp)}
                                                            </div>
                                                        </div>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeNumber(item.id);
                                                            }}
                                                            className="text-gray-400 hover:text-red-500 h-6 w-6 flex items-center justify-center"
                                                        >
                                                            &times;
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Last capture */}
                {lastCapture && (
                    <div className="mb-6">
                        <h3 className="text-sm font-medium text-gray-700 mb-2">Last capture</h3>
                        <div className="bg-white p-2 rounded-lg shadow-sm">
                            <img
                                src={lastCapture}
                                alt="Last capture"
                                className="max-h-40 rounded mx-auto"
                            />
                        </div>
                    </div>
                )}

                {/* Footer */}
                <div className="text-xs text-center text-gray-500 mt-6">
                    <p>This component uses Tesseract.js for optical character recognition (OCR) of marathon runner numbers.</p>
                    <p>For best results, ensure numbers are clearly visible with good contrast.</p>
                </div>
            </div>
        </div>
    );
};

export default OCRReceiver;