import React, { useState, useEffect } from 'react';
import { Search, Filter, ArrowUpNarrowWide, ArrowDownNarrowWide, Edit, User, Mail, Phone, AlertCircle } from 'lucide-react';
import { collection, getDocs, doc, getDoc, updateDoc, query, where, setDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const UserTagControlUHF = ({ tagReaderValue, lastScannedTag }) => {
  // Estados
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [formValues, setFormValues] = useState({
    tagId: '',
    participantNumber: ''
  });
  const [formError, setFormError] = useState({
    tagId: '',
    participantNumber: ''
  });
  const [numberRanges, setNumberRanges] = useState(categoriesNumbersRange);
  const [usedNumbers, setUsedNumbers] = useState([]);
  const [availableNumbers, setAvailableNumbers] = useState([]);
  const [nfcTags, setNfcTags] = useState({});

  // Cargar eventos al inicializar el componente
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(eventsData);
      } catch (error) {
        console.error('Error al cargar eventos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  // Cargar participantes y categorías al cambiar el evento seleccionado
  useEffect(() => {
    if (!selectedEvent) return;

    const fetchParticipantsAndCategories = async () => {
      setLoading(true);
      try {
        // Cargar categorías
        const categoriesCollection = collection(db, `events/${selectedEvent}/categories`);
        const categoriesSnapshot = await getDocs(categoriesCollection);
        const categoriesData = categoriesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);

        // Cargar participantes
        const participantsCollection = collection(db, `events/${selectedEvent}/participants`);
        const participantsSnapshot = await getDocs(participantsCollection);
        const participantsData = participantsSnapshot.docs.map(doc => ({
          pid: doc.id,
          ...doc.data(),
        }));

        // Cargar todos los tags NFC
        const tagsCollection = collection(db, 'Tags');
        const tagsSnapshot = await getDocs(tagsCollection);

        // Crear un mapa de userIds a NFCIDs
        const tagsMap = {};
        tagsSnapshot.docs.forEach(tagDoc => {
          const tagData = tagDoc.data();
          if (tagData.Owner) {
            if (!tagsMap[tagData.Owner]) {
              tagsMap[tagData.Owner] = [];
            }
            tagsMap[tagData.Owner].push(tagData.NFCID);
          }
        });

        setNfcTags(tagsMap);

        // Crear un array de promesas para obtener los datos de usuario para cada participante
        const userPromises = participantsData.map(async (participant) => {
          if (!participant.userId) {
            return {
              ...participant,
              name: 'Usuario sin ID',
              email: '',
              nfcId: 'No disponible',
            };
          }

          try {
            // Obtener datos del usuario desde la colección Users
            const userDoc = await getDoc(doc(db, 'Users', participant.userId));

            // Asignar NFCID desde el mapa de tags
            const userNfcIds = tagsMap[participant.userId] || [];
            const nfcId = userNfcIds.length > 0 ? userNfcIds[0] : 'No asignado';

            if (userDoc.exists()) {
              const userData = userDoc.data();
              return {
                ...participant,
                name: userData.name || userData.displayName || 'Sin nombre',
                email: userData.email || '',
                nfcId: nfcId
              };
            } else {
              console.log(`Usuario no encontrado para userId: ${participant.userId}`);
              return {
                ...participant,
                name: 'Usuario no encontrado',
                email: '',
                nfcId: nfcId
              };
            }
          } catch (error) {
            console.error(`Error al obtener datos del usuario ${participant.userId}:`, error);
            return {
              ...participant,
              name: 'Error al cargar usuario',
              email: '',
              nfcId: 'Error'
            };
          }
        });

        // Esperar a que todas las promesas de datos de usuario se resuelvan
        const participantsWithUserData = await Promise.all(userPromises);

        // Agregar el nombre de la categoría a cada participante
        const enrichedParticipants = participantsWithUserData.map(participant => {
          const category = categoriesData.find(cat => cat.id === participant.categoryId);
          return {
            ...participant,
            categoryName: category ? category.name : 'Sin categoría'
          };
        });

        setParticipants(enrichedParticipants);

        // Recopilar los números de participante ya utilizados
        const usedParticipantNumbers = enrichedParticipants
          .filter(p => p.participantNumber)
          .map(p => p.participantNumber);

        setUsedNumbers(usedParticipantNumbers);
      } catch (error) {
        console.error('Error al cargar participantes y categorías:', error);
        // Resetear los arrays en caso de error para evitar datos corruptos
        setCategories([]);
        setParticipants([]);
        setNfcTags({});
      } finally {
        setLoading(false);
      }
    };

    fetchParticipantsAndCategories();
  }, [selectedEvent]);

  // Filtrar y ordenar participantes
  const filteredParticipants = () => {
    // Verificar si hay participantes antes de intentar filtrar
    if (!participants || participants.length === 0) {
      return [];
    }

    // Filtrar participantes
    const filtered = participants.filter(participant => {
      // Protección contra valores undefined/null en el nombre
      const participantName = participant.name || '';
      const nameMatch = searchText === '' || participantName.toLowerCase().includes(searchText.toLowerCase());
      const categoryMatch = categoryFilter === 'all' || participant.categoryId === categoryFilter;

      return nameMatch && categoryMatch;
    });

    // Ordenar participantes filtrados
    return [...filtered].sort((a, b) => {
      const nameA = a.name || '';
      const nameB = b.name || '';

      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
  };

  // Manejar cambio de evento
  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
    setSearchText('');
    setCategoryFilter('all');
    setSortOrder('asc');
  };

  // Abrir modal con participante seleccionado
  const handleParticipantSelect = (participant) => {
    setSelectedParticipant(participant);

    // Obtener los números disponibles para esta categoría
    const categoryRange = numberRanges.find(cat => cat.id === participant.categoryId);

    if (categoryRange) {
      // Crear array con todos los números posibles en el rango
      const allNumbers = [];
      for (let i = categoryRange.start; i <= categoryRange.end; i++) {
        allNumbers.push(i.toString());
      }

      // Filtrar números que ya están en uso (excepto el número actual del participante)
      const usedNumbersExceptCurrent = participants
        .filter(p => p.participantNumber && p.pid !== participant.pid)
        .map(p => p.participantNumber);

      const availableNums = allNumbers.filter(num => !usedNumbersExceptCurrent.includes(num));

      // Ordenar numéricamente los números disponibles
      availableNums.sort((a, b) => parseInt(a) - parseInt(b));

      setAvailableNumbers(availableNums);
    } else {
      setAvailableNumbers([]);
    }

    setFormValues({
      tagId: participant.tagId || tagReaderValue || '',
      participantNumber: participant.participantNumber || ''
    });

    setFormError({
      tagId: '',
      participantNumber: ''
    });

    setIsModalVisible(true);
  };

  // Manejar cambios en el formulario
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));

    // Limpiar errores al cambiar el valor
    setFormError(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  // Validar rango de número de participante según la categoría
  const validateParticipantNumber = (categoryId, number) => {
    // Buscar la categoría en el array de rangos de números
    const categoryRange = numberRanges.find(cat => cat.id === categoryId);

    if (!categoryRange) {
      return { valid: false, message: "Categoría no encontrada en los rangos definidos" };
    }

    const numValue = parseInt(number);

    if (isNaN(numValue)) {
      return { valid: false, message: "El número de participante debe ser un valor numérico" };
    }

    if (numValue < categoryRange.start || numValue > categoryRange.end) {
      return {
        valid: false,
        message: `El número debe estar entre ${categoryRange.start} y ${categoryRange.end} para la categoría ${categoryRange.name}`
      };
    }

    // Verificar si el número ya está en uso por otro participante
    const isNumberInUse = participants.some(p =>
      p.pid !== selectedParticipant.pid &&
      p.participantNumber === number
    );

    if (isNumberInUse) {
      return { valid: false, message: "Este número ya está asignado a otro participante" };
    }

    return { valid: true, message: "" };
  };

  // Guardar cambios del participante
  const handleSaveParticipant = async (e) => {
    e.preventDefault();

    // Validar el número de participante
    if (formValues.participantNumber) {
      const validation = validateParticipantNumber(
        selectedParticipant.categoryId,
        formValues.participantNumber
      );

      if (!validation.valid) {
        setFormError(prev => ({
          ...prev,
          participantNumber: validation.message
        }));
        return;
      }
    }

    try {
      setLoading(true);

      // Obtener el tag ID a usar (priorizar el último escaneado)
      // Formato correcto para el ID del tag basado en lo que se muestra en tu formulario
      const tagIdValue = lastScannedTag ? `${lastScannedTag.facilityCode}:${lastScannedTag.cardNumber}` : formValues.tagId;

      // 1. Actualizar solo el número de participante en el documento del participante
      const participantDocRef = doc(db, 'events', selectedEvent, 'participants', selectedParticipant.pid);
      await updateDoc(participantDocRef, {
        participantNumber: formValues.participantNumber,
      });

      // 2. Si hay un tag ID, verificar si ya existe y luego guardarlo en la colección Tags
      if (tagIdValue) {
        // Verificar si ya existe un tag con este NFCID
        const tagsRef = collection(db, 'Tags');
        const q = query(tagsRef, where("NFCID", "==", tagIdValue));
        const querySnapshot = await getDocs(q);

        // Si no existe el tag, agregarlo a la colección
        if (querySnapshot.empty) {
          // Datos a guardar en el documento de Tags
          const tagData = {
            NFCID: tagIdValue,
            Owner: selectedParticipant.userId,
            TagType: "TagUHF",
            status: 2
          };

          await addDoc(tagsRef, tagData);

          // Actualizar localmente el map de NFCTags
          if (selectedParticipant.userId) {
            setNfcTags(prev => {
              const newMap = { ...prev };
              if (!newMap[selectedParticipant.userId]) {
                newMap[selectedParticipant.userId] = [];
              }
              newMap[selectedParticipant.userId].push(tagIdValue);
              return newMap;
            });
          }
        } else {
          console.log(`El tag con NFCID ${tagIdValue} ya existe en la colección Tags`);
          // Puedes agregar aquí alguna lógica adicional si el tag ya existe
          // Por ejemplo, actualizar el propietario si es necesario
        }
      }

      // Actualizar el participante en el estado local con su nfcId
      const updatedParticipants = participants.map(p =>
        p.pid === selectedParticipant.pid
          ? {
            ...p,
            participantNumber: formValues.participantNumber,
            nfcId: tagIdValue || p.nfcId
          }
          : p
      );

      setParticipants(updatedParticipants);

      // Actualizar los números usados
      const newUsedNumbers = updatedParticipants
        .filter(p => p.participantNumber)
        .map(p => p.participantNumber);

      setUsedNumbers(newUsedNumbers);

      setIsModalVisible(false);
      setFormValues({ tagId: '', participantNumber: '' });
    } catch (error) {
      console.error('Error al actualizar participante:', error);
    } finally {
      setLoading(false);
    }
  };

  // Obtener información del rango para la categoría del participante seleccionado
  const getSelectedCategoryRange = () => {
    if (!selectedParticipant || !selectedParticipant.categoryId) {
      return null;
    }

    return numberRanges.find(cat => cat.id === selectedParticipant.categoryId);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Control de Tags UHF para Participantes</h2>

      {/* Tag Reader Value Display */}
      {tagReaderValue && (
        <div className="mb-6 p-4 bg-purple-50 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Tag UHF Detectado:</h3>
          <span className="bg-purple-600 text-white px-3 py-1 rounded-full text-sm">
            {tagReaderValue}
          </span>
        </div>
      )}

      {/* Event Selection */}
      <div className="mb-6">
        <select
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={handleEventChange}
          value={selectedEvent || ''}
          disabled={loading}
        >
          <option value="">Selecciona un evento</option>
          {events.map(event => (
            <option key={event.id} value={event.id}>{event.eventName || event.name}</option>
          ))}
        </select>
      </div>

      {selectedEvent && (
        <>
          {/* Filters */}
          <div className="mb-6 flex flex-wrap gap-3">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Buscar por nombre"
                className="pl-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <select
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="all">Todas las categorías</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>

            <div className="flex rounded-md overflow-hidden">
              <button
                className={`px-3 py-2 flex items-center ${sortOrder === 'asc' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                onClick={() => setSortOrder('asc')}
              >
                <ArrowUpNarrowWide className="mr-1 h-4 w-4" /> Asc
              </button>
              <button
                className={`px-3 py-2 flex items-center ${sortOrder === 'desc' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                onClick={() => setSortOrder('desc')}
              >
                <ArrowDownNarrowWide className="mr-1 h-4 w-4" /> Desc
              </button>
            </div>
          </div>

          {/* Participants Table */}
          <div className="overflow-x-auto">
            <div className="mb-2">
              <p className="text-gray-500 text-sm">
                Total participantes: {participants.length} | Filtrados: {filteredParticipants().length}
              </p>
            </div>

            <table className="min-w-full bg-white border border-gray-300 rounded-lg">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-2 px-4 border-b text-left">Nombre</th>
                  <th className="py-2 px-4 border-b text-left">Categoría</th>
                  <th className="py-2 px-4 border-b text-left">ID de Tag</th>
                  <th className="py-2 px-4 border-b text-left">NFC ID</th>
                  <th className="py-2 px-4 border-b text-left">Número de Participante</th>
                  <th className="py-2 px-4 border-b text-left">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6" className="py-4 text-center">Cargando...</td>
                  </tr>
                ) : participants.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="py-4 text-center">No hay participantes en este evento</td>
                  </tr>
                ) : filteredParticipants().length === 0 ? (
                  <tr>
                    <td colSpan="6" className="py-4 text-center">No hay participantes que coincidan con los filtros</td>
                  </tr>
                ) : (
                  filteredParticipants().map(participant => (
                    <tr
                      key={participant.pid}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleParticipantSelect(participant)}
                    >
                      <td className="py-2 px-4 border-b">
                        <div>
                          <div className="font-medium">{participant.name || 'Sin nombre'}</div>
                          {participant.email && <div className="text-xs text-gray-500">{participant.email}</div>}
                        </div>
                      </td>
                      <td className="py-2 px-4 border-b">
                        <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">
                          {participant.categoryName || 'Sin categoría'}
                        </span>
                      </td>
                      <td className="py-2 px-4 border-b">
                        {participant.tagId ? (
                          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
                            {participant.tagId}
                          </span>
                        ) : (
                          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs">
                            No asignado
                          </span>
                        )}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {participant.nfcId && participant.nfcId !== 'No asignado' && participant.nfcId !== 'No disponible' && participant.nfcId !== 'Error' ? (
                          <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
                            {participant.nfcId}
                          </span>
                        ) : (
                          <span className="bg-red-100 text-red-800 px-2 py-1 rounded-full text-xs">
                            {participant.nfcId || 'No asignado'}
                          </span>
                        )}
                      </td>
                      <td className="py-2 px-4 border-b">{participant.participantNumber || '-'}</td>
                      <td className="py-2 px-4 border-b">
                        <button
                          className="bg-blue-600 text-white px-3 py-1 rounded-md flex items-center text-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleParticipantSelect(participant);
                          }}
                        >
                          <Edit className="w-4 h-4 mr-1" />
                          Asignar Tag
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Modal for Tag Assignment */}
          {isModalVisible && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h3 className="text-xl font-bold mb-4">Asignar Tag UHF</h3>

                {selectedParticipant && (
                  <div>
                    <p className="mb-1"><span className="font-medium">Participante:</span> {selectedParticipant.name || 'Sin nombre'}</p>
                    {selectedParticipant.email && (
                      <p className="mb-1"><span className="font-medium">Email:</span> {selectedParticipant.email}</p>
                    )}
                    <p className="mb-1"><span className="font-medium">Categoría:</span> {selectedParticipant.categoryName || 'Sin categoría'}</p>
                    <p className="mb-4"><span className="font-medium">NFC ID actual:</span> {selectedParticipant.nfcId || 'No asignado'}</p>

                    {/* Mostrar información de rango de números */}
                    {getSelectedCategoryRange() && (
                      <div className="mb-4 p-3 bg-blue-50 rounded-md text-sm">
                        <p className="font-medium">Rango válido para {getSelectedCategoryRange().name}:</p>
                        <p>Desde {getSelectedCategoryRange().start} hasta {getSelectedCategoryRange().end}</p>
                        <p className="mt-1">Números disponibles: {availableNumbers.length}</p>
                      </div>
                    )}

                    <form onSubmit={handleSaveParticipant}>
                      <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">ID de Tag UHF</label>
                        <input
                          type="text"
                          name="tagId"
                          value={lastScannedTag ? `${lastScannedTag.facilityCode}:${lastScannedTag.cardNumber}` : ''}
                          placeholder="Ingrese ID de tag o use el valor detectado"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          readOnly
                        />
                        {formError.tagId && (
                          <p className="mt-1 text-red-500 text-xs flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" /> {formError.tagId}
                          </p>
                        )}
                      </div>

                      <div className="mb-4">
                        <label className="block text-sm font-medium mb-1">Número de Participante</label>
                        {availableNumbers.length > 0 ? (
                          <select
                            name="participantNumber"
                            value={formValues.participantNumber}
                            onChange={handleFormChange}
                            className={`w-full p-2 border rounded-md ${formError.participantNumber ? 'border-red-500' : 'border-gray-300'}`}
                          >
                            <option value="">Seleccionar número</option>
                            {availableNumbers.map(num => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p className="text-red-500 text-sm p-2 border border-red-200 rounded-md bg-red-50">
                            No hay números disponibles en esta categoría
                          </p>
                        )}
                        {formError.participantNumber && (
                          <p className="mt-1 text-red-500 text-xs flex items-center">
                            <AlertCircle className="w-3 h-3 mr-1" /> {formError.participantNumber}
                          </p>
                        )}
                      </div>

                      <div className="flex justify-end gap-2">
                        <button
                          type="button"
                          onClick={() => setIsModalVisible(false)}
                          className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                        >
                          Cancelar
                        </button>
                        <button
                          type="submit"
                          disabled={loading || availableNumbers.length === 0 || !formValues.participantNumber}
                          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center disabled:bg-blue-300 disabled:cursor-not-allowed"
                        >
                          {loading ? 'Guardando...' : 'Guardar'}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

// Array con los rangos de números para cada categoría
const categoriesNumbersRange = [
  {
    id: "50wCxv22N1JDqU89FL6w",
    name: "XC FEMENIL 11K",
    start: 251,
    end: 300
  },
  {
    id: "x2GnmNdyUD0DlyVFsv5a",
    name: "XC VARONIL 11K",
    start: 251,
    end: 300
  },
  {
    id: "AjbmmmJZoFEfxlps67QZ",
    name: "XC VARONIL 11K - 14 Años o menos",
    start: 251,
    end: 300
  },
  {
    id: "XOSf9mAcqJbEKmYtNMDz",
    name: "XC FEMENIL 11K - 14 Años o menor",
    start: 251,
    end: 300
  },
  {
    id: "6Sj31I0AjwvJUgj193MG",
    name: "MARATON VARONIL 15K",
    start: 501,
    end: 550
  },
  {
    id: "8PQjrXg2U4SLFiTzWpeX",
    name: "MARATON FEMENIL 15K",
    start: 501,
    end: 550
  },
  {
    id: "1ttdHqmbFqijbCSk13yF",
    name: "XC FEMENIL 27K",
    start: 51,
    end: 250
  },
  {
    id: "4Cgp6VKmSTLSV4njiy72",
    name: "XC VARONIL 27K",
    start: 51,
    end: 250
  },
  {
    id: "3eJiJVmYRopc7IzIGR6R",
    name: "XC FEMENIL 27K - 50+",
    start: 51,
    end: 250
  },
  {
    id: "GpQYlYmsg3dOqp73KbBm",
    name: "XC VARONIL 27K - 50+",
    start: 51,
    end: 250
  },
  {
    id: "9dMue8B7czuqayBYP9T9",
    name: "MARATON FEMENIL 32K",
    start: 401,
    end: 500
  },
  {
    id: "aqxxilQjqeKb3MTRkSYW",
    name: "MARATON VARONIL 32K",
    start: 401,
    end: 500
  },
  {
    id: "mFBVTZPZU3Bji2Czf7C1",
    name: "XC VARONIL 54k - 50+",
    start: 1,
    end: 50
  },
  {
    id: "spUCyQ3CD1OOr3Dgtnfm",
    name: "XC VARONIL 54K",
    start: 1,
    end: 50
  },
  {
    id: "62KErYSspXaezbzaQ5PE",
    name: "E-BIKES 64K",
    start: 551,
    end: 600
  },
  {
    id: "HQR5P120bJkbiPF6cGgX",
    name: "MARATON VARONIL 64K",
    start: 301,
    end: 400
  },
  {
    id: "cC1gjYLKfV8YfB9KQoQ9",
    name: "MARATON FEMENIL 64K",
    start: 301,
    end: 400
  }
];

export default UserTagControlUHF;