import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import { Volume2, VolumeX, ChevronDown, PlayCircle } from "lucide-react";

const Header = () => {
  const typedElement = useRef(null);
  const videoRef = useRef(null);
  const headerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  // Efectos de escritura
  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: ["Hobby", "Evento", "Aventura", "Parque", "Venta"],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
      smartBackspace: true,
      backDelay: 1500,
      startDelay: 500,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  // Efecto de scroll para añadir una clase cuando se hace scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Control de video y sonido
  const handleVolumeToggle = (e) => {
    e.stopPropagation();
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  // Pausar/reproducir el video
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Scroll suave a la siguiente sección
  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <header
      ref={headerRef}
      className="relative min-h-screen w-full overflow-hidden flex items-center justify-center"
    >
      {/* Video de Fondo con efecto de escala sutil */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <video
          ref={videoRef}
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/stories%2F4lh37bi7FjWvHVvK9GIMLDy6TX53_1730413988831_Snapinsta.app_video_7040AD1462F56D43F93FCCC2E5C31798_video_dashinit.mp4?alt=media&token=ed69249b-8646-42d3-8299-eb495e4eb12f"
          autoPlay
          loop
          muted
          playsInline
          className={`absolute inset-0 w-full h-full object-cover transition-transform duration-10000 ${
            scrolled ? "scale-110" : "scale-100"
          }`}
        />

        {/* Interfaz de control del video */}
        <div className="absolute bottom-8 right-8 flex items-center space-x-3 z-10">
          <button
            onClick={handleVolumeToggle}
            className="bg-black/60 backdrop-blur-sm text-white p-2.5 rounded-full hover:bg-black/80 transition-all transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white/30"
            aria-label={isMuted ? "Activar sonido" : "Silenciar"}
          >
            {isMuted ? (
              <VolumeX className="h-5 w-5" />
            ) : (
              <Volume2 className="h-5 w-5" />
            )}
          </button>

          <button
            onClick={handlePlayPause}
            className="bg-black/60 backdrop-blur-sm text-white p-2.5 rounded-full hover:bg-black/80 transition-all transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-white/30"
            aria-label={isPlaying ? "Pausar video" : "Reproducir video"}
          >
            <PlayCircle className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Capa de gradiente mejorada */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70 -z-10"></div>

      {/* Contenido Centralizado con animaciones */}
      <div className="relative flex flex-col items-center text-center text-white h-full px-6 justify-center">
        <div className="max-w-2xl animate-fadeIn">
          {/* Logo (opcional) */}
          <div className="mb-6 flex justify-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
              alt="LiveSpeed Logo"
              className="h-16 md:h-20 w-auto object-contain drop-shadow-lg animate-pulse"
              style={{ animationDuration: "3s" }}
            />
          </div>

          {/* Títulos con mejores efectos de tipografía */}
          <h1 className="text-4xl md:text-6xl font-black tracking-tight leading-tight mb-6 drop-shadow-xl animate-fadeIn">
            Elevamos tu
            <br />
            <span className="relative">
              <span
                ref={typedElement}
                className="text-yellow-400 font-bold inline-block"
              ></span>
              <span className="absolute bottom-0 left-0 w-full h-1 bg-yellow-400 animate-pulse opacity-75 rounded-full"></span>
            </span>
          </h1>

          <p className="text-lg md:text-xl mb-8 max-w-lg mx-auto drop-shadow-md font-light leading-relaxed">
            Mide, compite y mejora en tiempo real con la máxima precisión y
            análisis de{" "}
            <span className="font-medium">inteligencia artificial</span>.
          </p>

          {/* Botones con efecto de hover mejorado */}
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
            <Link
              to="/Login"
              className="block w-full md:w-auto text-center text-lg font-medium py-3 px-8 rounded-xl bg-black text-white border border-transparent hover:bg-white hover:text-black transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
            >
              Inicia Sesión
            </Link>
            <Link
              to="/SignUp"
              className="block w-full md:w-auto text-center text-lg font-medium py-3 px-8 rounded-xl border-2 border-white text-white hover:bg-white hover:text-black transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
            >
              Regístrate
            </Link>
          </div>
        </div>
      </div>

      {/* Indicador de scroll */}
      <div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer animate-bounce"
        onClick={scrollToContent}
      >
        <div className="flex flex-col items-center">
          <span className="text-white text-sm font-medium mb-2 tracking-wider">
            Descubre más
          </span>
          <ChevronDown className="h-6 w-6 text-white" />
        </div>
      </div>

      {/* Decoración de partículas (opcional) */}
      <div className="absolute inset-0 pointer-events-none">
        <div
          className="absolute top-1/4 left-1/4 w-2 h-2 rounded-full bg-yellow-400 animate-ping"
          style={{ animationDuration: "3s" }}
        ></div>
        <div
          className="absolute top-3/4 left-3/4 w-2 h-2 rounded-full bg-yellow-400 animate-ping"
          style={{ animationDuration: "4s" }}
        ></div>
        <div
          className="absolute top-1/3 right-1/4 w-2 h-2 rounded-full bg-yellow-400 animate-ping"
          style={{ animationDuration: "5s" }}
        ></div>
      </div>
    </header>
  );
};

export default Header;
