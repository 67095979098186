import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  MessageSquare,
  X,
  Send,
  User,
  Bot,
  AlertCircle,
  ExternalLink,
  History,
  ChevronLeft,
  Clock,
  Shield,
  Plus,
} from "lucide-react";
import axios from "axios";
import {
  collection,
  doc,
  onSnapshot,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
  getDocs,
  where,
  setDoc,
  limit,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

// Load bubble sound
const bubbleSound = new Audio(
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FPop%20Bubble%20Sound%20Effect%202022.mp3?alt=media&token=632363d4-0c77-4229-93fa-513b0749879e"
);

bubbleSound.onerror = () => {
  console.log("Error: No se pudo cargar el sonido de burbuja.");
};

const formatDate = (timestamp) => {
  if (!timestamp) return "Fecha desconocida";

  const date =
    typeof timestamp === "object" && timestamp.seconds
      ? new Date(timestamp.seconds * 1000)
      : new Date(timestamp);

  return new Intl.DateTimeFormat("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
};

// Chat Message component to display individual messages
const ChatMessage = ({ message, handleNavigateAndClose }) => {
  const isUser = message.role === "user";
  const isAdmin = message.role === "admin";
  const isGuest = message.isGuest;

  return (
    <div
      className={`flex flex-col ${isUser ? "items-end" : "items-start"} mb-4`}
    >
      <div
        className={`flex items-center gap-2 mb-1 ${
          isUser ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
          <img
            src={
              message.avatar ||
              (isUser ? "/default-user.png" : "/default-bot.png")
            }
            alt={message.name}
            className="w-full h-full object-cover"
          />
        </div>
        <span className="text-xs text-gray-500">
          {message.name}
          {isAdmin && (
            <Shield size={12} className="inline ml-1 text-blue-500" />
          )}
          {isGuest && (
            <span className="inline-block ml-1 text-xs text-gray-400">
              (Invitado)
            </span>
          )}
        </span>
      </div>

      <div
        className={`max-w-[80%] p-3 rounded-2xl break-words ${
          isUser
            ? "bg-black text-white rounded-tr-none"
            : isAdmin
            ? "bg-blue-600 text-white rounded-tl-none"
            : "bg-gray-100 text-gray-800 rounded-tl-none"
        }`}
      >
        <p className="text-sm">{message.content}</p>
      </div>

      {message.hasLink && (
        <button
          onClick={() => handleNavigateAndClose(`/${message.linkRoute}`)}
          className="mt-2 flex items-center gap-2 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 transition-colors text-sm"
        >
          <ExternalLink size={16} />
          <span>Ir a {message.linkRoute}</span>
        </button>
      )}
    </div>
  );
};

// Previous Chat Item component
const PreviousChatItem = ({ chat, onClick, isActive }) => {
  const firstMessage = chat.firstMessage || "Sin mensajes";
  const formattedDate = formatDate(chat.updatedAt || chat.createdAt);
  const isGuestChat = chat.isGuest;

  return (
    <button
      onClick={() => onClick(chat.id)}
      className={`w-full p-3 flex flex-col items-start border-b text-left transition-colors ${
        isActive ? "bg-gray-100" : "hover:bg-gray-50"
      }`}
    >
      <div className="flex items-center gap-2 w-full justify-between">
        <div className="flex items-center">
          <span className="font-medium text-sm truncate">
            {chat.sessionName || "Chat de soporte"}
          </span>
          {isGuestChat && (
            <span className="ml-2 text-xs bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full">
              Invitado
            </span>
          )}
        </div>
        <span className="text-xs text-gray-500 flex items-center">
          <Clock size={12} className="mr-1" />
          {formattedDate}
        </span>
      </div>
      <p className="text-xs text-gray-500 mt-1 truncate w-full">
        {firstMessage}
      </p>
    </button>
  );
};

// Main Component
const FloatingSupportButton = () => {
  const { user } = useUserAuth();
  const [openChat, setOpenChat] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [previousChats, setPreviousChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [isTyping, setIsTyping] = useState(false);
  const [userName, setUserName] = useState("");
  const [userPhotoURL, setUserPhotoURL] = useState("");
  const [showQuickPrompts, setShowQuickPrompts] = useState(true);
  const [showFloatingMessage, setShowFloatingMessage] = useState(false);
  const [chatId, setChatId] = useState("");
  const [showPreviousChats, setShowPreviousChats] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [chatsLoaded, setChatsLoaded] = useState(false);

  // Guest user states
  const [guestInfo, setGuestInfo] = useState({ phone: "", name: "" });
  const [showGuestForm, setShowGuestForm] = useState(false);
  const [guestFormCompleted, setGuestFormCompleted] = useState(false);

  const chatEndRef = useRef(null);
  const navigate = useNavigate();
  const chatContainerRef = useRef(null);

  // Open chat handler with conditional behavior
  const handleOpenChat = () => {
    setOpenChat(true);

    // If not logged in, show guest form
    if (!user && !guestFormCompleted) {
      setShowGuestForm(true);
    } else if (user && !chatId) {
      // For logged in users, initially show previous chats if they exist
      setShowPreviousChats(previousChats.length > 0);
    }
  };

  // Guest form submission
  const handleGuestFormSubmit = (e) => {
    e.preventDefault();
    if (guestInfo.phone.trim() && guestInfo.name.trim()) {
      setGuestFormCompleted(true);
      setShowGuestForm(false);
      createGuestChat();
    }
  };

  // Create chat for guest users
  const createGuestChat = async () => {
    try {
      const guestChatId = `guest_${guestInfo.phone.replace(
        /\D/g,
        ""
      )}_${Date.now()}`;
      const chatRef = doc(db, "Chats", guestChatId);

      await setDoc(chatRef, {
        isGuest: true,
        guestPhone: guestInfo.phone,
        guestName: guestInfo.name,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: "active",
        sessionName: `Invitado: ${guestInfo.name}`,
      });

      setChatId(guestChatId);
      setIsConnecting(true);
      setChatHistory([]);
      setUserName(guestInfo.name);

      // Setup listener for this chat
      loadChatMessages(guestChatId);

      return guestChatId;
    } catch (error) {
      console.error("Error creating guest chat:", error);
      return null;
    }
  };

  // Setup user data and chat sessions when user changes
  useEffect(() => {
    if (user && user.uid) {
      // Setup user profile data
      const userDocRef = doc(db, "Users", user.uid);
      const unsubUserProfile = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setUserName(userData.name || "Usuario");
          setUserPhotoURL(userData.photoUrl || "/default-user.png");
          setIsAdmin(userData.role === "admin" || userData.isAdmin === true);
        }
      });

      // Setup real-time listener for user's chats
      const chatsRef = collection(db, "Chats");
      const chatsQuery = query(
        chatsRef,
        where("userId", "==", user.uid),
        orderBy("updatedAt", "desc"),
        limit(10)
      );

      const unsubChats = onSnapshot(chatsQuery, async (snapshot) => {
        if (!snapshot.empty) {
          const chats = [];

          for (const chatDoc of snapshot.docs) {
            const chatData = chatDoc.data();

            // Get first message of each chat
            const messagesRef = collection(db, "Chats", chatDoc.id, "messages");
            const msgQuery = query(
              messagesRef,
              orderBy("timestamp", "asc"),
              limit(1)
            );
            const msgSnapshot = await getDocs(msgQuery);

            let firstMessage = "Sin mensajes";
            if (!msgSnapshot.empty) {
              firstMessage = msgSnapshot.docs[0].data().content;
            }

            chats.push({
              id: chatDoc.id,
              ...chatData,
              firstMessage,
            });
          }

          setPreviousChats(chats);
          setChatsLoaded(true);

          // If no chat is currently selected and the chat panel is open,
          // select the most recent chat for returning users
          if (!chatId && chats.length > 0 && openChat) {
            setChatId(chats[0].id);
            loadChatMessages(chats[0].id);
            // Don't show previous chats list, go directly to the most recent conversation
            setShowPreviousChats(false);
          }
        } else {
          setPreviousChats([]);
          setChatsLoaded(true);

          // If no chats exist and chat is open, create a new chat for first-time users
          if (!chatId && openChat) {
            createNewChat(user.uid);
            setShowPreviousChats(false);
          }
        }
      });

      return () => {
        unsubUserProfile();
        unsubChats();
      };
    } else {
      // For non-authenticated users, we'll handle in the guest flow
      if (!guestFormCompleted) {
        setChatHistory([]);
        setPreviousChats([]);
        setChatId("");
      }
      setChatsLoaded(false);
    }
  }, [user, openChat, chatId]);

  // Load messages for a specific chat
  const loadChatMessages = (selectedChatId) => {
    if (!selectedChatId) {
      console.error("Cannot load messages: no chat ID provided");
      return () => {};
    }

    try {
      console.log("Setting up listener for messages in chat:", selectedChatId);
      const messagesRef = collection(db, "Chats", selectedChatId, "messages");
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      // Use onSnapshot for real-time updates
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          console.log(
            "Message snapshot received, count:",
            querySnapshot.docs.length
          );
          if (!querySnapshot.empty) {
            const messages = querySnapshot.docs.map((doc) => doc.data());
            setChatHistory(messages);
            setIsConnecting(false);
          } else {
            setChatHistory([]);
            setIsConnecting(true);
          }
        },
        (error) => {
          console.error("Error in messages snapshot listener:", error);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error("Error setting up message listener:", error);
      return () => {};
    }
  };

  // Effect to load messages when chatId changes
  useEffect(() => {
    let unsubscribe = () => {};

    if (chatId) {
      console.log("Loading messages for chat:", chatId);
      unsubscribe = loadChatMessages(chatId);
    } else {
      console.log("No chatId available yet");
    }

    return () => {
      unsubscribe();
    };
  }, [chatId]);

  // Create a new chat document
  const createNewChat = async (userId) => {
    if (!userId) return null;

    try {
      const newChatId = `chat_${userId}_${Date.now()}`;
      const chatRef = doc(db, "Chats", newChatId);

      const chatData = {
        userId: userId,
        userName: userName || "Usuario",
        userPhotoURL: userPhotoURL || "/default-user.png",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: "active",
        sessionName: "Chat de soporte",
      };

      await setDoc(chatRef, chatData);

      setChatId(newChatId);
      setIsConnecting(true);
      setChatHistory([]);

      return newChatId;
    } catch (error) {
      console.error("Error creating new chat:", error);
      return null;
    }
  };

  // Load a specific chat
  const handleLoadChat = async (selectedChatId) => {
    if (!selectedChatId) return;

    try {
      setChatId(selectedChatId);
      setShowPreviousChats(false);
    } catch (error) {
      console.error("Error loading chat:", error);
    }
  };

  // Create a new chat session
  const handleNewChat = async () => {
    if (!user && !guestFormCompleted) {
      setShowGuestForm(true);
      return;
    }

    let newChatId;
    if (user) {
      newChatId = await createNewChat(user.uid);
    } else {
      newChatId = await createGuestChat();
    }

    if (newChatId) {
      setChatId(newChatId);
      setShowPreviousChats(false);
    }
  };

  // Save a message to the chat's subcollection
  const saveMessageToChat = async (messageData) => {
    if (!chatId) {
      console.error("Cannot save message: missing chatId");
      return false;
    }

    try {
      console.log("Saving message to chat:", chatId);
      // Add message to the messages subcollection
      const messagesRef = collection(db, "Chats", chatId, "messages");
      await addDoc(messagesRef, {
        ...messageData,
        timestamp: serverTimestamp(),
      });

      // Update the chat document's updatedAt field
      const chatRef = doc(db, "Chats", chatId);
      await setDoc(
        chatRef,
        {
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );

      return true;
    } catch (error) {
      console.error("Error saving message to chat:", error);
      return false;
    }
  };

  const playBubbleSound = () => {
    try {
      bubbleSound.play();
    } catch (error) {
      console.error("Error al reproducir el sonido de burbuja:", error);
    }
  };

  const handleNavigateAndClose = (route) => {
    setOpenChat(false);
    setShowFloatingMessage(true);
    navigate(route);
    setTimeout(() => {
      setShowFloatingMessage(false);
    }, 4000);
  };

  const handleSendMessage = async (promptMessage) => {
    const userMessageContent = promptMessage || message.trim();

    if (userMessageContent === "" || isLoading) return;

    // For guests, verify we have contact info
    if (!user && !guestFormCompleted) {
      setShowGuestForm(true);
      return;
    }

    // If no chatId, create a new chat first
    if (!chatId) {
      let newChatId;
      if (user) {
        newChatId = await createNewChat(user.uid);
      } else {
        newChatId = await createGuestChat();
      }

      if (!newChatId) {
        console.error("Failed to create a new chat");
        return;
      }
      setChatId(newChatId);
    }

    setIsLoading(true);
    setIsTyping(false);

    // Determine the sender details
    const messageDetails = adminMode
      ? {
          role: "admin",
          content: userMessageContent,
          name: "Livespeed Soporte",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
        }
      : {
          role: "user",
          content: userMessageContent,
          name: user ? userName : guestInfo.name,
          avatar: user ? userPhotoURL : "/default-user.png",
          isGuest: !user,
        };

    // Update chat history for immediate feedback
    setChatHistory((prev) => [...prev, messageDetails]);

    // Then save to Firebase
    await saveMessageToChat(messageDetails);
    setMessage("");
    setShowQuickPrompts(false);

    // If admin mode is active, don't query the AI
    if (adminMode) {
      setIsLoading(false);
      return;
    }

    try {
      if (isConnecting) {
        const connectingMessage = {
          role: "system",
          content:
            "Te presentamos a Speedy la nueva inteligencia artificial de Livespeed!",
          name: "Livespeed",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
        };
        setChatHistory((prev) => [...prev, connectingMessage]);
        await saveMessageToChat(connectingMessage);
        setIsConnecting(false);
      }

      setIsTyping(true);

      const response = await axios.post(
        "https://heroic-truck-437503-j4.wl.r.appspot.com/sendMessageToAssistant",
        {
          message: userMessageContent,
        }
      );

      let assistantContent = response.data.response;

      if (Array.isArray(assistantContent)) {
        assistantContent = assistantContent
          .filter((item) => item.type === "text")
          .map((item) => item.text?.value)
          .join(" ");
      }

      const linkyPattern = /livespeed.mx\/(\S+)/g;
      const match = linkyPattern.exec(assistantContent);

      const assistantMessage = {
        role: "assistant",
        content: assistantContent,
        name: "Speedy AI",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FDALL%C2%B7E%202024-10-02%2020.15.08%20-%20An%20animated%20logo%20for%20Speedy%2C%20a%20chatbot%20expert%20in%20bicycles.%20The%20logo%20should%20feature%20a%20friendly%20chatbot%20character%20with%20bicycle%20elements%20incorporated%20int.png?alt=media&token=d8fd5f7a-7f96-4e0e-9c7d-88ec36e60dd6",
        hasLink: match ? true : false,
        linkRoute: match ? match[1] : null,
      };

      setIsTyping(false);
      setChatHistory((prev) => [...prev, assistantMessage]);
      await saveMessageToChat(assistantMessage);
      playBubbleSound();
    } catch (error) {
      console.error("Error al comunicarse con el asistente:", error);
      const errorMessage = {
        role: "assistant",
        content: "Error al comunicarse con el asistente. Inténtalo de nuevo.",
        name: "Error",
        avatar:
          "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Fservicio-al-cliente.png?alt=media&token=74a19077-5e1c-43a5-b1dc-5bdf778d2842",
      };
      setIsTyping(false);
      setChatHistory((prev) => [...prev, errorMessage]);
      await saveMessageToChat(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle admin mode (only available for admin users)
  const toggleAdminMode = () => {
    if (!isAdmin) return;
    setAdminMode(!adminMode);
  };

  // Quick Prompts buttons
  const QuickPrompts = () =>
    showQuickPrompts && (
      <div className="flex flex-wrap justify-center gap-2 mt-4">
        {[
          "¿Cómo vinculo un brazalete?",
          "¿Cómo invito a mis amigos?",
          "¿Dónde veo mis tiempos?",
        ].map((prompt, index) => (
          <button
            key={index}
            onClick={() => handleSendMessage(prompt)}
            className="bg-black text-white px-3 py-2 rounded-lg hover:bg-gray-800 transition-colors text-sm font-medium"
          >
            {prompt}
          </button>
        ))}
      </div>
    );

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, isTyping]);

  useEffect(() => {
    const addWelcomeMessage = async () => {
      if (!chatHistory.length && chatId) {
        console.log("Adding welcome message to empty chat");
        const welcomeMessage = {
          role: "system",
          content: "¿Necesitas ayuda? ¡Pregúntale a Speedy!",
          name: "Speedy AI",
          avatar:
            "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FLoguito%20Livespeed.png?alt=media&token=d5944c21-922e-48ca-971b-5bb50181e740",
        };

        // Add to local state immediately for better UX
        setChatHistory([welcomeMessage]);

        // Then save to Firebase
        await saveMessageToChat(welcomeMessage);
      }
    };

    addWelcomeMessage();
  }, [chatHistory, chatId]);

  return (
    <>
      {/* Floating Support Button */}
      <button
        onClick={handleOpenChat}
        className="fixed bottom-20 left-6 md:bottom-28 md:left-8 bg-black text-white w-14 h-14 rounded-full flex items-center justify-center shadow-lg hover:bg-gray-800 transition-colors z-50"
        aria-label="Soporte"
      >
        <MessageSquare size={24} />
      </button>

      {/* Floating Message */}
      {showFloatingMessage && (
        <div className="fixed bottom-36 md:bottom-44 left-6 md:left-8 bg-black text-white p-3 rounded-xl shadow-lg z-50 max-w-xs animate-fadeInOut">
          <p className="text-sm">¡Cualquier duda aquí sigo en línea!</p>
        </div>
      )}

      {/* Chat Dialog */}
      {openChat && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white w-full max-w-md h-[85vh] rounded-xl flex flex-col overflow-hidden shadow-xl animate-scaleIn">
            {/* Guest Form - Only shown for non-authenticated users */}
            {showGuestForm && (
              <div className="flex-1 flex flex-col p-6">
                <div className="text-center mb-6">
                  <Bot size={48} className="mx-auto mb-4 text-black" />
                  <h2 className="text-xl font-bold">Bienvenido al Soporte</h2>
                  <p className="text-gray-500 mt-2">
                    Por favor proporciona tus datos para ayudarte mejor
                  </p>
                </div>

                <form onSubmit={handleGuestFormSubmit} className="space-y-4">
                  <div>
                    <label
                      htmlFor="guestName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Nombre
                    </label>
                    <input
                      id="guestName"
                      type="text"
                      value={guestInfo.name}
                      onChange={(e) =>
                        setGuestInfo({ ...guestInfo, name: e.target.value })
                      }
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-black focus:outline-none"
                      placeholder="Tu nombre"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="guestPhone"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Teléfono
                    </label>
                    <input
                      id="guestPhone"
                      type="tel"
                      value={guestInfo.phone}
                      onChange={(e) =>
                        setGuestInfo({ ...guestInfo, phone: e.target.value })
                      }
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-black focus:outline-none"
                      placeholder="Tu número de teléfono"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 transition-colors"
                  >
                    Iniciar Chat
                  </button>
                </form>
              </div>
            )}

            {/* Regular Chat Interface - shown when not in guest form */}
            {!showGuestForm && (
              <>
                {/* Header */}
                <div className="bg-white border-b px-4 py-3 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    {showPreviousChats ? (
                      <>
                        <button
                          onClick={() => setShowPreviousChats(false)}
                          className="p-1 rounded-full hover:bg-gray-100 transition-colors"
                        >
                          <ChevronLeft size={20} />
                        </button>
                        <h2 className="font-semibold text-lg">
                          Conversaciones
                        </h2>
                      </>
                    ) : (
                      <>
                        <Bot
                          size={24}
                          className={adminMode ? "text-blue-600" : "text-black"}
                        />
                        <h2 className="font-semibold text-lg">
                          {adminMode ? "Livespeed Soporte" : "Chat de Soporte"}
                          {!user && guestFormCompleted && (
                            <span className="ml-2 text-xs bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full">
                              Invitado
                            </span>
                          )}
                        </h2>

                        {user && previousChats.length > 0 && (
                          <button
                            onClick={() => setShowPreviousChats(true)}
                            className="p-1 rounded-full hover:bg-gray-100 transition-colors ml-2"
                            title="Ver chats anteriores"
                          >
                            <History size={18} />
                          </button>
                        )}
                      </>
                    )}
                  </div>

                  <div className="flex items-center gap-2">
                    {/* Admin Mode Toggle - only visible to admins */}
                    {isAdmin && !showPreviousChats && (
                      <button
                        onClick={toggleAdminMode}
                        className={`text-xs px-2 py-1 rounded-full ${
                          adminMode
                            ? "bg-blue-600 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        title={
                          adminMode
                            ? "Modo Livespeed activo"
                            : "Cambiar a modo Livespeed"
                        }
                      >
                        {adminMode ? "Livespeed" : "Usuario"}
                      </button>
                    )}

                    <button
                      onClick={() => setOpenChat(false)}
                      className="p-1 rounded-full hover:bg-gray-100 transition-colors"
                      aria-label="Cerrar"
                    >
                      <X size={20} />
                    </button>
                  </div>
                </div>

                {showPreviousChats ? (
                  /* Previous Chats List */
                  <div className="flex-1 overflow-y-auto bg-white">
                    <div className="p-4 border-b">
                      <button
                        onClick={handleNewChat}
                        className="w-full py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors flex items-center justify-center gap-2"
                      >
                        <Plus size={16} />
                        <span>Nueva conversación</span>
                      </button>
                    </div>

                    {!chatsLoaded ? (
                      <div className="p-8 text-center text-gray-500">
                        <div className="w-8 h-8 border-4 border-t-black border-gray-200 rounded-full animate-spin mx-auto mb-2"></div>
                        <p>Cargando conversaciones...</p>
                      </div>
                    ) : previousChats.length === 0 ? (
                      <div className="p-8 text-center text-gray-500">
                        <MessageSquare
                          size={32}
                          className="mx-auto mb-2 opacity-50"
                        />
                        <p>No tienes conversaciones previas</p>
                      </div>
                    ) : (
                      <div className="divide-y">
                        {previousChats.map((chat) => (
                          <PreviousChatItem
                            key={chat.id}
                            chat={chat}
                            onClick={handleLoadChat}
                            isActive={chat.id === chatId}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  /* Current Chat View */
                  <>
                    {/* Chat Messages Area */}
                    <div
                      ref={chatContainerRef}
                      className="flex-1 overflow-y-auto bg-gray-50 p-4"
                    >
                      {chatHistory.map((msg, index) => (
                        <ChatMessage
                          key={index}
                          message={msg}
                          handleNavigateAndClose={handleNavigateAndClose}
                        />
                      ))}

                      {/* Typing Indicator */}
                      {isTyping && (
                        <div className="flex items-center gap-2 animate-fadeIn">
                          <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden animate-bounce">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FDALL%C2%B7E%202024-10-02%2020.15.08%20-%20An%20animated%20logo%20for%20Speedy%2C%20a%20chatbot%20expert%20in%20bicycles.%20The%20logo%20should%20feature%20a%20friendly%20chatbot%20character%20with%20bicycle%20elements%20incorporated%20int.png?alt=media&token=d8fd5f7a-7f96-4e0e-9c7d-88ec36e60dd6"
                              alt="Speedy typing"
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex gap-1 items-center animate-slideRight">
                            <span className="text-sm text-gray-500">
                              Speedy está escribiendo
                            </span>
                            <span className="flex items-center">
                              <span className="h-1 w-1 bg-gray-500 rounded-full animate-pulse"></span>
                              <span className="h-1 w-1 bg-gray-500 rounded-full mx-1 animate-pulse delay-100"></span>
                              <span className="h-1 w-1 bg-gray-500 rounded-full animate-pulse delay-200"></span>
                            </span>
                          </div>
                        </div>
                      )}
                      <div ref={chatEndRef} />
                    </div>

                    {/* Quick Prompts */}
                    {!adminMode && chatId && <QuickPrompts />}

                    {/* Message Input */}
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSendMessage();
                      }}
                      className="bg-gray-50 border-t p-3 flex gap-2 items-center"
                    >
                      <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={
                          adminMode
                            ? "Responder como Livespeed..."
                            : "Escribe tu mensaje..."
                        }
                        disabled={isLoading}
                        className={`flex-1 rounded-full px-4 py-2 border focus:outline-none focus:ring-2 focus:border-transparent ${
                          adminMode
                            ? "border-blue-300 focus:ring-blue-500"
                            : "border-gray-300 focus:ring-black"
                        }`}
                      />
                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`text-white p-2 rounded-full transition-colors flex items-center justify-center w-10 h-10 ${
                          adminMode
                            ? "bg-blue-600 hover:bg-blue-700"
                            : "bg-black hover:bg-gray-800"
                        }`}
                      >
                        {isLoading ? (
                          <div className="w-5 h-5 border-2 border-gray-300 border-t-white rounded-full animate-spin"></div>
                        ) : (
                          <Send size={18} />
                        )}
                      </button>
                    </form>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* Tailwind Animations */}
      <style jsx>{`
        @keyframes fadeInOut {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          10% {
            opacity: 1;
            transform: translateY(0);
          }
          90% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(20px);
          }
        }

        @keyframes scaleIn {
          from {
            transform: scale(0.95);
            opacity: 0;
          }
          to {
            transform: scale(1);
            opacity: 1;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes slideRight {
          from {
            transform: translateX(-10px);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }

        .animate-fadeInOut {
          animation: fadeInOut 4s forwards;
        }

        .animate-scaleIn {
          animation: scaleIn 0.2s ease-out;
        }

        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }

        .animate-slideRight {
          animation: slideRight 0.3s ease-out;
        }

        .delay-100 {
          animation-delay: 0.1s;
        }

        .delay-200 {
          animation-delay: 0.2s;
        }
      `}</style>
    </>
  );
};

export default FloatingSupportButton;
