import React from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCart, Eye } from "lucide-react";

const ProductList = ({ products, onAddToCart }) => {
  const navigate = useNavigate();

  const handleViewProduct = (productId) => {
    navigate(`/shop/product/${productId}`);
  };

  // Get the first category name if available
  const mainCategory =
    products.length > 0 && products[0].category
      ? products[0].category
      : "Products";

  return (
    <div className="flex-1 bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      {/* Header Title */}
      <div className="max-w-7xl mx-auto mb-12">
        <h1 className="text-3xl font-semibold text-gray-900 mb-1">
          {mainCategory}
          <span className="text-gray-500 font-normal">
            . Essentials that pair perfectly with your favorite devices.
          </span>
        </h1>
      </div>

      {/* Products Grid */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Featured Product Card with Text */}
        {products.length > 0 && (
          <div className="bg-white rounded-2xl shadow-sm p-8 flex flex-col justify-between">
            <div>
              <h2 className="text-2xl font-semibold text-gray-900">
                Get even more attached to {products[0]?.brand || "your devices"}
                .
              </h2>
              <p className="mt-2 text-gray-600">
                Snap on a case, wallet, or wireless charger.
              </p>
            </div>

            <div className="mt-6">
              {products.slice(0, 3).length > 0 && (
                <div className="flex space-x-4 overflow-hidden">
                  {products.slice(0, 3).map((product) => (
                    <img
                      key={`thumb-${product.id}`}
                      src={product.images && product.images[0]}
                      alt={product.title}
                      className="h-32 w-32 object-cover rounded-xl"
                      onClick={() => handleViewProduct(product.id)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Product Cards */}
        {products.map((product) => (
          <div
            key={product.id}
            className="bg-white rounded-2xl shadow-sm overflow-hidden flex flex-col"
          >
            {/* Product Image */}
            <div
              className="w-full h-48 flex items-center justify-center bg-white p-6 cursor-pointer"
              onClick={() => handleViewProduct(product.id)}
            >
              {product.images && product.images.length > 0 ? (
                <img
                  src={product.images[0]}
                  alt={product.title}
                  className="h-full object-contain"
                />
              ) : (
                <div className="h-32 w-32 bg-gray-100 rounded-xl"></div>
              )}
            </div>

            {/* Spacing element replacing color options */}
            <div className="px-8 py-2"></div>

            {/* Product Info */}
            <div className="px-8 pt-2 pb-6 flex-grow">
              {product.isNew && (
                <div className="text-orange-600 font-medium text-sm mb-1">
                  New
                </div>
              )}
              <h3
                className="text-lg font-medium text-gray-900 cursor-pointer"
                onClick={() => handleViewProduct(product.id)}
              >
                {product.title || `${product.brand} ${product.model}`}
              </h3>

              <div className="mt-1 mb-4">
                <p className="text-gray-900">${product.cost}</p>
              </div>

              {/* Action buttons */}
              <div className="flex space-x-3">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddToCart(product);
                  }}
                  className="flex-1 bg-gray-900 text-white py-2 px-4 rounded-full font-medium hover:bg-black transition flex items-center justify-center"
                >
                  <ShoppingCart className="w-4 h-4 mr-2" /> Add to Bag
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewProduct(product.id);
                  }}
                  className="bg-gray-200 text-gray-800 p-2 rounded-full hover:bg-gray-300 transition"
                >
                  <Eye className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
