import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Switch,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import { Trash2, AlertCircle } from "lucide-react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";

const MergeRunsTable = ({ startData, endData, receiverData }) => {
  const [runs, setRuns] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [multiRunMode, setMultiRunMode] = useState(true);
  const [unpaired, setUnpaired] = useState({ starts: [], ends: [] });

  const formatElapsedTime = (milliseconds) => {
    // Format milliseconds into HH:mm:ss.sss format properly
    const totalSeconds = Math.floor(milliseconds / 1000);
    const ms = String(milliseconds % 1000).padStart(3, '0');
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}.${ms}`;
  };

  const processAndMergeData = () => {
    if (!startData || !endData || !receiverData) return;

    // Deep clone the data to avoid modifying the original
    const startClone = JSON.parse(JSON.stringify(startData));
    const endClone = JSON.parse(JSON.stringify(endData));

    // Sort both arrays by time
    const sortedStarts = startClone.sort((a, b) => new Date(a.Time) - new Date(b.Time));
    const sortedEnds = endClone.sort((a, b) => new Date(a.Time) - new Date(b.Time));

    const mergedRuns = [];
    const unpairedStarts = [];
    const unpairedEnds = [];

    if (multiRunMode) {
      // Group by UID
      const runsByUID = {};

      // Initialize groups
      sortedStarts.forEach(startRow => {
        if (!runsByUID[startRow.UID]) {
          runsByUID[startRow.UID] = { starts: [], ends: [] };
        }
        runsByUID[startRow.UID].starts.push(startRow);
      });

      sortedEnds.forEach(endRow => {
        if (!runsByUID[endRow.UID]) {
          runsByUID[endRow.UID] = { starts: [], ends: [] };
        }
        runsByUID[endRow.UID].ends.push(endRow);
      });

      // Process each UID's runs
      Object.entries(runsByUID).forEach(([uid, data]) => {
        const { starts, ends } = data;

        // Create pairs of start and end times
        const pairs = [];
        let startIndex = 0;
        let endIndex = 0;

        while (startIndex < starts.length && endIndex < ends.length) {
          const startTime = new Date(starts[startIndex].Time);
          const endTime = new Date(ends[endIndex].Time);

          // If end time is before start time, this end likely belongs to a previous run
          // that we don't have the start for, so skip it
          if (endTime < startTime) {
            unpairedEnds.push(ends[endIndex]);
            endIndex++;
            continue;
          }

          // Create a pair
          pairs.push({
            start: starts[startIndex],
            end: ends[endIndex]
          });

          startIndex++;
          endIndex++;
        }

        // Add any leftover starts to unpaired list
        while (startIndex < starts.length) {
          unpairedStarts.push(starts[startIndex]);
          startIndex++;
        }

        // Add any leftover ends to unpaired list
        while (endIndex < ends.length) {
          unpairedEnds.push(ends[endIndex]);
          endIndex++;
        }

        // Process the pairs into runs
        pairs.forEach(pair => {
          const startTime = new Date(pair.start.Time);
          const endTime = new Date(pair.end.Time);

          // Skip if end is before start
          if (endTime <= startTime) return;

          const elapsedMs = endTime - startTime;

          mergedRuns.push({
            RUID: pair.start.RUID,
            StartTime: pair.start.Time,
            FinishTime: pair.end.Time,
            ElapsedTime: formatElapsedTime(elapsedMs),
            ActiveRun: false,
            EventID: receiverData.EventID,
            NFCIdentifier: pair.start.UID,
            ParkID: receiverData.ParkID,
            TrackID: receiverData.TrackID,
            LapNumber: null // You can implement lap numbering if needed
          });
        });
      });
    } else {
      // Original logic for single run mode
      // Function to process data and find the latest/earliest record per UID
      const processTable = (data, comparator) => {
        return Object.values(
          data.reduce((acc, row) => {
            acc[row.UID] = acc[row.UID] ? comparator(acc[row.UID], row) : row;
            return acc;
          }, {})
        );
      };

      // Get latest start times and earliest end times for each UID
      const latestStartData = processTable(startData, (a, b) =>
        new Date(a.Time) > new Date(b.Time) ? a : b
      );

      const earliestEndData = processTable(endData, (a, b) =>
        new Date(a.Time) < new Date(b.Time) ? a : b
      );

      // Merge the data
      latestStartData.forEach(startRow => {
        const endRow = earliestEndData.find((row) => row.UID === startRow.UID);
        if (!endRow) {
          unpairedStarts.push(startRow);
          return;
        }

        const startTime = new Date(startRow.Time);
        const finishTime = new Date(endRow.Time);

        // Validate times - ensure finish is after start
        if (finishTime <= startTime) {
          unpairedStarts.push(startRow);
          unpairedEnds.push(endRow);
          return;
        }

        const elapsedMs = finishTime - startTime;

        mergedRuns.push({
          RUID: startRow.RUID,
          StartTime: startRow.Time,
          FinishTime: endRow.Time,
          ElapsedTime: formatElapsedTime(elapsedMs),
          ActiveRun: false,
          EventID: receiverData.EventID,
          NFCIdentifier: startRow.UID,
          ParkID: receiverData.ParkID,
          TrackID: receiverData.TrackID
        });
      });

      // Find unpaired ends
      earliestEndData.forEach(endRow => {
        if (!latestStartData.some(start => start.UID === endRow.UID)) {
          unpairedEnds.push(endRow);
        }
      });
    }

    // Sort runs by elapsed time (convert to milliseconds for proper sorting)
    const sortedRuns = mergedRuns.sort((a, b) => {
      const timeA = a.ElapsedTime.split(/[:.]/).reduce(
        (acc, val, idx) => acc + Number(val) * (idx === 0 ? 3600000 : idx === 1 ? 60000 : idx === 2 ? 1000 : 1),
        0
      );
      const timeB = b.ElapsedTime.split(/[:.]/).reduce(
        (acc, val, idx) => acc + Number(val) * (idx === 0 ? 3600000 : idx === 1 ? 60000 : idx === 2 ? 1000 : 1),
        0
      );
      return timeA - timeB;
    });

    setRuns(sortedRuns);
    setUnpaired({ starts: unpairedStarts, ends: unpairedEnds });
  };

  useEffect(() => {
    processAndMergeData();
  }, [startData, endData, receiverData, multiRunMode]);

  const handleDeleteRow = (index) => {
    setRuns((prevRuns) => prevRuns.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    if (runs.length === 0) return;

    setUploading(true);
    try {
      const batchUpload = runs.map((run) =>
        addDoc(collection(db, "Runs"), run)
      );
      await Promise.all(batchUpload);
      alert("Tiempos subidos exitosamente.");
      setRuns([]); // Clear runs after upload
    } catch (error) {
      console.error("Error uploading runs:", error);
      alert("Error al subir los tiempos procesados.");
    } finally {
      setUploading(false);
    }
  };

  // Helper function to format display of cell values
  const formatCellValue = (value, cellIndex) => {
    if (value === undefined || value === null) return '';

    switch (cellIndex) {
      case 0: // RUID
        return value.substring(0, 5);
      case 1: // StartTime
      case 2: // FinishTime
        return value.split(" ").slice(0, 2).join(" ");
      case 4: // ActiveRun (boolean)
        return value ? "true" : "false";
      case 5: // EventID
      case 7: // ParkID
      case 8: // TrackID
        return value.substring(0, 5);
      default:
        return value;
    }
  };

  return (
    <div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={multiRunMode}
              onChange={(e) => setMultiRunMode(e.target.checked)}
              color="primary"
            />
          }
          label="Modo múltiples vueltas"
        />

        <Typography variant="body2">
          {multiRunMode
            ? "Cada paso por meta registra una vuelta independiente"
            : "Se toma solo la primera y última marca de cada corredor"}
        </Typography>
      </Box>

      {(unpaired.starts.length > 0 || unpaired.ends.length > 0) && (
        <Box sx={{ mb: 2, p: 1, bgcolor: '#fff3e0', borderRadius: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <AlertCircle size={20} color="#ed6c02" />
            <Typography variant="subtitle2" color="warning.dark">
              Algunas marcas no pudieron emparejarse
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 2 }}>
            {unpaired.starts.length > 0 && (
              <Chip
                label={`${unpaired.starts.length} inicios sin fin`}
                size="small"
                color="warning"
                variant="outlined"
              />
            )}

            {unpaired.ends.length > 0 && (
              <Chip
                label={`${unpaired.ends.length} finales sin inicio`}
                size="small"
                color="warning"
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      )}

      {runs.length === 0 ? (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography>No hay tiempos para procesar</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} className="mt-6">
          <Table size="small">
            <TableHead>
              <TableRow>
                {Object.keys(runs[0] || {}).filter(k => k !== "LapNumber" || multiRunMode).map((header) => (
                  <TableCell key={header} className="font-semibold">
                    {header}
                  </TableCell>
                ))}
                <TableCell className="font-semibold">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {runs.map((run, index) => (
                <TableRow key={index}>
                  {Object.entries(run)
                    .filter(([key]) => key !== "LapNumber" || multiRunMode)
                    .map(([key, cell], cellIndex) => (
                      <TableCell key={key}>
                        {formatCellValue(cell, cellIndex)}
                      </TableCell>
                    ))}
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(index)}
                      size="small"
                    >
                      <Trash2 size={20} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className="mt-4">
        <Button
          variant="contained"
          onClick={() => {
            const confirmUpload = window.confirm(
              `¿Estás seguro de que deseas subir ${runs.length} tiempos procesados?`
            );
            if (confirmUpload) handleUpload();
          }}
          disabled={uploading || runs.length === 0}
        >
          {uploading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            `Subir ${runs.length} tiempo${runs.length !== 1 ? 's' : ''} procesado${runs.length !== 1 ? 's' : ''}`
          )}
        </Button>
      </div>
    </div>
  );
};

export default MergeRunsTable;