import React, { useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Chip,
    Avatar,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import TimerIcon from "@mui/icons-material/Timer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EventParticipationTable = ({
    users,
    events,
    totalTimes,
    ownerData,
    categories = [],
    convertTimeToMilliseconds,
    convertMillisecondsToTime
}) => {
    // Función local para convertir tiempo a milisegundos (fallback)
    const convertTimeToMs = (time) => {
        if (!time || time === "---" || time === "DNF") return null;
        try {
            const [hours, minutes, seconds] = time.split(":");
            const [secs, ms] = seconds.split(".");
            return (
                parseInt(hours) * 3600000 +
                parseInt(minutes) * 60000 +
                parseInt(secs) * 1000 +
                parseInt(ms)
            );
        } catch (error) {
            console.error("Error converting time:", time, error);
            return 0;
        }
    };

    // Función local para convertir milisegundos a tiempo (fallback)
    const convertMsToTime = (ms) => {
        if (!ms || ms === 0) return "---";
        try {
            const hours = Math.floor(ms / 3600000);
            const minutes = Math.floor((ms % 3600000) / 60000);
            const seconds = Math.floor((ms % 60000) / 1000);
            const milliseconds = ms % 1000;

            return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
                    .toString()
                    .padStart(3, "0")}`;
        } catch (error) {
            console.error("Error converting milliseconds:", ms, error);
            return "---";
        }
    };

    // Usar funciones proporcionadas o fallback
    const timeToMs = convertTimeToMilliseconds || convertTimeToMs;
    const msToTime = convertMillisecondsToTime || convertMsToTime;

    // Procesar y agrupar usuarios por categoría
    const usersByCategory = useMemo(() => {
        // Mapeo para almacenar categorías
        const categoriesMap = new Map();

        // Primero, crear una entrada para cada categoría única
        // Usamos un conjunto para rastrear IDs de categorías ya procesadas
        const processedCategoryIds = new Set();

        categories.forEach(category => {
            if (!processedCategoryIds.has(category.id)) {
                processedCategoryIds.add(category.id);

                categoriesMap.set(category.id, {
                    id: category.id,
                    name: category.name,
                    users: []
                });
            }
        });

        // Grupo especial para usuarios sin categoría
        categoriesMap.set("uncategorized", {
            id: "uncategorized",
            name: "Sin Categoría",
            users: []
        });

        // Crear un mapa para consolidar información de usuarios y eliminar duplicados
        const uniqueUsersMap = new Map();

        // Procesar los datos de propietarios para identificar usuarios únicos
        // La clave del ownerData es `${NFCIdentifier}-${eventId}`
        Object.entries(ownerData).forEach(([key, userData]) => {
            const [nfcId, eventId] = key.split('-');

            if (!uniqueUsersMap.has(userData.Owner)) {
                uniqueUsersMap.set(userData.Owner, {
                    name: userData.Owner,
                    photoUrl: userData.PhotoUrl,
                    categoryIds: new Map(), // Mapa de eventId -> categoryId
                    eventTimes: {}, // eventId -> time
                    nfcIds: new Set([nfcId]), // Colección de NFCIdentifiers que pertenecen a este usuario
                    totalTimeMs: 0,
                    eventCount: 0,
                    hasDNF: false
                });
            } else {
                // Actualizar identificadores para este usuario
                const userInfo = uniqueUsersMap.get(userData.Owner);
                userInfo.nfcIds.add(nfcId);
            }

            // Agregar la categoría para este evento
            const userInfo = uniqueUsersMap.get(userData.Owner);
            if (userData.categoryId) {
                userInfo.categoryIds.set(eventId, userData.categoryId);
            }
        });

        // Procesar tiempos totales y asignarlos a los usuarios
        totalTimes.forEach(time => {
            // Buscar a qué usuario pertenece este tiempo
            let foundUser = null;

            for (const [userName, userInfo] of uniqueUsersMap.entries()) {
                if (userInfo.nfcIds.has(time.NFCIdentifier)) {
                    foundUser = userInfo;
                    break;
                }
            }

            if (foundUser) {
                // Asignar el tiempo para este evento
                if (!foundUser.eventTimes[time.eventId]) {
                    foundUser.eventTimes[time.eventId] = time.ElapsedTime;
                    foundUser.eventCount++;

                    // Si no es DNF, sumar al tiempo total
                    if (time.ElapsedTime !== "DNF") {
                        const ms = timeToMs(time.ElapsedTime);
                        if (ms) {
                            foundUser.totalTimeMs += ms;
                        }
                    } else {
                        foundUser.hasDNF = true;
                    }
                }
            }
        });

        // Asignar usuarios a sus respectivas categorías principales
        // Nota: Un usuario puede aparecer solo en una categoría
        for (const [userName, userData] of uniqueUsersMap.entries()) {
            // Determinar categoría primaria para el usuario (la más frecuente)
            const categoryFrequency = new Map();

            // Contar frecuencia de cada categoría
            userData.categoryIds.forEach((categoryId) => {
                categoryFrequency.set(
                    categoryId,
                    (categoryFrequency.get(categoryId) || 0) + 1
                );
            });

            // Encontrar la categoría más frecuente
            let primaryCategoryId = null;
            let maxFrequency = 0;

            categoryFrequency.forEach((frequency, categoryId) => {
                if (frequency > maxFrequency) {
                    maxFrequency = frequency;
                    primaryCategoryId = categoryId;
                }
            });

            if (primaryCategoryId && categoriesMap.has(primaryCategoryId)) {
                // Asignar a la categoría principal
                const categoryData = categoriesMap.get(primaryCategoryId);
                categoryData.users.push({
                    ...userData,
                    primaryCategoryId,
                    participationCount: userData.eventCount
                });
            } else {
                // Sin categoría
                const uncategorized = categoriesMap.get("uncategorized");
                uncategorized.users.push({
                    ...userData,
                    primaryCategoryId: null,
                    participationCount: userData.eventCount
                });
            }
        }

        // Ordenar usuarios dentro de cada categoría por tiempo total
        categoriesMap.forEach((category) => {
            category.users.sort((a, b) => {
                // Primero ordenar por cantidad de eventos (descendente)
                if (b.participationCount !== a.participationCount) {
                    return b.participationCount - a.participationCount;
                }

                // Si tienen los mismos eventos y uno tiene DNF, ponerlo después
                if (a.hasDNF && !b.hasDNF) return 1;
                if (!a.hasDNF && b.hasDNF) return -1;

                // Finalmente, ordenar por tiempo total (ascendente)
                return a.totalTimeMs - b.totalTimeMs;
            });
        });

        // Filtrar categorías vacías
        const filteredCategories = Array.from(categoriesMap.entries())
            .filter(([_, category]) => category.users.length > 0)
            .map(([_, category]) => category);

        return {
            categories: filteredCategories,
            hasData: filteredCategories.length > 0
        };
    }, [categories, events, ownerData, totalTimes, timeToMs]);

    // Renderizar tabla para una categoría específica
    const renderCategoryTable = (category) => {
        if (category.users.length === 0) return null;

        // Calcular el usuario con más participaciones en esta categoría
        const maxParticipations = Math.max(...category.users.map(user => user.participationCount));

        return (
            <Box sx={{ mb: 3 }} key={category.id}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CategoryIcon sx={{ mr: 1, color: "primary.main" }} />
                    <Typography variant="h6" fontWeight={600}>
                        {category.name}
                    </Typography>
                </Box>

                <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                                <TableCell sx={{ fontWeight: "bold" }}>Participante</TableCell>
                                {events.map(event => (
                                    <TableCell key={event.id} align="center" sx={{ fontWeight: "bold" }}>
                                        {event.eventName}
                                    </TableCell>
                                ))}
                                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                    Total Eventos
                                </TableCell>
                                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                    Tiempo Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {category.users.map((user, index) => {
                                const eventsParticipated = user.participationCount;
                                const totalTime = user.hasDNF ? "DNF" : msToTime(user.totalTimeMs);

                                return (
                                    <TableRow key={index}
                                        sx={{
                                            '&:nth-of-type(odd)': { bgcolor: 'rgba(0, 0, 0, 0.02)' },
                                            // Destacar usuarios que participaron en todos los eventos
                                            ...(eventsParticipated === events.length ? {
                                                bgcolor: 'rgba(255, 215, 0, 0.15)',
                                                '&:hover': { bgcolor: 'rgba(255, 215, 0, 0.25)' }
                                            } : {}),
                                            // Destacar posiciones del podio (solo los primeros 3)
                                            ...(index < 3 && !user.hasDNF ? {
                                                position: 'relative',
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: 0,
                                                    bottom: 0,
                                                    width: '4px',
                                                    background: index === 0
                                                        ? "linear-gradient(45deg, #FFD700, #FFA500)"
                                                        : index === 1
                                                            ? "linear-gradient(45deg, #C0C0C0, #A9A9A9)"
                                                            : "linear-gradient(45deg, #CD7F32, #8B4513)"
                                                }
                                            } : {})
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Avatar src={user.photoUrl} sx={{ width: 30, height: 30, mr: 1 }} />
                                                <Typography variant="body2" fontWeight={500}>
                                                    {user.name}
                                                    {index < 3 && !user.hasDNF && (
                                                        <Typography
                                                            component="span"
                                                            variant="caption"
                                                            sx={{
                                                                ml: 1,
                                                                fontWeight: 'bold',
                                                                color: index === 0 ? '#FFD700' : index === 1 ? '#A9A9A9' : '#CD7F32'
                                                            }}
                                                        >
                                                            #{index + 1}
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </TableCell>

                                        {events.map(event => {
                                            const time = user.eventTimes[event.id];
                                            const eventCategoryId = user.categoryIds.get(event.id);

                                            return (
                                                <TableCell key={`${user.name}-${event.id}`} align="center">
                                                    {time ? (
                                                        time === "DNF" ? (
                                                            <Tooltip title="No completó todas las etapas">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <CancelIcon color="error" />
                                                                    <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
                                                                        DNF
                                                                    </Typography>
                                                                </Box>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Tiempo total del evento">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                                    <Typography variant="body2">
                                                                        {time}
                                                                    </Typography>
                                                                </Box>
                                                            </Tooltip>
                                                        )
                                                    ) : (
                                                        <Tooltip title="No participó en este evento">
                                                            <HelpOutlineIcon color="disabled" />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            );
                                        })}

                                        <TableCell align="center">
                                            <Chip
                                                label={`${eventsParticipated}/${events.length}`}
                                                size="small"
                                                color={eventsParticipated === events.length ? "success" :
                                                    eventsParticipated > 0 ? "primary" : "default"}
                                                sx={{
                                                    fontWeight: "bold",
                                                    minWidth: "60px",
                                                    ...(eventsParticipated === maxParticipations && maxParticipations > 1 ? {
                                                        background: "linear-gradient(45deg, #4CAF50, #2E7D32)",
                                                        color: "white"
                                                    } : {})
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell align="center">
                                            {user.hasDNF ? (
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <CancelIcon color="error" />
                                                    <Typography variant="body2" color="error" sx={{ ml: 0.5 }}>
                                                        DNF
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <TimerIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {totalTime}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    if (!usersByCategory.hasData) {
        return (
            <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="subtitle1">
                    No hay datos de participación disponibles
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6" fontWeight={600}>
                    Tabla de Participación por Categoría
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Esta tabla muestra los tiempos de los participantes en todos los eventos, agrupados por categoría.
                </Typography>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center", flexWrap: "wrap" }}>
                    <Tooltip title="Usuario participó y completó el evento">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CheckCircleIcon color="success" sx={{ mr: 0.5 }} fontSize="small" />
                            <Typography variant="body2">Completado</Typography>
                        </Box>
                    </Tooltip>
                    <Tooltip title="Usuario participó pero no completó todas las etapas">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CancelIcon color="error" sx={{ mr: 0.5 }} fontSize="small" />
                            <Typography variant="body2">DNF</Typography>
                        </Box>
                    </Tooltip>
                    <Tooltip title="Usuario no participó en este evento">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <HelpOutlineIcon color="disabled" sx={{ mr: 0.5 }} fontSize="small" />
                            <Typography variant="body2">No participó</Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>

            {/* Mostrar una sección de acordeón por cada categoría */}
            {usersByCategory.categories.map((category) => (
                <Accordion defaultExpanded key={category.id} sx={{ mb: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.07)" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                            bgcolor: "rgba(0, 0, 0, 0.03)",
                            borderBottom: 1,
                            borderColor: "divider"
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CategoryIcon sx={{ mr: 1 }} />
                            <Typography variant="subtitle1" fontWeight={600}>
                                {category.name}
                                <Chip
                                    label={`${category.users.length} participantes`}
                                    size="small"
                                    sx={{ ml: 2, fontSize: "0.7rem", height: "20px" }}
                                />
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 2 }}>
                        {renderCategoryTable(category)}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default EventParticipationTable;