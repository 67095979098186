import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { ArrowLeft, Users, User, ChevronRight, Search } from "lucide-react";

const MyFriends = () => {
  const { uid } = useParams();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = subscribeToFriends(uid || auth.currentUser?.uid);
    return () => unsubscribe();
  }, [uid]);

  const subscribeToFriends = (userId) => {
    if (userId) {
      const userDocRef = doc(db, "Users", userId);
      return onSnapshot(userDocRef, async (docSnapshot) => {
        const userData = docSnapshot.data();
        if (userData && userData.friends && userData.friends.length > 0) {
          const friendDetailsPromises = userData.friends.map(
            async (friendUid) => {
              const friendDocRef = doc(db, "Users", friendUid);
              const friendDocSnap = await getDoc(friendDocRef);
              if (friendDocSnap.exists()) {
                const friendData = friendDocSnap.data();
                return {
                  uid: friendUid,
                  name: friendData.name,
                  photoUrl: friendData.photoUrl,
                  nickName: friendData.nickName,
                };
              } else {
                console.error("Friend details not found for UID:", friendUid);
                return null;
              }
            }
          );
          Promise.all(friendDetailsPromises).then((resolvedFriendDetails) => {
            setFriends(resolvedFriendDetails.filter(Boolean));
            setLoading(false);
          });
        } else {
          setFriends([]);
          setLoading(false);
        }
      });
    }
    return () => {};
  };

  const handleFriendClick = (friendUid) => {
    navigate(`/user/${friendUid}`);
  };

  // Filter friends by search term
  const filteredFriends = friends.filter(
    (friend) =>
      friend.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      friend.nickName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="h-screen w-full flex justify-center items-center bg-gray-50">
        <div className="animate-pulse flex flex-col items-center">
          <div className="rounded-full bg-gray-200 h-16 w-16 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-48 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header with back button */}
      <div className="px-4 py-3 bg-white border-b border-gray-100 flex justify-start items-center sticky top-0 z-10">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-700 hover:text-black transition-colors"
        >
          <ArrowLeft className="w-5 h-5 mr-1" />
          <span className="font-medium">Back</span>
        </button>
      </div>

      {/* Title and Search Section */}
      <div className="px-4 py-4 bg-white border-b border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-xl font-bold text-gray-900 flex items-center">
            <Users className="w-5 h-5 mr-2" />
            Followers
          </h1>
          <span className="text-sm text-gray-500">
            {friends.length} followers
          </span>
        </div>

        {/* Search input */}
        <div className="relative mb-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          <input
            type="text"
            placeholder="Search followers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-100 border border-gray-100 rounded-full text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
          />
        </div>
      </div>

      {/* Friends List */}
      {filteredFriends.length > 0 ? (
        <div className="divide-y divide-gray-100 bg-white">
          {filteredFriends.map((friend) => (
            <div
              key={friend.uid}
              onClick={() => handleFriendClick(friend.uid)}
              className="flex items-center py-3 px-4 hover:bg-gray-50 cursor-pointer transition"
            >
              {/* User Avatar */}
              <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-100 mr-3 flex-shrink-0">
                {friend.photoUrl ? (
                  <img
                    src={friend.photoUrl}
                    alt={friend.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-6 h-6 text-gray-400" />
                  </div>
                )}
              </div>

              {/* User Info */}
              <div className="flex-grow min-w-0">
                <h3 className="font-medium text-gray-900 truncate">
                  {friend.name}
                </h3>
                <p className="text-gray-500 text-sm truncate">
                  @{friend.nickName}
                </p>
              </div>

              {/* Arrow Icon */}
              <ChevronRight className="w-5 h-5 text-gray-400" />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-8 bg-white mt-4 mx-4 rounded-xl shadow-sm">
          <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
            <User className="w-8 h-8 text-gray-400" />
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-1">
            No followers yet
          </h3>
          <p className="text-gray-500 text-center">
            {searchTerm
              ? "No followers match your search."
              : "When someone follows you, they'll appear here."}
          </p>
        </div>
      )}
    </div>
  );
};

export default MyFriends;
