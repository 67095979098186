import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Search, Clock, Download, ChevronDown, ChevronUp, Share2, Printer, RefreshCw, ArrowUp, Filter, X, AlertTriangle, Award, Medal, Trophy, Check, FileText, Calendar, Users } from 'lucide-react';

const ResultsViewer = () => {
    const [categories, setCategories] = useState([]);
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [expandedCategories, setExpandedCategories] = useState({});
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [activeFilters, setActiveFilters] = useState(false);
    const [showFullList, setShowFullList] = useState({});
    const [categoryGroups, setCategoryGroups] = useState({});
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [stats, setStats] = useState({
        totalParticipants: 0,
        finishedCount: 0,
        dnfCount: 0
    });

    const resultsContainerRef = useRef(null);

    // Monitorear scroll para mostrar/ocultar botón "volver arriba"
    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Agrupar categorías al cargar
    useEffect(() => {
        if (categories.length > 0) {
            const groups = {};
            let totalParticipants = 0;
            let finishedCount = 0;
            let dnfCount = 0;

            // Clasificar categorías en grupos
            categories.forEach(category => {
                const categoryResults = results[category] || [];
                totalParticipants += categoryResults.length;

                // Contar resultados DNF vs finalizados
                categoryResults.forEach(result => {
                    if (result.tiempo === 'DNF') {
                        dnfCount++;
                    } else {
                        finishedCount++;
                    }
                });

                // Grupos principales: E-BIKES, MARATON, XC
                const parts = category.split(' ');
                const mainGroup = parts[0]; // E-BIKES, MARATON, XC, etc.
                const gender = parts[1]?.includes('FEMENIL') ? 'FEMENIL' : 'VARONIL';
                const distance = parts[parts.length - 1]?.includes('K') ? parts[parts.length - 1] : '';

                // Crear clave de grupo
                const groupKey = mainGroup;

                if (!groups[groupKey]) {
                    groups[groupKey] = [];
                }

                if (!groups[groupKey].includes(category)) {
                    groups[groupKey].push(category);
                }
            });

            setCategoryGroups(groups);
            setStats({
                totalParticipants,
                finishedCount,
                dnfCount
            });
        }
    }, [categories, results]);

    // Cargar datos al iniciar
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            // 1. Obtener lista de categorías
            const categoriesDoc = await getDoc(doc(db, 'Esmeralda', 'categories'));

            if (!categoriesDoc.exists()) {
                setError('No se encontraron categorías');
                setLoading(false);
                return;
            }

            const categoriesList = categoriesDoc.data().list || [];
            setCategories(categoriesList);

            // 2. Obtener los resultados para cada categoría
            const resultsData = {};
            const initialExpandedState = {};
            const initialFullListState = {};

            for (const category of categoriesList) {
                const categoryId = category.replace(/\s+/g, '_');
                const categoryDoc = await getDoc(doc(db, 'Esmeralda', categoryId));

                if (categoryDoc.exists()) {
                    resultsData[category] = categoryDoc.data().resultados || [];
                    initialExpandedState[category] = false; // Inicialmente colapsado
                    initialFullListState[category] = true; // Inicialmente mostrar todos los resultados
                }
            }

            setResults(resultsData);
            setExpandedCategories(initialExpandedState);
            setShowFullList(initialFullListState);

        } catch (err) {
            console.error('Error al cargar los resultados:', err);
            setError('Error al cargar los datos: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    const toggleCategory = (category) => {
        setExpandedCategories(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handleGroupSelect = (group) => {
        setSelectedGroup(group === selectedGroup ? null : group);
        if (group !== selectedGroup) {
            setSelectedCategory(null); // Resetear categoría seleccionada al cambiar de grupo
        }
    };

    const filterResults = (categoryResults) => {
        if (!searchText) return categoryResults;

        return categoryResults.filter(result =>
            result.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
            result.posicion.toString().includes(searchText)
        );
    };

    const toggleFullList = (category) => {
        setShowFullList(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const clearSearch = () => {
        setSearchText('');
    };

    const toggleFilters = () => {
        setActiveFilters(!activeFilters);
    };

    const clearFilters = () => {
        setSelectedCategory(null);
        setSelectedGroup(null);
        setSearchText('');
    };

    const exportToCSV = (category, e) => {
        e.stopPropagation();
        const categoryResults = results[category];
        if (!categoryResults || categoryResults.length === 0) return;

        // Crear contenido CSV
        const headers = ['Posición', 'Nombre', 'Tiempo', 'Categoría'];
        const csvContent = [
            headers.join(','),
            ...categoryResults.map(result => [
                result.posicion,
                `"${result.nombre}"`, // Usar comillas para evitar problemas con comas en nombres
                result.tiempo,
                `"${result.categoria}"`
            ].join(','))
        ].join('\n');

        // Crear y descargar archivo
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `resultados_${category.replace(/\s+/g, '_')}.csv`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const printResults = (category, e) => {
        e.stopPropagation();
        const categoryResults = results[category];
        if (!categoryResults || categoryResults.length === 0) return;

        // Crear contenido para imprimir
        const printWindow = window.open('', '_blank');

        if (!printWindow) {
            alert('Por favor, permite las ventanas emergentes para imprimir');
            return;
        }

        const htmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Resultados - ${category}</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          h1 { text-align: center; margin-bottom: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { padding: 8px; text-align: left; border-bottom: 1px solid #ddd; }
          th { background-color: #f2f2f2; }
          .header { display: flex; justify-content: space-between; margin-bottom: 20px; }
          .medal-1 { background-color: #FFD700; }
          .medal-2 { background-color: #C0C0C0; }
          .medal-3 { background-color: #CD7F32; }
          .dnf { background-color: #FFEBEE; color: #D32F2F; }
          @media print {
            .no-print { display: none; }
            body { margin: 0; }
          }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>Resultados: ${category}</h1>
          <div class="no-print">
            <button onclick="window.print()">Imprimir</button>
            <button onclick="window.close()">Cerrar</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Posición</th>
              <th>Nombre</th>
              <th>Tiempo</th>
            </tr>
          </thead>
          <tbody>
            ${categoryResults.map(result => `
              <tr class="${result.tiempo === 'DNF' ? 'dnf' :
                parseInt(result.posicion) === 1 ? 'medal-1' :
                    parseInt(result.posicion) === 2 ? 'medal-2' :
                        parseInt(result.posicion) === 3 ? 'medal-3' : ''}">
                <td>${result.posicion}</td>
                <td>${result.nombre}</td>
                <td>${result.tiempo === 'DNF' ? 'No Finalizó (DNF)' : result.tiempo}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
        <div style="margin-top: 20px; text-align: center; font-size: 12px;">
          Fecha de impresión: ${new Date().toLocaleString()}
        </div>
      </body>
      </html>
    `;

        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();

        // Esperar a que los estilos se carguen antes de imprimir
        setTimeout(() => {
            printWindow.focus();
        }, 300);
    };

    const shareResults = (category, e) => {
        e.stopPropagation();
        if (navigator.share) {
            navigator.share({
                title: `Resultados - ${category}`,
                text: `Resultados de la categoría ${category}`,
                url: window.location.href,
            })
                .then(() => console.log('Contenido compartido exitosamente'))
                .catch((error) => console.log('Error al compartir', error));
        } else {
            // Fallback para navegadores que no soportan Web Share API
            const shareUrl = window.location.href;
            const tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.value = shareUrl;
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);

            alert('URL copiada al portapapeles');
        }
    };

    // Obtener el icono para el grupo de categoría
    const getCategoryIcon = (group) => {
        switch (group) {
            case 'E-BIKES':
                return <Trophy className="w-5 h-5" />;
            case 'MARATON':
                return <Clock className="w-5 h-5" />;
            case 'XC':
                return <Award className="w-5 h-5" />;
            default:
                return <Medal className="w-5 h-5" />;
        }
    };

    // Función para renderizar una tarjeta de resultados
    const renderCategoryCard = (category) => {
        const categoryResults = results[category] || [];
        if (categoryResults.length === 0) return null;

        // Determinar si mostrar todos los resultados
        const isFullList = showFullList[category];
        const filteredResults = filterResults(categoryResults);

        // Obtener los resultados a mostrar
        const resultsToShow = isFullList ? filteredResults : filteredResults.slice(0, 3);

        // Contar DNFs en esta categoría
        const dnfCount = categoryResults.filter(r => r.tiempo === 'DNF').length;
        const finishRate = categoryResults.length > 0
            ? Math.round(((categoryResults.length - dnfCount) / categoryResults.length) * 100)
            : 0;

        return (
            <div key={`card-${category}`} className="bg-white rounded-lg shadow-sm mb-4 overflow-hidden border border-gray-200 hover:shadow-md transition-shadow">
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 px-4 py-3 border-b border-gray-600 flex justify-between items-center">
                    <div className="flex items-center">
                        <h3 className="text-lg font-bold text-white">{category}</h3>
                        {dnfCount > 0 && (
                            <div className="ml-2 bg-red-100 text-red-700 text-xs font-semibold px-2 py-0.5 rounded-full flex items-center">
                                <AlertTriangle className="w-3 h-3 mr-1" />
                                {dnfCount} DNF
                            </div>
                        )}
                    </div>

                    <div className="flex space-x-1">
                        <button
                            onClick={(e) => exportToCSV(category, e)}
                            className="bg-white/20 hover:bg-white/30 text-white p-1.5 rounded transition-colors"
                            title="Exportar CSV"
                        >
                            <Download className="w-4 h-4" />
                        </button>
                        <button
                            onClick={(e) => printResults(category, e)}
                            className="bg-white/20 hover:bg-white/30 text-white p-1.5 rounded transition-colors"
                            title="Imprimir"
                        >
                            <Printer className="w-4 h-4" />
                        </button>
                        <button
                            onClick={(e) => shareResults(category, e)}
                            className="bg-white/20 hover:bg-white/30 text-white p-1.5 rounded transition-colors"
                            title="Compartir"
                        >
                            <Share2 className="w-4 h-4" />
                        </button>
                    </div>
                </div>

                <div className="p-4">
                    {/* Encabezado con columnas */}
                    <div className="hidden sm:flex justify-between p-2 mb-2 font-semibold text-sm text-gray-600 border-b">
                        <div className="flex items-center w-1/2">
                            <div className="w-8 mr-3 text-center">POS</div>
                            <div>NOMBRE</div>
                        </div>
                        <div className="text-right">TIEMPO</div>
                    </div>

                    {resultsToShow.length === 0 ? (
                        <div className="p-4 text-center text-gray-500">
                            No se encontraron resultados para esta búsqueda
                        </div>
                    ) : (
                        resultsToShow.map((result, index) => (
                            <div key={index} className={`flex flex-col sm:flex-row items-start sm:items-center justify-between p-2 mb-1 rounded ${result.tiempo === 'DNF' ? 'bg-red-50 border border-red-100' :
                                    parseInt(result.posicion) === 1 ? 'bg-yellow-50 border border-yellow-200' :
                                        parseInt(result.posicion) === 2 ? 'bg-gray-50 border border-gray-200' :
                                            parseInt(result.posicion) === 3 ? 'bg-amber-50 border border-amber-200' :
                                                index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                }`}>
                                <div className="flex items-center w-full sm:w-auto mb-1 sm:mb-0">
                                    <div className="flex items-center justify-center w-8 h-8 rounded-full mr-3 text-sm font-bold text-center" style={{
                                        backgroundColor: result.tiempo === 'DNF' ? '#fecaca' : // bg-red-200 
                                            parseInt(result.posicion) === 1 ? '#fef08a' : // bg-yellow-200
                                                parseInt(result.posicion) === 2 ? '#e5e7eb' : // bg-gray-200
                                                    parseInt(result.posicion) === 3 ? '#b45309' : // bg-amber-700
                                                        '#e5e7eb', // bg-gray-200
                                        color: result.tiempo === 'DNF' ? '#991b1b' : // text-red-800
                                            parseInt(result.posicion) === 1 ? '#713f12' : // text-yellow-900
                                                parseInt(result.posicion) === 2 ? '#1f2937' : // text-gray-800
                                                    parseInt(result.posicion) === 3 ? '#fffbeb' : // text-amber-100
                                                        '#1f2937' // text-gray-800
                                    }}>
                                        {result.tiempo === 'DNF' ? '✗' : result.posicion}
                                    </div>
                                    <div className="font-medium text-gray-900 truncate max-w-xs">{result.nombre}</div>
                                </div>
                                <div className={`font-mono font-medium ml-11 sm:ml-0 ${result.tiempo === 'DNF' ? 'text-red-600 flex items-center' : 'text-gray-800'
                                    }`}>
                                    {result.tiempo === 'DNF' ? (
                                        <>
                                            <AlertTriangle className="w-4 h-4 mr-1" />
                                            <span>No Finalizó (DNF)</span>
                                        </>
                                    ) : result.tiempo}
                                </div>
                            </div>
                        ))
                    )}

                    {filteredResults.length > 3 && (
                        <div className="mt-3 text-center">
                            <button
                                onClick={() => toggleFullList(category)}
                                className="inline-flex items-center text-gray-600 hover:text-gray-800 text-sm px-3 py-1 border border-gray-200 rounded-full hover:bg-gray-50 transition-colors"
                            >
                                <span>{isFullList ? 'Ver menos' : `Ver todos (${filteredResults.length})`}</span>
                                {isFullList ? <ChevronUp className="w-4 h-4 ml-1" /> : <ChevronDown className="w-4 h-4 ml-1" />}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="w-full bg-gray-50 min-h-screen" ref={resultsContainerRef}>
            <div className="w-full max-w-screen-2xl mx-auto px-3 py-4">
                {/* Cabecera */}
                <div className="bg-gradient-to-r from-gray-800 to-gray-700 text-white py-6 px-6 rounded-lg shadow-lg mb-6">
                    <h1 className="text-2xl sm:text-3xl font-bold text-center">VII Carrera Rancho La Esmeralda</h1>
                    <p className="text-center text-gray-300 mt-2">Resultados oficiales de la competencia</p>
                </div>

                {loading ? (
                    <div className="flex justify-center items-center p-8 bg-white rounded-lg shadow-sm">
                        <div className="animate-spin rounded-full h-10 w-10 border-4 border-gray-500 border-t-transparent"></div>
                        <span className="ml-3">Cargando resultados...</span>
                    </div>
                ) : error ? (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg">
                        <p>{error}</p>
                        <button
                            onClick={fetchData}
                            className="mt-2 bg-gray-700 hover:bg-gray-800 text-white px-4 py-2 rounded-lg transition-colors"
                        >
                            <RefreshCw className="w-4 h-4 inline mr-1" /> Reintentar
                        </button>
                    </div>
                ) : (
                    <>
                        {/* Barra de búsqueda y filtros (sin sticky) */}
                        <div className="bg-white p-4 rounded-lg shadow-sm mb-6 border border-gray-200">
                            <div className="flex flex-col sm:flex-row gap-3">
                                <div className="relative flex-grow">
                                    <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                                    <input
                                        type="text"
                                        placeholder="Buscar competidor por nombre..."
                                        className="pl-10 pr-10 p-2 w-full border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500 outline-none transition"
                                        value={searchText}
                                        onChange={handleSearch}
                                    />
                                    {searchText && (
                                        <button
                                            onClick={clearSearch}
                                            className="absolute right-3 top-2.5 text-gray-400 hover:text-gray-600"
                                        >
                                            <X className="w-5 h-5" />
                                        </button>
                                    )}
                                </div>

                                <div className="flex gap-2">
                                    <button
                                        onClick={toggleFilters}
                                        className={`flex items-center px-3 py-2 rounded-lg border transition-colors ${activeFilters ? 'bg-gray-200 border-gray-300 text-gray-700' : 'border-gray-300 hover:bg-gray-100'}`}
                                    >
                                        <Filter className="w-5 h-5 mr-1" />
                                        <span className="hidden sm:inline">Filtros</span>
                                    </button>

                                    {(selectedCategory || selectedGroup || searchText) && (
                                        <button
                                            onClick={clearFilters}
                                            className="flex items-center px-3 py-2 rounded-lg border border-red-300 bg-red-50 text-red-700 hover:bg-red-100 transition-colors"
                                            title="Limpiar filtros"
                                        >
                                            <X className="w-5 h-5 mr-1" />
                                            <span className="hidden sm:inline">Limpiar</span>
                                        </button>
                                    )}

                                    <button
                                        onClick={fetchData}
                                        className="bg-gray-800 hover:bg-gray-900 text-white p-2 rounded-lg transition-colors"
                                        title="Actualizar"
                                    >
                                        <RefreshCw className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>

                            {/* Panel de filtros expandible - Versión simplificada para móviles */}
                            {activeFilters && (
                                <div className="mt-4 p-4 bg-gray-100 rounded-lg border border-gray-200">
                                    <div className="mb-4">
                                        <h3 className="font-semibold text-gray-700 mb-2 flex items-center">
                                            <Calendar className="w-4 h-4 mr-1" />
                                            Filtrar por tipo
                                        </h3>
                                        <div className="flex flex-wrap gap-2">
                                            <button
                                                className={`px-3 py-1.5 rounded-md text-sm font-medium ${!selectedGroup ? 'bg-gray-800 text-white' : 'bg-white text-gray-700 border border-gray-300'
                                                    }`}
                                                onClick={() => setSelectedGroup(null)}
                                            >
                                                Todos
                                            </button>
                                            {Object.keys(categoryGroups).map(group => (
                                                <button
                                                    key={`group-${group}`}
                                                    className={`px-3 py-1.5 rounded-md text-sm font-medium flex items-center ${selectedGroup === group
                                                            ? 'bg-gray-800 text-white'
                                                            : 'bg-white border border-gray-300 text-gray-700'
                                                        }`}
                                                    onClick={() => handleGroupSelect(group)}
                                                >
                                                    {getCategoryIcon(group)}
                                                    <span className="ml-1">{group}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Lista simplificada de categorías */}
                                    <div>
                                        <h3 className="font-semibold text-gray-700 mb-2">
                                            Seleccionar categoría
                                        </h3>
                                        <select
                                            className="w-full p-2 border border-gray-300 rounded-md bg-white"
                                            value={selectedCategory || ''}
                                            onChange={(e) => handleCategorySelect(e.target.value || null)}
                                        >
                                            <option value="">Todas las categorías</option>
                                            {categories
                                                .filter(category => !selectedGroup || categoryGroups[selectedGroup]?.includes(category))
                                                .map(category => (
                                                    <option key={category} value={category}>
                                                        {category} ({results[category]?.length || 0})
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Mensaje si no hay resultados con los filtros actuales */}
                        {categories
                            .filter(category =>
                                (!selectedCategory || category === selectedCategory) &&
                                (!selectedGroup || categoryGroups[selectedGroup]?.includes(category))
                            ).length === 0 && (
                                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6 mb-6 text-center">
                                    <AlertTriangle className="w-8 h-8 text-yellow-500 mx-auto mb-2" />
                                    <h3 className="text-lg font-semibold text-yellow-700">No hay resultados que coincidan con los filtros seleccionados</h3>
                                    <p className="text-yellow-600 mt-1">Intenta ajustar tus criterios de búsqueda o selecciona otras categorías</p>
                                    <button
                                        onClick={clearFilters}
                                        className="mt-3 px-4 py-2 bg-yellow-100 text-yellow-700 rounded-lg hover:bg-yellow-200 transition-colors"
                                    >
                                        Limpiar filtros
                                    </button>
                                </div>
                            )}

                        {/* Tarjetas de categorías con resultados */}
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
                            {categories
                                .filter(category =>
                                    (!selectedCategory || category === selectedCategory) &&
                                    (!selectedGroup || categoryGroups[selectedGroup]?.includes(category))
                                )
                                .map(category => renderCategoryCard(category))}
                        </div>

                        {/* Footer */}
                        <div className="mt-8 pb-4 text-center text-gray-500 text-sm">
                            <p>© {new Date().getFullYear()} VII Carrera Rancho La Esmeralda</p>
                        </div>
                    </>
                )}
            </div>

            {/* Botón flotante para volver arriba */}
            {showScrollTop && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-4 right-4 bg-gray-800 hover:bg-gray-900 text-white p-3 rounded-full shadow-lg transition-colors"
                    aria-label="Volver arriba"
                >
                    <ArrowUp className="w-5 h-5" />
                </button>
            )}
        </div>
    );
};

export default ResultsViewer;