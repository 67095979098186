import React from "react";
import { UserCheck, Clock, Phone, RefreshCw } from "lucide-react";

const ChatHeader = ({ selectedChat, formatChatTime, handleRefresh }) => {
    if (!selectedChat) return null;

    return (
        <div className="bg-white p-4 border-b flex items-center justify-between">
            <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-gray-200 overflow-hidden mr-3">
                    <img
                        src={
                            selectedChat.userPhotoURL ||
                            (selectedChat.isGuest
                                ? "/default-user.png"
                                : "/default-user.png")
                        }
                        alt="User"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div>
                    <h2 className="font-semibold">
                        {selectedChat.sessionName ||
                            (selectedChat.isGuest
                                ? selectedChat.guestName
                                : selectedChat.userName || "Usuario")}
                        {selectedChat.isGuest && (
                            <span className="ml-2 text-xs bg-gray-200 text-gray-700 px-2 py-0.5 rounded-full">
                                Invitado
                            </span>
                        )}
                    </h2>
                    <div className="flex items-center text-xs text-gray-500">
                        {selectedChat.isGuest && selectedChat.guestPhone && (
                            <span className="flex items-center mr-3">
                                <Phone size={12} className="mr-1" />
                                {selectedChat.guestPhone}
                            </span>
                        )}
                        {!selectedChat.isGuest && (
                            <span className="flex items-center mr-3">
                                <UserCheck size={12} className="mr-1" />
                                Usuario registrado
                            </span>
                        )}
                        <span className="flex items-center">
                            <Clock size={12} className="mr-1" />
                            {formatChatTime(selectedChat.updatedAt)}
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <div className="flex items-center">
                    <span className="text-xs bg-blue-600 text-white px-3 py-1 rounded-full mr-2">
                        Respondiendo como Livespeed
                    </span>
                    <button
                        onClick={handleRefresh}
                        className="p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-full"
                        aria-label="Actualizar chat"
                    >
                        <RefreshCw size={18} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatHeader;