import React, { useState, useEffect } from 'react';
import { Clock, Save, Filter, ArrowUpNarrowWide, ArrowDownNarrowWide, Trash2, Download, Flag, User, Search } from 'lucide-react';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

const UserTagHistory = ({ lastScannedTag }) => {
    // Estados
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [readings, setReadings] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('timestamp');
    const [savedSuccess, setSavedSuccess] = useState(false);

    // Cargar eventos al inicializar el componente
    useEffect(() => {
        const fetchEvents = async () => {
            setLoading(true);
            try {
                const eventsCollection = collection(db, 'events');
                const eventsSnapshot = await getDocs(eventsCollection);
                const eventsData = eventsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setEvents(eventsData);

                // Cargar evento seleccionado desde localStorage si existe
                const savedEventId = localStorage.getItem('selectedEventId');
                if (savedEventId && eventsData.some(event => event.id === savedEventId)) {
                    setSelectedEvent(savedEventId);
                }
            } catch (error) {
                console.error('Error al cargar eventos:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    // Cargar participantes y categorías al cambiar el evento seleccionado
    useEffect(() => {
        if (!selectedEvent) return;

        // Guardar el evento seleccionado en localStorage
        localStorage.setItem('selectedEventId', selectedEvent);

        const fetchParticipantsAndCategories = async () => {
            setLoading(true);
            try {
                // Cargar categorías
                const categoriesCollection = collection(db, `events/${selectedEvent}/categories`);
                const categoriesSnapshot = await getDocs(categoriesCollection);
                const categoriesData = categoriesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCategories(categoriesData);

                // Cargar participantes
                const participantsCollection = collection(db, `events/${selectedEvent}/participants`);
                const participantsSnapshot = await getDocs(participantsCollection);
                const participantsData = participantsSnapshot.docs.map(doc => ({
                    pid: doc.id,
                    ...doc.data(),
                }));

                // Crear un array de promesas para obtener los datos de usuario para cada participante
                const userPromises = participantsData.map(async (participant) => {
                    if (!participant.userId) {
                        return {
                            ...participant,
                            name: 'Usuario sin ID',
                            email: '',
                            nfcId: 'No disponible',
                        };
                    }

                    try {
                        // Obtener datos del usuario desde la colección Users
                        const userDoc = await getDoc(doc(db, 'Users', participant.userId));

                        // Cargar tags asociados al usuario
                        const tagsCollection = collection(db, 'Tags');
                        const tagsQuery = query(tagsCollection, where("Owner", "==", participant.userId));
                        const tagsSnapshot = await getDocs(tagsQuery);

                        // Obtener todos los NFCIDs asociados a este usuario
                        const nfcIds = tagsSnapshot.docs.map(doc => doc.data().NFCID);
                        const nfcId = nfcIds.length > 0 ? nfcIds[0] : 'No asignado';

                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            return {
                                ...participant,
                                name: userData.name || userData.displayName || 'Sin nombre',
                                email: userData.email || '',
                                nfcId: nfcId,
                                allNfcIds: nfcIds
                            };
                        } else {
                            console.log(`Usuario no encontrado para userId: ${participant.userId}`);
                            return {
                                ...participant,
                                name: 'Usuario no encontrado',
                                email: '',
                                nfcId: nfcId,
                                allNfcIds: nfcIds
                            };
                        }
                    } catch (error) {
                        console.error(`Error al obtener datos del usuario ${participant.userId}:`, error);
                        return {
                            ...participant,
                            name: 'Error al cargar usuario',
                            email: '',
                            nfcId: 'Error',
                            allNfcIds: []
                        };
                    }
                });

                // Esperar a que todas las promesas de datos de usuario se resuelvan
                const participantsWithUserData = await Promise.all(userPromises);

                // Agregar el nombre de la categoría a cada participante
                const enrichedParticipants = participantsWithUserData.map(participant => {
                    const category = categoriesData.find(cat => cat.id === participant.categoryId);
                    return {
                        ...participant,
                        categoryName: category ? category.name : 'Sin categoría'
                    };
                });

                setParticipants(enrichedParticipants);

                // Cargar lecturas guardadas del localStorage
                loadSavedReadings(selectedEvent);
            } catch (error) {
                console.error('Error al cargar participantes y categorías:', error);
                setCategories([]);
                setParticipants([]);
            } finally {
                setLoading(false);
            }
        };

        fetchParticipantsAndCategories();
    }, [selectedEvent]);

    // Detectar nuevas lecturas de tags
    useEffect(() => {
        if (!lastScannedTag || !selectedEvent || !participants.length) return;

        // Formato del tag (facilityCode:cardNumber)
        const tagId = `${lastScannedTag.facilityCode}:${lastScannedTag.cardNumber}`;

        // Buscar si este tag pertenece a algún participante
        const matchingParticipant = participants.find(p =>
            p.allNfcIds && p.allNfcIds.includes(tagId)
        );

        if (matchingParticipant) {
            const now = new Date();

            // Crear un nuevo registro de lectura
            const newReading = {
                id: `reading_${Date.now()}`,
                timestamp: now.toISOString(),
                readTime: now.toLocaleTimeString(),
                readDate: now.toLocaleDateString(),
                tagId: tagId,
                participantId: matchingParticipant.pid,
                participantName: matchingParticipant.name,
                participantNumber: matchingParticipant.participantNumber || 'N/A',
                categoryId: matchingParticipant.categoryId,
                categoryName: matchingParticipant.categoryName
            };

            // Añadir al estado y guardarlo en localStorage
            setReadings(prevReadings => {
                const updatedReadings = [newReading, ...prevReadings];
                saveReadingsToLocalStorage(selectedEvent, updatedReadings);
                return updatedReadings;
            });
        }
    }, [lastScannedTag, selectedEvent, participants]);

    // Cargar lecturas guardadas del localStorage
    const loadSavedReadings = (eventId) => {
        try {
            const savedReadings = localStorage.getItem(`tagReadings_${eventId}`);
            if (savedReadings) {
                setReadings(JSON.parse(savedReadings));
            } else {
                setReadings([]);
            }
        } catch (error) {
            console.error('Error al cargar lecturas guardadas:', error);
            setReadings([]);
        }
    };

    // Guardar lecturas en localStorage
    const saveReadingsToLocalStorage = (eventId, readingsData) => {
        try {
            localStorage.setItem(`tagReadings_${eventId}`, JSON.stringify(readingsData));
        } catch (error) {
            console.error('Error al guardar lecturas:', error);
        }
    };

    // Filtrar y ordenar lecturas
    const filteredReadings = () => {
        if (!readings || readings.length === 0) {
            return [];
        }

        // Filtrar lecturas
        const filtered = readings.filter(reading => {
            const nameMatch =
                searchText === '' ||
                reading.participantName.toLowerCase().includes(searchText.toLowerCase()) ||
                reading.participantNumber.toString().includes(searchText);

            const categoryMatch = categoryFilter === 'all' || reading.categoryId === categoryFilter;

            return nameMatch && categoryMatch;
        });

        // Ordenar lecturas filtradas
        return [...filtered].sort((a, b) => {
            if (sortBy === 'timestamp') {
                const timeA = new Date(a.timestamp).getTime();
                const timeB = new Date(b.timestamp).getTime();
                return sortOrder === 'asc' ? timeA - timeB : timeB - timeA;
            } else if (sortBy === 'participantName') {
                const nameA = a.participantName || '';
                const nameB = b.participantName || '';
                return sortOrder === 'asc'
                    ? nameA.localeCompare(nameB)
                    : nameB.localeCompare(nameA);
            } else if (sortBy === 'participantNumber') {
                const numA = parseInt(a.participantNumber) || 0;
                const numB = parseInt(b.participantNumber) || 0;
                return sortOrder === 'asc' ? numA - numB : numB - numA;
            }
            return 0;
        });
    };

    // Manejar cambio de evento
    const handleEventChange = (e) => {
        setSelectedEvent(e.target.value);
        setSearchText('');
        setCategoryFilter('all');
        setSortOrder('desc');
    };

    // Guardar manualmente las lecturas
    const handleSaveReadings = () => {
        if (selectedEvent) {
            saveReadingsToLocalStorage(selectedEvent, readings);
            setSavedSuccess(true);
            setTimeout(() => setSavedSuccess(false), 3000);
        }
    };

    // Limpiar todas las lecturas
    const handleClearReadings = () => {
        if (window.confirm('¿Estás seguro de que quieres eliminar todas las lecturas? Esta acción no se puede deshacer.')) {
            setReadings([]);
            if (selectedEvent) {
                localStorage.removeItem(`tagReadings_${selectedEvent}`);
            }
        }
    };

    // Exportar las lecturas a CSV
    const handleExportReadings = () => {
        if (readings.length === 0) return;

        const headers = ['Fecha', 'Hora', 'Número', 'Nombre', 'Categoría', 'Tag ID'];

        const csvRows = [
            headers.join(','),
            ...filteredReadings().map(reading => [
                reading.readDate,
                reading.readTime,
                reading.participantNumber,
                reading.participantName,
                reading.categoryName,
                reading.tagId
            ].join(','))
        ];

        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `lecturas_${selectedEvent}_${new Date().toISOString().slice(0, 10)}.csv`);
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Registrar una llegada a meta manualmente
    const handleManualEntry = (participantId) => {
        const participant = participants.find(p => p.pid === participantId);
        if (!participant) return;

        const now = new Date();

        // Crear un nuevo registro de lectura manual
        const newReading = {
            id: `manual_${Date.now()}`,
            timestamp: now.toISOString(),
            readTime: now.toLocaleTimeString(),
            readDate: now.toLocaleDateString(),
            tagId: participant.nfcId || 'Registro manual',
            participantId: participant.pid,
            participantName: participant.name,
            participantNumber: participant.participantNumber || 'N/A',
            categoryId: participant.categoryId,
            categoryName: participant.categoryName,
            isManual: true
        };

        // Añadir al estado y guardarlo en localStorage
        setReadings(prevReadings => {
            const updatedReadings = [newReading, ...prevReadings];
            saveReadingsToLocalStorage(selectedEvent, updatedReadings);
            return updatedReadings;
        });
    };

    return (
        <div className="p-6 bg-gray-50">
            <h2 className="text-2xl font-bold mb-6 flex items-center">
                <Flag className="h-6 w-6 mr-2 text-blue-600" />
                Historial de Llegadas a Meta
            </h2>

            {/* Tag Reader Value Display */}
            {lastScannedTag && (
                <div className="mb-6 p-4 bg-purple-50 rounded-lg">
                    <h3 className="text-lg font-semibold mb-2">Tag UHF Detectado:</h3>
                    <span className="bg-purple-600 text-white px-3 py-1 rounded-full text-sm">
                        {`${lastScannedTag.facilityCode}:${lastScannedTag.cardNumber}`}
                    </span>
                </div>
            )}

            {/* Event Selection */}
            <div className="mb-6">
                <select
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onChange={handleEventChange}
                    value={selectedEvent || ''}
                    disabled={loading}
                >
                    <option value="">Selecciona un evento</option>
                    {events.map(event => (
                        <option key={event.id} value={event.id}>{event.eventName || event.name}</option>
                    ))}
                </select>
            </div>

            {selectedEvent && (
                <>
                    {/* Filtros y Acciones */}
                    <div className="mb-6 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                        <div className="flex flex-wrap gap-3 mb-4">
                            <div className="relative flex-grow max-w-md">
                                <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                                <input
                                    type="text"
                                    placeholder="Buscar por nombre o número"
                                    className="pl-10 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>

                            <select
                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={categoryFilter}
                                onChange={(e) => setCategoryFilter(e.target.value)}
                            >
                                <option value="all">Todas las categorías</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>

                            <select
                                className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <option value="timestamp">Ordenar por tiempo</option>
                                <option value="participantName">Ordenar por nombre</option>
                                <option value="participantNumber">Ordenar por número</option>
                            </select>

                            <div className="flex rounded-md overflow-hidden">
                                <button
                                    className={`px-3 py-2 flex items-center ${sortOrder === 'asc' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                                    onClick={() => setSortOrder('asc')}
                                >
                                    <ArrowUpNarrowWide className="mr-1 h-4 w-4" /> Asc
                                </button>
                                <button
                                    className={`px-3 py-2 flex items-center ${sortOrder === 'desc' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                                    onClick={() => setSortOrder('desc')}
                                >
                                    <ArrowDownNarrowWide className="mr-1 h-4 w-4" /> Desc
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-2">
                            <button
                                onClick={handleSaveReadings}
                                className="bg-green-600 text-white px-3 py-2 rounded-md hover:bg-green-700 flex items-center"
                                disabled={!readings.length}
                            >
                                <Save className="mr-1 h-4 w-4" />
                                Guardar
                            </button>

                            <button
                                onClick={handleExportReadings}
                                className="bg-blue-600 text-white px-3 py-2 rounded-md hover:bg-blue-700 flex items-center"
                                disabled={!readings.length}
                            >
                                <Download className="mr-1 h-4 w-4" />
                                Exportar CSV
                            </button>

                            <button
                                onClick={handleClearReadings}
                                className="bg-red-600 text-white px-3 py-2 rounded-md hover:bg-red-700 flex items-center"
                                disabled={!readings.length}
                            >
                                <Trash2 className="mr-1 h-4 w-4" />
                                Limpiar
                            </button>
                        </div>

                        {savedSuccess && (
                            <div className="mt-3 p-2 bg-green-100 text-green-800 rounded-md text-sm flex items-center">
                                <Save className="mr-1 h-4 w-4" />
                                Lecturas guardadas correctamente
                            </div>
                        )}
                    </div>

                    {/* Readings Table */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                        <div className="p-3 border-b border-gray-200 bg-gray-50 flex justify-between items-center">
                            <h3 className="font-medium">Historial de Lecturas</h3>
                            <span className="text-sm text-gray-500">
                                {filteredReadings().length} de {readings.length} lecturas
                            </span>
                        </div>

                        {readings.length === 0 ? (
                            <div className="p-8 text-center text-gray-500">
                                <Clock className="h-12 w-12 mx-auto mb-3 text-gray-300" />
                                <p className="mb-1">No hay lecturas registradas</p>
                                <p className="text-sm">Las lecturas aparecerán aquí cuando se detecten tags o se registren manualmente</p>
                            </div>
                        ) : filteredReadings().length === 0 ? (
                            <div className="p-8 text-center text-gray-500">
                                <Filter className="h-12 w-12 mx-auto mb-3 text-gray-300" />
                                <p>No hay lecturas que coincidan con los filtros aplicados</p>
                            </div>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Fecha/Hora
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Número
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Participante
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Categoría
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Tag ID
                                            </th>
                                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Tipo
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredReadings().map((reading, index) => (
                                            <tr key={reading.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <Clock className="h-4 w-4 mr-2 text-blue-500" />
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">{reading.readTime}</div>
                                                            <div className="text-xs text-gray-500">{reading.readDate}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    <div className="text-sm font-medium bg-blue-100 text-blue-800 px-2 py-1 rounded-full inline-block">
                                                        {reading.participantNumber}
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">{reading.participantName}</div>
                                                </td>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{reading.categoryName}</div>
                                                </td>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    <div className="text-xs font-mono text-gray-500">{reading.tagId}</div>
                                                </td>
                                                <td className="px-4 py-3 whitespace-nowrap">
                                                    {reading.isManual ? (
                                                        <span className="px-2 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800">Manual</span>
                                                    ) : (
                                                        <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">Automático</span>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>

                    {/* Participant Quick Selection for Manual Entry */}
                    <div className="mt-6 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                        <div className="p-3 border-b border-gray-200 bg-gray-50">
                            <h3 className="font-medium flex items-center">
                                <User className="h-4 w-4 mr-2 text-blue-600" />
                                Registro Manual de Llegada
                            </h3>
                        </div>

                        <div className="p-4">
                            <p className="text-sm text-gray-500 mb-4">
                                Selecciona un participante para registrar su llegada manualmente:
                            </p>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                {loading ? (
                                    <p className="col-span-full text-center p-4">Cargando participantes...</p>
                                ) : participants.length === 0 ? (
                                    <p className="col-span-full text-center p-4">No hay participantes en este evento</p>
                                ) : (
                                    participants
                                        .filter(p => p.participantNumber) // Solo mostrar participantes con número asignado
                                        .slice(0, 9) // Limitar a 9 participantes
                                        .map(participant => (
                                            <button
                                                key={participant.pid}
                                                onClick={() => handleManualEntry(participant.pid)}
                                                className="flex items-center justify-between p-3 border rounded-lg hover:bg-blue-50 hover:border-blue-300 transition-colors"
                                            >
                                                <div className="flex items-center">
                                                    <div className="bg-blue-100 text-blue-800 rounded-full h-8 w-8 flex items-center justify-center font-medium mr-3">
                                                        {participant.participantNumber}
                                                    </div>
                                                    <div className="text-left">
                                                        <div className="font-medium">{participant.name}</div>
                                                        <div className="text-xs text-gray-500">{participant.categoryName}</div>
                                                    </div>
                                                </div>
                                                <Flag className="h-4 w-4 text-blue-500" />
                                            </button>
                                        ))
                                )}
                            </div>

                            {participants.length > 9 && (
                                <p className="text-center text-sm text-gray-500 mt-4">
                                    Mostrando solo los primeros 9 participantes. Usa la búsqueda para encontrar participantes específicos.
                                </p>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UserTagHistory;