import React, { useState, useEffect } from "react";
import { CalendarDays, Medal, X } from "lucide-react";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  getDocs,
  orderBy
} from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";

const EventCategories = () => {
  const { user, loading } = useUserAuth();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(collection(db, "events"), orderBy("dateTime", "desc"));
        const snapshot = await getDocs(q);
        setEvents(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setIsLoading(false);
      } catch (error) {
        showToast("Error al cargar eventos", "error");
      }
    };
    fetchEvents();
  }, []);

  const fetchEventData = async (eventId) => {
    setIsLoading(true);
    try {
      const eventDoc = await getDoc(doc(db, "events", eventId));
      if (!eventDoc.exists()) throw new Error("Evento no encontrado");

      const categoriesSnap = await getDocs(collection(doc(db, "events", eventId), "categories"));
      const categories = categoriesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const participantsSnap = await getDocs(collection(doc(db, "events", eventId), "participants"));
      const participants = await Promise.all(
        participantsSnap.docs.map(async (pDoc) => {
          const pData = pDoc.data();
          const userDoc = await getDoc(doc(db, "Users", pData.userId));
          return {
            id: pDoc.id,
            userId: pData.userId,
            categoryId: pData.categoryId,
            user: userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null
          };
        })
      );

      setEventData({ ...eventDoc.data(), id: eventDoc.id, categories, participants });
      setIsLoading(false);
    } catch (error) {
      showToast("Error al cargar datos del evento", "error");
      setIsLoading(false);
    }
  };

  const handleCategoryChange = async () => {
    if (!user) {
      showToast("Debes estar autenticado para realizar esta acción", "error");
      return;
    }

    if (!selectedCategory || !selectedParticipant || !selectedEvent) {
      showToast("Información incompleta para actualizar", "error");
      return;
    }

    try {
      // Actualizar el participante en la subcolección de participants
      const participantRef = doc(
        db,
        "events",
        selectedEvent.id,
        "participants",
        selectedParticipant.id  // Usando el id del documento, no el userId
      );

      // Realizar la actualización simple solo del categoryId
      await updateDoc(participantRef, {
        categoryId: selectedCategory
      });

      // Actualizar el estado local
      setEventData(prev => ({
        ...prev,
        participants: prev.participants.map(p =>
          p.id === selectedParticipant.id
            ? { ...p, categoryId: selectedCategory }
            : p
        )
      }));

      showToast("Categoría actualizada exitosamente", "success");
      setIsModalOpen(false);

      // Recargar los datos para asegurar sincronización
      await fetchEventData(selectedEvent.id);

    } catch (error) {
      console.error("Error updating category:", error);
      showToast(
        error.code === 'permission-denied'
          ? "No tienes permisos para realizar esta acción"
          : "Error al actualizar categoría: " + error.message,
        "error"
      );
    }
  };

  const showToast = (message, type) => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast({ show: false, message: "", type: "" }), 3000);
  };

  if (loading || isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      {toast.show && (
        <div className={`fixed top-4 right-4 p-4 rounded-lg shadow-lg z-50 ${toast.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white`}>
          {toast.message}
        </div>
      )}

      <div className="bg-white rounded-lg shadow mb-6 p-4">
        <div className="flex items-center gap-2 mb-4">
          <CalendarDays className="h-5 w-5 text-blue-600" />
          <h2 className="text-lg font-semibold">Eventos</h2>
        </div>

        <div className="h-48 overflow-y-auto pr-2">
          <div className="space-y-2">
            {events.map(event => (
              <button
                key={event.id}
                onClick={() => {
                  setSelectedEvent(event);
                  fetchEventData(event.id);
                }}
                className={`w-full p-3 rounded-lg text-left transition-all ${selectedEvent?.id === event.id
                    ? "bg-blue-50 border border-blue-200"
                    : "bg-gray-50 hover:bg-gray-100 border border-transparent"
                  }`}
              >
                <div className="flex items-center justify-between">
                  <div>
                    <div className="font-medium">{event.eventName}</div>
                    <div className="text-sm text-gray-500">
                      {new Date(event.dateTime).toLocaleDateString()}
                    </div>
                  </div>
                  <Medal className="h-4 w-4 text-blue-600" />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      {eventData && (
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center gap-2 mb-4">
            <Medal className="h-5 w-5 text-blue-600" />
            <h2 className="text-lg font-semibold">{eventData.eventName} - Participantes</h2>
          </div>

          <div className="h-96 overflow-y-auto pr-2">
            <div className="space-y-2">
              {eventData.participants.map((participant) => (
                <div
                  key={participant.userId}
                  className="p-4 bg-gray-50 rounded-lg border border-gray-200"
                >
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                    <div>
                      <div className="font-medium">
                        {participant.user?.name || 'Usuario no encontrado'}
                      </div>
                      <div className="text-sm text-gray-500">
                        {eventData.categories.find(c => c.id === participant.categoryId)?.name || 'Sin categoría'}
                        {participant.user?.nickName && ` • ${participant.user.nickName}`}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setSelectedParticipant(participant);
                        setSelectedCategory(participant.categoryId);
                        setIsModalOpen(true);
                      }}
                      className="px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 bg-white border border-blue-600 hover:border-blue-700 rounded-md transition-colors"
                    >
                      Cambiar Categoría
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Cambiar Categoría</h3>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-2 max-h-96 overflow-y-auto">
              {eventData?.categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setSelectedCategory(category.id)}
                  className={`w-full p-3 rounded-lg text-left transition-all ${selectedCategory === category.id
                      ? "bg-blue-50 border border-blue-200"
                      : "bg-gray-50 hover:bg-gray-100 border border-transparent"
                    }`}
                >
                  <div className="font-medium">{category.name}</div>
                  <div className="text-sm text-gray-500">{category.description}</div>
                </button>
              ))}
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-700 bg-white border border-gray-300 hover:border-gray-400 rounded-md transition-colors"
              >
                Cancelar
              </button>
              <button
                onClick={handleCategoryChange}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCategories;