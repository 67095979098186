import React from 'react';
import SplitFlapDisplay from 'react-split-flap-display';

// Full character set including letters, numbers, and special characters
const FULL_SET = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y', 'Z',
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    ' ', ':', '.'
];

// Common styles for all flap displays
const flapContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#000000',
    borderRadius: '12px',
    margin: '10px 0',
    perspective: '1000px' // 3D effect for better realism
};

// Common styles for individual flap displays
const getFlapStyle = (intensity = 1) => ({
    boxShadow: `
        0 0 20px rgba(0,0,0,0.5),
        inset 0 0 10px rgba(255,255,255,0.1),
        0 5px 15px rgba(0,0,0,0.7)
    `, // Multiple shadows for depth
    border: '4px solid #444444',
    borderRadius: '8px',
    padding: '15px 30px', // More horizontal padding
    backgroundColor: '#2A2A2A',
    backgroundImage: `
        linear-gradient(45deg, #262626 25%, transparent 25%, transparent 75%, #262626 75%, #262626),
        linear-gradient(45deg, #262626 25%, transparent 25%, transparent 75%, #262626 75%, #262626)
    `, // Subtle texture
    backgroundSize: '4px 4px',
    backgroundPosition: '0 0, 2px 2px',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '-100%',
        width: '50%',
        height: '100%',
        background: 'linear-gradient(to right, transparent, rgba(255,255,255,0.15), transparent)',
        transform: 'skewX(-25deg)',
        animation: `shine ${2 + intensity}s infinite`,
    }
});

// Style for the shine effect overlay
const shineOverlayStyle = {
    position: 'absolute',
    top: '-10%',
    left: '-10%',
    width: '120%',
    height: '120%',
    background: 'linear-gradient(135deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)',
    pointerEvents: 'none',
    zIndex: 10
};

// Texture overlay for the flaps
const textureOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'url("data:image/svg+xml,%3Csvg width=\'40\' height=\'40\' viewBox=\'0 0 40 40\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0 0h20v20H0V0zm10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm20 0a7 7 0 1 0 0-14 7 7 0 0 0 0 14zM10 37a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm10-17h20v20H20V20zm10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z\' fill=\'%23000000\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
    opacity: 0.3,
    pointerEvents: 'none',
    zIndex: 5
};

const SplitFlapDisplayDemo = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#000000', // Black background
                gap: '30px',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            {/* First Row: CONGRATS */}
            <div style={flapContainerStyle}>
                <div style={{ position: 'relative' }}>
                    <SplitFlapDisplay
                        value="CONGRATS"
                        characterSet={FULL_SET}
                        minLength={8}
                        padDirection="left"
                        backgroundColor="#333333" // Darker gray background for characters
                        borderColor="#555555" // Medium gray border between letters
                        borderWidth="3px"
                        characterMargin="0.3em" // More space between characters
                        textColor="#FFFFFF" // White text
                        characterWidth="3em"
                        fontSize="6em"
                        fontFamily="'Times New Roman', serif"
                        step={250}
                        withSound={false}
                        style={getFlapStyle(1.5)}
                    />
                    <div style={shineOverlayStyle}></div>
                    <div style={textureOverlayStyle}></div>
                </div>
            </div>

            {/* Second Row: 28K 2584 */}
            <div style={flapContainerStyle}>
                <div style={{ position: 'relative' }}>
                    <SplitFlapDisplay
                        value="28K 2584"
                        characterSet={FULL_SET}
                        minLength={8}
                        padDirection="left"
                        backgroundColor="#333333" // Darker gray background for characters
                        borderColor="#555555" // Medium gray border between letters
                        borderWidth="4px"
                        characterMargin="0.3em" // More space between characters
                        textColor="#FFFFFF" // White text
                        characterWidth="3em"
                        fontSize="6em"
                        fontFamily="'Times New Roman', serif"
                        step={50}
                        withSound={false}
                        style={getFlapStyle(3)}
                    />
                    <div style={shineOverlayStyle}></div>
                    <div style={textureOverlayStyle}></div>
                </div>
            </div>

            {/* Third Row: 06:27:15 */}
            <div style={flapContainerStyle}>
                <div style={{ position: 'relative' }}>
                    <SplitFlapDisplay
                        value="06:27:15"
                        characterSet={FULL_SET}
                        minLength={8}
                        padDirection="left"
                        backgroundColor="#333333" // Darker gray background for characters
                        borderColor="#555555" // Medium gray border between letters
                        borderWidth="3px"
                        characterMargin="0.3em" // More space between characters
                        textColor="#FFFFFF" // White text
                        characterWidth="3em"
                        fontSize="6em"
                        fontFamily="'Times New Roman', serif"
                        step={50}
                        withSound={false}
                        style={getFlapStyle(1)}
                    />
                    <div style={shineOverlayStyle}></div>
                    <div style={textureOverlayStyle}></div>
                </div>
            </div>

            {/* Global styles for animations */}
            <style>{`
                @keyframes shine {
                    0% {
                        left: -100%;
                    }
                    20% {
                        left: 100%;
                    }
                    100% {
                        left: 100%;
                    }
                }
                
                /* Add a subtle pulse effect to simulate flickering light */
                @keyframes pulse {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0.9; }
                }
            `}</style>
        </div>
    );
};

export default SplitFlapDisplayDemo;