import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Users, Search, ChevronRight, X } from "lucide-react";
import { useUserAuth } from "../../context/userAuthContext";

const InitialAvatar = ({ name, photoUrl }) => {
  const getInitials = (fullName) => {
    return fullName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join("");
  };

  if (photoUrl) {
    return (
      <img
        src={photoUrl}
        alt={name}
        className="w-12 h-12 rounded-full border-2 border-gray-300 object-cover"
      />
    );
  }

  return (
    <div className="w-12 h-12 rounded-full bg-black text-white flex items-center justify-center font-bold text-lg">
      {getInitials(name)}
    </div>
  );
};

const ParticipantCard = ({ participant, onClick, isMobile }) => {
  // Truncate name to prevent UI issues
  const truncateName = (name, limit) => {
    return name.length > limit ? name.substring(0, limit) + "..." : name;
  };

  if (isMobile) {
    return (
      <div
        onClick={onClick}
        className="flex items-center p-3 bg-white hover:bg-gray-100 rounded-lg shadow-sm transition-colors cursor-pointer border border-gray-100"
      >
        <InitialAvatar
          name={participant.user.name}
          photoUrl={participant.user.photoUrl}
        />
        <div className="ml-4 flex-grow overflow-hidden">
          <h2 className="text-sm font-semibold text-gray-900 truncate">
            {truncateName(participant.user.name, 18)}
          </h2>
          <p className="text-xs text-gray-500 truncate">
            @{truncateName(participant.user.nickName, 15)}
          </p>
        </div>
        <ChevronRight className="text-gray-400 w-5 h-5" />
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className="group cursor-pointer transform transition-all duration-200 hover:-translate-y-1"
    >
      <div className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow flex flex-col items-center text-center">
        <InitialAvatar
          name={participant.user.name}
          photoUrl={participant.user.photoUrl}
        />
        <div className="mt-3 w-full overflow-hidden">
          <h2 className="text-sm font-semibold text-gray-900 truncate group-hover:text-black transition-colors">
            {truncateName(participant.user.name, 15)}
          </h2>
          <p className="text-xs text-gray-500 truncate">
            @{truncateName(participant.user.nickName, 12)}
          </p>
        </div>
      </div>
    </div>
  );
};

// Componente para mostrar el resumen de categoría
const CategorySummary = ({ category, count, color }) => (
  <div className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow">
    <div className="flex items-center justify-between">
      <div>
        <h3 className="font-semibold text-gray-900">{category}</h3>
        <p className="text-xs text-gray-500">Participantes</p>
      </div>
      <div className={`text-2xl font-bold ${color} px-3 py-1 rounded-lg`}>
        {count}
      </div>
    </div>
  </div>
);

// Componente para el modal de participantes
const ParticipantsModal = ({ isOpen, onClose, participants }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleParticipantClick = (uid) => {
    navigate(`/user/${uid}`);
    onClose();
  };

  // Filtramos los participantes según el término de búsqueda
  const filteredParticipants = useMemo(() => {
    return participants.filter((participant) =>
      participant.user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [participants, searchTerm]);

  // Agrupamos los participantes por categoría
  const groupedParticipants = useMemo(() => {
    return filteredParticipants.reduce((acc, participant) => {
      const category = participant.category || "General";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(participant);
      return acc;
    }, {});
  }, [filteredParticipants]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-70 flex items-center justify-center p-4 backdrop-blur-sm">
      <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] flex flex-col overflow-hidden">
        {/* Cabecera del modal */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b sticky top-0 bg-white z-10">
          <div className="flex items-center">
            <Users className="w-5 h-5 md:w-6 md:h-6 text-gray-700 mr-2" />
            <h2 className="text-lg md:text-xl font-bold text-gray-900">
              Lista de Participantes
            </h2>
            <span className="ml-3 text-sm bg-gray-100 text-gray-700 px-2 py-1 rounded-full">
              {filteredParticipants.length} total
            </span>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Cerrar"
          >
            <X className="w-5 h-5 md:w-6 md:h-6 text-gray-500" />
          </button>
        </div>

        {/* Barra de búsqueda */}
        <div className="p-4 border-b sticky top-16 bg-white z-10 shadow-sm">
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar participantes..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full pl-10 pr-4 py-2 md:py-3 rounded-full bg-gray-50 border border-gray-300 focus:ring-2 focus:ring-black focus:border-gray-400 focus:outline-none transition-all"
              autoFocus
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1 rounded-full hover:bg-gray-200"
              >
                <X className="w-4 h-4 text-gray-500" />
              </button>
            )}
          </div>
        </div>

        {/* Contenido del modal (scrollable) */}
        <div className="flex-1 overflow-y-auto p-4">
          {/* Si no hay resultados */}
          {Object.keys(groupedParticipants).length === 0 ? (
            <div className="text-center py-12">
              <Users className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-700">
                No se encontraron participantes
              </h3>
              <p className="text-sm text-gray-500 mt-2">
                Intenta con otra búsqueda
              </p>
            </div>
          ) : (
            <div className="space-y-8">
              {Object.entries(groupedParticipants).map(
                ([category, categoryParticipants]) => (
                  <div key={category} className="bg-white rounded-lg mb-6">
                    {/* Encabezado de categoría (no sticky) */}
                    <div className="bg-gray-50 rounded-t-lg border border-gray-100 p-4 mb-4">
                      <div className="flex items-center justify-between">
                        <h3 className="text-lg font-semibold text-gray-900">
                          {category}
                        </h3>
                        <span className="text-sm font-medium bg-gray-200 text-gray-700 px-3 py-1 rounded-full">
                          {categoryParticipants.length}
                        </span>
                      </div>
                    </div>

                    {/* Lista de participantes */}
                    <div
                      className={
                        isMobile
                          ? "space-y-3"
                          : "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
                      }
                    >
                      {categoryParticipants.map((participant, idx) => (
                        <ParticipantCard
                          key={idx}
                          participant={participant}
                          onClick={() =>
                            handleParticipantClick(participant.user.uid)
                          }
                          isMobile={isMobile}
                        />
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EventParticipants = ({ participants, isLoading = false }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useUserAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Categorizar participantes con colores
  const categorySummary = useMemo(() => {
    // Agrupamos los participantes por categoría
    const grouped = participants.reduce((acc, participant) => {
      const category = participant.category || "General";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(participant);
      return acc;
    }, {});

    // Asignamos colores para cada categoría
    const colors = [
      "text-blue-600",
      "text-green-600",
      "text-purple-600",
      "text-yellow-600",
      "text-pink-600",
      "text-indigo-600",
      "text-red-600",
      "text-teal-600",
    ];

    // Generamos el resumen de categorías con sus colores
    return Object.entries(grouped).map(
      ([category, categoryParticipants], index) => ({
        category,
        count: categoryParticipants.length,
        color: colors[index % colors.length],
      })
    );
  }, [participants]);

  const totalParticipants = participants.length;

  // Manejar clic en el botón de ver todos
  const handleViewAllClick = () => {
    if (!user) {
      navigate("/login");
    } else {
      setIsModalOpen(true);
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-6 animate-pulse">
        <div className="h-10 bg-gray-200 rounded w-3/4 mb-6"></div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="bg-gray-200 rounded-lg h-24"></div>
          ))}
        </div>
        <div className="h-12 bg-gray-200 rounded-lg mt-6"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="flex items-center mb-4 md:mb-0">
          <Users className="w-6 h-6 text-gray-900 mr-2" />
          <h2 className="text-xl font-bold text-gray-900">
            Participantes del Evento
          </h2>
        </div>
        <div className="flex items-center bg-gray-100 px-4 py-2 rounded-full shadow-sm">
          <span className="font-bold text-gray-900 mr-1">
            {totalParticipants}
          </span>
          <span className="text-gray-500 text-sm">
            {totalParticipants === 1 ? "participante" : "participantes"} en
            total
          </span>
        </div>
      </div>

      {/* Mostrar resumen de categorías */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-4 mb-6">
        {categorySummary.map((item, index) => (
          <CategorySummary
            key={index}
            category={item.category}
            count={item.count}
            color={item.color}
          />
        ))}
      </div>

      {/* Botón para ver todos los participantes */}
      <button
        onClick={handleViewAllClick}
        className="w-full py-3 md:py-4 bg-gradient-to-r from-gray-900 to-gray-700 hover:from-gray-800 hover:to-gray-600 text-white rounded-xl shadow-lg transition-all duration-300 transform hover:-translate-y-1 flex items-center justify-center font-medium"
      >
        <Users className="w-5 h-5 mr-2" />
        {user ? (
          <span className="text-sm md:text-base">
            Ver listado completo de participantes
          </span>
        ) : (
          <span className="text-sm md:text-base">
            Inicia sesión para ver todos los participantes
          </span>
        )}
      </button>

      {/* Modal de participantes (solo se muestra cuando isModalOpen es true) */}
      <ParticipantsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        participants={participants}
      />
    </div>
  );
};

export default EventParticipants;
