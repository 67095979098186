import React, { useState, useEffect } from "react";
import { CalendarDays, Medal, X, Search, UserPlus, CheckCircle, AlertCircle } from "lucide-react";
import {
    collection,
    doc,
    getDoc,
    updateDoc,
    query,
    getDocs,
    orderBy,
    addDoc,
    where
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../context/userAuthContext";

const AddManualUser = () => {
    const { user, loading } = useUserAuth();
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [toast, setToast] = useState({ show: false, message: "", type: "" });

    // Cargar eventos al iniciar
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const q = query(collection(db, "events"), orderBy("dateTime", "desc"));
                const snapshot = await getDocs(q);
                setEvents(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
                setIsLoading(false);
            } catch (error) {
                console.error("Error loading events:", error);
                showToast("Error al cargar eventos", "error");
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    // Cargar datos del evento cuando se selecciona uno
    const fetchEventData = async (eventId) => {
        setIsLoading(true);
        try {
            const eventDoc = await getDoc(doc(db, "events", eventId));
            if (!eventDoc.exists()) throw new Error("Evento no encontrado");

            const categoriesSnap = await getDocs(collection(doc(db, "events", eventId), "categories"));
            const categories = categoriesSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const participantsSnap = await getDocs(collection(doc(db, "events", eventId), "participants"));
            const participants = await Promise.all(
                participantsSnap.docs.map(async (pDoc) => {
                    const pData = pDoc.data();
                    const userDoc = await getDoc(doc(db, "Users", pData.userId));
                    return {
                        id: pDoc.id,
                        userId: pData.userId,
                        categoryId: pData.categoryId,
                        user: userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null
                    };
                })
            );

            setEventData({ ...eventDoc.data(), id: eventDoc.id, categories, participants });

            // Seleccionar la primera categoría por defecto si existe
            if (categories.length > 0) {
                setSelectedCategory(categories[0].id);
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error loading event data:", error);
            showToast("Error al cargar datos del evento", "error");
            setIsLoading(false);
        }
    };

    // Buscar usuarios
    const handleSearch = async () => {
        if (!searchTerm.trim()) {
            setSearchResults([]);
            return;
        }

        setIsSearching(true);
        try {
            console.log("Iniciando búsqueda con término:", searchTerm);

            // Esta vez usamos la misma técnica que en EventCategories para obtener usuarios
            const usersSnapshot = await getDocs(collection(db, "Users"));

            console.log("Total de usuarios en la colección:", usersSnapshot.size);

            // Imprimir todos los usuarios para depuración con detalle
            usersSnapshot.forEach(doc => {
                console.log("Usuario encontrado en DB:", {
                    id: doc.id,
                    data: doc.data(),
                    name: doc.data().name,
                    nickName: doc.data().nickName,
                    tipoName: typeof doc.data().name,
                    tipoNickName: typeof doc.data().nickName
                });
            });

            const searchTermLower = searchTerm.toLowerCase();
            console.log("Término de búsqueda normalizado:", searchTermLower);

            // Filtrar usuarios basados en nombre o apodo - usando la misma lógica que funciona en el componente original
            const filteredUsers = [];

            for (const doc of usersSnapshot.docs) {
                const userData = doc.data();
                const userId = doc.id;

                // Obtener valores de name y nickName, garantizando que sean string o un valor por defecto
                const name = typeof userData.name === 'string' ? userData.name : '';
                const nickName = typeof userData.nickName === 'string' ? userData.nickName : '';

                console.log(`Comparando usuario ${userId}: name="${name}", nickName="${nickName}" con término="${searchTermLower}"`);

                // Comprobar si alguno contiene el término de búsqueda
                const nameMatch = name.toLowerCase().includes(searchTermLower);
                const nickMatch = nickName.toLowerCase().includes(searchTermLower);

                console.log(`  - Resultado: nameMatch=${nameMatch}, nickMatch=${nickMatch}`);

                if (nameMatch || nickMatch) {
                    console.log(`  - COINCIDENCIA ENCONTRADA: Agregando usuario ${userId} a resultados`);
                    filteredUsers.push({
                        id: userId,
                        ...userData
                    });
                }
            }

            console.log("Total de usuarios que coinciden con la búsqueda:", filteredUsers.length);
            console.log("Usuarios encontrados:", filteredUsers);

            // Filtrar usuarios que ya están en el evento
            if (selectedEvent && eventData) {
                const existingUserIds = eventData.participants.map(p => p.userId);
                console.log("IDs de usuarios ya en el evento:", existingUserIds);

                const newFilteredUsers = filteredUsers.filter(user => {
                    const isInEvent = existingUserIds.includes(user.id);
                    console.log(`Verificando si ${user.id} ya está en el evento: ${isInEvent}`);
                    return !isInEvent;
                });

                console.log("Usuarios después de filtrar los existentes:", newFilteredUsers);
                setSearchResults(newFilteredUsers);

                if (newFilteredUsers.length === 0 && filteredUsers.length > 0) {
                    console.log("Todos los usuarios ya están en el evento");
                    showToast("Todos los usuarios encontrados ya están en este evento", "error");
                }
            } else {
                setSearchResults(filteredUsers);
            }

        } catch (error) {
            console.error("Error searching users:", error);
            showToast("Error al buscar usuarios: " + error.message, "error");
        } finally {
            setIsSearching(false);
        }
    };

    // Verificar si el usuario ya está en el evento
    const checkUserInEvent = async (userId, eventId) => {
        try {
            const participantsQuery = query(
                collection(doc(db, "events", eventId), "participants"),
                where("userId", "==", userId)
            );

            const participantsSnapshot = await getDocs(participantsQuery);
            return !participantsSnapshot.empty;
        } catch (error) {
            console.error("Error checking user in event:", error);
            return false;
        }
    };

    // Agregar usuario al evento
    const addUserToEvent = async () => {
        if (!user) {
            showToast("Debes estar autenticado para realizar esta acción", "error");
            return;
        }

        if (!selectedUser || !selectedEvent || !selectedCategory) {
            showToast("Selecciona un usuario, evento y categoría", "error");
            return;
        }

        setIsLoading(true);
        try {
            // Verificar si el usuario ya está en el evento
            const isUserInEvent = await checkUserInEvent(selectedUser.id, selectedEvent.id);

            if (isUserInEvent) {
                showToast("Este usuario ya está inscrito en este evento", "error");
                setIsLoading(false);
                return;
            }

            // Agregar usuario al evento
            const newParticipantRef = await addDoc(
                collection(doc(db, "events", selectedEvent.id), "participants"),
                {
                    userId: selectedUser.id,
                    categoryId: selectedCategory,
                    registrationDate: new Date().toISOString()
                }
            );

            // Actualizar el estado local para incluir el nuevo participante
            const userDoc = await getDoc(doc(db, "Users", selectedUser.id));
            const newParticipant = {
                id: newParticipantRef.id,
                userId: selectedUser.id,
                categoryId: selectedCategory,
                user: userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null
            };

            setEventData(prev => ({
                ...prev,
                participants: [...prev.participants, newParticipant]
            }));

            showToast(`${selectedUser.name} ha sido agregado al evento exitosamente`, "success");

            // Limpiar selección y resultados de búsqueda
            setSelectedUser(null);
            setSearchTerm("");
            setSearchResults([]);
        } catch (error) {
            console.error("Error adding user to event:", error);
            showToast("Error al agregar usuario al evento", "error");
        } finally {
            setIsLoading(false);
        }
    };

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
        setTimeout(() => setToast({ show: false, message: "", type: "" }), 3000);
    };

    if (loading || isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-6">
            {toast.show && (
                <div className={`fixed top-4 right-4 p-4 rounded-lg shadow-lg z-50 ${toast.type === "success" ? "bg-green-500" : "bg-red-500"
                    } text-white flex items-center gap-2`}>
                    {toast.type === "success" ? (
                        <CheckCircle className="h-5 w-5" />
                    ) : (
                        <AlertCircle className="h-5 w-5" />
                    )}
                    {toast.message}
                </div>
            )}

            <div className="bg-white rounded-lg shadow p-6">
                <h1 className="text-2xl font-bold mb-6">Agregar Corredor a Evento</h1>

                {/* Selector de Evento */}
                <div className="mb-6">
                    <div className="flex items-center gap-2 mb-3">
                        <CalendarDays className="h-5 w-5 text-blue-600" />
                        <h2 className="text-lg font-semibold">1. Seleccionar Evento</h2>
                    </div>
                    <div className="h-48 overflow-y-auto pr-2">
                        <div className="space-y-2">
                            {events.map(event => (
                                <button
                                    key={event.id}
                                    onClick={() => {
                                        setSelectedEvent(event);
                                        fetchEventData(event.id);
                                        setSelectedUser(null);
                                        setSearchResults([]);
                                        setSearchTerm("");
                                    }}
                                    className={`w-full p-3 rounded-lg text-left transition-all ${selectedEvent?.id === event.id
                                            ? "bg-blue-50 border border-blue-200"
                                            : "bg-gray-50 hover:bg-gray-100 border border-transparent"
                                        }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <div className="font-medium">{event.eventName}</div>
                                            <div className="text-sm text-gray-500">
                                                {new Date(event.dateTime).toLocaleDateString()}
                                            </div>
                                        </div>
                                        <Medal className="h-4 w-4 text-blue-600" />
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Selector de Categoría */}
                {selectedEvent && eventData && (
                    <div className="mb-6">
                        <div className="flex items-center gap-2 mb-3">
                            <Medal className="h-5 w-5 text-blue-600" />
                            <h2 className="text-lg font-semibold">2. Seleccionar Categoría</h2>
                        </div>
                        {eventData.categories.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                {eventData.categories.map(category => (
                                    <button
                                        key={category.id}
                                        onClick={() => setSelectedCategory(category.id)}
                                        className={`p-4 rounded-lg text-left transition-all ${selectedCategory === category.id
                                                ? "bg-blue-50 border-2 border-blue-500"
                                                : "bg-gray-50 hover:bg-gray-100 border border-gray-200"
                                            }`}
                                    >
                                        <div className="font-medium">{category.name}</div>
                                        {category.description && (
                                            <div className="text-sm text-gray-500">{category.description}</div>
                                        )}
                                    </button>
                                ))}
                            </div>
                        ) : (
                            <div className="text-gray-500">No hay categorías disponibles para este evento</div>
                        )}
                    </div>
                )}

                {/* Buscador de Usuarios y Participantes Actuales */}
                {selectedEvent && eventData && (
                    <div className="mb-6">
                        <div className="flex items-center gap-2 mb-3">
                            <UserPlus className="h-5 w-5 text-green-600" />
                            <h2 className="text-lg font-semibold">3. Buscar Corredor</h2>
                        </div>
                        <div className="flex gap-2 mb-4">
                            <div className="relative flex-1">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                                    placeholder="Buscar por nombre o apodo..."
                                    className="w-full p-3 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                {searchTerm && (
                                    <button
                                        onClick={() => {
                                            setSearchTerm("");
                                            setSearchResults([]);
                                        }}
                                        className="absolute right-10 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                    >
                                        <X className="h-5 w-5" />
                                    </button>
                                )}
                            </div>
                            <button
                                onClick={handleSearch}
                                disabled={!searchTerm.trim() || isSearching}
                                className={`p-3 rounded-lg flex items-center gap-2 ${searchTerm.trim() && !isSearching
                                        ? "bg-blue-600 text-white hover:bg-blue-700"
                                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                                    }`}
                            >
                                <Search className="h-5 w-5" />
                                {isSearching ? "Buscando..." : "Buscar"}
                            </button>
                        </div>

                        {/* Resultados de la búsqueda */}
                        {searchResults.length > 0 && (
                            <div className="mb-6">
                                <h3 className="text-md font-medium mb-2">Resultados ({searchResults.length})</h3>
                                <div className="space-y-2 max-h-64 overflow-y-auto pr-2 border border-gray-200 rounded-lg p-2">
                                    {searchResults.map(user => (
                                        <button
                                            key={user.id}
                                            onClick={() => setSelectedUser(user)}
                                            className={`w-full p-4 rounded-lg text-left transition-all flex justify-between items-center ${selectedUser?.id === user.id
                                                    ? "bg-blue-50 border-2 border-blue-500"
                                                    : "bg-gray-50 hover:bg-gray-100 border border-gray-200"
                                                }`}
                                        >
                                            <div>
                                                <div className="font-medium">
                                                    {typeof user.name === 'string' ? user.name : 'Nombre no disponible'}
                                                </div>
                                                {typeof user.nickName === 'string' && user.nickName && (
                                                    <div className="text-sm text-gray-500">Apodo: {user.nickName}</div>
                                                )}
                                                {typeof user.phoneNumber === 'string' && user.phoneNumber && (
                                                    <div className="text-sm text-gray-500">Tel: {user.phoneNumber}</div>
                                                )}
                                                {typeof user.state === 'string' && user.state && (
                                                    <div className="text-sm text-gray-500">Estado: {user.state}</div>
                                                )}
                                            </div>
                                            {selectedUser?.id === user.id && (
                                                <CheckCircle className="h-5 w-5 text-blue-600" />
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}

                        {searchTerm && searchResults.length === 0 && !isSearching && (
                            <div className="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200 text-gray-500">
                                No se encontraron usuarios con ese nombre o apodo
                            </div>
                        )}

                        {/* Participantes Actuales del Evento */}
                        <div className="mt-8">
                            <div className="flex items-center justify-between mb-3">
                                <h3 className="text-lg font-semibold">Participantes Actuales</h3>
                                <span className="text-sm text-gray-500">{eventData.participants.length} corredores</span>
                            </div>

                            <div className="border border-gray-200 rounded-lg">
                                <div className="h-96 overflow-y-auto pr-2">
                                    <div className="space-y-2 p-2">
                                        {eventData.participants.map((participant) => (
                                            <div
                                                key={participant.id}
                                                className="p-4 bg-gray-50 rounded-lg border border-gray-200"
                                            >
                                                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                                                    <div>
                                                        <div className="font-medium">
                                                            {participant.user?.name || 'Usuario no encontrado'}
                                                        </div>
                                                        <div className="text-sm text-gray-500">
                                                            {eventData.categories.find(c => c.id === participant.categoryId)?.name || 'Sin categoría'}
                                                            {participant.user?.nickName && ` • ${participant.user.nickName}`}
                                                            {participant.user?.phoneNumber && ` • Tel: ${participant.user.phoneNumber}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Botón para agregar usuario */}
                {selectedEvent && selectedCategory && selectedUser && (
                    <div className="mt-8">
                        <button
                            onClick={addUserToEvent}
                            disabled={isLoading}
                            className="w-full sm:w-auto px-6 py-3 bg-green-600 hover:bg-green-700 text-white rounded-lg font-medium flex items-center justify-center gap-2 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                            <UserPlus className="h-5 w-5" />
                            {isLoading ? "Agregando..." : "Agregar al Evento"}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddManualUser;