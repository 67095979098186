import React, { useState, useEffect } from "react";
import { Download, Trash2, PlusCircle } from "lucide-react";
import { eventsApi } from "../../../lib/client";

const SimulatorComponent = () => {
  // Estados para manejar la información del evento y receptor
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Estados para manejar los UIDs de los corredores
  const [currentUid, setCurrentUid] = useState("");
  const [uidList, setUidList] = useState([
    "f0:8e:69:23",
    "d4:62:bb:cf",
    "30:9f:5c:03",
    "70:12:68:23",
    "b4:61:01:8e",
  ]);
  const [customUid, setCustomUid] = useState("");

  // Estados para manejar los tiempos generados
  const [startTimes, setStartTimes] = useState([]);
  const [finishTimes, setFinishTimes] = useState([]);

  // Estado para manejar la zona horaria
  const [timezone, setTimezone] = useState("UTC-7");

  // Obtiene los eventos al cargar el componente
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsFound = await eventsApi.getAllEvents();
      setEvents(eventsFound);
      if (eventsFound.length > 0 && !selectedEvent) {
        setSelectedEvent(eventsFound[0]);
      }
    };

    fetchEvents();
  }, []);

  // Función para formatear la fecha y hora en el formato requerido
  const formatDateTime = (date) => {
    const pad = (num) => String(num).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());

    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    // Generar milisegundos aleatorios entre 100 y 999 para simular precisión
    const ms = Math.floor(Math.random() * 900) + 100;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${ms} ${timezone}`;
  };

  // Función para manejar el cambio de evento seleccionado
  const handleEventChange = (e) => {
    const selectedEventId = e.target.value;
    const eventObj = events.find((e) => e.id === selectedEventId);
    setSelectedEvent(eventObj);
  };

  // Función para generar un tiempo de inicio
  const generateStartTime = () => {
    if (!currentUid) {
      alert("Por favor selecciona un UID");
      return;
    }

    if (!selectedEvent) {
      alert("Por favor selecciona un evento");
      return;
    }

    const currentTime = new Date();

    const newStartTime = {
      RUID: selectedEvent.id,
      Time: formatDateTime(currentTime),
      UID: currentUid,
      CloudStatus: "oncloud",
    };

    setStartTimes([...startTimes, newStartTime]);
  };

  // Función para generar un tiempo de fin
  const generateFinishTime = () => {
    if (!currentUid) {
      alert("Por favor selecciona un UID");
      return;
    }

    if (!selectedEvent) {
      alert("Por favor selecciona un evento");
      return;
    }

    // Verificar si hay un tiempo de inicio para este UID
    // const hasStartTime = startTimes.some((time) => time.UID === currentUid);
    // if (!hasStartTime) {
    //   alert("Este UID no tiene un tiempo de inicio registrado");
    //   return;
    // }

    const currentTime = new Date();

    const newFinishTime = {
      RUID: selectedEvent.id,
      Time: formatDateTime(currentTime),
      UID: currentUid,
      CloudStatus: "oncloud",
    };

    setFinishTimes([...finishTimes, newFinishTime]);
  };

  // Función para eliminar un tiempo de inicio
  const deleteStartTime = (index) => {
    setStartTimes(startTimes.filter((_, i) => i !== index));
  };

  // Función para eliminar un tiempo de fin
  const deleteFinishTime = (index) => {
    setFinishTimes(finishTimes.filter((_, i) => i !== index));
  };

  // Función para añadir un UID personalizado
  const addCustomUid = () => {
    if (customUid && !uidList.includes(customUid)) {
      setUidList([...uidList, customUid]);
      setCurrentUid(customUid);
      setCustomUid("");
    }
  };

  // Función para generar un tiempo de inicio aleatorio
  const generateRandomStartTime = () => {
    if (!currentUid) {
      alert("Por favor selecciona un UID");
      return;
    }

    if (!selectedEvent) {
      alert("Por favor selecciona un evento");
      return;
    }

    // Generar un tiempo aleatorio entre ahora y 10 minutos antes
    const currentTime = new Date();
    const randomTime = new Date(currentTime);
    randomTime.setMinutes(
      randomTime.getMinutes() - Math.floor(Math.random() * 10)
    );

    const newStartTime = {
      RUID: selectedEvent.id,
      Time: formatDateTime(randomTime),
      UID: currentUid,
      CloudStatus: "oncloud",
    };

    setStartTimes([...startTimes, newStartTime]);
  };

  // Función para generar un tiempo de fin aleatorio
  const generateRandomFinishTime = () => {
    if (!currentUid) {
      alert("Por favor selecciona un UID");
      return;
    }

    if (!selectedEvent) {
      alert("Por favor selecciona un evento");
      return;
    }

    // Verificar si hay un tiempo de inicio para este UID
    const hasStartTime = startTimes.some((time) => time.UID === currentUid);
    if (!hasStartTime) {
      alert("Este UID no tiene un tiempo de inicio registrado");
      return;
    }

    // Encontrar el último tiempo de inicio para este UID
    const startTimesForUid = startTimes.filter(
      (time) => time.UID === currentUid
    );
    const lastStartTimeStr = startTimesForUid[startTimesForUid.length - 1].Time;

    // Extraer la fecha y hora sin la zona horaria
    const dateTimePart =
      lastStartTimeStr.split(" ")[0] + " " + lastStartTimeStr.split(" ")[1];
    const lastStartTime = new Date(dateTimePart);

    // Generar un tiempo aleatorio entre el último tiempo de inicio y 10 minutos después
    const randomTime = new Date(lastStartTime);
    randomTime.setMinutes(
      randomTime.getMinutes() + Math.floor(Math.random() * 10) + 1
    );

    const newFinishTime = {
      RUID: selectedEvent.id,
      Time: formatDateTime(randomTime),
      UID: currentUid,
      CloudStatus: "oncloud",
    };

    setFinishTimes([...finishTimes, newFinishTime]);
  };

  // Función para convertir un array de objetos a formato CSV
  const convertToCSV = (objArray) => {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    // Encabezados
    str += Object.keys(array[0]).join(",") + "\r\n";

    // Datos
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (line !== "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  // Función para descargar datos como CSV
  const downloadCSV = (data, filename) => {
    if (!data.length) {
      alert("No hay datos para descargar");
      return;
    }

    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Función para generar múltiples corredores con tiempos aleatorios
  const generateMultipleRuns = () => {
    if (!selectedEvent) {
      alert("Por favor selecciona un evento");
      return;
    }

    const newStartTimes = [...startTimes];
    const newFinishTimes = [...finishTimes];

    // Base time to start generating events
    const currentTime = new Date();
    let baseTime = new Date(currentTime);
    baseTime.setHours(baseTime.getHours() - 1);

    // Generar tiempos de inicio y fin para cada UID
    uidList.forEach((uid, index) => {
      // Tiempo específico para este corredor
      let runnerTime = new Date(baseTime);
      runnerTime.setMinutes(runnerTime.getMinutes() + index);

      // Generar tiempo de inicio
      const startTime = {
        RUID: selectedEvent.id,
        Time: formatDateTime(runnerTime),
        UID: uid,
        CloudStatus: "oncloud",
      };
      newStartTimes.push(startTime);

      // Avanzar entre 1 y 3 minutos para el tiempo de fin
      runnerTime.setMinutes(
        runnerTime.getMinutes() + Math.floor(Math.random() * 3) + 1
      );

      // Generar tiempo de fin
      const finishTime = {
        RUID: selectedEvent.id,
        Time: formatDateTime(runnerTime),
        UID: uid,
        CloudStatus: "oncloud",
      };
      newFinishTimes.push(finishTime);
    });

    setStartTimes(newStartTimes);
    setFinishTimes(newFinishTimes);
  };

  // Función para limpiar todos los datos
  const clearAllData = () => {
    if (window.confirm("¿Estás seguro de que deseas borrar todos los datos?")) {
      setStartTimes([]);
      setFinishTimes([]);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Simulador de Receptor</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          {/* Configuración del Receptor */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">
              Configuración del Receptor
            </h2>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Evento
              </label>
              <select
                value={selectedEvent ? selectedEvent.id : ""}
                onChange={handleEventChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {events.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.eventName || event.id}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Fecha y Hora Actual (Tiempo Real)
              </label>
              <input
                type="text"
                value={formatDateTime(new Date())
                  .split(" ")
                  .slice(0, 2)
                  .join(" ")}
                readOnly
                className="w-full p-2 bg-gray-100 border border-gray-300 rounded-md"
              />
            </div> */}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Zona Horaria
              </label>
              <input
                type="text"
                placeholder="Zona Horaria"
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <p className="mt-1 text-sm text-gray-500">
                Formato: UTC-7, UTC+1, etc.
              </p>
            </div>
          </div>

          {/* Selección de Corredor */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">
              Selección de Corredor
            </h2>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                UID del Corredor
              </label>
              <select
                value={currentUid}
                onChange={(e) => setCurrentUid(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Seleccionar UID</option>
                {uidList.map((uid) => (
                  <option key={uid} value={uid}>
                    {uid}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex space-x-2 items-center">
              <input
                type="text"
                placeholder="Agregar UID personalizado"
                value={customUid}
                onChange={(e) => setCustomUid(e.target.value)}
                className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={addCustomUid}
                className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <PlusCircle size={20} />
              </button>
            </div>
          </div>

          {/* Acciones */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Acciones</h2>

            <div className="flex justify-between mb-4">
              <button
                onClick={generateStartTime}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Registrar Inicio
              </button>
              <button
                onClick={generateFinishTime}
                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                Registrar Fin
              </button>
            </div>

            <div className="flex justify-between mb-4">
              <button
                onClick={generateRandomStartTime}
                className="px-4 py-2 border border-blue-600 text-blue-600 rounded-md hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Inicio Aleatorio
              </button>
              <button
                onClick={generateRandomFinishTime}
                className="px-4 py-2 border border-purple-600 text-purple-600 rounded-md hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                Fin Aleatorio
              </button>
            </div>

            <div className="border-t border-gray-200 my-4 pt-4">
              <div className="flex justify-between">
                <button
                  onClick={generateMultipleRuns}
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 flex-1 mr-2"
                >
                  Generar Múltiples Carreras
                </button>
                <button
                  onClick={clearAllData}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 flex-1 ml-2"
                >
                  Limpiar Datos
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* Tiempos de Inicio */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                Tiempos de Inicio ({startTimes.length})
              </h2>
              <button
                onClick={() =>
                  downloadCSV(
                    startTimes,
                    `S${
                      selectedEvent ? selectedEvent.id.substring(0, 1) : ""
                    }.csv`
                  )
                }
                disabled={startTimes.length === 0 || !selectedEvent}
                className={`flex items-center px-3 py-1 border ${
                  startTimes.length === 0 || !selectedEvent
                    ? "border-gray-300 text-gray-300 cursor-not-allowed"
                    : "border-blue-600 text-blue-600 hover:bg-blue-50"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
              >
                <Download size={16} className="mr-1" /> Descargar CSV
              </button>
            </div>

            <div className="border border-gray-200 rounded-md overflow-hidden">
              <div className="overflow-x-auto max-h-48">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0">
                    <tr>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        RUID
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        UID
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        CloudStatus
                      </th>
                      <th className="px-3 py-2 text-xs font-medium text-gray-500"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {startTimes.map((time, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.RUID}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                          <span className="px-2 py-1 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                            {time.UID}
                          </span>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.Time}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.CloudStatus}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => deleteStartTime(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 size={16} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Tiempos de Fin */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                Tiempos de Fin ({finishTimes.length})
              </h2>
              <button
                onClick={() =>
                  downloadCSV(
                    finishTimes,
                    `F${
                      selectedEvent ? selectedEvent.id.substring(0, 1) : ""
                    }.csv`
                  )
                }
                disabled={finishTimes.length === 0 || !selectedEvent}
                className={`flex items-center px-3 py-1 border ${
                  finishTimes.length === 0 || !selectedEvent
                    ? "border-gray-300 text-gray-300 cursor-not-allowed"
                    : "border-purple-600 text-purple-600 hover:bg-purple-50"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500`}
              >
                <Download size={16} className="mr-1" /> Descargar CSV
              </button>
            </div>

            <div className="border border-gray-200 rounded-md overflow-hidden">
              <div className="overflow-x-auto max-h-48">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50 sticky top-0">
                    <tr>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        RUID
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        UID
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Time
                      </th>
                      <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        CloudStatus
                      </th>
                      <th className="px-3 py-2 text-xs font-medium text-gray-500"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {finishTimes.map((time, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.RUID}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap">
                          <span className="px-2 py-1 text-xs font-medium bg-purple-100 text-purple-800 rounded-full">
                            {time.UID}
                          </span>
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.Time}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          {time.CloudStatus}
                        </td>
                        <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                          <button
                            onClick={() => deleteFinishTime(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 size={16} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Análisis de Datos */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Análisis de Datos</h2>

            <div className="grid grid-cols-2 gap-4">
              <div className="border border-gray-200 rounded-md p-4 text-center">
                <p className="text-sm text-gray-500 mb-1">Corredores Únicos</p>
                <p className="text-2xl font-bold">
                  {
                    new Set([...startTimes, ...finishTimes].map((t) => t.UID))
                      .size
                  }
                </p>
              </div>
              <div className="border border-gray-200 rounded-md p-4 text-center">
                <p className="text-sm text-gray-500 mb-1">Vueltas Completas</p>
                <p className="text-2xl font-bold">
                  {uidList.reduce((acc, uid) => {
                    const starts = startTimes.filter(
                      (t) => t.UID === uid
                    ).length;
                    const finishes = finishTimes.filter(
                      (t) => t.UID === uid
                    ).length;
                    return acc + Math.min(starts, finishes);
                  }, 0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimulatorComponent;
