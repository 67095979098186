import React, { useMemo } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Chip,
    Avatar,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import CancelIcon from "@mui/icons-material/Cancel";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CategoryIcon from "@mui/icons-material/Category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

const CategoryLeaderboardTable = ({
    events,
    categories,
    totalTimes,
    ownerData,
    convertTimeToMilliseconds
}) => {
    // Función interna para convertir tiempos si no se proporciona
    const timeToMs = (time) => {
        if (!time || time === "---" || time === "DNF" || time === "DNS") return null;
        try {
            const [hours, minutes, seconds] = time.split(":");
            const [secs, ms] = seconds.split(".");
            return (
                parseInt(hours) * 3600000 +
                parseInt(minutes) * 60000 +
                parseInt(secs) * 1000 +
                parseInt(ms)
            );
        } catch (error) {
            console.error("Error converting time:", time, error);
            return 0;
        }
    };

    // Usar la función proporcionada o la interna
    const convertToMs = convertTimeToMilliseconds || timeToMs;

    // Función para convertir milisegundos a formato de tiempo
    const msToTimeFormat = (ms) => {
        if (!ms || ms === 0) return "---";
        try {
            const hours = Math.floor(ms / 3600000);
            const minutes = Math.floor((ms % 3600000) / 60000);
            const seconds = Math.floor((ms % 60000) / 1000);
            const milliseconds = ms % 1000;

            return `${hours.toString().padStart(2, "0")}:${minutes
                .toString()
                .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
                    .toString()
                    .padStart(3, "0")}`;
        } catch (error) {
            console.error("Error converting milliseconds:", ms, error);
            return "---";
        }
    };

    // Sistema de puntos según posición
    const getPointsForPosition = (position) => {
        const pointsMap = {
            1: 5,    // Primer lugar: 5 puntos
            2: 4.5,  // Segundo lugar: 4.5 puntos
            3: 4,    // Tercer lugar: 4 puntos
            4: 3.5,  // Cuarto lugar: 3.5 puntos
            5: 3,    // Quinto lugar: 3 puntos
            6: 2.5,  // Sexto lugar: 2.5 puntos
            7: 2,    // Séptimo lugar: 2 puntos
            8: 1.5,  // Octavo lugar: 1.5 puntos
            9: 0.5,  // Noveno lugar: 0.5 puntos
            10: 0.5  // Décimo lugar: 0.5 puntos
        };

        return position <= 10 ? pointsMap[position] || 0 : 0;
    };

    // Crear un mapa de categorías por nombre (para unificar categorías con el mismo nombre)
    const categoryNameToDataMap = useMemo(() => {
        const categoryMap = new Map();

        // Agrupar categorías por nombre
        categories.forEach(category => {
            const categoryName = category.name;

            if (!categoryMap.has(categoryName)) {
                categoryMap.set(categoryName, {
                    ids: [category.id], // Lista de IDs de esta categoría
                    name: categoryName,
                    eventIds: [category.eventId] // Lista de eventos que tienen esta categoría
                });
            } else {
                const existingCategory = categoryMap.get(categoryName);
                existingCategory.ids.push(category.id);
                existingCategory.eventIds.push(category.eventId);
            }
        });

        return categoryMap;
    }, [categories]);

    // Procesar y agrupar usuarios por categoría
    const categoriesData = useMemo(() => {
        // Crear un mapa para categorías por nombre
        const categoriesMap = new Map();

        // Inicializar categorías
        categoryNameToDataMap.forEach((categoryData, categoryName) => {
            categoriesMap.set(categoryName, {
                name: categoryName,
                categoryIds: categoryData.ids, // Lista de IDs de categoría que comparten este nombre
                eventIds: categoryData.eventIds, // Lista de eventos que tienen esta categoría
                users: new Map(), // Mapa de usuarios de esta categoría
                eventRankings: {} // Almacenará los rankings por evento
            });
        });

        // Categoría para usuarios sin clasificar
        categoriesMap.set("uncategorized", {
            name: "Sin Categoría",
            categoryIds: [],
            eventIds: [],
            users: new Map(),
            eventRankings: {}
        });

        // Mapa para rastrear NFCIdentifier -> Owner y su categoría
        const nfcToOwnerMap = {};

        // Procesar ownerData para determinar categorías de cada usuario
        Object.entries(ownerData).forEach(([key, userData]) => {
            const [nfcId, eventId] = key.split('-');

            // Registrar NFC -> propietario
            const categoryName = userData.categoryId ?
                (categories.find(cat => cat.id === userData.categoryId)?.name || "Sin Categoría") :
                "Sin Categoría";

            nfcToOwnerMap[nfcId] = {
                name: userData.Owner,
                photoUrl: userData.PhotoUrl,
                categoryId: userData.categoryId,
                categoryName: categoryName
            };

            // Si tiene categoría, procesar
            if (categoryName !== "Sin Categoría" && categoriesMap.has(categoryName)) {
                const category = categoriesMap.get(categoryName);

                if (!category.users.has(userData.Owner)) {
                    // Inicializar información del usuario en esta categoría
                    category.users.set(userData.Owner, {
                        name: userData.Owner,
                        photoUrl: userData.photoUrl,
                        eventTimes: {}, // Por eventId
                        eventPositions: {}, // Posición en cada evento
                        eventPoints: {}, // Puntos obtenidos en cada evento
                        nfcIds: new Set([nfcId]),
                        totalTimeMs: 0,
                        totalPoints: 0,
                        isDNF: false,
                        eventsParticipated: new Set()
                    });
                } else {
                    // Actualizar NFCs
                    const user = category.users.get(userData.Owner);
                    user.nfcIds.add(nfcId);
                }
            } else {
                // Sin categoría o categoría no encontrada
                const uncategorized = categoriesMap.get("uncategorized");
                if (!uncategorized.users.has(userData.Owner)) {
                    uncategorized.users.set(userData.Owner, {
                        name: userData.Owner,
                        photoUrl: userData.photoUrl,
                        eventTimes: {},
                        eventPositions: {},
                        eventPoints: {},
                        nfcIds: new Set([nfcId]),
                        totalTimeMs: 0,
                        totalPoints: 0,
                        isDNF: false,
                        eventsParticipated: new Set()
                    });
                } else {
                    // Actualizar NFCs
                    const user = uncategorized.users.get(userData.Owner);
                    user.nfcIds.add(nfcId);
                }
            }
        });

        // Procesar tiempos para asignarlos a cada usuario en su categoría
        totalTimes.forEach(time => {
            const nfcId = time.NFCIdentifier;
            const owner = nfcToOwnerMap[nfcId];

            if (owner) {
                const categoryName = owner.categoryName;
                const category = categoriesMap.get(categoryName);

                if (category && category.users.has(owner.name)) {
                    const user = category.users.get(owner.name);

                    // Guardar el tiempo para este evento
                    if (!user.eventTimes[time.eventId]) {
                        user.eventTimes[time.eventId] = time.ElapsedTime;
                        user.eventsParticipated.add(time.eventId);

                        // Inicializar rankings por evento si aún no existe
                        if (!category.eventRankings[time.eventId]) {
                            category.eventRankings[time.eventId] = [];
                        }

                        // Agregar este tiempo al ranking del evento
                        category.eventRankings[time.eventId].push({
                            userName: owner.name,
                            time: time.ElapsedTime,
                            timeMs: time.ElapsedTime !== "DNF" && time.ElapsedTime !== "DNS" ?
                                convertToMs(time.ElapsedTime) : null,
                            isDNF: time.ElapsedTime === "DNF",
                            isDNS: time.ElapsedTime === "DNS"
                        });

                        // Calcular tiempo total acumulado
                        if (time.ElapsedTime !== "DNF" && time.ElapsedTime !== "DNS") {
                            const timeMs = convertToMs(time.ElapsedTime);
                            if (timeMs) {
                                user.totalTimeMs += timeMs;
                            }
                        } else if (time.ElapsedTime === "DNF") {
                            user.isDNF = true;
                        }
                    }
                }
            }
        });

        // Calcular rankings y puntos para cada evento en cada categoría
        categoriesMap.forEach(category => {
            // Procesar cada evento
            Object.keys(category.eventRankings).forEach(eventId => {
                const eventTimes = category.eventRankings[eventId];

                // Ordenar tiempos para este evento
                eventTimes.sort((a, b) => {
                    // DNF y DNS van al final
                    if ((a.isDNF || a.isDNS) && !(b.isDNF || b.isDNS)) return 1;
                    if (!(a.isDNF || a.isDNS) && (b.isDNF || b.isDNS)) return -1;

                    // DNS después de DNF
                    if (a.isDNS && b.isDNF) return 1;
                    if (a.isDNF && b.isDNS) return -1;

                    // Si ambos son DNF o DNS, mantener orden original
                    if ((a.isDNF && b.isDNF) || (a.isDNS && b.isDNS)) return 0;

                    // Ordenar por tiempo
                    return a.timeMs - b.timeMs;
                });

                // Asignar posiciones y puntos
                eventTimes.forEach((entry, index) => {
                    const position = index + 1;
                    const points = entry.isDNS ? 0 : getPointsForPosition(position);

                    const user = category.users.get(entry.userName);
                    if (user) {
                        user.eventPositions[eventId] = position;
                        user.eventPoints[eventId] = points;
                        user.totalPoints += points;
                    }
                });
            });
        });

        // Finalizar procesamiento de cada usuario
        categoriesMap.forEach(category => {
            category.users.forEach(user => {
                // Calcular en cuántos eventos participó
                user.eventCount = user.eventsParticipated.size;

                // Verificar si participó en todos los eventos
                user.completedAllEvents = user.eventCount === events.length;

                // Para los eventos en los que no participó, marcar como DNS
                events.forEach(event => {
                    if (!user.eventTimes[event.id]) {
                        user.eventTimes[event.id] = "DNS";
                        user.eventPositions[event.id] = "-";
                        user.eventPoints[event.id] = 0;
                    }
                });

                // Calcular tiempo total si no es DNF
                if (!user.isDNF) {
                    user.totalTime = msToTimeFormat(user.totalTimeMs);
                } else {
                    user.totalTime = "DNF";
                }
            });
        });

        // Convertir a formato para renderizado y ordenar
        const result = [];

        categoriesMap.forEach(category => {
            // Convertir usuarios de mapa a array
            const usersArray = Array.from(category.users.values());

            // Ordenar por total de puntos (mayor a menor)
            usersArray.sort((a, b) => {
                // Ordenar por puntos totales (descendente)
                return b.totalPoints - a.totalPoints;
            });

            // Solo agregar categoría si tiene usuarios
            if (usersArray.length > 0) {
                result.push({
                    ...category,
                    users: usersArray
                });
            }
        });

        return result;
    }, [categories, events, ownerData, totalTimes, convertToMs, categoryNameToDataMap, getPointsForPosition]);

    // Función para mostrar puntos
    const renderPoints = (points) => {
        if (points === 0) return "-";
        return points % 1 === 0 ? points.toFixed(0) : points.toFixed(1);
    };

    // Renderizar tabla para una categoría
    const renderCategoryTable = (category) => {
        return (
            <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: "0 2px 8px rgba(0,0,0,0.1)" }}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                            <TableCell sx={{ fontWeight: "bold", width: "50px" }}>Pos.</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>Corredor</TableCell>
                            {events.map(event => (
                                <TableCell key={event.id} align="center" sx={{ fontWeight: "bold" }}>
                                    {event.eventName}
                                </TableCell>
                            ))}
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                Total Puntos
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {category.users.map((user, index) => {
                            const position = index + 1;
                            const isMedalPosition = position <= 3;

                            // Colores para el podio
                            const medalColor = isMedalPosition
                                ? position === 1
                                    ? "linear-gradient(45deg, #FFD700, #FFA500)"
                                    : position === 2
                                        ? "linear-gradient(45deg, #C0C0C0, #A9A9A9)"
                                        : "linear-gradient(45deg, #CD7F32, #8B4513)"
                                : null;

                            return (
                                <TableRow key={`${category.name}-${user.name}`}
                                    sx={{
                                        '&:nth-of-type(odd)': { bgcolor: 'rgba(0, 0, 0, 0.02)' },
                                        ...(isMedalPosition ? {
                                            position: 'relative',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                bottom: 0,
                                                width: '4px',
                                                background: medalColor
                                            }
                                        } : {})
                                    }}
                                >
                                    <TableCell>
                                        {isMedalPosition ? (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: medalColor,
                                                borderRadius: '50%',
                                                width: 26,
                                                height: 26,
                                                color: '#000',
                                                fontWeight: 'bold'
                                            }}>
                                                {position}
                                            </Box>
                                        ) : (
                                            <Typography variant="body2">
                                                {position}
                                            </Typography>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Avatar src={user.photoUrl} sx={{ width: 30, height: 30, mr: 1 }} />
                                            <Box>
                                                <Typography variant="body2" fontWeight={500}>
                                                    {user.name}
                                                </Typography>
                                                {!user.completedAllEvents && (
                                                    <Typography variant="caption" color="text.secondary">
                                                        {user.eventCount}/{events.length} eventos
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    </TableCell>

                                    {/* Columnas para cada evento */}
                                    {events.map(event => {
                                        const time = user.eventTimes[event.id];
                                        const position = user.eventPositions[event.id];
                                        const points = user.eventPoints[event.id] || 0;

                                        return (
                                            <TableCell key={`${user.name}-${event.id}`} align="center">
                                                {time ? (
                                                    time === "DNF" ? (
                                                        <Tooltip title={`DNF - ${renderPoints(points)} pts (Pos. ${position})`}>
                                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Typography variant="caption" color="error">
                                                                    DNF
                                                                </Typography>
                                                                <Typography variant="caption" fontWeight="bold">
                                                                    {renderPoints(points)} pts
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    ) : time === "DNS" ? (
                                                        <Tooltip title="No participó en este evento">
                                                            <Typography variant="caption" color="text.disabled">
                                                                DNS
                                                            </Typography>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={`${time} - Posición ${position}`}>
                                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <Typography variant="caption">
                                                                    {time}
                                                                </Typography>
                                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                    <Typography variant="caption" fontWeight="bold" sx={{ mr: 0.5 }}>
                                                                        {renderPoints(points)} pts
                                                                    </Typography>
                                                                    {position <= 3 && (
                                                                        <Box
                                                                            component="span"
                                                                            sx={{
                                                                                width: 8,
                                                                                height: 8,
                                                                                borderRadius: '50%',
                                                                                display: 'inline-block',
                                                                                background: position === 1
                                                                                    ? "gold"
                                                                                    : position === 2
                                                                                        ? "silver"
                                                                                        : "#CD7F32"
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Tooltip>
                                                    )
                                                ) : (
                                                    <Tooltip title="No participó en este evento">
                                                        <Typography variant="caption" color="text.disabled">
                                                            DNS
                                                        </Typography>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        );
                                    })}

                                    {/* Total Puntos */}
                                    <TableCell align="center">
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Typography
                                                variant="body2"
                                                fontWeight={600}
                                                sx={{
                                                    ...(isMedalPosition ? {
                                                        color: position === 1 ? '#FFD700' : position === 2 ? '#C0C0C0' : '#CD7F32'
                                                    } : {})
                                                }}
                                            >
                                                {user.totalPoints.toFixed(1)} pts
                                            </Typography>
                                            {position === 1 && (
                                                <EmojiEventsIcon sx={{ ml: 0.5, color: '#FFD700', fontSize: 18 }} />
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    if (categoriesData.length === 0) {
        return (
            <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="subtitle1">
                    No hay datos disponibles para mostrar.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" fontWeight={600}>
                    Campeonato por Categoría
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                    Sistema de puntos por posición en cada evento. El corredor con más puntos es el ganador de la categoría.
                </Typography>

                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" fontWeight={600} gutterBottom>
                        Puntos por posición:
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        <Chip label="1° - 5 pts" size="small" sx={{ bgcolor: "gold", color: "black" }} />
                        <Chip label="2° - 4.5 pts" size="small" sx={{ bgcolor: "silver", color: "black" }} />
                        <Chip label="3° - 4 pts" size="small" sx={{ bgcolor: "#CD7F32", color: "white" }} />
                        <Chip label="4° - 3.5 pts" size="small" />
                        <Chip label="5° - 3 pts" size="small" />
                        <Chip label="6° - 2.5 pts" size="small" />
                        <Chip label="7° - 2 pts" size="small" />
                        <Chip label="8° - 1.5 pts" size="small" />
                        <Chip label="9° - 0.5 pts" size="small" />
                        <Chip label="10° - 0.5 pts" size="small" />
                        <Chip label="DNF - Puntos según posición" size="small" color="error" />
                        <Chip label="DNS - 0 pts" size="small" sx={{ color: "text.disabled" }} />
                    </Box>
                </Box>
            </Box>

            {categoriesData.map(category => (
                <Accordion defaultExpanded key={category.name} sx={{ mb: 2 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ bgcolor: "rgba(0, 0, 0, 0.03)" }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CategoryIcon sx={{ mr: 1, color: "primary.main" }} />
                            <Typography variant="subtitle1" fontWeight={600}>
                                {category.name}
                                <Chip
                                    label={`${category.users.length} participantes`}
                                    size="small"
                                    sx={{ ml: 2, fontSize: "0.7rem", height: "20px" }}
                                />
                            </Typography>

                            {/* Mostrar ganador en el encabezado */}
                            {category.users.length > 0 && (
                                <Chip
                                    icon={<EmojiEventsIcon />}
                                    label={`Líder: ${category.users[0].name} (${category.users[0].totalPoints.toFixed(1)} pts)`}
                                    size="small"
                                    color="primary"
                                    sx={{ ml: 2, fontSize: "0.7rem", height: "20px" }}
                                />
                            )}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderCategoryTable(category)}

                        {/* Resumen de ganadores */}
                        {category.users.length > 0 && (
                            <Box sx={{ display: "flex", mt: 1, justifyContent: "space-between" }}>
                                <Box>
                                    <Typography variant="body2" fontWeight={600}>
                                        <EmojiEventsIcon sx={{ fontSize: 16, verticalAlign: 'middle', color: '#FFD700', mr: 0.5 }} />
                                        Top 3:
                                    </Typography>
                                    {category.users.slice(0, 3).map((user, idx) => (
                                        <Typography key={idx} variant="body2" component="span" sx={{ mr: 2 }}>
                                            {idx + 1}. {user.name} ({user.totalPoints.toFixed(1)} pts)
                                        </Typography>
                                    ))}
                                </Box>
                                <Typography variant="body2">
                                    Total eventos: {events.length} | Participantes: {category.users.length}
                                </Typography>
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default CategoryLeaderboardTable;