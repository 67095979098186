import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import {
  Settings,
  Search,
  Flag,
  MapPin,
  Filter,
  Clock,
  User,
  AlertCircle,
  ArrowRight,
  Calendar,
} from "lucide-react";

const MyReceivers = () => {
  const { user, loading: authLoading } = useUserAuth();
  const navigate = useNavigate();
  const [receivers, setReceivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parks, setParks] = useState({});
  const [trackNames, setTrackNames] = useState({});
  const [ownerDetails, setOwnerDetails] = useState({});
  const [eventDetails, setEventDetails] = useState({});
  const [searchUid, setSearchUid] = useState("");
  const [selectedPark, setSelectedPark] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const [expandedTrack, setExpandedTrack] = useState(null);

  const fetchParks = async () => {
    const parksQuery = await getDocs(collection(db, "Parks"));
    let parksData = {};
    parksQuery.forEach((doc) => {
      parksData[doc.id] = doc.data();
    });
    setParks(parksData);

    // Después de obtener los parques, obtener todos los tracks
    await fetchAllTracks(parksData);
  };

  const fetchAllTracks = async (parksData) => {
    let tracksData = {};

    for (const parkId of Object.keys(parksData)) {
      try {
        const tracksQuery = await getDocs(
          collection(db, "Parks", parkId, "Tracks")
        );

        tracksQuery.forEach((trackDoc) => {
          tracksData[trackDoc.id] = {
            ...trackDoc.data(),
            parkId: parkId,
          };
        });
      } catch (error) {
        console.error(`Error fetching tracks for park ${parkId}:`, error);
      }
    }

    setTrackNames(tracksData);
  };

  const fetchOwnerDetails = async (owners) => {
    let ownerDetailsTemp = {};
    for (const ownerUid of owners) {
      const userDoc = await getDoc(doc(db, "Users", ownerUid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        ownerDetailsTemp[ownerUid] = {
          name: data.name || "Nombre no disponible",
          photoUrl: data.photoUrl || "",
        };
      } else {
        ownerDetailsTemp[ownerUid] = {
          name: "Nombre no disponible",
          photoUrl: "",
        };
      }
    }
    return ownerDetailsTemp;
  };

  const fetchEventDetails = async (eventIds) => {
    const uniqueEventIds = [...new Set(eventIds.filter((id) => id))];
    let eventDetailsTemp = {};

    for (const eventId of uniqueEventIds) {
      try {
        console.log(`Buscando evento con ID: ${eventId}`);
        // Asegurarnos de que el ID es un string válido para Firestore
        const sanitizedEventId = String(eventId).trim();

        const eventDoc = await getDoc(doc(db, "events", sanitizedEventId));
        if (eventDoc.exists()) {
          const data = eventDoc.data();
          console.log(`Evento encontrado:`, data);
          eventDetailsTemp[eventId] = {
            eventName:
              data.EventName ||
              data.eventName ||
              "Nombre de evento no disponible",
            eventDate: data.EventDate || data.eventDate || null,
          };
        } else {
          console.log(`Documento de evento no encontrado para ID: ${eventId}`);

          // Intentar buscar el evento por consulta donde algún campo coincida con el ID
          const eventsQuery = await getDocs(collection(db, "events"));
          let foundEvent = false;

          eventsQuery.forEach((doc) => {
            const eventData = doc.data();
            // Revisar si algún campo coincide con el ID que estamos buscando
            if (
              doc.id === eventId ||
              (eventData.id && eventData.id === eventId) ||
              (eventData.EventID && eventData.EventID === eventId)
            ) {
              eventDetailsTemp[eventId] = {
                eventName:
                  eventData.EventName ||
                  eventData.eventName ||
                  "Nombre recuperado",
                eventDate: eventData.EventDate || eventData.eventDate || null,
              };
              foundEvent = true;
              console.log(
                `Evento encontrado por consulta alternativa:`,
                eventData
              );
            }
          });

          if (!foundEvent) {
            eventDetailsTemp[eventId] = {
              eventName: `Evento: ${eventId.substring(0, 8)}...`,
              eventDate: null,
            };
          }
        }
      } catch (error) {
        console.error(`Error fetching event details for ${eventId}:`, error);
        eventDetailsTemp[eventId] = {
          eventName: `Evento ${eventId.substring(0, 8)}...`,
          eventDate: null,
        };
      }
    }

    return eventDetailsTemp;
  };

  useEffect(() => {
    const fetchReceivers = async () => {
      if (user) {
        try {
          const receiversQuery = await getDocs(collection(db, "Receivers"));
          const receiversData = receiversQuery.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter(
              (receiver) =>
                Array.isArray(receiver.Owners) &&
                receiver.Owners.includes(user.uid)
            );

          // Obtener detalles de los propietarios
          for (const receiver of receiversData) {
            if (Array.isArray(receiver.Owners) && receiver.Owners.length > 0) {
              const details = await fetchOwnerDetails(receiver.Owners);
              setOwnerDetails((prev) => ({ ...prev, ...details }));
            }
          }

          // Obtener detalles de los eventos
          const eventIds = receiversData
            .map((receiver) => receiver.EventID)
            .filter(Boolean);
          if (eventIds.length > 0) {
            const events = await fetchEventDetails(eventIds);
            setEventDetails(events);
          }

          setReceivers(receiversData);
        } catch (error) {
          console.error("Error fetching receivers:", error);
        } finally {
          setLoading(false);
        }

        fetchParks();
      }
    };

    fetchReceivers();
  }, [user]);

  useEffect(() => {
    if (!authLoading && !user) {
      navigate("/login");
    }
  }, [user, navigate, authLoading]);

  const handleSearch = (e) => {
    setSearchUid(e.target.value);
  };

  const handleParkFilter = (e) => {
    setSelectedPark(e.target.value);
    setSelectedTrack("");
  };

  const handleTrackFilter = (e) => {
    setSelectedTrack(e.target.value);
  };

  const toggleTrackExpansion = (trackId) => {
    setExpandedTrack(expandedTrack === trackId ? null : trackId);
  };

  const filteredReceivers = receivers.filter((receiver) => {
    return (
      (searchUid === "" ||
        receiver.id.toLowerCase().includes(searchUid.toLowerCase())) &&
      (selectedPark === "" || receiver.ParkID === selectedPark) &&
      (selectedTrack === "" || receiver.TrackID === selectedTrack)
    );
  });

  // Organizar receivers por TrackID para agrupar inicio y final
  const groupedByTrack = {};
  filteredReceivers.forEach((receiver) => {
    const trackId = receiver.TrackID || "sin-track";
    if (!groupedByTrack[trackId]) {
      groupedByTrack[trackId] = [];
    }
    groupedByTrack[trackId].push(receiver);
  });

  // Ordenar cada grupo para que el inicio aparezca antes que el final
  Object.keys(groupedByTrack).forEach((trackId) => {
    groupedByTrack[trackId].sort((a, b) => {
      // Type "0" es inicio, cualquier otro valor (normalmente "1") es final
      return a.Type === "0" ? -1 : 1;
    });
  });

  // Crear array ordenado de tracks para mostrarlos alfabéticamente
  const sortedTracks = Object.entries(groupedByTrack)
    .map(([trackId, receivers]) => ({
      trackId,
      receivers,
      trackName: trackNames[trackId]?.TrackName || trackId,
    }))
    .sort((a, b) => {
      // Primero ordenar por TrackName o trackId si no hay TrackName
      return a.trackName.localeCompare(b.trackName);
    });

  if (authLoading || loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="w-full max-w-6xl p-6">
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            <h2 className="text-xl font-semibold text-gray-700">
              Cargando información...
            </h2>
            <p className="text-gray-500">
              Obteniendo tus receivers y información de pistas
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8 max-w-6xl">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800 mb-2 flex items-center justify-center sm:justify-start gap-3">
            <span className="bg-blue-500 p-2 rounded-lg text-white">
              <MapPin size={24} />
            </span>
            Mis Receivers
          </h1>
          <p className="text-gray-600 text-center sm:text-left max-w-2xl">
            Gestiona tus dispositivos receptores asignados en diferentes pistas
            y parques
          </p>
        </div>

        {/* Search and Filters Section */}
        <div className="bg-white rounded-xl shadow-sm p-4 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="relative group">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search
                  size={18}
                  className="text-gray-400 group-focus-within:text-blue-500 transition-colors"
                />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-3 border border-gray-200 rounded-lg bg-gray-50 focus:bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                placeholder="Buscar por UID del receptor"
                value={searchUid}
                onChange={handleSearch}
              />
            </div>

            <div className="relative group">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Filter
                  size={18}
                  className="text-gray-400 group-focus-within:text-blue-500 transition-colors"
                />
              </div>
              <select
                className="block w-full pl-10 pr-8 py-3 border border-gray-200 rounded-lg bg-gray-50 focus:bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all appearance-none"
                value={selectedPark}
                onChange={handleParkFilter}
              >
                <option value="">Todos los Parques</option>
                {Object.keys(parks).map((parkId) => (
                  <option key={parkId} value={parkId}>
                    {parks[parkId].ParkName}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <ArrowRight size={16} className="text-gray-400 rotate-90" />
              </div>
            </div>

            {selectedPark && (
              <div className="relative group">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin
                    size={18}
                    className="text-gray-400 group-focus-within:text-blue-500 transition-colors"
                  />
                </div>
                <select
                  className="block w-full pl-10 pr-8 py-3 border border-gray-200 rounded-lg bg-gray-50 focus:bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all appearance-none"
                  value={selectedTrack}
                  onChange={handleTrackFilter}
                >
                  <option value="">Todas las Pistas</option>
                  {Object.keys(trackNames)
                    .filter(
                      (trackId) => trackNames[trackId].parkId === selectedPark
                    )
                    .sort((a, b) => {
                      const nameA = trackNames[a].TrackName || a;
                      const nameB = trackNames[b].TrackName || b;
                      return nameA.localeCompare(nameB);
                    })
                    .map((trackId) => (
                      <option key={trackId} value={trackId}>
                        {trackNames[trackId].TrackName || trackId}
                      </option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <ArrowRight size={16} className="text-gray-400 rotate-90" />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Stats Summary */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Total Receivers
                </p>
                <h3 className="text-2xl font-bold mt-1">{receivers.length}</h3>
              </div>
              <div className="bg-blue-100 p-3 rounded-full">
                <MapPin size={20} className="text-blue-600" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Pistas Activas
                </p>
                <h3 className="text-2xl font-bold mt-1">
                  {
                    new Set(receivers.map((r) => r.TrackID).filter((id) => id))
                      .size
                  }
                </h3>
              </div>
              <div className="bg-green-100 p-3 rounded-full">
                <Flag size={20} className="text-green-600" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Puntos de Inicio
                </p>
                <h3 className="text-2xl font-bold mt-1">
                  {receivers.filter((r) => r.Type === "0").length}
                </h3>
              </div>
              <div className="bg-green-100 p-3 rounded-full">
                <Clock size={20} className="text-green-600" />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl p-4 shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
            <div className="flex items-start justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Puntos de Llegada
                </p>
                <h3 className="text-2xl font-bold mt-1">
                  {receivers.filter((r) => r.Type === "1").length}
                </h3>
              </div>
              <div className="bg-red-100 p-3 rounded-full">
                <MapPin size={20} className="text-red-600" />
              </div>
            </div>
          </div>
        </div>

        {/* Tracks list with grouped receivers */}
        <div className="space-y-6">
          {sortedTracks.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-16 bg-white rounded-xl shadow-sm">
              <AlertCircle size={48} className="text-gray-400 mb-4" />
              <p className="text-gray-500 text-lg font-medium mb-2">
                No se encontraron receivers
              </p>
              <p className="text-gray-400 max-w-md text-center">
                No hay dispositivos que coincidan con los criterios de búsqueda
                actuales.
              </p>
            </div>
          ) : (
            sortedTracks.map(({ trackId, receivers, trackName }) => {
              const trackParkId = trackNames[trackId]?.parkId;
              const parkName = trackParkId
                ? parks[trackParkId]?.ParkName
                : "Parque desconocido";
              const isExpanded =
                expandedTrack === trackId || expandedTrack === null;

              return (
                <div
                  key={trackId}
                  className="border border-gray-200 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 bg-white"
                >
                  <div
                    className="bg-gradient-to-r from-blue-50 to-blue-100 px-5 py-4 border-b border-gray-200 cursor-pointer"
                    onClick={() => toggleTrackExpansion(trackId)}
                  >
                    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
                      <div>
                        <h3 className="text-xl font-bold text-gray-800 flex items-center gap-2">
                          <span className="text-blue-600 flex-shrink-0">#</span>
                          {trackId !== "sin-track"
                            ? trackName
                            : "Sin asignación de pista"}
                          <span
                            className={`ml-2 transform transition-transform ${
                              isExpanded ? "rotate-180" : "rotate-0"
                            }`}
                          >
                            <ArrowRight
                              size={16}
                              className="rotate-90 text-gray-500"
                            />
                          </span>
                        </h3>
                        {trackParkId && (
                          <div className="mt-1 text-base text-gray-700 font-medium flex items-center">
                            <MapPin size={16} className="mr-1 text-blue-500" />
                            {parkName}
                          </div>
                        )}
                      </div>
                      <div className="text-sm mt-1 sm:mt-0 flex items-center gap-2">
                        <span className="inline-flex items-center px-3 py-1 rounded-full bg-blue-100 text-blue-700 font-medium border border-blue-200">
                          {trackId !== "sin-track"
                            ? `Track ID: ${trackId.substring(0, 8)}...`
                            : "Sin ID"}
                        </span>
                        <span className="inline-flex items-center px-3 py-1 rounded-full bg-purple-100 text-purple-700 font-medium border border-purple-200">
                          {receivers.length}{" "}
                          {receivers.length === 1 ? "receiver" : "receivers"}
                        </span>
                      </div>
                    </div>
                  </div>

                  {isExpanded && (
                    <div className="bg-white divide-y divide-gray-100">
                      {receivers.map((receiver, index) => {
                        const isStartTime = receiver.Type === "0";
                        const bgColor = isStartTime
                          ? "bg-green-50"
                          : "bg-red-50";
                        const borderColor = isStartTime
                          ? "border-green-100"
                          : "border-red-100";
                        const typeLabel = isStartTime
                          ? "Punto de Inicio"
                          : "Punto de Llegada";
                        const typeIcon = isStartTime ? (
                          <Flag size={24} className="text-green-600" />
                        ) : (
                          <MapPin size={24} className="text-red-600" />
                        );

                        // Información del evento asociado si existe
                        const eventInfo = receiver.EventID
                          ? eventDetails[receiver.EventID]
                          : null;

                        return (
                          <div
                            key={receiver.id}
                            className={`${bgColor} transition-all duration-200 hover:bg-opacity-80`}
                          >
                            <div className="p-5">
                              <div className="flex flex-col sm:flex-row gap-5">
                                <div
                                  className={`p-3 rounded-full ${
                                    isStartTime ? "bg-green-100" : "bg-red-100"
                                  } flex items-center justify-center self-start flex-shrink-0`}
                                >
                                  {typeIcon}
                                </div>

                                <div className="flex-1 min-w-0">
                                  <div className="flex flex-wrap items-center gap-3 mb-3">
                                    <h2
                                      className={`text-lg font-bold ${
                                        isStartTime
                                          ? "text-green-700"
                                          : "text-red-700"
                                      }`}
                                    >
                                      {typeLabel}
                                    </h2>
                                    <span className="text-sm px-3 py-1 rounded-full bg-white text-gray-600 font-medium border border-gray-200 shadow-sm hover:shadow-md transition-shadow">
                                      UID: {receiver.id}
                                    </span>

                                    {receiver.EventID && (
                                      <span className="text-sm px-3 py-1 rounded-full bg-indigo-50 text-indigo-600 font-medium border border-indigo-100 shadow-sm flex items-center gap-1">
                                        <Calendar
                                          size={14}
                                          className="text-indigo-500"
                                        />
                                        {eventInfo?.eventName ||
                                          `Evento ${receiver.EventID.substring(
                                            0,
                                            6
                                          )}...`}
                                      </span>
                                    )}
                                  </div>

                                  <div className="mt-4">
                                    <p className="text-sm font-semibold text-gray-700 mb-2 flex items-center gap-1">
                                      <User
                                        size={16}
                                        className="text-gray-500"
                                      />
                                      Propietarios:
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                      {receiver.Owners?.map((ownerUid) => (
                                        <div
                                          key={ownerUid}
                                          className="flex items-center gap-2 bg-white rounded-full pl-1 pr-3 py-1 shadow-sm border border-gray-100 hover:shadow-md transition-shadow"
                                        >
                                          {ownerDetails[ownerUid]?.photoUrl ? (
                                            <img
                                              src={
                                                ownerDetails[ownerUid]?.photoUrl
                                              }
                                              alt={ownerDetails[ownerUid]?.name}
                                              className="w-7 h-7 rounded-full object-cover"
                                            />
                                          ) : (
                                            <div className="w-7 h-7 rounded-full bg-gray-200 flex items-center justify-center text-xs text-gray-500">
                                              <User size={14} />
                                            </div>
                                          )}
                                          <span className="text-sm font-medium text-gray-700 truncate max-w-[120px] sm:max-w-[160px]">
                                            {ownerDetails[ownerUid]?.name ||
                                              "Cargando..."}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>

                                <div className="self-end sm:self-center mt-4 sm:mt-0">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/receiver/${receiver.id}`);
                                    }}
                                    className="rounded-lg px-4 py-2 bg-white shadow hover:shadow-md transition-all border border-gray-200 hover:bg-gray-50 hover:text-blue-600 flex items-center gap-2 group"
                                    title="Configurar receiver"
                                  >
                                    <Settings
                                      size={20}
                                      className="text-gray-700 group-hover:text-blue-600 transition-colors"
                                    />
                                    <span className="hidden sm:inline text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors">
                                      Configurar
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>

        {/* Pagination - if needed in the future */}
        <div className="mt-8 flex justify-center">
          {/* Pagination would go here */}
        </div>
      </div>
    </div>
  );
};

export default MyReceivers;
