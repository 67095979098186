import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  getDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { toast } from "react-hot-toast";
import {
  X,
  Bike,
  Ticket,
  CheckCircle,
  Hourglass,
  XCircle,
  CreditCard,
  CalendarDays,
  Tag,
  ShoppingBag,
  Clock,
} from "lucide-react";

const MyTickets = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [openQR, setOpenQR] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "orders"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);

        // Crear un array para almacenar todos los tickets con datos completos
        const ticketsPromises = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const docStatus = data.status;
          let parsedStatus =
            docStatus === "approved"
              ? "Pagado"
              : docStatus === "in_process"
              ? "En Proceso"
              : "No Pagado";

          // Get payment method text
          let paymentMethodText = "No especificado";
          if (data.paymentMethod === 0) {
            paymentMethodText = "Tarjeta de crédito/débito";
          } else if (data.paymentMethod === 1) {
            paymentMethodText = "SpeedCoins";
          } else if (data.paymentMethod === 2) {
            paymentMethodText = "Efectivo";
          } else if (data.paymentMethod === 3) {
            paymentMethodText = "MercadoPago";
          }

          // Handle Firestore timestamp properly
          let createdDate;
          try {
            if (data.createdAt) {
              // Check if it's a Firestore timestamp
              if (data.createdAt.seconds) {
                createdDate = new Date(data.createdAt.seconds * 1000);
              }
              // Check if it's already a Date object or timestamp
              else if (
                data.createdAt instanceof Date ||
                !isNaN(new Date(data.createdAt).getTime())
              ) {
                createdDate = new Date(data.createdAt);
              } else {
                createdDate = null;
              }
            } else {
              createdDate = null;
            }
          } catch (e) {
            console.error("Error parsing date:", e);
            createdDate = null;
          }

          // Obtener información del evento si tenemos itemId (para eventos)
          let eventName = data.categoryName || "Evento";
          let userName = "";

          try {
            // Si es un evento, intentar obtener más detalles
            if (data.type === "event" && data.itemId) {
              // Intentar obtener el nombre del evento usando itemId
              console.log(`Buscando evento con ID: ${data.itemId}`);
              const eventDoc = await getDoc(doc(db, "Events", data.itemId));
              if (eventDoc.exists()) {
                console.log(
                  `Evento encontrado: ${JSON.stringify(eventDoc.data())}`
                );
                eventName =
                  eventDoc.data().eventName ||
                  eventDoc.data().name ||
                  data.categoryName ||
                  "Evento";
              } else {
                console.log(`No se encontró evento con ID: ${data.itemId}`);
              }
            }

            // Intentar obtener nombre del usuario
            if (data.userId) {
              const userDoc = await getDoc(doc(db, "Users", data.userId));
              if (userDoc.exists()) {
                userName = userDoc.data().name || "Usuario";
              }
            }
          } catch (error) {
            console.error("Error obteniendo datos adicionales:", error);
          }

          return {
            id: doc.id,
            status: parsedStatus,
            total: data.total,
            categoryId: data.categoryId,
            categoryName: data.categoryName || "Categoría",
            categoryPrice: data.categoryPrice || 0,
            addons: data.addons || [],
            userId: data.userId,
            userName: userName,
            type: data.type,
            itemId: data.itemId,
            eventName: eventName,
            createdAt: createdDate,
            paymentMethod: paymentMethodText,
            customPrice: data.customPrice || false,
          };
        });

        // Esperar a que todas las promesas se resuelvan
        const ticketsData = await Promise.all(ticketsPromises);
        setTickets(ticketsData);
      } catch (error) {
        console.error("Error al obtener los tickets:", error);
        setError("Error al obtener los tickets. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchTickets();
    }
  }, [user]);

  const handlePayEvent = (id) => {
    navigate(`/checkout/${id}`);
  };

  const handleQRClick = (ticket) => {
    setSelectedTicket(ticket);
    setOpenQR(true);
  };

  const handleCloseQR = () => {
    setOpenQR(false);
    setSelectedTicket(null);
  };

  const handleDeleteTicket = async () => {
    try {
      await deleteDoc(doc(db, "orders", ticketToDelete.id));
      setTickets(tickets.filter((ticket) => ticket.id !== ticketToDelete.id));
      toast.success("Ticket eliminado correctamente.");
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error("Error al eliminar el ticket:", error);
      toast.error("No se pudo eliminar el ticket. Intente nuevamente.");
    }
  };

  const formatDate = (date) => {
    if (!date) return "Fecha no disponible";

    try {
      // Check if the date is a valid Date object
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        return "Fecha inválida";
      }

      return date.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Error de formato";
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-black border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-lg text-red-600">{error}</div>;
  }

  if (tickets.length === 0) {
    return (
      <div className="text-center p-4 text-lg text-black">
        No tienes tickets
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Mis Entradas</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="bg-white rounded-lg shadow-lg p-6 flex flex-col"
          >
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <Bike className="w-6 h-6 text-gray-700" />
                <span className="font-medium text-gray-900">
                  {ticket.type === "event" ? "Evento" : "Producto"}
                </span>
              </div>
              <Ticket className="w-6 h-6 text-gray-700" />
            </div>

            <h2 className="text-xl font-semibold mb-1">{ticket.eventName}</h2>
            <p className="text-sm text-gray-500 mb-1">
              Categoría: {ticket.categoryName}
            </p>
            <p className="text-sm text-gray-500 mb-4">
              ID: {ticket.id.substring(0, 8)}...
            </p>

            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <Tag className="w-4 h-4 text-gray-600" />
                <span className="text-gray-600">Participante:</span>
              </div>
              <span className="font-medium">
                {ticket.userName || "Usuario"}
              </span>
            </div>

            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <Tag className="w-4 h-4 text-gray-600" />
                <span className="text-gray-600">Precio categoría:</span>
              </div>
              <span className="font-medium">${ticket.categoryPrice}</span>
            </div>

            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <ShoppingBag className="w-4 h-4 text-gray-600" />
                <span className="text-gray-600">Total:</span>
              </div>
              <span className="font-bold text-lg">${ticket.total}</span>
            </div>

            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center gap-2">
                <CreditCard className="w-4 h-4 text-gray-600" />
                <span className="text-gray-600">Método de pago:</span>
              </div>
              <span className="text-sm">{ticket.paymentMethod}</span>
            </div>

            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-600" />
                <span className="text-gray-600">Fecha:</span>
              </div>
              <span className="text-sm">{formatDate(ticket.createdAt)}</span>
            </div>

            <div
              className={`p-2 rounded-lg flex items-center gap-2 font-bold text-sm mb-4 ${
                ticket.status === "Pagado"
                  ? "bg-green-100 text-green-700"
                  : ticket.status === "En Proceso"
                  ? "bg-yellow-100 text-yellow-700"
                  : "bg-red-100 text-red-700"
              }`}
            >
              {ticket.status === "Pagado" ? (
                <CheckCircle className="w-4 h-4" />
              ) : ticket.status === "En Proceso" ? (
                <Hourglass className="w-4 h-4" />
              ) : (
                <XCircle className="w-4 h-4" />
              )}
              {ticket.status}
            </div>

            {ticket.addons && ticket.addons.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold text-gray-700 mb-2">
                  Artículos adicionales:
                </h3>
                <div className="bg-gray-50 rounded-lg p-3">
                  {ticket.addons.map((addon, index) => (
                    <div
                      key={index}
                      className={`${
                        index > 0 ? "border-t border-gray-200 pt-2 mt-2" : ""
                      }`}
                    >
                      <div className="flex justify-between">
                        <p className="font-medium">{addon.name}</p>
                        {addon.price > 0 && (
                          <p className="font-medium">${addon.price}</p>
                        )}
                      </div>
                      {addon.description && (
                        <p className="text-xs text-gray-500">
                          {addon.description}
                        </p>
                      )}

                      {/* Handle selected variants if present */}
                      {addon.selectedVariant && (
                        <div className="mt-1 bg-white p-2 rounded text-sm">
                          <div className="flex justify-between">
                            <span>{addon.selectedVariant.name}:</span>
                            <span className="font-medium">
                              {addon.selectedVariant.option}
                            </span>
                          </div>
                          {addon.selectedVariant.price > 0 && (
                            <div className="flex justify-between">
                              <span>Precio:</span>
                              <span>${addon.selectedVariant.price}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {ticket.status === "Pagado" ? (
              <button
                onClick={() => handleQRClick(ticket)}
                className="mt-auto bg-black text-white py-3 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-gray-800 transition-colors"
              >
                <QRCode
                  value={`Ticket ID: ${ticket.id}`}
                  size={16}
                  renderAs="svg"
                  className="w-4 h-4"
                />
                Ver QR
              </button>
            ) : (
              <div className="mt-auto grid grid-cols-2 gap-2">
                <button
                  onClick={() => handlePayEvent(ticket.id)}
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700 transition-colors"
                >
                  <CreditCard className="w-4 h-4" /> Pagar
                </button>
                <button
                  onClick={() => {
                    setTicketToDelete(ticket);
                    setShowDeleteConfirmation(true);
                  }}
                  className="bg-red-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2 hover:bg-red-700 transition-colors"
                >
                  <X className="w-4 h-4" /> Eliminar
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* QR Code Dialog */}
      {openQR && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Código QR de entrada</h3>
              <button
                onClick={handleCloseQR}
                className="text-gray-500 hover:text-gray-800"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            {selectedTicket && (
              <div className="text-center">
                <div className="bg-white p-4 rounded-lg inline-block mb-4">
                  <QRCode
                    value={`Ticket ID: ${selectedTicket.id}`}
                    size={256}
                    level="H"
                    includeMargin={true}
                    renderAs="svg"
                  />
                </div>

                <div className="text-left bg-gray-50 p-4 rounded-lg mb-4">
                  <p className="mb-2">
                    <span className="font-medium">Evento:</span>{" "}
                    {selectedTicket.eventName}
                  </p>
                  <p className="mb-2">
                    <span className="font-medium">Categoría:</span>{" "}
                    {selectedTicket.categoryName}
                  </p>
                  <p className="mb-2">
                    <span className="font-medium">Participante:</span>{" "}
                    {selectedTicket.userName || "Usuario"}
                  </p>
                  <p className="mb-2">
                    <span className="font-medium">ID Ticket:</span>{" "}
                    {selectedTicket.id}
                  </p>
                  <p className="mb-2">
                    <span className="font-medium">Estado:</span>{" "}
                    {selectedTicket.status}
                  </p>
                  <p className="mb-2">
                    <span className="font-medium">Método de pago:</span>{" "}
                    {selectedTicket.paymentMethod}
                  </p>
                  <p>
                    <span className="font-medium">Total:</span> $
                    {selectedTicket.total}
                  </p>
                </div>

                <p className="text-sm text-gray-500">
                  Muestra este código QR en la entrada del evento para validar
                  tu ticket.
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">
              Confirmación de eliminación
            </h2>
            <p className="text-gray-700 mb-6">
              ¿Estás seguro que deseas eliminar este ticket? Esta acción no se
              puede deshacer.
            </p>
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-400 transition-colors"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteTicket}
                className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition-colors"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTickets;
