import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Calendar,
  Tag,
  Nfc,
  TreeDeciduous,
  Users,
  Camera,
  Map,
  Upload,
  Bike,
  Router,
  ChevronLeft,
  ChevronRight,
  Notebook,
  Gift,
  Users2,
  Flag,
  LineChart,
  Settings,
  CircleDollarSign,
  Layout,
  Video,
  TagsIcon,
  Eye,
  Plus,
  Filter,
  SquareStack,
} from "lucide-react";

export const routes = [
  {
    category: "Eventos",
    icon: <Calendar className="text-blue-500" />,
    links: [
      {
        label: "Terminar evento",
        path: "event-list",
        icon: <Calendar />,
      },
      { label: "Reviews", path: "/reviews", icon: <Router /> },
      {
        label: "Participants",
        path: "event-list-participants",
        icon: <Users2 />,
      },
      {
        label: "Data Filters",
        path: "event-data-filterer",
        icon: <Filter />,
      },
      {
        label: "Add Manual User",
        path: "addmanualuser",
        icon: <Filter />,
      },
      {
        label: "Cambiar categoria",
        path: "changecategory",
        icon: <Filter />,
      },
      {
        label: "Empezar Categoria XC",
        path: "category-start",
        icon: <Flag />,
      },
    ],
  },
  {
    category: "Finanzas",
    icon: <CircleDollarSign className="text-green-500" />,
    links: [
      {
        label: "Órdenes",
        path: "ordercontrol",
        icon: <Notebook />,
      },
      {
        label: "Estadísticas",
        path: "orderstats",
        icon: <LineChart />,
      },
    ],
  },
  {
    category: "Live Stream",
    icon: <Video className="text-red-500" />,
    links: [
      {
        label: "Rider Select",
        path: "liveriderselect",
        icon: <Camera />,
      },
    ],
  },
  {
    category: "GiftCards",
    icon: <Gift className="text-purple-500" />,
    links: [{ label: "GiftCard Control", path: "speedgift", icon: <Gift /> }],
  },
  {
    category: "Administración",
    icon: <Settings className="text-gray-500" />,
    links: [
      { label: "LoRa CSV Reader", path: "signaltest", icon: <Router /> },
      { label: "Receiver Manager", path: "/ReceiverManager", icon: <Router /> },
      {
        label: "CSV Uploader",
        path: "csv-uploader",
        icon: <Upload />,
      },
      {
        label: "Final Times Uploader",
        path: "final-times-uploader",
        icon: <Flag />,
      },
      {
        label: "Multi laps times uploader",
        path: "multi-laps-uploader",
        icon: <SquareStack />,
      },
      { label: "Usuarios", path: "/UsersControl", icon: <Users /> },
    ],
  },
  {
    category: "NFC y Tags",
    icon: <TagsIcon className="text-yellow-500" />,
    links: [
      { label: "Tags Control", path: "tagcontrol", icon: <Tag /> },
      { label: "Mis Tags", path: "/MyTags", icon: <Tag /> },
      { label: "Start NFC", path: "/StartNFC", icon: <Nfc /> },
      { label: "Finish NFC", path: "/finishNFC", icon: <Nfc /> },
      { label: "Riders Tags", path: "/riderstags", icon: <Tag /> },
    ],
  },
  {
    category: "Monitoreo",
    icon: <Eye className="text-indigo-500" />,
    links: [
      { label: "RFID Scanner", path: "rfidscanner", icon: <Map /> },
      { label: "MapCam", path: "/MapCam", icon: <Map /> },
      { label: "NfcCam", path: "/NfcCam", icon: <Camera /> },
      { label: "FinishCam", path: "/FinishCam", icon: <Camera /> },
      { label: "Active Riders", path: "/activeriders", icon: <Bike /> },
      { label: "OCR Receiver", path: "ocrreceiver", icon: <Bike /> },
    ],
  },
  {
    category: "Otros",
    icon: <Plus className="text-gray-400" />,
    links: [
      { label: "CreatePark", path: "/CreatePark", icon: <TreeDeciduous /> },
      { label: "Manual Start", path: "/manualstart", icon: <Calendar /> },
      { label: "Manual Live Rider", path: "/ManualLiveRider", icon: <Bike /> },
      { label: "uploadTags", path: "/uploadTags", icon: <Upload /> },
    ],
  },
];

const ControlLinks = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Manejar eventos de redimensionamiento
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      // Si cambia a móvil, siempre mostrar expandido
      if (window.innerWidth < 768) {
        setIsExpanded(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleCategory = (categoryIndex) => {
    setActiveCategory(activeCategory === categoryIndex ? null : categoryIndex);
  };

  return (
    <div
      className={`bg-gray-900 text-white transition-all duration-300 flex flex-col ${
        isMobile ? "w-full" : isExpanded ? "w-64" : "w-20"
      } ${isMobile ? "overflow-y-auto" : "h-screen"}`}
    >
      {/* Header y botón de expandir/contraer */}
      <div className="p-4 border-b border-gray-700 flex items-center justify-between">
        {isExpanded && <div className="font-bold text-xl">SpeedHub</div>}
        {!isMobile && (
          <button
            onClick={toggleSidebar}
            className={`p-1 rounded-full hover:bg-gray-700 transition-colors ${
              isExpanded ? "ml-auto" : "mx-auto"
            }`}
          >
            {isExpanded ? (
              <ChevronLeft className="h-5 w-5" />
            ) : (
              <ChevronRight className="h-5 w-5" />
            )}
          </button>
        )}
      </div>

      {/* Contenido del Sidebar */}
      <div className="overflow-y-auto flex-grow">
        {isMobile ? (
          // Vista Móvil - Mostrar como secciones colapsables
          <div className="flex flex-col w-full">
            {routes.map((section, sectionIndex) => (
              <div key={sectionIndex} className="border-b border-gray-700">
                <button
                  className="flex items-center w-full p-4 hover:bg-gray-800 transition-colors"
                  onClick={() => toggleCategory(sectionIndex)}
                >
                  <span className="mr-3">{section.icon}</span>
                  <span className="font-medium">{section.category}</span>
                  <ChevronRight
                    className={`ml-auto h-4 w-4 transition-transform ${
                      activeCategory === sectionIndex ? "rotate-90" : ""
                    }`}
                  />
                </button>

                {activeCategory === sectionIndex && (
                  <div className="pl-6 pr-4 pb-2">
                    {section.links.map((route, index) => (
                      <Link
                        key={index}
                        to={route.path}
                        className="flex items-center py-3 px-2 rounded text-gray-300 hover:text-white hover:bg-gray-800 transition-colors"
                      >
                        <span className="mr-3 text-gray-400">{route.icon}</span>
                        <span>{route.label}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          // Vista Desktop
          <div className="py-4">
            {routes.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-6">
                {isExpanded && (
                  <div className="px-4 mb-2 flex items-center">
                    <span className="mr-2">{section.icon}</span>
                    <h3 className="uppercase text-xs font-semibold text-gray-400">
                      {section.category}
                    </h3>
                  </div>
                )}
                <div>
                  {section.links.map((route, index) => (
                    <Link
                      key={index}
                      to={route.path}
                      className={`
                        flex items-center py-3 px-4 text-gray-300 hover:bg-gray-800 transition-colors
                        ${!isExpanded ? "justify-center" : ""}
                        ${index === 0 ? "mt-1" : ""}
                        hover:text-white
                      `}
                    >
                      <span
                        className={`text-gray-400 ${isExpanded ? "mr-3" : ""}`}
                      >
                        {route.icon}
                      </span>
                      {isExpanded && (
                        <span className="truncate">{route.label}</span>
                      )}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Footer - Información o acciones adicionales */}
      <div className="mt-auto p-4 border-t border-gray-700 flex items-center">
        {isExpanded ? (
          <div className="text-sm text-gray-400">
            <div>SpeedHub Dashboard</div>
            <div className="text-xs">v2.0</div>
          </div>
        ) : (
          <Layout className="h-5 w-5 mx-auto text-gray-400" />
        )}
      </div>
    </div>
  );
};

export default ControlLinks;
