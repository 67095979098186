import React, { useState, useEffect, useRef } from 'react';
import { Database, Download, Trash, Save, Clock, Activity } from 'lucide-react';

const RFIDLogger = ({
    serialPort,
    connected,
    addDebugLog = () => { },
    selectedEvent = null
}) => {
    // Estado para almacenar todos los registros
    const [logEntries, setLogEntries] = useState([]);
    const [isLogging, setIsLogging] = useState(true);
    const [filterText, setFilterText] = useState('');
    const [showRawData, setShowRawData] = useState(false);

    // Referencias para la comunicación serial
    const textDecoder = useRef(new TextDecoder());
    const lineBuffer = useRef('');

    // Referencia para auto-scroll del contenedor de logs
    const logContainerRef = useRef(null);

    // Función para procesar los datos que vienen del puerto serial
    const processData = (data) => {
        if (!isLogging) return;

        // Convertir a texto
        const text = textDecoder.current.decode(data);
        lineBuffer.current += text;

        // Buscar líneas completas
        const lines = lineBuffer.current.split('\n');

        // Si no hay línea completa, esperar más datos
        if (lines.length === 1) return;

        // Guardar el resto incompleto
        lineBuffer.current = lines.pop() || '';

        // Procesar líneas completas
        for (const line of lines) {
            const trimmedLine = line.trim();
            if (trimmedLine) {
                // Crear un objeto de entrada de log con timestamp
                const entry = {
                    id: Date.now() + Math.random().toString(36).substr(2, 9),
                    rawData: trimmedLine,
                    timestamp: new Date(),
                    formattedTime: new Date().toLocaleTimeString(),
                    processed: false,
                    jsonData: null
                };

                // Intentar procesar como JSON si comienza con { y termina con }
                if (trimmedLine.startsWith('{') && trimmedLine.endsWith('}')) {
                    try {
                        const jsonData = JSON.parse(trimmedLine);
                        entry.processed = true;
                        entry.jsonData = jsonData;

                        // Si es una tarjeta, agregar propiedades específicas
                        if (jsonData.type === 'card') {
                            entry.cardNumber = jsonData.cardNumber;
                            entry.facilityCode = jsonData.facilityCode;
                            entry.nfcId = `${jsonData.facilityCode}:${jsonData.cardNumber}`;
                        }
                    } catch (error) {
                        // No es un JSON válido, mantener como datos crudos
                        console.log("Error parsing JSON:", error);
                    }
                }

                // Agregar la entrada al estado
                setLogEntries(prev => [...prev, entry]);
            }
        }
    };

    // Escuchar datos del puerto serial
    useEffect(() => {
        if (!serialPort || !connected) return;

        let reader;
        let isMounted = true;

        const readFromSerial = async () => {
            try {
                reader = serialPort.readable.getReader();

                while (isMounted) {
                    const { value, done } = await reader.read();

                    if (done) break;

                    // Procesar los datos
                    processData(value);
                }
            } catch (error) {
                console.error("Error reading from serial:", error);
                addDebugLog(`Error en el logger: ${error.message}`, "error");
            } finally {
                if (reader) {
                    try {
                        reader.releaseLock();
                    } catch (e) {
                        console.error("Error releasing reader lock:", e);
                    }
                }
            }
        };

        readFromSerial();

        // Cleanup al desmontar
        return () => {
            isMounted = false;
            if (reader) {
                try {
                    reader.cancel();
                    reader.releaseLock();
                } catch (e) {
                    // Ignorar errores de cancelación
                }
            }
        };
    }, [serialPort, connected]);

    // Auto-scroll cuando se agregan nuevos logs
    useEffect(() => {
        if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logEntries]);

    // Limpiar registros
    const clearLogs = () => {
        setLogEntries([]);
        addDebugLog("Registros del logger limpiados", "info");
    };

    // Alternar registro
    const toggleLogging = () => {
        setIsLogging(prev => !prev);
        addDebugLog(`Registro ${!isLogging ? 'activado' : 'desactivado'}`, "info");
    };

    // Descargar logs como CSV
    const downloadCSV = () => {
        const headers = ["Timestamp", "FormattedTime", "NFCID", "FacilityCode", "CardNumber", "RawData"];

        const csvRows = logEntries.map(entry => {
            const row = [
                entry.timestamp.toISOString(),
                entry.formattedTime,
                entry.nfcId || "",
                entry.jsonData?.facilityCode || "",
                entry.jsonData?.cardNumber || "",
                JSON.stringify(entry.rawData).replace(/"/g, '""')
            ];
            return row.join(',');
        });

        const csvContent = [
            headers.join(','),
            ...csvRows
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Crear nombre de archivo con fecha y hora
        const now = new Date();
        const timestamp = now.toISOString().replace(/[:.]/g, '-').substring(0, 19);
        const eventName = selectedEvent || 'unknown-event';
        link.download = `rfid-logs-${eventName}-${timestamp}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        addDebugLog("Logs descargados como CSV", "success");
    };

    // Descargar logs como JSON
    const downloadJSON = () => {
        const jsonContent = JSON.stringify(logEntries, null, 2);
        const blob = new Blob([jsonContent], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Crear nombre de archivo con fecha y hora
        const now = new Date();
        const timestamp = now.toISOString().replace(/[:.]/g, '-').substring(0, 19);
        const eventName = selectedEvent || 'unknown-event';
        link.download = `rfid-logs-${eventName}-${timestamp}.json`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        addDebugLog("Logs descargados como JSON", "success");
    };

    // Filtrar los logs según el texto de búsqueda
    const filteredLogs = logEntries.filter(entry => {
        if (!filterText) return true;

        const searchText = filterText.toLowerCase();

        // Buscar en diferentes propiedades
        return (
            (entry.nfcId && entry.nfcId.toLowerCase().includes(searchText)) ||
            (entry.jsonData && JSON.stringify(entry.jsonData).toLowerCase().includes(searchText)) ||
            (entry.rawData && entry.rawData.toLowerCase().includes(searchText)) ||
            (entry.formattedTime && entry.formattedTime.toLowerCase().includes(searchText))
        );
    });

    return (
        <div className="border rounded-lg bg-white shadow-sm p-4">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-medium flex items-center">
                    <Database className="h-5 w-5 mr-2 text-blue-600" />
                    Registro de Capturas RFID
                </h2>
                <div className="flex space-x-2">
                    <button
                        onClick={toggleLogging}
                        className={`px-3 py-1 rounded text-sm font-medium flex items-center ${isLogging ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                            }`}
                    >
                        <Activity className="h-4 w-4 mr-1" />
                        {isLogging ? 'Registrando' : 'Pausado'}
                    </button>
                    <button
                        onClick={clearLogs}
                        className="bg-red-50 text-red-600 hover:bg-red-100 px-3 py-1 rounded text-sm font-medium flex items-center"
                        disabled={logEntries.length === 0}
                    >
                        <Trash className="h-4 w-4 mr-1" />
                        Limpiar
                    </button>
                    <button
                        onClick={downloadCSV}
                        className="bg-blue-50 text-blue-600 hover:bg-blue-100 px-3 py-1 rounded text-sm font-medium flex items-center"
                        disabled={logEntries.length === 0}
                    >
                        <Download className="h-4 w-4 mr-1" />
                        CSV
                    </button>
                    <button
                        onClick={downloadJSON}
                        className="bg-purple-50 text-purple-600 hover:bg-purple-100 px-3 py-1 rounded text-sm font-medium flex items-center"
                        disabled={logEntries.length === 0}
                    >
                        <Save className="h-4 w-4 mr-1" />
                        JSON
                    </button>
                </div>
            </div>

            {/* Controles de filtrado */}
            <div className="flex mb-3 items-center">
                <div className="relative flex-1">
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder="Buscar en registros..."
                        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    {filterText && (
                        <button
                            onClick={() => setFilterText('')}
                            className="absolute right-2 top-2 text-gray-400 hover:text-gray-600"
                        >
                            ×
                        </button>
                    )}
                </div>
                <div className="ml-3 flex items-center">
                    <label className="text-sm text-gray-600 flex items-center">
                        <input
                            type="checkbox"
                            checked={showRawData}
                            onChange={() => setShowRawData(!showRawData)}
                            className="mr-1"
                        />
                        Mostrar datos crudos
                    </label>
                </div>
                <div className="ml-3 text-sm text-gray-600">
                    {logEntries.length} registros totales | {filteredLogs.length} filtrados
                </div>
            </div>

            {/* Contenedor de logs */}
            <div
                ref={logContainerRef}
                className="border rounded-lg bg-gray-50 p-3 h-96 overflow-y-auto font-mono text-xs"
            >
                {filteredLogs.length === 0 ? (
                    <div className="text-center text-gray-500 py-10">
                        {logEntries.length === 0
                            ? `${connected ? 'No hay capturas de RFID. Esperando lecturas...' : 'Conecta el lector RFID para comenzar a capturar datos'}`
                            : 'No hay resultados para el filtro actual'}
                    </div>
                ) : (
                    <div className="space-y-1">
                        {filteredLogs.map((entry) => (
                            <div key={entry.id} className="p-2 border-b border-gray-200 hover:bg-gray-100 rounded">
                                <div className="flex items-center justify-between mb-1">
                                    <span className="text-gray-500 flex items-center">
                                        <Clock className="h-3 w-3 mr-1" />
                                        {entry.formattedTime}
                                    </span>

                                    {entry.processed && entry.jsonData?.type === 'card' && (
                                        <span className="bg-blue-100 text-blue-800 text-xs px-2 py-0.5 rounded-full">
                                            Tag RFID
                                        </span>
                                    )}
                                </div>

                                {entry.processed && entry.jsonData?.type === 'card' ? (
                                    <>
                                        <div className="grid grid-cols-2 gap-1 mb-1">
                                            <div>
                                                <span className="text-gray-600">NFCID:</span>{' '}
                                                <span className="text-blue-600 font-bold">{entry.nfcId}</span>
                                            </div>
                                            <div>
                                                <span className="text-gray-600">Facility:</span>{' '}
                                                <span>{entry.jsonData.facilityCode}</span>
                                            </div>
                                            <div>
                                                <span className="text-gray-600">Número:</span>{' '}
                                                <span>{entry.jsonData.cardNumber}</span>
                                            </div>
                                            <div>
                                                <span className="text-gray-600">Formato:</span>{' '}
                                                <span>{entry.jsonData.format || 'N/A'}</span>
                                            </div>
                                        </div>

                                        {showRawData && (
                                            <div className="text-xs mt-1 bg-gray-100 p-1 rounded overflow-x-auto">
                                                <code>{typeof entry.rawData === 'string' ? entry.rawData : JSON.stringify(entry.rawData)}</code>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="overflow-x-auto">
                                        <code>{typeof entry.rawData === 'string' ? entry.rawData : JSON.stringify(entry.rawData)}</code>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Panel de estadísticas y estado */}
            <div className="mt-3 grid grid-cols-4 gap-2 text-sm">
                <div className="bg-blue-50 p-2 rounded">
                    <div className="text-xs text-blue-700 mb-1">Total Capturas</div>
                    <div className="font-bold">{logEntries.length}</div>
                </div>
                <div className="bg-green-50 p-2 rounded">
                    <div className="text-xs text-green-700 mb-1">Tags Detectados</div>
                    <div className="font-bold">
                        {logEntries.filter(entry => entry.processed && entry.jsonData?.type === 'card').length}
                    </div>
                </div>
                <div className="bg-purple-50 p-2 rounded">
                    <div className="text-xs text-purple-700 mb-1">Estado</div>
                    <div className="font-medium">
                        {connected ? (isLogging ? 'Registrando' : 'Pausado') : 'Desconectado'}
                    </div>
                </div>
                <div className="bg-gray-50 p-2 rounded">
                    <div className="text-xs text-gray-700 mb-1">Evento</div>
                    <div className="font-medium overflow-hidden text-ellipsis">
                        {selectedEvent || 'No seleccionado'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RFIDLogger;