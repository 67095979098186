import React from "react";
import { Download, BarChart, Clock, MessageSquare, Bell, CheckCircle } from "lucide-react";

const ChatInfoPanel = ({ selectedChat, formatChatTime, messagesCount }) => {
    return (
        <div className="bg-white rounded-lg p-3 mb-4 border shadow-sm">
            <div className="flex flex-wrap items-center justify-between gap-2 mb-2">
                <h3 className="text-sm font-bold">Información del chat</h3>
                <div className="flex gap-2">
                    <button
                        className="text-xs bg-gray-100 px-2 py-1 rounded-md flex items-center text-gray-600 hover:bg-gray-200"
                        title="Exportar chat"
                    >
                        <Download size={12} className="mr-1" />
                        Exportar
                    </button>
                    <button
                        className="text-xs bg-gray-100 px-2 py-1 rounded-md flex items-center text-gray-600 hover:bg-gray-200"
                        title="Ver estadísticas"
                    >
                        <BarChart size={12} className="mr-1" />
                        Estadísticas
                    </button>
                </div>
            </div>
            <div className="flex flex-wrap gap-x-4 gap-y-1 text-xs text-gray-500">
                <div className="flex items-center">
                    <Clock size={12} className="mr-1" />
                    Creado: {formatChatTime(selectedChat?.createdAt)}
                </div>
                <div className="flex items-center">
                    <MessageSquare size={12} className="mr-1" />
                    Mensajes: {messagesCount}
                </div>
                <div className="flex items-center">
                    <Bell size={12} className="mr-1" />
                    Estado:
                    <span className="ml-1 text-green-600 flex items-center">
                        <CheckCircle size={10} className="mr-1" /> Activo
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ChatInfoPanel;