import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProfileStats from "./ProfileStats";
import ProfileBikes from "./ProfileBikes";
import ProfileRides from "./ProfileRides";
import RiderStats from "./RiderStats";
import { Bike, Route, BarChart3, Calendar, MapPin } from "lucide-react";

const Profile = () => {
  const { uid } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  // Handle scroll effect for tabs
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 180) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleTabChange = (index) => {
    setTabIndex(index);

    // Smooth scroll to content if tabs are sticky
    if (scrolled) {
      const contentElement = document.getElementById("profile-content");
      if (contentElement) {
        contentElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const tabs = [
    {
      index: 0,
      icon: <Bike className="w-5 h-5" />,
      label: "Bikes",
      component: <ProfileBikes uid={uid} />,
    },
    {
      index: 1,
      icon: <Route className="w-5 h-5" />,
      label: "Rides",
      component: <ProfileRides uid={uid} />,
    },
    {
      index: 2,
      icon: <BarChart3 className="w-5 h-5" />,
      label: "Stats",
      component: <RiderStats uid={uid} />,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Profile Info */}
      <div className="bg-white shadow-sm">
        <ProfileStats uid={uid} />
      </div>

      {/* Tabs Navigation */}
      <div
        className={`bg-white border-b border-gray-200 ${
          scrolled ? "sticky top-0 z-10 shadow-sm transition-shadow" : ""
        }`}
      >
        <div className="max-w-3xl mx-auto">
          <div className="flex">
            {tabs.map((tab) => (
              <TabButton
                key={tab.index}
                index={tab.index}
                tabIndex={tabIndex}
                onClick={handleTabChange}
                icon={tab.icon}
                label={tab.label}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Tab Content */}
      <div id="profile-content" className="max-w-3xl mx-auto px-4 py-6">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
          {tabs.map((tab) => (
            <CustomTabPanel key={tab.index} value={tabIndex} index={tab.index}>
              {tab.component}
            </CustomTabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};

const TabButton = ({ index, tabIndex, onClick, icon, label }) => (
  <button
    onClick={() => onClick(index)}
    className={`flex items-center justify-center py-3 px-4 flex-1 transition-all duration-200 relative ${
      tabIndex === index
        ? "text-black font-medium"
        : "text-gray-500 hover:text-black"
    }`}
  >
    <div className="flex flex-col items-center">
      {icon}
      <span className="text-xs mt-1">{label}</span>

      {/* Active indicator */}
      {tabIndex === index && (
        <div className="absolute bottom-0 left-0 w-full h-0.5 bg-black"></div>
      )}
    </div>
  </button>
);

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      className={`transition-opacity duration-300 ${
        value === index ? "opacity-100" : "opacity-0 h-0 overflow-hidden"
      }`}
    >
      {value === index && <div className="p-4">{children}</div>}
    </div>
  );
}

export default Profile;
