import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { useUserAuth } from "../context/userAuthContext";
import {
  Mail,
  Lock,
  ArrowLeft,
  AlertCircle,
  Loader2,
  LogIn,
  KeyRound,
  CheckCircle2,
  Eye,
  EyeOff,
  UserPlus,
} from "lucide-react";

const Login = () => {
  const { logIn } = useUserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Verificar si hay credenciales almacenadas
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Guardar email si "recuérdame" está activado
    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
    } else {
      localStorage.removeItem("rememberedEmail");
    }

    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err) {
      if (err.code === "auth/invalid-credential") {
        setError("Correo o contraseña incorrectos. Intenta nuevamente.");
      } else if (err.code === "auth/too-many-requests") {
        setError(
          "Demasiados intentos fallidos. Intenta más tarde o restablece tu contraseña."
        );
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/home");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setError("Inicio de sesión cancelado. Por favor intenta nuevamente.");
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      if (!email) {
        setError("Por favor ingresa tu correo electrónico.");
        setLoading(false);
        return;
      }
      await sendPasswordResetEmail(auth, email);
      setSuccess(
        "Se ha enviado un correo electrónico para restablecer tu contraseña."
      );
      setTimeout(() => {
        setShowResetForm(false);
      }, 3000);
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        setError("No existe una cuenta con este correo electrónico.");
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen w-full flex flex-col md:flex-row">
      {/* Banner con logo - OCULTO EN MÓVIL, VISIBLE EN DESKTOP */}
      <div className="hidden md:flex md:w-1/2 bg-gray-900 flex-col justify-center items-center p-8 md:p-12">
        <div className="max-w-lg mx-auto text-center">
          {/* Logo con animación */}
          <div className="mb-6 relative">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
              alt="LiveSpeed Logo"
              className="w-40 h-40 md:w-48 md:h-48 mx-auto object-contain animate-pulse"
              style={{ animationDuration: "3s" }}
            />
            <div className="absolute -inset-1 rounded-full bg-gray-800 -z-10 blur-md opacity-75"></div>
          </div>

          {/* Texto informativo - solo visible en desktop */}
          <h1 className="text-3xl font-bold text-white mb-3">LiveSpeed</h1>
          <p className="text-xl text-gray-300 mb-4">
            Tu aplicación para compartir experiencias de ciclismo y conectar con
            otros entusiastas.
          </p>
          <p className="text-lg text-gray-400">
            Regístrate y forma parte de la comunidad.
          </p>
        </div>
      </div>

      {/* Panel de login - ocupa toda la pantalla en móvil */}
      <div className="w-full md:w-1/2 bg-white flex flex-col flex-grow">
        {/* Eliminado el logo pequeño para móvil */}

        <div className="flex-1 overflow-y-auto p-6 pt-10 sm:p-8 md:p-10 lg:p-12">
          <div className="max-w-md mx-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                {showResetForm ? "Recupera tu cuenta" : "Bienvenido"}
              </h2>

              {!showResetForm && (
                <Link
                  to="/signup"
                  className="inline-flex items-center space-x-2 text-sm font-medium px-4 py-2 rounded-full border-2 border-black text-black hover:bg-black hover:text-white transition-colors duration-300"
                >
                  <UserPlus size={18} />
                  <span>Registrarse</span>
                </Link>
              )}
            </div>

            <p className="text-gray-500 text-sm mb-5">
              {showResetForm
                ? "Ingresa tu correo para recibir un enlace de recuperación"
                : "Ingresa tus datos para acceder a tu cuenta"}
            </p>

            {/* Mensajes de error o éxito */}
            {error && (
              <div className="flex items-center gap-2 p-3 bg-red-50 border-l-4 border-red-500 rounded-r-xl text-red-700 animate-fadeIn mb-4">
                <AlertCircle className="w-5 h-5 flex-shrink-0" />
                <p className="text-sm">{error}</p>
              </div>
            )}

            {success && (
              <div className="flex items-center gap-2 p-3 bg-green-50 border-l-4 border-green-500 rounded-r-xl text-green-700 animate-fadeIn mb-4">
                <CheckCircle2 className="w-5 h-5 flex-shrink-0" />
                <p className="text-sm">{success}</p>
              </div>
            )}

            {/* Formularios */}
            {!showResetForm ? (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700 block">
                    Correo Electrónico
                  </label>
                  <div className="relative group">
                    <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 group-focus-within:text-black transition-colors" />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-xl focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all"
                      placeholder="ejemplo@correo.com"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="text-sm font-medium text-gray-700 block">
                    Contraseña
                  </label>
                  <div className="relative group">
                    <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 group-focus-within:text-black transition-colors" />
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full pl-10 pr-12 py-2.5 border border-gray-200 rounded-xl focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all"
                      placeholder="••••••••"
                      required
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
                    >
                      {showPassword ? (
                        <EyeOff className="w-5 h-5" />
                      ) : (
                        <Eye className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-black border-gray-300 rounded focus:ring-black cursor-pointer"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-700 cursor-pointer"
                    >
                      Recordarme
                    </label>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowResetForm(true)}
                    className="text-sm text-gray-700 hover:text-black transition-colors font-medium"
                  >
                    ¿Olvidaste tu contraseña?
                  </button>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-3 bg-black text-white rounded-xl font-medium hover:bg-gray-800 transition-all disabled:opacity-70 disabled:cursor-not-allowed flex items-center justify-center gap-2 shadow-lg text-sm sm:text-base mt-2"
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      <span>Accediendo...</span>
                    </>
                  ) : (
                    <>
                      <LogIn className="w-5 h-5 mr-2" />
                      <span>Iniciar sesión</span>
                    </>
                  )}
                </button>

                <button
                  type="button"
                  onClick={handleGoogleSignIn}
                  disabled={loading}
                  className="w-full flex items-center justify-center py-3 px-4 bg-white border-2 border-gray-200 rounded-xl hover:bg-gray-50 hover:border-gray-300 transition-all disabled:opacity-70 disabled:cursor-not-allowed shadow-sm mt-3"
                >
                  <svg
                    className="w-5 h-5 sm:w-6 sm:h-6 mr-2 sm:mr-3"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                  </svg>
                  <span className="font-medium text-sm sm:text-base">
                    Continuar con Google
                  </span>
                </button>
              </form>
            ) : (
              <div className="space-y-5">
                <button
                  onClick={() => setShowResetForm(false)}
                  className="flex items-center text-sm text-gray-700 hover:text-black transition-colors"
                >
                  <ArrowLeft className="w-4 h-4 mr-1" />
                  Volver al inicio de sesión
                </button>

                <div className="space-y-4 mt-2">
                  <div className="space-y-1">
                    <label className="text-sm font-medium text-gray-700 block">
                      Correo Electrónico
                    </label>
                    <div className="relative group">
                      <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 group-focus-within:text-black transition-colors" />
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full pl-10 pr-4 py-2.5 border border-gray-200 rounded-xl focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all"
                        placeholder="ejemplo@correo.com"
                        required
                      />
                    </div>
                  </div>

                  <button
                    onClick={handleResetPassword}
                    disabled={loading}
                    className="w-full py-3 bg-black text-white rounded-xl font-medium hover:bg-gray-800 transition-all disabled:opacity-70 disabled:cursor-not-allowed flex items-center justify-center gap-2 shadow-lg text-sm sm:text-base mt-2"
                  >
                    {loading ? (
                      <>
                        <Loader2 className="w-5 h-5 animate-spin" />
                        <span>Enviando...</span>
                      </>
                    ) : (
                      <>
                        <KeyRound className="w-5 h-5 mr-2" />
                        <span>Recuperar contraseña</span>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Footer fijo en la parte inferior */}
        <div className="py-3 px-6 border-t border-gray-200 text-center text-sm text-gray-500">
          © {new Date().getFullYear()} LiveSpeed. Todos los derechos reservados.
        </div>
      </div>
    </div>
  );
};

export default Login;
