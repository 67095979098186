import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { useUserAuth } from "./context/userAuthContext";
import Notificaciones from "./Notificaciones";
import {
  Search,
  LayoutDashboard,
  QrCode,
  UserPlus,
  User,
  Palmtree,
  Users,
  Wrench,
  ShoppingCart,
  BarChart2,
  CalendarDays,
  Map,
  Video,
  LogOut,
  Bell,
  ShieldAlert,
  Menu,
  ChevronDown,
} from "lucide-react";

const NavbarComponent = () => {
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [userType, setUserType] = useState([]);
  const [friendRequestsCount, setFriendRequestsCount] = useState(0);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData) {
            setPhotoURL(userData.photoUrl || "");
            setUserType(userData.type || []);
            if (
              userData.friendRequests &&
              Array.isArray(userData.friendRequests)
            ) {
              setFriendRequestsCount(userData.friendRequests.length);
            } else {
              setFriendRequestsCount(0);
            }
          } else {
            setPhotoURL("");
            setUserType([]);
            setFriendRequestsCount(0);
          }
        } else {
          console.log("No such document!");
          setPhotoURL("");
          setUserType([]);
          setFriendRequestsCount(0);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const navItems = [
    { label: "Search", path: "/search", icon: <Search size={18} /> },
    { label: "Home", path: "/home", icon: <LayoutDashboard size={18} /> },
    { label: "Mis Tickets", path: "/tickets", icon: <QrCode size={18} /> },
    {
      label: "Solicitudes",
      path: "/friendsrequests",
      icon: <UserPlus size={18} />,
    },
    { label: "Perfil", path: "/Profile", icon: <User size={18} /> },
    { label: "Parques", path: "/parks", icon: <Palmtree size={18} /> },
    { label: "Equipos", path: "/teams", icon: <Users size={18} /> },
    { label: "Workshops", path: "/workshops", icon: <Wrench size={18} /> },
    {
      label: "Marketplace",
      path: "/marketplace",
      icon: <ShoppingCart size={18} />,
    },
    {
      label: "Leaderboards",
      path: "/Leaderboards",
      icon: <BarChart2 size={18} />,
    },
    { label: "Eventos", path: "/events", icon: <CalendarDays size={18} /> },
    { label: "Mapa en vivo", path: "/livemap", icon: <Map size={18} /> },
    {
      label: "Transmisiones en Vivo",
      path: "/live",
      icon: <Video size={18} />,
    },
  ];

  const restrictedNavItems = [
    { label: "Iniciar Sesión", path: "/login", icon: <User size={18} /> },
    { label: "Registro", path: "/signup", icon: <UserPlus size={18} /> },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/home");
      setIsMenuOpen(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white border-b border-slate-200 z-50">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/home" className="flex items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media&token=f30dbc3b-6932-486d-b992-3a705f04f0b5"
              alt="Logo"
              className="h-12 w-auto"
            />
          </Link>

          {/* Right side controls */}
          <div className="flex items-center space-x-2">
            {/* Admin button */}
            {userType && userType.includes("admin") && (
              <button
                onClick={() => navigate("home/geekdashboard")}
                className="flex items-center gap-1 px-3 py-1 bg-red-600 hover:bg-red-700 text-white text-xs font-medium rounded-md transition-colors"
              >
                <ShieldAlert size={14} />
                <span>Admin</span>
              </button>
            )}

            {/* Notifications */}
            <div className="relative">
              <button
                onClick={toggleNotifications}
                className="p-2 text-slate-600 hover:text-slate-900 hover:bg-slate-100 rounded-full transition-colors relative"
              >
                <Bell size={20} />
                {friendRequestsCount > 0 && (
                  <span className="absolute top-0 right-0 w-4 h-4 bg-red-500 text-white text-xs font-bold rounded-full flex items-center justify-center">
                    {friendRequestsCount > 9 ? "9+" : friendRequestsCount}
                  </span>
                )}
              </button>

              {/* Notifications dropdown */}
              {isNotificationsOpen && (
                <div className="absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-lg py-2 border border-slate-200 z-50">
                  <Notificaciones />
                </div>
              )}
            </div>

            {/* User menu */}
            <div className="relative">
              <button
                onClick={toggleMenu}
                className="flex items-center space-x-1 p-1 rounded-full hover:bg-slate-100"
              >
                <div className="w-8 h-8 rounded-full overflow-hidden bg-slate-200 flex items-center justify-center">
                  {photoURL ? (
                    <img
                      src={photoURL}
                      alt="Avatar"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <Menu size={16} className="text-slate-600" />
                  )}
                </div>
                <ChevronDown size={16} className="text-slate-600" />
              </button>

              {/* Menu dropdown */}
              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg overflow-hidden border border-slate-200 z-50">
                  <div className="max-h-[70vh] overflow-y-auto">
                    {(user ? navItems : restrictedNavItems).map((item) => (
                      <Link
                        key={item.path}
                        to={item.path}
                        className="flex items-center px-4 py-2 text-sm text-slate-700 hover:bg-slate-100"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <span className="mr-3 text-slate-500">{item.icon}</span>
                        {item.label}
                      </Link>
                    ))}

                    {user && (
                      <>
                        <div className="border-t border-slate-200 my-1"></div>
                        <button
                          onClick={handleLogout}
                          className="flex items-center w-full px-4 py-2 text-sm text-slate-700 hover:bg-slate-100"
                        >
                          <span className="mr-3 text-slate-500">
                            <LogOut size={18} />
                          </span>
                          Cerrar Sesión
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavbarComponent;
